import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './style.css';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';
import GlobalFuncs from 'utils/GlobalFuncs';
import Loader from 'components/Loader';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Swal from 'sweetalert2';

function Notifications() {

    const history = useHistory();
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState([]);
    const [marketing, setMarketing] = useState(null)

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        const cancelToken = API.cancelToken();
        API.isActive().then(none => {
            hotjar.initialize('3361828')
            ReactGA.pageview(window.location.pathname);
            setLoading(true);
            API.getPolicyInfo(cancelToken).then(res => {
                setInfo(res.data)
            }).catch(err => {
                if (err.message === 'cancelling') return
                console.log(err.response);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(sel => {
                    window.location.href = "/"
                })
            }).finally(f => {
                setLoading(false)
            });;
            API.getMarketing(cancelToken).then(res => {
                setMarketing(res.data[0].is_marketing_allowed)
            }).catch(err => {
                console.log(err.response);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(sel => {
                    window.location.href = "/"
                })
            })
        }).catch(err => {
            context.logOut()
        })
        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    useEffect(() => {
    }, [marketing])

    const handleChecked = (e) => {
        setMarketing(marketing => !marketing)
        let button = e.currentTarget
        button.disabled = true
        let tempMarketing = marketing
        const cancelToken = API.cancelToken();
        API.updateMarketing(cancelToken, !tempMarketing).then(res => {
            Swal.fire({
                title: 'Sucesso!',
                text: 'Dados atualizados com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(s => {
                button.disabled = false
            })
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.response);
            setMarketing(tempMarketing)
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(f => {
                button.disabled = false
            })
        })
    }

    return (
        <div className="section-wrapper-small notifications-page-wrapper page-wrapper-white one-whole margin-top-50">
            <div className="one-whole">
                <div className="breadcrumbs flex-row-nowrap align-center justify-center">
                <h4 className='margin-right-10'><Link to='/account' className='reset your-account'>Minha conta</Link></h4>
                    <svg className="svg-icon__arrow--right" viewBox="0 0 7 12" height="10px" width="10px" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#C3C4C5" fillRule="evenodd" d="M6.893 5.967c0-.268-.107-.524-.295-.713L1.724.37C1.33-.024.692-.024.3.37c-.394.394-.394 1.033 0 1.427l4.162 4.17L.3 10.137c-.394.394-.394 1.033 0 1.427.392.394 1.03.394 1.424 0L6.598 6.68c.188-.19.295-.445.295-.713z" />
                    </svg>
                    <p>Notificações</p>
                </div>
                <div className="main-section">
                    <div className="user-wrapper margin-bottom-20 margin-top-20 section-wrapper-xsmall">
                        {
                            loading ?
                                <div className="loader-wrapper">
                                    <Loader light={true} />
                                </div> :
                                <div className='info-section-wrapper'>
                                    <p className='margin-bottom-20'>Termos e Políticas</p>
                                    <div className='info-section'>
                                        <p className='light-text'>
                                            Para melhorar sua experiência no AZPartes.com, você compartilha alguns dados com a gente?
                                        </p>
                                        <p className='light-text margin-bottom-30'>
                                            Enviamos nossas melhores ofertas e comunicações por e-mail, configure abaixo se deseja receber essas notificações.
                                        </p>
                                        {
                                            info && info.map((e, index) => (
                                                <label className="checkbox-wrapper" key={'policy' + index}>
                                                    <p>
                                                        <Link
                                                            className='reset2'
                                                            to={e.name.toLowerCase().includes("uso") ? "/terms" : (e.name.toLowerCase().includes("privacidade") ? "/privacy" : e.name.toLowerCase().includes("troca") ? "/exchange" : '')}>
                                                            {e.name}
                                                        </Link>
                                                        &nbsp; (Aceito na data {GlobalFuncs.databaseToDate(e.created_at.split()[0])})
                                                    </p>
                                                    <input className="checkbox" type="checkbox" checked={true} disabled={true} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            ))
                                        }
                                        {
                                            marketing !== null &&
                                            <label className="checkbox-wrapper">
                                                <p>Gostaria de receber notificações do AZPartes via e-mail?</p>
                                                <input className="checkbox" type="checkbox" checked={marketing} onChange={e => handleChecked(e)} />
                                                <span className="checkmark"></span>
                                            </label>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Notifications;