import './style.css';
import { useEffect, useState, useContext, useRef } from 'react';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';

function Terms() {
    const context = useContext(AppContext);
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        API.isActive().then(none => {
        }).catch(err => {
            context.logOut()
        });
    }, [])

    return (
        <div className="terms-page margin-top-40 margin-bottom-40 section-wrapper">
            <h3>TERMOS DE USO</h3>
            <br />
            <p>
                Esses termos de uso (os "Termos") são celebrados entre você, em nome de você mesmo e a empresa
                em nome da qual você está usando este e-commerce (coletivamente, "você") e a SKF do Brasil Ltda.
                ("SKF" ou "nós") e governam seu acesso e uso deste e-commerce (o "E-commerce"), incluindo qualquer
                conteúdo, funcionalidade e serviços oferecidos no ou por meio do E-commerce, seja como convidado ou
                como usuário registrado. Por favor, leia estes Termos cuidadosamente antes de usar o E-commerce. Não
                use o E-commerce se você não aceitar os termos.
            </p><br/>
            <p>
                Ao acessar, visualizar ou usar o conteúdo, material, produtos ou serviços disponíveis no ou por meio do
                E-commerce, você certifica que leu, entendeu e concorda em ser legalmente vinculado, tanto em seu nome
                como em nome da empresa que você representa, por estes termos e política de privacidade da SKF, que
                está incorporada a estes Termos por referência como se fossem completamente apresentadas aqui. Você
                declara e garante que possui toda a autoridade necessária para vincular a empresa que representa a estes
                Termos. Você também certifica que atingiu a maioridade na jurisdição em que reside, ou maior, ou que
                possui a permissão de um tutor legal (por exemplo, um pai ou mãe) para usar o E-commerce. Você entende
                e concorda que o uso do E-commerce ou de quaisquer serviços disponibilizados no ou por meio do Ecommerce significa que você aceita e concorda totalmente com estes Termos. Se você não concordar com
                estes Termos, não terá permissão para usar o E-commerce e deverá sair e interromper seu uso
                imediatamente.
            </p><br/>
            <p>
                Se você tiver alguma dúvida sobre esses Termos ou desejar exercer seus direitos conforme descrito
                abaixo, entre em contato com carisma@skf.com
            </p><br/>
            <h4>
                1. Cadastro
            </h4>
            <p>
                Para acessar determinados serviços ou recursos disponibilizados pelo E-commerce por meio do site
                https://www.compreskf.com.br/ (todos esses serviços, os "Serviços"), pode ser solicitado que você se
                registre e crie uma conta. Como parte do processo de registro, pode ser solicitado que você clique que
                concorda com estes Termos e, em seguida, que selecione ou envie um nome de usuário e senha. Também
                pode ser solicitado que você forneça certas informações pessoais à SKF, incluindo alguns tipos de
                informações de identificação pessoal, como nome legal, número de telefone, endereço físico e endereço
                de e-mail, além de informações relacionadas ao seu empregador ou à empresa em nome de quem você
                está usando o E-commerce. Você declara que as informações pessoais que você fornece à SKF por meio
                do E-commerce são verdadeiras, válidas, completas e atualizadas em todos os aspectos. Se alguma
                informação fornecida no E-commerce mudar, faça login na sua conta e atualize essas informações
                diretamente no E-commerce. Quaisquer informações pessoais que você forneça à SKF por meio do Ecommerce estão sujeitas à Política de Privacidade da SKF.
            </p><br/>
            <p>
                Você é responsável por garantir que a senha e o login da conta sejam mantidos em sigilo e protegidos o
                tempo todo. Você é o único responsável por toda e qualquer atividade que ocorra na sua conta. A SKF
                não se responsabiliza por qualquer uso indevido da sua conta, incluindo, sem limitação, caso um terceiro
                tenha acesso e use sua senha e login da conta de qualquer forma. Caso seu nome de usuário ou login da
                conta sejam usados sem o seu consentimento ou você descubra qualquer outra violação de segurança,
                você concorda em nos notificar imediatamente.
                A SKF tem o direito de desativar qualquer nome de usuário, login da conta, senha ou outro identificador,
                escolhido por você ou fornecido por nós, a qualquer momento, a nosso critério, por qualquer ou sem
                motivo, inclusive se, em nossa opinião, você tenha violado qualquer disposição destes Termos.
            </p><br/>
            <h4>
                2. Restrições Gerais de Uso
            </h4>
            <p>
                Você concorda em usar o E-commerce e os Serviços apenas para fins permitidos por estes Termos, em
                conformidade com todas as leis, regulamentos e práticas e diretrizes geralmente aceitas nas jurisdições
                relevantes. Você só pode usar o E-commerce e os Serviços conforme autorizado nestes Termos e para
                outros fins.
            </p><br/>
            <p>
                Você concorda em não usar o E-commerce:
            </p><br/>
            <p>
                • De qualquer forma que viole qualquer lei ou regulamento federal, estadual, provincial/territorial,
                local ou internacional aplicável (incluindo, sem limitação, quaisquer leis relativas à exportação de
                dados ou software);
            </p><br/>
            <p>
                • Para personificar ou tentar personificar a SKF, um funcionário da SKF, outro usuário ou qualquer
                outra pessoa ou entidade (incluindo, sem limitação, usando endereços de e-mail ou nomes de tela
                ou conta associados a qualquer um dos itens anteriores); e
            </p><br/>
            <p>
                • Para se envolver em qualquer outra conduta que restrinja ou iniba o uso ou o aproveitamento de
                alguém do E-commerce, ou que, conforme determinado por nós, possa prejudicar a SKF ou os
                usuários do E-commerce ou expô-los a responsabilidades, ou que possa violar estes Termos.
                Sem limitar o acima exposto, você também concorda em não:
            </p><br/>
            <p>
                • Usar o E-commerce de qualquer maneira que possa desativar, sobrecarregar, danificar ou
                prejudicar o E-commerce ou qualquer sistema da SKF ou interferir no uso do E-commerce por
                qualquer outra parte, incluindo a capacidade de se envolver em atividades em tempo real por meio
                do E-commerce;
            </p><br/>
            <p>
                • Usar qualquer bot, crawler, spider, raspador ou outro dispositivo, processo ou meio automático
                para acessar o E-commerce ou qualquer informação transferida através do E-commerce, para
                qualquer finalidade, incluindo monitoramento, cópia ou transferência de qualquer conteúdo do Ecommerce;
            </p><br/>
            <p>
                • Usar qualquer processo manual para monitorar, copiar ou transferir qualquer conteúdo do Ecommerce ou qualquer informação transferida através do E-commerce ou para qualquer outro
                objetivo não autorizado sem o nosso consentimento prévio por escrito;
            </p><br/>
            <p>
                • Reproduzir, duplicar, copiar, vender, comercializar ou revender qualquer aspecto do E-commerce
                ou dos Serviços para qualquer fim, incluindo fins comerciais;
            </p><br/>
            <p>
                • Reproduzir, duplicar, copiar, vender, comercializar ou revender quaisquer produtos ou serviços
                que ostentem qualquer marca comercial, marca de serviço, nome comercial, logotipo ou outro
                significante de propriedade da SKF de uma maneira provável ou com a intenção de causar
                confusão sobre o proprietário ou usuário autorizado dos materiais;
            </p><br/>
            <p>
                • Usar qualquer dispositivo, software ou rotina que interfira no bom funcionamento do E-commerce;
            </p><br/>
            <p>
                • Introduzir vírus, cavalos de Troia, worms, bombas lógicas ou outro material malicioso ou
                tecnologicamente prejudicial;
            </p><br/>
            <p>
                • Tentar obter acesso não autorizado, interferir, danificar ou interromper qualquer parte do Ecommerce, incluindo, sem limitação, qualquer informação transferida por meio do E-commerce, o
                servidor no qual o E-commerce está armazenado ou qualquer servidor, computador ou banco de
                dados conectado ao E-commerce;
            </p><br/>
            <p>
                • Atacar o E-commerce por meio de um ataque de negação de serviço ou um ataque distribuído de
                negação de serviço; ou
            </p><br/>
            <p>
                • De outra forma tentar interferir com o bom funcionamento do E-commerce.
            </p><br/>
            <h4>
                3. Propriedade Intelectual
            </h4>
            <p>
            Entre você e a SKF, a SKF possui todas as patentes, direitos autorais, marca registrada, segredo
            comercial, ideias, conceitos, know-how, documentação ou técnicas ou outros direitos de propriedade
            intelectual que possam existir sobre os (i) Serviços, o E-commerce, quaisquer gráficos, textos, ícones,
            botões, dados ou informações que incluímos no E-commerce e quaisquer produtos, materiais de
            treinamento, entregas e a rede ou bancos de dados da SKF que possam ser utilizados para fornecer os
            serviços (“Conteúdo do E-commerce”), e (ii) qualquer informações, dados, tendências, análises,
            metadados ou outros dados que possam ser derivados de qualquer um dos itens anteriores derivados ou
            criados pela SKF por referência aos serviços e aos bancos de dados e rede da SKF.Todo o conteúdo do
            E-commerce é protegido de acordo com direitos autorais, marca comercial, patente e outras leis aplicáveis.
            Você não deve remover ou alterar nenhum aviso de direitos autorais ou qualquer outro aviso de
            propriedade sobre o E-commerce ou qualquer conteúdo do E-commerce.Todos os nomes, marcas
            comerciais, símbolos, slogans ou logotipos que aparecem no E-commerce são de propriedade da SKF ou
            de seus licenciadores ou fornecedores.O uso ou uso indevido dessas marcas comerciais é expressamente
            proibido e pode violar a lei federal e estadual das marcas comerciais.Sob nenhuma circunstância você
            terá quaisquer direitos de qualquer espécie no ou para o Conteúdo do E-commerce, exceto o direito de
            usar o Conteúdo do E-commerce de acordo com estes Termos.
            </p><br/>
            <p>
            Certos recursos do E-commerce podem permitir que você contribua com texto, imagens, dados e outras
            informações e materiais do E-commerce para acesso, uso, visualização e comentários de outros usuários
            do E-commerce e/ou da SKF (coletivamente, “Conteúdo do Usuário”).Ao publicar o Conteúdo do usuário
            no E-commerce ou enviar o Conteúdo do usuário à SKF, você declara que tem o direito legal total de
            fornecer o Conteúdo do usuário e que o uso do Conteúdo do usuário pela SFK e todas as outras pessoas
            e entidades sobre o E-commerce e/ou de acordo com esta seção, não irá: (a) violar quaisquer direitos de
            propriedade intelectual de qualquer pessoa ou entidade ou quaisquer direitos de publicidade,
            personalidade ou privacidade de qualquer pessoa ou entidade, inclusive devido à sua falha em obter
            consentimento publicar informações de identificação pessoal ou privadas sobre uma pessoa; (b) violar
            qualquer lei, estatuto, portaria, regulamento ou acordo, ou promover ou fornecer informações instrutivas
            sobre atividades ilegais, promover danos físicos ou ferimentos contra qualquer grupo ou indivíduo, ou
            promover qualquer ato de crueldade com animais, incluindo instruções sobre como montar bombas,
            granadas e outras armas ou como usar ou obter drogas ilegais; (c) ser difamatório ou calunioso, ameaçar
            ilegalmente ou assediar ilegalmente ou promover discriminação com base em raça, sexo, religião,
            nacionalidade, deficiência, orientação sexual ou idade; (d) personificar qualquer pessoa ou entidade ou
            declarar falsamente ou implicar uma afiliação a uma pessoa ou entidade, ou incluir quaisquer
            representações falsas, compostas ou não autênticas de eventos, locais, marcos, entidades ou pessoas;
            (e) conter ou transmitir qualquer material que contenha vírus de software ou qualquer outro código, arquivo
            ou programa de computador que possa interromper, destruir ou limitar a funcionalidade de qualquer
            software ou hardware ou equipamento de telecomunicações; (f) ser obsceno, pornográfico infantil ou
            indecente; (g) violar qualquer padrão da comunidade ou da Internet; (h) constituir apropriação indébita de
            qualquer segredo comercial ou know-how; ou (i) constituir a divulgação de qualquer informação
            confidencial de propriedade de terceiros, conforme determinado pela SKF, a nosso exclusivo e absoluto
            critério.Após o envio do Conteúdo do usuário ou de outro material ou informação à SKF, você concede à
            SKF uma licença internacional, perpétua, irrevogável, transferível, para acessar, usar, distribuir, reproduzir,
            exibir, modificar, modificar, traduzir, criar trabalhos derivados baseados e sublicenciados Conteúdo do
            Usuário, sem que você tenha direito a qualquer compensação.Além disso, você renuncia a todos os
            direitos morais e a todo o Conteúdo do Usuário que você publica ou envia à SKF em favor da SKF e de
            qualquer outra pessoa autorizada a usar esse Conteúdo do Usuário.Para evitar dúvidas, a SKF não tem
            nenhuma obrigação de: (1) manter qualquer Conteúdo do Usuário em sigilo; (2) compensar você de
            qualquer forma por seu Conteúdo do Usuário; ou (3) responder por qualquer Conteúdo do Usuário.
            Alguns serviços, incluindo serviços de confiabilidade executados pela SKF, podem exigir a coleta de dados
            do seu equipamento relevantes para os serviços de confiabilidade e manutenção preditiva ("Informações
            de Ativos") que podem ser inseridos e armazenados nos bancos de dados da SKF. Você reconhece e
            concorda que a SKF terá o direito de reter essas informações sobre ativos e usá-las conforme descrito na
            Política de Privacidade da SKF, inclusive para fornecer a você os Serviços por meio do E-commerce.
            Você reconhece que a SKF fornece serviços profissionais para outras partes e concorda que nada neste
            documento será considerado ou interpretado para impedir a SKF de executar tais serviços,
            independentemente de tais partes serem competitivas com você.A SKF terá o direito de usar técnicas,
            metodologias, ferramentas, ideias e outros conhecimentos adquiridos durante o curso de fornecimento dos
            Serviços e do E-commerce para promover seus próprios negócios e aperfeiçoar todos os outros direitos
            de propriedade intelectual relacionados a eles, incluindo patentes, direitos autorais, marca registrada e
            segredos comerciais.
            </p><br/>
            <p>
            Nenhum direito, título ou interesse em ou ao E-commerce ou em qualquer conteúdo do E-commerce é
            transferido para você e todos os direitos não concedidos expressamente neste documento são reservados
            à SKF.Qualquer uso do E-commerce que não seja expressamente permitido por estes Termos é uma
            violação destes Termos e pode violar patentes, direitos autorais, marcas comerciais e outras leis.
            </p><br/>
            <h4>
            4. Monitoramento e Execução; Rescisão
            </h4>
            <p>
            A SKF tem o direito de: (a) tomar as ações legais apropriadas, incluindo, sem limitação, encaminhamento
            para a aplicação da lei, por qualquer uso ilegal ou não autorizado do E-commerce; e (b) encerrar ou
            suspender seu acesso a todo ou parte do E-commerce por qualquer ou nenhum motivo, incluindo, sem
            limitação, qualquer violação ou suspeita de violação destes Termos.Sem limitar o exposto, a SKF tem o
            direito de cooperar totalmente com quaisquer autoridades policiais ou ordem judicial solicitando ou
            instruindo-nos a divulgar a identidade ou outras informações de qualquer pessoa que forneça informações
            no ou por meio do E-commerce.Você renuncia e isentará a SKF e suas afiliadas, licenciadas e provedores
            de serviços de quaisquer reivindicações resultantes de qualquer ação da SKF ou de qualquer das partes
            acima mencionadas durante ou como resultado de suas investigações e de quaisquer ações tomadas
            como consequência de investigações de tais partes ou autoridades policiais.
            </p><br/>
            <h4>
            5. Isenções de Responsabilidade e Garantias
            </h4>
            <p>
            NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, A SKF NÃO FAZ NENHUMA PROMESSA,
            DECLARAÇÃO OU GARANTIA, EXPRESSA OU IMPLÍCITA, COM RELAÇÃO À SUFICIÊNCIA DO ECOMMERCE E DOS SERVIÇOS A SEREM FORNECIDOS NO ÂMBITO DESTE PELA SKF, INCLUINDO
            QUALQUER GARANTIA CONTRA EVENTUAL DEFEITO OU INOPERABILIDADE DO SEU
            EQUIPAMENTO, MESMO POR RAZÕES QUE POSSAM ESTAR RELACIONADAS AO DESEMPENHO
            DOS ROLAMENTOS, OU QUE O E-COMMERCE, OS SERVIÇOS OU O EQUIPAMENTO OU
            QUALQUER OU TODAS AS DEFICIÊNCIAS, ERROS, DEFEITOS OU NÃO-CONFORMIDADES NELE
            SERÃO CORRIGIDOS PELA SKF.
            </p><br/>
            <p>
            As informações apresentadas no ou por meio do E-commerce, incluindo todas as informações fornecidas
            a você, solicitadas por meio do E-commerce (como relatórios solicitados via ou com base nas informações
            coletadas por meio do E-commerce), são disponibilizadas para fins de informações gerais.Embora a SKF
            se esforce para fornecer informações que julga precisas com base em informações e dados fornecidos por
            você, a SKF não pode e não garante a precisão, integridade ou utilidade dessas informações.O Ecommerce e os serviços não pretendem substituir os serviços profissionais de monitoramento, diagnóstico,
            reparo e/ou substituição.Sempre procure o conselho de um profissional treinado, familiarizado com os
            detalhes específicos e o contexto de seu uso de qualquer equipamento, com qualquer dúvida que possa
            ter em relação ao seu equipamento.Você concorda que, sob nenhuma circunstância, desconsiderará
            qualquer conselho profissional ou atraso na busca de tais conselhos, com base em qualquer informação
            fornecida a você no ou por meio do E-commerce, incluindo qualquer informação fornecida a você que seja
            solicitada ou recebida por meio do E-commerce (como relatórios solicitados via ou com base nas
            informações coletadas pelo E-commerce).
            </p><br/>
            <p>
            Qualquer confiança que você depositar nas informações apresentadas a você no ou por meio do Ecommerce, incluindo qualquer informação fornecida a você que seja solicitada ou recebida por meio do Ecommerce (como relatórios solicitados por meio ou com base nas informações coletadas por meio do Ecommerce), é estritamente por sua própria conta em risco.Negamos toda a responsabilidade decorrente
            de qualquer confiança depositada em tais materiais por você ou por qualquer outro visitante do Ecommerce ou por qualquer pessoa que possa ser informada de qualquer conteúdo.
            </p><br/>
            <p>
            O E-commerce não tolera falhas e não foi projetado, fabricado ou designado para uso em ambientes
            perigosos que exigem desempenho à prova de falhas, no qual a falha do E-commerce possa levar
            diretamente à morte, ferimentos pessoais ou danos físicos, equipamentos ou ambientais graves; e a SKF
            se isenta especificamente de qualquer garantia expressa ou implícita de adequação a esses fins.
            Você reconhece e concorda que todas e quaisquer representações, promessas, garantias ou declarações
            do vendedor, representante ou agente da SKF que difiram de alguma forma dos termos aqui contidos não
            terão força ou efeito.Se a SKF fornecer a você alguma sugestão ou assistência referente a qualquer
            produto, sistema ou trabalho que não seja exigido de acordo com estes Termos, o fornecimento de tal
            sugestão ou assistência não sujeitará a SKF a qualquer responsabilidade por você, seja por contrato ou
            ato ilícito (incluindo negligência), sob garantia ou não.
            </p><br/>
            <p>
            O USO DO E-COMMERCE É FEITO POR SEU PRÓPRIO RISCO.O E-COMMERCE É FORNECIDO "NO
            ESTADO EM QUE SE ENCONTRA" E "COM TODAS AS FALHAS", SEM GARANTIAS DE QUALQUER
            TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, ENTRE OUTRAS, GARANTIAS DE
            COMERCIALIZAÇÃO, NÃO VIOLAÇÃO E ADEQUAÇÃO A FINALIDADES ESPECÍFICAS.NEM A SKF
            NEM QUALQUER PESSOA ASSOCIADA À SKF OFERECE QUALQUER GARANTIA OU
            REPRESENTAÇÃO COM RELAÇÃO À INTEGRIDADE, SEGURANÇA, CONFIABILIDADE, QUALIDADE,
            PRECISÃO OU DISPONIBILIDADE DOS SERVIÇOS, DO E-COMMERCE, DO CONTEÚDO DO ECOMMERCE OU DAS INFORMAÇÕES NELE CONTIDAS.SEM LIMITAR O ACIMA EXPOSTO, NEM A
            SKF NEM QUALQUER PESSOA ASSOCIADA À SKF REPRESENTA OU GARANTE QUE O ECOMMERCE OU SEU CONTEÚDO SERÁ PRECISO, CONFIÁVEL, LIVRE DE ERROS OU
            ININTERRUPTO, QUE OS DEFEITOS SERÃO CORRIGIDOS, QUE O E-COMMERCE OU SERVIÇOS
            QUE DISPONIBILIZAM O E-COMMERCE SÃO LIVRE DE VÍRUS OU OUTROS COMPONENTES
            PREJUDICIAIS OU QUE O E-COMMERCE OU QUAISQUER SERVIÇOS OBTIDOS POR MEIO DO ECOMMERCE ATENDAM ÀS SUAS NECESSIDADES OU EXPECTATIVAS.VOCÊ RECONHECE E
            CONCORDA QUE TODO RISCO DECORRENTE DO USO DO E-COMMERCE PERMANECE
            EXCLUSIVAMENTE COM VOCÊ, DENTRO DO ALCANCE MÁXIMO DA LEI.
            COMENTÁRIOS OU OPINIÕES EXPRESSOS NO E-COMMERCE OU NO CONTEÚDO DO ECOMMERCE SÃO EXCLUSIVAMENTE DE SEUS RESPECTIVOS USUÁRIOS.AS OPINIÕES
            EXPRESSAS NO E-COMMERCE OU NO CONTEÚDO DO E-COMMERCE E NO CONTEÚDO DO
            USUÁRIO NÃO REPRESENTAM OU REFLETEM NECESSARIAMENTE AS OPINIÕES DA SKF.A SKF
            NÃO É RESPONSÁVEL POR, E SE EXIME DE TODA E QUALQUER RESPONSABILIDADE
            RELACIONADA A CONTEÚDO DO USUÁRIO PUBLICADO, UPLOAD OU ENVIADO PARA O ECOMMERCE OU POR MEIO DELE.
            QUAISQUER PRODUTOS E/OU SERVIÇOS DESCRITOS NO E-COMMERCE SÃO OFERTADOS EM
            JURISDIÇÕES ONDE POSSAM SER LEGALMENTE OFERTADOS.AS INFORMAÇÕES NO ECOMMERCE NÃO SÃO UMA OFERTA OU PEDIDO DE ALGUÉM DE QUALQUER JURISDIÇÃO NA
            QUAL SE POSSA FAZER UMA OFERTA OU PEDIDO LEGALMENTE OU PARA QUALQUER PESSOA
            A QUEM SEJA ILEGAL FAZER UM PEDIDO.
            </p><br/>
            <h4>
            6. Limitação de Responsabilidade
            </h4>
            <p>
            EM NENHUM CASO A SKF SERÁ RESPONSÁVEL POR OUTROS DANOS QUE NÃO SEJAM DANOS
            REAIS E DIRETOS COMPROVADOS EM UM TRIBUNAL OU POR MEIO DE ARBITRAGEM.EM
            NENHUM CASO A RESPONSABILIDADE DA SKF EXCEDERÁ O PREÇO QUE VOCÊ PAGOU PELO
            SERVIÇO SUJEITO À REIVINDICAÇÃO.SE QUALQUER PARTE DESTA LIMITAÇÃO DE
            RESPONSABILIDADE FOR CONSIDERADA INVÁLIDA OU INEXEQUÍVEL POR QUALQUER MOTIVO,
            A RESPONSABILIDADE TOTAL DA SKF E DE SEUS LICENCIADORES NÃO DEVERÃO EXCEDER DEZ
            DÓLARES AMERICANOS.
            </p><br/>
            <p>
            Sem limitar o exposto, você entende e reconhece que a SKF não será responsável por:
            </p><br/>
            <p>
            a) Quaisquer perdas indiretas, incidentais, consequenciais, punitivas ou exemplares que possam ser
            incorridas por você devido ao uso ou da incapacidade de usar o E-commerce ou os Serviços,
            incluindo quaisquer lucros cessantes (seja direta ou indiretamente), qualquer perda de fundo de
            comércio ou reputação comercial, ou qualquer perda de dados sofrida por você; ou
            </p><br/>
            <p>
            b) Qualquer perda ou dano incorrido por você em decorrência de: (1) qualquer confiança depositada
            por você na integridade, precisão ou existência de qualquer informação ou material disponibilizado
            por meio do E-commerce, incluindo qualquer publicidade; (2) qualquer relacionamento ou
            transação entre você e qualquer anunciante ou patrocinador cuja publicidade apareça no Ecommerce ou nos Serviços; (3) quaisquer alterações que a SKF possa fazer no E-commerce ou
            Serviços, ou por qualquer interrupção permanente ou temporária no fornecimento do E-commerce
            ou Serviços (ou qualquer recurso do E-commerce ou Serviços); (4) a exclusão de, corrupção de,
            acesso não autorizado a ou falha em armazenar qualquer conteúdo e outros dados de
            comunicação mantidos ou transmitidos pelo ou por meio do uso do E-commerce ou dos Serviços;
            (5) o uso de quaisquer produtos ou serviços obtidos no ou por meio do E-commerce; ou (6)
            qualquer outro assunto relacionado ao E-commerce, aos Serviços, ao Conteúdo do E-commerce
            ou ao Conteúdo do Usuário.
            </p><br/>
            <p>
            As limitações de responsabilidade da SKF em relação a você nesta Seção 6 aplicam-se
            independentemente do conhecimento da SKF sobre a possibilidade de tais perdas ocorrerem.
            </p><br/>
            <p>
            SE VOCÊ NÃO ESTIVER SATISFEITO COM O E-COMMERCE, COM OS SERVIÇOS OU COM ESTES
            TERMOS, SEU ÚNICO E EXCLUSIVO RECURSO É INTERROMPER O USO DO E-COMMERCE.
            </p><br/>
            <h4>
            7. Indenização
            </h4>
            <p>
            Você concorda em defender, indenizar e isentar a SKF, suas controladoras, subsidiárias, executivos,
            diretores, acionistas, membros, funcionários, agentes, afiliados, licenciadores e fornecedores, de e contra
            todas as reivindicações, danos, obrigações, perdas, responsabilidades, custos ou dívida e despesas
            (incluindo, sem limitação, honorários advocatícios) decorrentes de: (a) seu uso e acesso ao E-commerce
            e Serviços; (b) sua violação de qualquer um desses Termos, incluindo a Política de Privacidade; (c) sua
            violação de quaisquer direitos de terceiros, incluindo propriedade intelectual ou direitos de privacidade; e
            (d) o uso do E-commerce por qualquer pessoa que use sua conta ou o login da conta.Esta obrigação de
            defesa e indenização sobreviverá ao término ou cessação destes Termos e ao uso do E-commerce.A
            SKF se reserva o direito, às custas da SKF, de assumir a defesa e o controle exclusivos de qualquer
            assunto sujeito a indenização por você nos termos deste documento, e você deverá cooperar em todos os
            aspectos razoáveis em tal defesa.Você não pode resolver nenhuma reclamação mencionada ou coberta
            por esta Seção 7 sem o consentimento prévio por escrito da SKF.
            </p><br/>
            <h4>
            8. Arbitragem
            </h4>
            <p>
            Você e a SKF concordam que quaisquer controvérsias decorrentes ou relacionadas a estes Termos ou ao
            uso do E-commerce e/ou serviços, incluindo controvérsias decorrentes ou relacionadas à interpretação,
            violação, invalidez, incumprimento ou rescisão, que não possam ser resolvidas informalmente, serão
            apresentadas à arbitragem final e vinculativa perante um (1) árbitro; desde que, no entanto, caso a disputa
            envolva uma alegação de responsabilidade superior a $250.000, serão utilizados três (3) árbitros.Qualquer
            controvérsia decorrente destes Termos deverá ser resolvida exclusivamente por meio de arbitragem final
            e vinculativa conduzida pela American Arbitration Association (a "AAA") de acordo com suas Regras de
            Arbitragem Comercial.A arbitragem emprega um árbitro neutro em vez de um juiz ou júri, e a revisão
            judicial de uma sentença arbitral é muito limitada.No entanto, um árbitro pode deferir as mesmas
            indenizações e medidas individuais que um tribunal pode conceder a um indivíduo; e um árbitro também
            deve seguir e fazer cumprir estes Termos, como um tribunal.Todas as questões cabem ao árbitro decidir,
            exceto que as questões relacionadas à arbitrabilidade e ao escopo ou aplicabilidade deste contrato a ser
            arbitrado caberão a um tribunal de jurisdição competente.A arbitragem será realizada na Filadélfia,
            Pensilvânia, a menos que a SKF decida de outra forma.
            </p><br/>
            <p>
            O árbitro decidirá o embasamento de todas as reivindicações de acordo com as leis da Comunidade da
            Pensilvânia.O árbitro não ficará vinculado a decisões em arbitragens anteriores envolvendo diferentes
            usuários da SKF, mas estará vinculado a decisões em arbitragens anteriores envolvendo o mesmo usuário
            da SKF na extensão exigida pela lei aplicável.A decisão do árbitro será final e vinculativa, e a decisão
            sobre a decisão proferida pelo árbitro poderá ser proferida em qualquer tribunal com jurisdição.Esta
            cláusula não impedirá que as partes busquem recursos provisórios em auxílio à arbitragem em um tribunal
            de jurisdição apropriada.
            </p><br/>
            <p>
            Você reconhece e concorda que você e a SKF estão renunciando ao direito a um julgamento por júri.Você
            também reconhece e concorda que renuncia ao seu direito de participar como autor ou membro da classe
            em qualquer ação coletiva ou processo representativo.Além disso, a menos que você e a SKF concordem
            por escrito, o árbitro não poderá consolidar mais do que as reivindicações de uma pessoa e não poderá
            presidir qualquer forma de qualquer processo de classe ou representante.
            </p><br/>
            <p>
            Caso esta Seção 8 seja considerada inaplicável, a totalidade desta Seção 8 será considerada nula.Exceto
            conforme previsto na frase anterior, esta Seção 8 sobreviverá ao término dos Termos e ao uso do Ecommerce.
            Não obstante o acima exposto, cada parte mantém o direito de obter uma medida cautelar ou outra em um
            tribunal de jurisdição competente para impedir a violação, a apropriação ou a violação real ou ameaçada
            dos direitos autorais, marcas comerciais, segredos comerciais, patentes ou outros direitos de propriedade
            intelectual de uma parte.
            </p><br/>
            <h4>
            9. Lei Aplicável e Jurisdição
            </h4>
            <p>
            Todos os assuntos relacionados ao E-commerce, aos Serviços e a estes Termos e a qualquer disputa ou
            reclamação decorrente ou relacionada a ele (em cada caso, incluindo disputas ou reivindicações
            contratuais), serão regidos e interpretados de acordo com as leis internas do Brasil sem efetivar sua
            escolha ou conflito de disposições ou regras legais, e serão dirimidos pelo Foro de Cajamar, Estado de
            São Paulo.
            </p><br/>
            <h4>
            10. Operação do E-commerce e Lei dos Estados Unidos
            </h4>
            <p>
            O E-commerce é controlado e operado dentro do Brasil.Sem outras limitações, a SKF não garante que o
            E-commerce, Conteúdo do E-commerce, Conteúdo do Usuário, serviços, produtos, informações ou outros
            materiais disponíveis no, dentro ou por meio do E-commerce sejam adequados ou disponíveis para uso
            fora do Brasil e o acesso a eles de territórios onde são ilegais é proibido.Aqueles que optarem por acessar
            o E-commerce de fora do Brasil o fazem por vontade própria e são responsáveis pelo cumprimento das
            leis aplicáveis.
            </p><br/>
            <h4>
            11. Aviso de Violação de Conteúdo
            </h4>
            <p>
            Embora não tenhamos obrigação legal de rastrear ou editar ativamente o Conteúdo do Usuário, nos
            reservamos o direito, a nosso critério exclusivo e absoluto, de modificar, editar ou remover qualquer
            Conteúdo do Usuário ou solicitar que um usuário modifique ou edite seu Conteúdo do Usuário, se uma
            reclamação ou notificação de material supostamente ilícito for recebida com relação ao Conteúdo do
            Usuário ou por qualquer outro motivo.
            Para reclamar sobre o Conteúdo do Usuário e/ou para enviar um aviso de materiais supostamente ilícitos
            no E-commerce, entre em contato conosco usando as informações de contato fornecidas acima.
            </p><br/>
            <h4>
            12. Diversos
            </h4>
            <p>
            Estes Termos, incluindo a Política de Privacidade, constituem todo o contrato legal entre você e a SKF em
            relação ao E-commerce e regem seu uso do E-commerce, Serviços e quaisquer transações que você
            possa ter com a SKF por meio do E-commerce.Esses Termos substituem completamente qualquer acordo
            ou entendimento prévio, compromisso ou proposta, escrito ou oral, entre você e a SKF sobre esses
            assuntos.No caso de qualquer outra regra, código de conduta ou outro assunto publicado no E-commerce
            entrar em conflito com estes Termos, estes Termos prevalecerão.Nenhuma explicação oral ou informação
            oral fornecida por qualquer parte deve alterar a interpretação destes Termos.
            Você entende que a SKF pode fazer alterações nesses Termos periodicamente.Seu uso continuado do
            E-commerce após a publicação de alterações nestes Termos será considerado seu consentimento para
            essas alterações.Quando essas alterações forem feitas, a SKF fará uma nova cópia dos Termos
            disponíveis no E-commerce.Você concorda que a SKF não tem nenhuma obrigação de fornecer avisos
            sobre alterações a estes Termos.Você entende que é sua responsabilidade verificar os Termos
            regularmente quanto a alterações.
            </p><br/>
            <p>
            Você concorda que qualquer falha ou atraso por parte da SKF em exercer ou fazer cumprir qualquer direito
            ou recurso legal contido ou disponibilizado por estes Termos (ou que a SKF tenha o benefício de acordo
            com qualquer lei aplicável) não será considerado uma renúncia formal à SKF direitos e esses direitos ou
            soluções ainda estarão disponíveis para a SKF.Se qualquer tribunal, com jurisdição para decidir uma
            questão decorrente destes Termos, determinar que qualquer disposição destes Termos é inválida, essa
            disposição será removida destes Termos sem afetar os demais Termos e as demais disposições
            continuarão válidas e aplicáveis.
            </p><br/>
            <p>
            Você reconhece e concorda que nos reservamos o direito de, a qualquer momento, modificar ou
            descontinuar todo ou parte do E-commerce e dos Serviços, sem aviso prévio, e que não seremos
            responsáveis, direta ou indiretamente, por você ou qualquer outra pessoa ou entidade, por qualquer perda
            ou dano de qualquer tipo incorrido como resultado de tais modificações ou descontinuação.
            Estes Termos são vinculativos para você, seus herdeiros, executores, beneficiários, sucessores e
            cessionários, e você não pode atribuí-los a nenhuma outra parte sem o nosso consentimento prévio por
            escrito, que pode ser retido a nosso critério exclusivo e absoluto.
            </p><br/>
        </div>
    )

}

export default Terms;