import { useRef, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './style.css';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';
import GlobalFuncs from 'utils/GlobalFuncs';
import CheckoutItem from 'components/CheckoutItem';
import Loader from 'components/Loader';
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import * as XLSX from 'xlsx'
import { useScreenshot, createFileName } from 'use-react-screenshot';
import PaymentMethod from 'components/PaymentMethod';
import QuantityPicker from 'components/QuantityPicker';
import { useLanguage } from '../../context/LanguageContext';

function Checkout() {

    const history = useHistory();
    const context = useContext(AppContext);
    const { translate } = useLanguage();
    const [cart, setCart] = useState(null);
    const [noStockCart, setNoStockCart] = useState(null);
    const [noShippingCart, setNoShippingCart] = useState(null);
    const [noDeliveryCart, setNoDeliveryCart] = useState(null);
    const [tempCart, setTempCart] = useState(null);
    const [total, setTotal] = useState(0);
    const [tempTotal, setTempTotal] = useState(null);
    const [orderDetails, setOrderDetails] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shipping, setShipping] = useState(null);
    const [paymentCondition, setPaymentCondition] = useState('Carregando...');
    const [businessSegment, setBusinessSegment] = useState(null);
    const [expectedShippingDate, setExpectedShippingDate] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [payment, setPayment] = useState(null)
    const [paymentLink, setPaymentLink] = useState(null)
    const [credit, setCredit] = useState(null)
    const [id, setId] = useState(null);
    const paymentMethod = new Map([[null, 'Carregando'], [1, 'Limite de crédito'], [2, 'Cartão Credito']])
    const warehouses = {}
    const ref = useRef(null);
    const [image, takeScreenshot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Checkout',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
        console.log(`SUB BUSS - ${context.getSub_business_segment}`)
    }, []);

    useEffect(() => {
        if (success == true) {
            downloadScreenshot(id)
        }
    }, [success]);

    const handlePaymentChange = (paymentValue) => {
        console.log(paymentValue)
        if (paymentValue == "2") {
            setPayment(2)
            setPaymentCondition(null)
        } else {
            setPayment(1)
        }
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const downloadScreenshot = (id) => {
        takeScreenshot(ref.current).then((image) => {
            // console.log(image)
            const imageBlob = dataURItoBlob(image);
            const cancelToken = API.cancelToken();
            API.uploadImage(cancelToken, image, id).then(res => {
                console.log(res)
            })
        });
    };

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        const cancelToken = API.cancelToken();
        context.updateCart();
        API.isActive().then(none => {
            hotjar.initialize('3361828')
            ReactGA.pageview(window.location.pathname);
        }).catch(err => {
            context.logOut()
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    useEffect(() => {
        const cancelToken = API.cancelToken();
        API.getCredit(cancelToken).then(res => {
            res.data.saldo_total >= 0 ? setCredit(res.data.saldo_total) : setCredit(0);
            setPaymentCondition(res.data.payment_condition)
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.message);
            setCredit(0)
        });
    }, []);

    useEffect(() => {
        if (orderDetails.length > 0) {
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(orderDetails);
            worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 30 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Order');
            Swal.fire({
                title: 'Sucesso',
                text: 'Ordem exportada com sucesso.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, `order.xlsx`);
            setOrderDetails(false)
        }
    }, [orderDetails]);

    const downloadExcelOrder = (e) => {
        const cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Exportando pedido...',
            showConfirmButton: false,
            allowOutsideClick: false
        })
        try {
            var delivery_string = 'Verificar na guia Meus Pedidos, após o registro do pedido nos sistemas SKF'
            if (delivery && delivery != -1) {
                delivery_string = delivery.toLocaleDateString("pt-BR", { weekday: 'long', month: 'long', day: 'numeric' })
            }
            var fees = 0
            if (payment && payment == 2 && total) {
                fees = total * 0.0227
            }
            var arr = [['Código do Produto', 'Quantidade', 'Preço Unitário', 'Preço Total', 'Prazo Estimado de Entrega', 'Método de Pagamento', 'Frete', 'Taxas', 'Total com taxas']]
            cart.forEach(product => {
                arr.push([product['skf_product_id'], product['amount'], GlobalFuncs.priceToReal(product['price']), GlobalFuncs.priceToReal(product['amount'] * product['price']), delivery_string, paymentMethod.get(payment), GlobalFuncs.priceToReal(shipping), GlobalFuncs.priceToReal(fees), GlobalFuncs.priceToReal(total + fees)])
            })
            setOrderDetails(arr)
        } catch (e) {
            console.log(e)
            setOrderDetails(false)
        }

    }

    useEffect(() => {
        setBusinessSegment(context.getSub_business_segment)
    }, [context.getSub_business_segment]);

    useEffect(() => {
        if (total === 0) return
        if ((context.getSub_business_segment === "Two Wheeler" && total < 2000) || (context.getSub_business_segment === "SKF Center" && total < 300)) history.push('/cart');
        if (context.getSub_business_segment !== "Private Label") {
            if (credit !== null && total != 0) {
                if (total > credit) {
                    setPayment(2)
                    setPaymentCondition(null)
                } else {
                    setPayment(1)
                }
            }
        }else{
            setPayment(1)
        }
        if (credit !== null && total != 0 && context.getSub_business_segment !== "Stock Car") {
            if (total > credit) {
                setPayment(2)
                setPaymentCondition(null)
            } else {
                setPayment(1)
            }
        }
    }, [credit, total])

    useEffect(() => {
        setNoStockCart(context.getCart);
    }, [context.getCart]);

    useEffect(async () => {
        let tempTotal = 0;
        let tempAmount = 0;
        let tempOverage = [];
        if (!noStockCart) return
        let tempoCart = []
        const cancelToken = API.cancelToken();
        for (const [index, e] of noStockCart.entries()) {
            let tempItem = { ...e }
            let res = await API.getAvailability(cancelToken, e.skf_product_id)
            let avail = {
                "dimensions": false,
                "stock": {
                    "BZD": 0,
                    "ARG": 0,
                    "others": 0,
                    "total": 0
                }
            }
            console.log("Availability is feched")
            console.log(res)
            console.log("Availability end")
            if (res.data.availability.dimensions) {
                avail = res.data.availability
                // avail = { dimensions: true, MWH: 100, BZD: 1000 }
            }
            tempItem.stock = avail
            tempItem.availability = avail
            tempoCart.push(tempItem)
            tempTotal += e.amount * e.price;
            tempAmount += e.amount;
            console.log("**************************")
            console.log({ index: index, amount: e.amount, availability: avail })
            console.log("**************************")
            if (e.amount > e.stock) tempOverage.push({ index: index, amount: e.amount, availability: avail })
        }
        setTotal(tempTotal);
        setNoShippingCart(tempoCart)
    }, [noStockCart])

    useEffect(() => {
        let tempoCart = []
        if (!noShippingCart) return
        let breaking = true
        for (let product of noShippingCart) {
            if ("lines" in product) {
                let sum = product.lines.reduce((accumulator, obj) => accumulator + obj.amount, 0);
                if (sum !== product.amount) {
                    breaking = false
                    break
                }
            } else {
                breaking = false
                break
            }
        }
        if (breaking) return
        for (let product of noShippingCart) {
            let tempProduct = { ...product }
            let tempCount = product.amount
            let tempLines = []
            for (let [value, delay] of Object.entries(warehouses)) {
                if (product.stock && product.stock[value] >= tempCount) {
                    tempLines.push({ "warehouse": value, "amount": tempCount });
                    tempCount = 0
                    break
                } else {
                    if (value in product.stock) {
                        tempLines.push({ "warehouse": value, "amount": product.stock[value] });
                        tempCount -= GlobalFuncs.findClosestMultipleUp(product.stock[value], product.multiple)

                    }
                }
            }

            if (tempCount != 0) {
                tempLines.push({ "warehouse": "N/A", "amount": tempCount });
            }
            tempProduct.lines = tempLines
            tempoCart.push(tempProduct)
        }

        if (tempoCart.length !== 0) {
            setNoDeliveryCart(tempoCart)
        }

    }, [noShippingCart])

    useEffect(async () => {
        let cancelToken;
        let tempoCart = []
        let tempShipping = 0
        if (!noDeliveryCart) return
        for (let product of noDeliveryCart) {
            let tempProduct = { ...product }
            try {
                cancelToken = API.cancelToken();
                for (let line of tempProduct.lines) {
                    const res = await API.getShipping(
                        cancelToken,
                        product.product_id,
                        line.amount,
                        product.price
                    );
                    let date = res.data.quote.estimatedDelivery.split('/');
                    let tempDelivery = new Date(date[2], date[1] - 1, date[0]);
                    let deliveryDelay = warehouses[line.warehouse];
                    while (deliveryDelay > 0) {
                        tempDelivery.setDate(tempDelivery.getDate() + 1);
                        if (tempDelivery.getDay() != 0 && tempDelivery.getDay() != 1) deliveryDelay--;
                    }
                    tempShipping += res.data.quote.price
                    line.shipping = res.data.quote.price;
                    line.delivery = tempDelivery.toLocaleDateString("pt-BR", {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    });
                    line.extraInfo = res.data.product_information;
                }
            } catch (err) {
                console.log(err);
            }
            tempoCart.push(tempProduct)
        }
        setCart(tempoCart)
        setShipping(tempShipping)
        return () => {
            if (cancelToken) {
                cancelToken.cancel(); // Cancel the ongoing request using the cancel token
            }
        };
    }, [noDeliveryCart])

    const handleQuantityChange = (newQuantity, index) => {
        const cancelToken = API.cancelToken();
        const updatedCart = [...cart];
        const item = updatedCart[index];
        item.amount = newQuantity; // Update the item quantity
        item.loading = true
        // setCart(updatedCart);
        setTempCart(updatedCart);

        for (let line of item.lines) {
            API.getShipping(cancelToken, item.product_id, newQuantity, item.price)
                .then(res => {

                    console.log("Logging Item:")
                    console.log(item)

                    console.log(res)
                    let date = res.data.quote.estimatedDelivery.split('/');
                    let tempDelivery = new Date(date[2], date[1] - 1, date[0]);
                    let deliveryDelay = 3; // Ensure 'warehouses' is available in scope

                    while (deliveryDelay > 0) {
                        tempDelivery.setDate(tempDelivery.getDate() + 1);
                        if (tempDelivery.getDay() !== 0 && tempDelivery.getDay() !== 6) deliveryDelay--;
                    }

                    var dateString = tempDelivery.toLocaleDateString("pt-BR", {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                    })

                    item.loading = false;
                    line.delivery = dateString;
                    line.shipping = res.data.quote.price;
                    line.extraInfo = res.data.product_information;
                    setCart(updatedCart);
                    // setTempCart(updatedCart);

                });
        }
    };


    useEffect(() => {
        console.log(cart)
    }, [cart])


    function submitOrder(payload) {
        console.log("Payload")
        console.log(payload)
        API.submitOrder(payload).then(async res => {
            setId(res.data.order_id);
            if (payment === 2) {
                Swal.fire({
                    title: 'Gerando link de pagamento...',
                    showConfirmButton: false,
                });
                while (true) {
                    try {
                        // let link = {}
                        let link = await API.getPaymentLink(res.data.order_id)
                        setPaymentLink(link.data.url)
                        window.open(link.data.url, '_blank', 'height=900,width=900')
                        break
                    } catch {
                        let result = await Swal.fire({
                            title: 'Erro.',
                            text: 'Algo deu errado, clique em ok para tentar novamente. Se este erro persistir, entre em contato conosco.',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            showCancelButton: true,
                            confirmButtonColor: "#131921",
                            cancelButtonColor: "#880808",
                            confirmButtonText: 'Ok',
                            cancelButtonText: 'Cancelar'
                        })
                        if (!result.isConfirmed) {
                            Swal.fire({
                                title: 'Enviado.',
                                text: 'Seu pedido foi enviado, mas um link de pagamento não foi gerado. Entre em contato conosco para obter suporte.',
                                confirmButtonColor: "#131921",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            })
                            setLoading(false);
                            setSuccess(true);
                            await API.deleteFromCart(null, null, true)
                            context.setCart([]);
                            return
                        }
                    }
                }
            }
            setLoading(false);
            setSuccess(true);
            await API.deleteFromCart(null, null, true)
            context.setCart([]);
            Swal.close();
        }).catch(err => {
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }

    useEffect(() => {
        let tempTotal = 0;
        cart && cart.forEach(e => {
            tempTotal += e.amount * e.price;
        })
        setTempTotal(tempTotal);

    }, [tempCart]);

    const handleSubmit = () => {
        if (payment === null) return

        setLoading(true);
        setTempCart(cart);
        let tempArr = []
        var products = []

        console.log(cart)

        for (let prod of cart) {
            // let tempArr = prod.lines.map(e => ({ product_id: prod.product_id, warehouse: e.warehouse, amount: e.amount }))
            prod.lines.forEach(e => {
                Object.keys(e.extraInfo.allocate_order).forEach(key => {
                    const value = e.extraInfo.allocate_order[key];
                    tempArr.push({ product_id: prod.product_id, skf_product_id: prod.skf_product_id, warehouse: key, dpc: value.dpc, pk: value.pk, amount: value.quantity, scheduled_date: prod.scheduled_date })
                    products.push({ product_id: prod.product_id, skf_product_id: prod.skf_product_id, warehouse: key, dpc: value.dpc, pk: value.pk, quantity: value.quantity, price: prod.price, scheduled_date: prod.scheduled_date })
                    console.log(`Delivery Date: ${key}, Quantity: ${value}`);
                });
            })
        }


        var payload = {
            order: {
                payment_method: payment,
                total_amount: total,
                delivery_address: context.getAddress.mailing.id,
                products: products,
                shipping_cost: shipping,
                expected_shipping_date: expectedShippingDate,
                lines: tempArr,
            }
        }

        console.log(payload)
        var skf_order_id = ''
        if (context.getSub_business_segment === "VA Distributor" || context.getSub_business_segment === "Private Label") {
            Swal.fire({
                title: 'Digite aqui o número do pedido',
                html: 'O número do pedido do cliente será incluído no arquivo PDF e XML da nota fiscal. <br><br> Obs: Para seguir sem Número do Pedido do Cliente, basta clicar em Confirmar Pedido.',
                imageUrl: 'https://skfbuy-public-assets.s3.amazonaws.com/cust_own_id_display.png',
                imageWidth: 600,
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: translate('confirmOrderLabel'),
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#131921",
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    const cancelToken = API.cancelToken();
                    API.verifyCustomerOrder(cancelToken, '', result.value).then(async res => {
                        if (result.value.length < 19 && result.value.length != "") {
                            payload.order.customer_own_order = result.value
                            skf_order_id = res.data.skf_order_id
                            if (res.data.existing_order == 0) {
                                submitOrder(payload)
                            } else {
                                Swal.fire({
                                    title: `O Número do Pedido do Cliente ${result.value} está registrado na SKF no(s) pedido(s) ${skf_order_id}`,
                                    text: 'Por favor, deseja concluir essa compra com o mesmo Número do Pedido do Cliente?',
                                    showCancelButton: true,
                                    width: '600px',
                                    confirmButtonText: 'SIM',
                                    cancelButtonText: 'NÃO',
                                    confirmButtonColor: "#131921",
                                    showLoaderOnConfirm: true,
                                    allowOutsideClick: () => !Swal.isLoading()
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        submitOrder(payload)
                                    } else {
                                        setLoading(false);
                                    }
                                })
                            }
                        } else if (result.value == "") {
                            Swal.fire({
                                title: `Confirma a compra sem o Número do Pedido do Cliente?`,
                                showCancelButton: true,
                                width: '100px',
                                confirmButtonText: 'SIM',
                                cancelButtonText: 'NÃO',
                                confirmButtonColor: "#131921",
                                showLoaderOnConfirm: true,
                                allowOutsideClick: () => !Swal.isLoading()
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    payload.order.customer_own_order = result.value
                                    skf_order_id = res.data.skf_order_id
                                    if (res.data.existing_order == 0) {
                                        submitOrder(payload)
                                    } else {
                                        Swal.fire({
                                            title: `O pedido ${skf_order_id} já está registrado nos sistemas SKF`,
                                            text: 'Por favor, deseja incluir esse pedido com o mesmo Número do Pedido do Cliente?',
                                            showCancelButton: true,
                                            width: '100px',
                                            confirmButtonText: 'SIM',
                                            cancelButtonText: 'NÃO',
                                            confirmButtonColor: "#131921",
                                            showLoaderOnConfirm: true,
                                            allowOutsideClick: () => !Swal.isLoading()
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                submitOrder(payload)
                                            } else {
                                                setLoading(false);
                                            }
                                        })
                                    }
                                } else {
                                    setLoading(false);
                                }
                            })
                        }
                        else {
                            Swal.fire({
                                title: 'Oups.',
                                text: 'O número máximo de caracteres permitidos nesse campo são 18 (dezoito).',
                                confirmButtonColor: "#131921",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            });
                            setLoading(false);
                        }
                    }).catch(err => {
                        Swal.fire({
                            title: 'Erro.',
                            text: 'Tente novamente mais tarde.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    });


                } else {
                    setLoading(false);
                }
            })
        } else {
            submitOrder(payload)
        }
    }

    const deleteItem = async (indexToDelete) => {
        try{
            const updatedCart = [...cart];
            const item = updatedCart[indexToDelete];
            item.deleting = true
            setCart(updatedCart);
            
            item.deleting = false
            const id = updatedCart[indexToDelete].product_id;
            const filteredCart = cart.filter((item, index) => index !== indexToDelete);
            const cancelToken = API.cancelToken();
            let res = await API.deleteFromCart(cancelToken, id, false);
            setCart(filteredCart);
        }catch(err){
            console.log(err)
        }
    }

    const formatDate = (date) => {
        var split_date = date.split("-")
        let day = split_date[2]
        let month = split_date[1]
        let year = split_date[0]
        return `${day}/${month}/${year}`
    }

    return (
        <div ref={ref} className="checkout-page-wrapper page-wrapper-white one-whole">
            <div className="section-wrapper">
                <h3 className="margin-bottom-30 margin-top-50">{translate('finalizeOrderLabel')}</h3>
            </div>
            <div className="section-wrapper one-whole flex-row-nowrap space-between wrap">
                <div className="main-section">
                    {delivery &&
                        <div className='action-button margin-bottom-20' onClick={downloadExcelOrder}>Baixar Pedido em Excel</div>
                    }
                    {
                        context.getSub_business_segment === "Stock Car" &&
                        <PaymentMethod onPaymentChange={handlePaymentChange} />
                    }
                    <div className="user-wrapper flex-row space-between margin-bottom-20 margin-top-20">
                        {
                            context.getAddress &&
                            <div className="limit-width margin-bottom-20">
                                <h4 className="margin-bottom-10">{translate('deliveryAddressLabel')}</h4>
                                <p className="info">{context.getAddress.mailing.company && context.getAddress.mailing.company}</p>
                                <p className="info">
                                    {context.getAddress.mailing.address1 && context.getAddress.mailing.address1},
                                    {context.getAddress.mailing.number && ` ${context.getAddress.mailing.number}`}
                                    {context.getAddress.mailing.address2 && ' - ' + context.getAddress.mailing.address2}
                                </p>
                                <p className="info">
                                    {context.getAddress.mailing.city && context.getAddress.mailing.city},
                                    {context.getAddress.mailing.state && ` ${context.getAddress.mailing.state}`}
                                </p>
                                <p className="info">{context.getAddress.mailing.postal && context.getAddress.mailing.postal}</p>
                            </div>
                        }
                        {
                            context.getAddress &&
                            <div className="limit-width margin-bottom-20">
                                <h4 className="margin-bottom-10">{translate('chargingAddressLabel')}</h4>
                                <p className="info">{context.getAddress.billing.company && context.getAddress.billing.company}</p>
                                <p className="info">
                                    {context.getAddress.billing.address1 && context.getAddress.billing.address1},
                                    {context.getAddress.billing.number && ` ${context.getAddress.billing.number}`}
                                    {context.getAddress.billing.address2 && ' - ' + context.getAddress.billing.address2}
                                </p>
                                <p className="info">
                                    {context.getAddress.billing.city && context.getAddress.billing.city},
                                    {context.getAddress.billing.state && ` ${context.getAddress.billing.state}`}
                                </p>
                                <p className="info">{context.getAddress.billing.postal && context.getAddress.billing.postal}</p>
                            </div>
                        }

                        
                            <div className="limit-width margin-bottom-0">
                                <h4 className="margin-bottom-10">{translate('paymentMethodLabel')}</h4>
                        {payment ?
                            <>
                                <p className="info margin-bottom-20">{paymentMethod.get(payment)}</p>
                                {
                                    (businessSegment === "Two Wheeler" || businessSegment === "VA Distributor") &&
                                    <div className="limit-width margin-bottom-20">
                                        <h4 className="margin-bottom-10">Condição de Pagamento</h4>
                                        <p className="info">{paymentCondition}</p>
                                    </div>
                                }
                            </>
                            :
                            <p>{translate('selectPaymentMethodLabel')}</p>
                                
                            }
                            </div>

                    </div>
                    <div className="checkout-cart-wrapper">
                        <table className='checkout-table w-full'>
                            <tr>
                                <th>{translate('productsLabel')}</th>
                                <th>{translate('unitPrice')}</th>
                                <th>{translate('totalPrice')}</th>
                                <th>{translate('estimatedDeliveryLabel')}</th>
                            </tr>
                            {tempCart ?
                                tempCart.map((e, index) => (
                                    <tr className='text-center' key={index + "checkoutitem"}>
                                        <td>
                                            <div className='flex-row-nowrap space-between mt-3 mb-3'>
                                                <div className='mr-5'>
                                                    <img className='checkout-img' src={e.image_url.includes("http") ? e.image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${e.image_url}`} alt={e.name} onError={(event) => {
                                        event.target.src = require("../../assets/images/placeholder.png").default;
                                }}/>
                                                </div>
                                                <div className='w-80'>
                                                    <p className='product-cell mb-3'><strong>{e.name}</strong></p>
                                                    <div className={`product-cell status-cell ${e.amount <= e.availability.stock.total ? '' : e.availability.stock.total == 0 ? 'amount-red' : 'amount-yellow'} mb-2`}>
                                                        {
                                                            e.amount <= e.availability.stock.total ? translate('inStock') : e.availability.stock.total == 0 ? translate('unavailable') : translate('partiallyAvailable')
                                                        }

                                                    </div>
                                                    {/* <p className='product-cell qtd' >Quantidade: <strong>{e.amount}</strong></p> */}
                                                    
                                                    {
                                                        context.getSub_business_segment === "Stock Car" &&
                                                        <QuantityPicker
                                                            quantity={e.amount}
                                                            multiple={1}  // Assuming '1' as multiple, adjust as necessary
                                                            max={e.availability.stock.total}
                                                            index={index}
                                                            onChange={(newQuantity, idx) => handleQuantityChange(newQuantity, idx)}
                                                        />
                                                    }
                                                    {
                                                        e.loading &&
                                                        <p className='product-cell qtd mt-3 yellow'>{translate('loadingInformation')}</p>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        <td className='w-15'>{GlobalFuncs.priceToReal(e.price)}</td>
                                        <td className='w-15'>{GlobalFuncs.priceToReal(e.price * e.amount)}</td>
                                        {/* <td>
                                            <p className='product-cell qtd' >Entrega Agendada: <strong>13/12/2023</strong></p>
                                        </td> */}
                                        <td className='col6'>
                                            {!e.scheduled_date ? (
                                                e.lines.map((line, lineIndex) => (
                                                    <div key={lineIndex}>
                                                        {line.extraInfo && line.extraInfo['delivery'] ? (
                                                            Object.entries(line.extraInfo.delivery).map(([key, value]) => (
                                                                <div key={key}>
                                                                    {value} peças: {key === 'null' ? context.getSub_business_segment == "Stock Car" ? 'Disponibilidade sujeita à confirmação' : translate('unavailable') : key}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div>Sem informações de entrega para esta linha.</div>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <div>Compra programada: {formatDate(e.scheduled_date)}</div>
                                            )}
                                        </td>
                                    </tr>
                                )) :
                                cart ?
                                    (cart.length > 0 && cart.map((e, index) => (
                                        <tr className='text-center' key={index + "checkoutitem"}>
                                            <td>
                                                <div className='flex-row-nowrap space-between mt-3 mb-3'>
                                                    <div className='mr-5'>
                                                        <img className='checkout-img' src={e.image_url.includes("http") ? e.image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${e.image_url}`} alt={e.name} onError={(event) => {
                                        event.target.src = require("../../assets/images/placeholder.png").default;
                                }}/>
                                                    </div>
                                                    <div className='w-80'>
                                                        <p className='product-cell mb-3'><strong>{e.name}</strong></p>
                                                        <div style={{ alignItems: 'center' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                                <div className={`product-cell status-cell ${e.amount <= e.availability.stock.total ? '' : e.availability.stock.total == 0 ? 'amount-red' : 'amount-yellow'} mb-2`}>
                                                                    {e.amount <= e.availability.stock.total ? translate('inStock') : e.availability.stock.total == 0 ? translate('unavailable') : translate('partiallyAvailable')}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* <p className='product-cell qtd' >Quantidade: <strong>{e.amount}</strong></p> */}
                                                        {
                                                            context.getSub_business_segment === "Stock Car" &&
                                                            <QuantityPicker
                                                                quantity={e.amount}
                                                                multiple={1}  // Assuming '1' as multiple, adjust as necessary
                                                                max={e.availability.stock.total}
                                                                index={index}
                                                                onChange={(newQuantity, idx) => handleQuantityChange(newQuantity, idx)}
                                                            />
                                                        
                                                        }              
                                                        {
                                                            e.loading &&
                                                            <p className='product-cell qtd mt-3 yellow'>{translate('loadingInformation')}</p>
                                                        }

                                                        {
                                                            context.getSub_business_segment === "Stock Car" &&
                                                                <button className='pointer remove-item-btn' style={{ marginTop: "10px" }}  onClick={() => deleteItem(index)}>
                                                                    <span className="links remove-item" style={{ color: "#8F2C2C" }}>
                                                                        {e.deleting ?  'Excluindo item': 'Excluir Item'}
                                                                    </span>
                                                                </button>
                                                            }
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='w-15'>{GlobalFuncs.priceToReal(e.price)}</td>
                                            <td className='w-15'>{GlobalFuncs.priceToReal(e.price * e.amount)}</td>
                                            {/* <td className='col6'>
                                                <p className='p ' >Entrega Agendada: <strong>{e}</strong></p> */}

                                            <td className='col6' style={{width:"28%"}}>
                                                {!e.scheduled_date ? (
                                                    e.lines.map((line, lineIndex) => (
                                                        <div key={lineIndex}>
                                                            {line.extraInfo && line.extraInfo['delivery'] ? (
                                                                Object.entries(line.extraInfo.delivery).map(([key, value]) => (
                                                                    <div key={key}>
                                                                        {value} peças: {key === 'null' ? context.getSub_business_segment == "Stock Car" ? 'Disponibilidade sujeita à confirmação' : translate('unavailable') : key}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div>Sem informações de entrega para esta linha.</div>
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>Compra programada: {formatDate(e.scheduled_date)}</div>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                    ) :
                                    <Loader light={true} />
                            }

                        </table>

                    </div>
                </div>
                <div className="checkout-purchase-box margin-bottom-20 margin-top-20">
                    {loading ?
                        <div className="loader-wrapper margin-bottom-10">
                            <Loader light={true} />
                        </div> :
                        success ?
                            <Link className="reset" to={`/pedido/${id}`}>
                                <div className='action-button margin-bottom-20 success'>Pedido Efetuado</div>
                            </Link> :
                            shipping === null || payment === null ?
                                <div className='action-button margin-bottom-20 disabled'>{translate('confirmOrderLabel')}</div> :
                                <div className='action-button margin-bottom-20' onClick={handleSubmit}>{translate('confirmOrderLabel')}</div>
                    }


                    {

                        success ?
                            (payment == 2 ?
                                (paymentLink ?
                                    <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                        <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                        <div>
                                            <a className="bold blue pointer" target="_blank" rel="noopener noreferrer" href={paymentLink && paymentLink}>
                                                <p className='underline bold blue pointer'>Clique aqui para acessar a página de pagamento</p>
                                            </a>
                                            <p>O seu pedido foi efetuado com sucesso. </p>
                                        </div>
                                    </div> :
                                    <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                        <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                        <div>
                                            <p>Seu pedido foi enviado, mas um link de pagamento não foi gerado. Entre em contato conosco para obter suporte.</p>

                                        </div>
                                    </div>
                                )
                                :
                                <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                    <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                    <p>O seu pedido foi efetuado com sucesso. Após aprovação ele será encaminhado para faturamento. Para acompanha-lo, clique no botão acima.</p>
                                </div>)
                            : <p className="disclaimer margin-bottom-20">
                                {translate('confirmOrderMessage')}
                            </p>
                    }


                    <h4 className="margin-bottom-20">{translate('orderSummary')}</h4>
                    <div className="flex-row space-between margin-bottom-10">
                        <p className="">Itens:</p>
                        <p className="">{tempTotal ? GlobalFuncs.priceToReal(tempTotal) : GlobalFuncs.priceToReal(total)}</p>
                    </div>
                    {payment && payment == 2 &&
                        <div className="flex-row space-between margin-bottom-10">
                            <p className="">Taxa:</p>
                            <p className="">{tempTotal ? GlobalFuncs.priceToReal(tempTotal * 0.0227) : GlobalFuncs.priceToReal(total * 0.0227)}</p>
                        </div>
                    }
                    <div className="flex-row space-between margin-bottom-20">
                        <p className="">{translate('shippingLabel')}:</p>
                        <p className="">{shipping !== null ? (shipping > 0 ? GlobalFuncs.priceToReal(shipping) : 'Grátis') : 'Carregando'}</p>
                    </div>
                    <hr />
                    <div className="flex-row space-between margin-top-30">
                        <p className="bold-red">Total</p>
                        <p className="bold-red">{tempTotal ? (payment && payment == 2) ? GlobalFuncs.priceToReal(tempTotal + tempTotal * 0.0227 + (shipping && shipping)) : GlobalFuncs.priceToReal(tempTotal + (shipping && shipping)) : (payment && payment == 2) ? GlobalFuncs.priceToReal(total + total * 0.0227 + (shipping && shipping)) : GlobalFuncs.priceToReal(total + (shipping && shipping))}</p>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Checkout;