import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import GlobalFuncs from "utils/GlobalFuncs";
import API from "utils/API";
import QuantityPicker from "components/QuantityPicker";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "components/Loader";
import { useLanguage } from '../../context/LanguageContext';

function CartItemPl(props) {
  const self = useRef();
  const { translate } = useLanguage();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [netPriceDescription, setNetPriceDescription] = useState(false);
  const [grossPriceDescription, setGrossPriceDescription] = useState(false);
  const [finalPriceDescription, setFinalPriceDescription] = useState(false);


  const showNetPriceDescription = async (cancelToken) => {
    setNetPriceDescription(true)
    setGrossPriceDescription(false)
    setFinalPriceDescription(false)
  }

  const hideNetPriceDescription = async (cancelToken) => {
    setNetPriceDescription(false)
  }

  const showGrossPriceDescription = async (cancelToken) => {
    setNetPriceDescription(false)
    setGrossPriceDescription(true)
    setFinalPriceDescription(false)
  }

  const hideGrossPriceDescription = async (cancelToken) => {
    setGrossPriceDescription(false)
  }

  const showFinalPriceDescription = async (cancelToken) => {
    setNetPriceDescription(false)
    setGrossPriceDescription(false)
    setFinalPriceDescription(true)
  }

  const hideFinalPriceDescription = async (cancelToken) => {
    setFinalPriceDescription(false)
  }

  const deleteItem = () => {
    Swal.fire({
      text: `${translate('excludeItemLabel')}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#131921",
      cancelButtonColor: "#880808",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const cancelToken = API.cancelToken();
        API.deleteFromCart(cancelToken, props.item.product_id, false)
          .then((res) => {
            setLoading(false);
            props.remove();
          })
          .catch((err) => {
            if (err.message === "cancelling") return;
            console.log(err);
            Swal.fire({
              title: "Erro.",
              text: "Tente novamente mais tarde.",
              confirmButtonColor: "#131921",
              icon: "warning",
              confirmButtonText: "Ok",
            });
            setLoading(false);
          });
        return () => {
          API.cancel(cancelToken);
        };
      }
    });
  };

  const handleChecked = (e) => {
    setChecked(e.currentTarget.checked);
    e.currentTarget.checked ? props.handleCheck() : props.handleUncheck();
  };

  useEffect(() => {
    if (props.checkAll == 1) {
      setChecked(true);
    }

    if (props.checkAll == 0) {
      setChecked(false);
    }

  }, [props.checkAll]);

  return (
    <div className="cart-item-wrapper" ref={self}>
      {loading ? (
        <div className="loader-wrapper">
          <Loader light={true} />
        </div>
      ) : (
        <div className="first-flex flex-row space-between">
          <div className="first-col checkbox-holder">
            <input
              className="checkbox"
              type="checkbox"
              onChange={(e) => handleChecked(e)}
              checked={checked}
            />
          </div>
          <div className="second-col first-section flex-row-nowrap">
            <img
              src={
                props.item.image_url.includes("http")
                  ? props.item.image_url
                  : `https://azpartes-catalog-images-production.s3.amazonaws.com/${props.item.image_url}`
              }
              onError={(event) => {
                event.target.src = require("../../assets/images/placeholder.png").default;
              }}
              alt={props.item.name}
            />
            <div className="item-info">
              <Link
                className="reset"
                to={`/produto/${GlobalFuncs.cleanString(props.item.name)}/${props.item.product_id
                  }`}
              >
                <p className="margin-bottom-10 name">{`${props.item.name}`}</p>
              </Link>
              <p
                className={`in-stock margin-bottom-10 ${props.item.amount > props.item.availability.stock.total
                  ? props.item.availability.stock.total == 0 ? "amount-red" : "amount-yellow partial-stock"
                  : "amount-green"}`}
              >
                {props.item.amount > props.item.availability.stock.total
                  ? props.item.availability.stock.total == 0 ? "Indisponível" : "Parcialmente Disponível"
                  : "Em estoque"}
              </p>
              <div className="qtt-holder flex-row align-center hide-small">
                <QuantityPicker
                  quantity={props.item.amount}
                  multiple={props && props.item.multiple ? props.item.multiple : 1}
                  index={props.index}
                  onChange={props.updateAmount}
                />
                <p className="margin-left">
                  <span
                    className="links"
                    style={{ color: "red" }}
                    onClick={deleteItem}
                  >
                    {translate('excludeItemLabel')}
                  </span>
                  {/* <span className="links">Salvar para mais tarde</span> */}
                </p>
              </div>
              {
                props.item.multiple && props.item.multiple != 1 &&
                <div className="qtt-holder flex-row align-center hide-small">
                  <p className='min-desc margin-top-10'>Este produto só é adquirido em múltiplos de {props.item.multiple}</p>
                </div>
              }
            </div>
          </div>
          <div className="qtt-holder flex-row align-center hide-big">
            <QuantityPicker
              quantity={props.item.amount}
              index={props.index}
              onChange={props.updateAmount}
            />
            <p className="margin-left">
              <span
                className="links"
                style={{ color: "red" }}
                onClick={deleteItem}
              >
                {translate('excludeItemLabel')}
              </span>
              {/* <span className="links">Salvar para mais tarde</span> */}
            </p>
          </div>
          <div className="third-col">
            <div className="price">
              <b>{translate('netPrice')}: </b>
              <p>{GlobalFuncs.priceToReal((props.item.price - props.item.vlIcms - props.item.vlIpi - props.item.vlIStf - props.item.vlPis - props.item.vlCofins))}</p>
              <div >
                <img className='info-img pointer '
                  src={require("../../assets/images/info-icon-lg.png").default}
                  alt=""
                  onMouseEnter={showNetPriceDescription}
                  onMouseLeave={hideNetPriceDescription} />
                {
                  netPriceDescription &&
                  <>
                    <div className="price-desc">{translate('netComment')}</div>
                  </>
                }
              </div>
            </div>
            <div className="price">
              <b>{translate('grossPrice')}: </b>
              <p>{GlobalFuncs.priceToReal((props.item.price - props.item.vlIcms - props.item.vlIpi - props.item.vlIStf)) }</p>
              <div >
              <img className='info-img pointer'
                src={require("../../assets/images/info-icon-lg.png").default}
                alt=""
                onMouseEnter={showGrossPriceDescription}
                onMouseLeave={hideGrossPriceDescription} />
              {
                grossPriceDescription &&
                <>
                  <div className="price-desc">{translate('grossComment')}</div>
                </>
              }</div>
            </div>
            <div className="price">
              <b>{translate('finalPrice')}: </b>
              <p>{GlobalFuncs.priceToReal(props.item.price)}</p>
              <div >
              <img className='info-img pointer'
                src={require("../../assets/images/info-icon-lg.png").default}
                alt=""
                onMouseEnter={showFinalPriceDescription}
                onMouseLeave={hideFinalPriceDescription} />
              {
                finalPriceDescription &&
                <>
                  <div className="price-desc">{translate('finalComment')}</div>
                </>
              }
              </div>
            </div>

          </div>
          <div className="fourth-col">
            <p className="price">
              {GlobalFuncs.priceToReal(props.item.price * props.item.amount)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(CartItemPl);