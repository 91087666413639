import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from 'utils/ContextProvider';
import './style.css';

function Hero() {

    const [background, setBackground] = useState(0);
    const context = useContext(AppContext);
    const [backgrounds, setBackgrounds] = useState([[]]);

    const heroNext = () => {
        background < backgrounds.length - 1 ? setBackground(background + 1) : setBackground(0);
    }
    const heroPrev = () => {
        background !== 0 ? setBackground(background - 1) : setBackground(backgrounds.length - 1);
    }

    useEffect(() => {
        if(context.getSub_business_segment === "Two Wheeler"){
            setBackgrounds([
            [require('../../assets/images/banner_2_2W.jpg').default, '/products/search?q=vkwy&cat=null&min=null&max=null&priceCat=null&openCat=null&page=1&sorting=0&type=null&make=null&model=null&motor=null&license=null&available=1&alph=null'],
            [(require('../../assets/images/banner_1_2W.jpg').default), '/products/search?q=&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=1&type=null&license=null&available=1&alph=null'],
            [require('../../assets/images/banner_3_2W.jpg').default, null]
            ])
        }else if(context.getSub_business_segment === "SKF Center"){
            setBackgrounds([
            [(require('../../assets/images/banner_1.jpg').default), '/products/search?q=null&cat=100&min=null&max=null&priceCat=null&openCat=null&page=1&sorting=null&type=null&license=null&available=0&alph=null'],
            [require('../../assets/images/banner_4.jpg').default, null],
            [require('../../assets/images/banner_3.jpg').default, '/products/search?q=null&cat=16&min=null&max=null&priceCat=null&openCat=null&page=1&sorting=null&type=null&license=null&available=0&alph=null'],
            [require('../../assets/images/banner_5.jpg').default, '/products/search?q=LT%20MV&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=null&license=null&available=0&alph=null'],
            [require('../../assets/images/banner_2.jpg').default, null]])
        }
        else if(context.getSub_business_segment === "VA Distributor"){
            setBackgrounds([
            [(require('../../assets/images/banner_va_1.jpg').default), '/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=Linha%20Pesada&make=null&model=null&motor=null&license=null&available=0&alph=null'],
            [(require('../../assets/images/banner_va_2.jpg').default), '/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=Linha%20Agrícola&make=null&model=null&motor=null&license=null&available=0&alph=null'],
            [(require('../../assets/images/banner_va_3.jpg').default), '/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=Linha%20Agrícola&make=null&model=null&motor=null&license=null&available=0&alph=null'],
            [require('../../assets/images/banner_3.jpg').default, '/products/search?q=null&cat=16&min=null&max=null&priceCat=null&openCat=null&page=1&sorting=null&type=null&license=null&available=0&alph=null']])
        }
        else if(context.getSub_business_segment === "Private Label"){
            setBackgrounds([
            [(require('../../assets/images/banner_private_label_1.jpg').default), '/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=Linha%20Pesada&make=null&model=null&motor=null&license=null&available=0&alph=null'],
            [(require('../../assets/images/banner_private_label_2.jpg').default), '/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=Linha%20Agrícola&make=null&model=null&motor=null&license=null&available=0&alph=null'],])
        }
    },[context.getPricelist])

    useEffect(() => {
        let int = setInterval(function () {
            heroNext();
        }, 5000)
        return () => {
            clearInterval(int)
          }
    });

    return (
        <div className="hero-wrapper">
            <div className="image-wrapper">
               { backgrounds[background][1]?
               <a href={backgrounds[background][1]} className="zero-top">
                    <div className="float-wrapper one-whole image_hero" >
                        <img src={backgrounds[background][0]} alt="next" />
                    </div>
                </a>
                :
                <div className="float-wrapper one-whole image_hero" >
                    <img src={backgrounds[background][0]} alt="next" />
                </div>}
                <div className="prev" onClick={() => heroPrev()}>
                    <img alt="previous" className="arrow" src={require('../../assets/images/left.png').default} />
                </div>
                <div className="next" onClick={() => heroNext()}>
                    <img alt="next" className="arrow" src={require('../../assets/images/right.png').default} />
                </div>
            </div>
            <div className="hero-buffer one-whole"></div>
        </div>
    )
}

export default Hero;