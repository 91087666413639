import { useState, useContext, useEffect } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import Loader from 'components/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckoutItem from 'components/CheckoutItem';
import GlobalFuncs from 'utils/GlobalFuncs';


function AcceptAvailability() {

    const context = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();
    const [hide, setHide] = useState(false);
    const [info, setInfo] = useState(null);
    const [tempCart, setTempCart] = useState(null);
    const [tempTotal, setTempTotal] = useState(null);
    const [shipping, setShipping] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [id, setId] = useState(null);
    const [token, setToken] = useState(null)
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(null)
    const [credit, setCredit] = useState(null);
    const [paymentLink, setPaymentLink] = useState(null)
    const paymentMethod = new Map([[null, 'Carregando'], [1, 'Limite de crédito'], [2, 'Cartão Credito']])

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if(context.getSub_business_segment == "Stock Car"){
            history.push('/stockcar');
            return;
        }
        API.isActive().then(none => {
            const params = new URLSearchParams(location.search);
            if (params.get('ref') && params.get('ref') !== "null") {
                let token = params.get('ref')
                getResponse(token)
                const cancelToken = API.cancelToken();
                API.getCredit(cancelToken).then(res => {
                    res.data.saldo_total >= 0 ? setCredit(res.data.saldo_total) : setCredit(0);
                }).catch(err => {
                    if (err.message === 'cancelling') return
                    console.log(err.message);
                    setCredit(0)
                });
            } else {
                Swal.fire({
                    title: 'Erro.',
                    text: 'URL Invalido.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(res => {
                    history.push('/home')
                });
            }
        }).catch(err => {
            console.log(err)
            context.logOut()
        });
    }, [location])

    useEffect(() => {
        let tempTotal2 = 0;
        tempCart && tempCart.forEach(e => {
            tempTotal2 += e.amount * e.price;
        })
        setTempTotal(tempTotal2);

        let cancelToken = null;

        async function updateCart() {

            let tempShipping = null;
            for (let e of tempCart) {
                let unitShipping;
                cancelToken = API.cancelToken();
                try {
                    unitShipping = await API.getShipping(cancelToken, e.product_id, e.amount, e.price)
                    // tempShipping += unitShipping.data.quote.products[0].totalPrice[0].price
                    tempShipping += unitShipping.data.quote.price;
                } catch (err) {
                    console.log(err.message)
                }
            }
            setShipping(tempShipping)
        }

        if (tempCart && tempCart.length > 0) updateCart();
        return () => {
            API.cancel(cancelToken);
        }


    }, [tempCart]);

    useEffect(() => {
        if (tempTotal === 0) return
        if (credit !== null && tempTotal != 0) {
            if (tempTotal > credit) {
                setPayment(2)
            } else {
                setPayment(1)
            }
        }
    }, [credit, tempTotal])

    const accept = (token) => {
        API.acceptAvailability(token).then(res => {
            getResponse(token)
        }).catch(err => {
            if (err.message === 'cancelling') return
            let message = 'Tente novamente mais tarde.'
            if (err.response.status === 403) {
                message = 'Este link não é mais válido.'
            }
            setHide(true)
            Swal.fire({
                title: 'Erro.',
                text: message,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(val => {
                history.push('/')
            });
        });
    }

    useEffect(async () => {
        if (!info) return;
        const cancelToken = API.cancelToken();
        let temp_cart = []
        for (let item of info) {
            try {
                let prod = await API.getProduct(item.product_id, cancelToken)
                temp_cart.push({ ...item, image_url: prod.data.product.image_url, name: prod.data.product.name, price: prod.data.product.price, amount: item.quantity })
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(val => {
                    history.push('/')
                });
            }
        }
        setTempCart(temp_cart)
        return () => {
            API.cancel(cancelToken);
        }
    }, [info])

    const getResponse = (token) => {
        API.getAvailabilityResponse(token).then(res => {
            setInfo(res.data.response)
            setToken(res.data.company.response_token)
            let tempDelivery = null;
            console.log(`Adding to cart - ${JSON.stringify(res.data.response)}`)
            res.data.response.forEach(e => {
                API.addToCart(e.product_id, e.quantity, e.quantity, undefined).then(res => {
                    history.push('/cart');
                }).catch(err => {
                    // handle error
                    console.log(err)
                })
            })
            
            
            for (let resp of res.data.response) {
                let temp = new Date(resp.date.replace("-", "/"));
                if (!tempDelivery || temp > tempDelivery) tempDelivery = new Date(temp)
            }
            let deliveryDelay = 2
            while (deliveryDelay > 0) {
                tempDelivery.setDate(tempDelivery.getDate() + 1)
                if (tempDelivery.getDay() != 0 && tempDelivery.getDay() != 1) deliveryDelay--
            }
            // tempDelivery.setDate(tempDelivery.getDate() + deliveryDelay);
            setDelivery(tempDelivery)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 403) {
                accept(token)
            } else {
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(val => {
                    history.push('/')
                });
            }
        });
    }

    const handleSubmit = () => {
        if (payment === null) return
        setLoading(true);
        let prodPayload = tempCart.map(e=> {return { product_id: e.product_id, quantity: e.amount }})
        let payload = {
            order: {
                payment_method: payment,
                total_amount: tempTotal,
                delivery_address: context.getAddress.mailing.id,
                products: prodPayload,
                shipping_cost: shipping,
                token: token
            }
        }
        API.submitOrder(payload).then(async res => {
            setId(res.data.order_id);
            if (payment === 2) {
                Swal.fire({
                    title: 'Gerando link de pagamento...',
                    showConfirmButton: false,
                });
                while (true) {
                    try {
                        let link = await API.getPaymentLink(res.data.order_id)
                        setPaymentLink(link.data.url)
                        window.open(link.data.url, '_blank', 'height=900,width=900')
                        break
                    } catch {
                        let result = await Swal.fire({
                            title: 'Erro.',
                            text: 'Algo deu errado, clique em ok para tentar novamente. Se este erro persistir, entre em contato conosco.',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            showCancelButton: true,
                            confirmButtonColor: "#131921",
                            cancelButtonColor: "#880808",
                            confirmButtonText: 'Ok',
                            cancelButtonText: 'Cancelar'
                        })
                        if (!result.isConfirmed) {
                            Swal.fire({
                                title: 'Enviado.',
                                text: 'Seu pedido foi enviado, mas um link de pagamento não foi gerado. Entre em contato conosco para obter suporte.',
                                confirmButtonColor: "#131921",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            })
                            setLoading(false);
                            setSuccess(true);
                            await API.deleteFromCart(null, null, true)
                            context.setCart([]);
                            return
                        }
                    }
                }
            }
            setLoading(false);
            setSuccess(true);
            await API.deleteFromCart(null, null, true)
            context.setCart([]);
            Swal.close();
        }).catch(err => {
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }


    return (
        <div>
            {hide || (info && tempCart) ?
                <div className="checkout-page-wrapper page-wrapper-white one-whole">
                    <div className="section-wrapper">
                        <h3 className="margin-bottom-30 margin-top-50">Finalize seu pedido</h3>
                    </div>
                    <div className="section-wrapper one-whole flex-row-nowrap space-between wrap">
                        <div className="main-section">
                            <div className="user-wrapper flex-row space-between margin-bottom-20 margin-top-20">
                                {
                                    context.getAddress &&
                                    <div className="limit-width margin-bottom-20">
                                        <h4 className="margin-bottom-10">Endereço de Entrega</h4>
                                        <p className="info">{context.getAddress.mailing.company && context.getAddress.mailing.company}</p>
                                        <p className="info">
                                            {context.getAddress.mailing.address1 && context.getAddress.mailing.address1},
                                            {context.getAddress.mailing.number && ` ${context.getAddress.mailing.number}`}
                                            {context.getAddress.mailing.address2 && ' - ' + context.getAddress.mailing.address2}
                                        </p>
                                        <p className="info">
                                            {context.getAddress.mailing.city && context.getAddress.mailing.city},
                                            {context.getAddress.mailing.state && ` ${context.getAddress.mailing.state}`}
                                        </p>
                                        <p className="info">{context.getAddress.mailing.postal && context.getAddress.mailing.postal}</p>
                                    </div>
                                }
                                {
                                    context.getAddress &&
                                    <div className="limit-width margin-bottom-20">
                                        <h4 className="margin-bottom-10">Endereço de Cobrança</h4>
                                        <p className="info">{context.getAddress.billing.company && context.getAddress.billing.company}</p>
                                        <p className="info">
                                            {context.getAddress.billing.address1 && context.getAddress.billing.address1},
                                            {context.getAddress.billing.number && ` ${context.getAddress.billing.number}`}
                                            {context.getAddress.billing.address2 && ' - ' + context.getAddress.billing.address2}
                                        </p>
                                        <p className="info">
                                            {context.getAddress.billing.city && context.getAddress.billing.city},
                                            {context.getAddress.billing.state && ` ${context.getAddress.billing.state}`}
                                        </p>
                                        <p className="info">{context.getAddress.billing.postal && context.getAddress.billing.postal}</p>
                                    </div>
                                }
                                <div className="limit-width margin-bottom-20">
                                    <h4 className="margin-bottom-10">Método de Pagamento</h4>
                                    <p className="info">{paymentMethod.get(payment)}</p>
                                </div>
                                {/* <div className="limit-width margin-bottom-20">
                            <h4 className="margin-bottom-10">Aplicar cupom de desconto</h4>
                            <form className="flex-row-nowrap" action="">
                                <input type="text" />
                                <button>Aplicar</button>
                            </form>
                        </div> */}
                            </div>
                            <div className="checkout-cart-wrapper flex-row spacebetween">
                                <div className="listing margin-top-10 margin-bottom-20">
                                    {tempCart &&
                                        tempCart.map((e, index) => (
                                            <CheckoutItem key={e.product_id * e.price + index} item={e} static={true} />
                                        ))
                                    }
                                </div>
                                <div className="shipping">
                                    <h4 className="margin-bottom-10">Frete</h4>
                                    <p>{shipping !== null ? (shipping > 0 ? `Intecom - ${GlobalFuncs.priceToReal(shipping)}` : 'Grátis') : 'Carregando'}</p>
                                    <h4 className="margin-top-20 margin-bottom-10">Entrega</h4>
                                    {/* <p className="delivery-date">{delivery ? delivery.toLocaleDateString("pt-BR", { weekday: 'long', month: 'long', day: 'numeric' }) : 'Carregando'}</p> */}
                                    <p className="email-delivery-date">Prazo descrito no email de disponibilidade</p>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-purchase-box margin-bottom-20 margin-top-20">
                            {loading ?
                                <div className="loader-wrapper margin-bottom-10">
                                    <Loader light={true} />
                                </div> :
                                success ?
                                <Link className="reset" to={`/pedido/${id}`}>
                                        <div className='action-button margin-bottom-20 success'>Pedido Efetuado</div>
                                    </Link> :
                                    shipping === null || payment === null ?
                                        <div className='action-button margin-bottom-20 disabled'>Confirmar pedido</div> :
                                        <div className='action-button margin-bottom-20' onClick={handleSubmit}>Confirmar pedido</div>
                            }


                            {

                                success ?
                                (payment == 2 ?
                                    (paymentLink?
                                     <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                         <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                         <div>
                                             <p>O seu pedido foi efetuado com sucesso. </p>
                                             <a className="bold blue pointer" target="_blank" rel="noopener noreferrer" href={paymentLink && paymentLink}>
                                                 <p className='underline bold blue pointer'>Clique aqui para acessar a página de pagamento</p>
                                             </a>
                                         </div>
                                     </div>:
                                     <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                     <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                     <div>
                                         <p>Seu pedido foi enviado, mas um link de pagamento não foi gerado. Entre em contato conosco para obter suporte.</p>
                                         
                                     </div>
                                 </div>
                                      )
                                     :
                                    <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                        <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                        <p>O seu pedido foi efetuado com sucesso. Após aprovação ele será encaminhado para faturamento.</p>
                                    </div>)
                                    : <p className="disclaimer margin-bottom-20">
                                        Ao fazer seu pedido, você concorda com as Condições de Uso da <strong>AZPartes</strong>.
                                        Por favor verifique a Notificação de Privacidade, Notificação de Cookies
                                        e a Notificação de Anúncios Baseados em Interesse.
                                    </p>
                            }
                            <h4 className="margin-bottom-20">Resumo do Pedido</h4>
                            <div className="flex-row space-between margin-bottom-10">
                                <p className="">Itens:</p>
                                <p className="">{tempTotal && GlobalFuncs.priceToReal(tempTotal)}</p>
                            </div>
                            <div className="flex-row space-between margin-bottom-20">
                                <p className="">Frete:</p>
                                <p className="">{shipping !== null ? (shipping > 0 ? GlobalFuncs.priceToReal(shipping) : 'Grátis') : 'Carregando'}</p>
                            </div>
                            <hr />
                            <div className="flex-row space-between margin-top-30">
                                <p className="bold-red">Total</p>
                                <p className="bold-red">{tempTotal && GlobalFuncs.priceToReal(tempTotal + (shipping && shipping))}</p>
                            </div>
                        </div>
                    </div >
                </div > :
                <div className="loader-wrapper margin-bottom-10">
                    <Loader light={true} />
                </div>
            }
        </div>
    )
}

export default AcceptAvailability;