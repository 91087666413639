import React, { useState, useContext, useEffect, useRef } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import IconText from 'components/IconText';
import { Link, useHistory, useLocation } from 'react-router-dom';
import API from "utils/API";
import Loader from 'components/Loader';
import Swal from 'sweetalert2';
import GlobalFuncs from 'utils/GlobalFuncs';
import { useLanguage } from '../../context/LanguageContext';

function Navigation() {

    const context = useContext(AppContext);
    const history = useHistory();
    const [menu, setMenu] = useState(true);
    const [license, setLicense] = useState('');
    const [car, setCar] = useState({ line: '', make: '', model: '', motor: '' })
    const [lineList, setLineList] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState(getLanguage(localStorage.getItem('appLanguage')));
    const [filtersList, setFiltersList] = useState(null)
    const [makeList, setMakeList] = useState(null);
    const [modelList, setModelList] = useState(null);
    const [motorList, setMotorList] = useState(null);
    const [predictions, setPredictions] = useState(null)
    const [predBox, setPredBox] = useState(false)
    const [desktop, setDesktop] = useState(true);
    const [showAccounts, setShowAccounts] = useState(false);
    const [loading, setLoading] = useState(false);
    const searchQuery = useRef();
    const location = useLocation();
    const { switchLanguage } = useLanguage();
    const { translate } = useLanguage();
    let timeout = null

    window.addEventListener('resize', function (event) {
        setDesktop(window.innerWidth > 1250);
    }, true);

    useEffect(() => {
        setDesktop(window.innerWidth > 1250);
    }, [])

    useEffect(() => {
    }, [context])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (!context.getLogin) return
        API.getFilterValues(cancelToken).then(res => {
            setFiltersList(res.data)
        }).catch(err => {
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }, [context.getLogin])

    useEffect(() => {
        if (!filtersList) return;
        let lineListTemp = filtersList.map(e => {
            let bool = true
            if (car.motor) e.Motor.toLowerCase() !== car.motor.toLowerCase() ? bool = false : null;
            if (car.model) e.DescricaoAplicacao.toLowerCase() !== car.model.toLowerCase() ? bool = false : null;
            if (car.make) e.Montadora.toLowerCase() !== car.make.toLowerCase() ? bool = false : null;
            if (bool) return e.DescricaoTipoAplicacao
        })
        lineListTemp.sort()
        setLineList(lineListTemp.filter((e, index) => (
            lineListTemp.indexOf(e) === index
        )));
        let makeListTemp = filtersList.map(e => {
            let bool = true
            if (car.motor) e.Motor.toLowerCase() !== car.motor.toLowerCase() ? bool = false : null;
            if (car.model) e.DescricaoAplicacao.toLowerCase() !== car.model.toLowerCase() ? bool = false : null;
            if (car.line) e.DescricaoTipoAplicacao.toLowerCase() !== car.line.toLowerCase() ? bool = false : null;
            if (bool) return e.Montadora
        })
        makeListTemp.sort()
        setMakeList(makeListTemp.filter((e, index) => (
            makeListTemp.indexOf(e) === index
        )));
        let modelListTemp = filtersList.map(e => {
            let bool = true
            if (car.motor) e.Motor.toLowerCase() !== car.motor.toLowerCase() ? bool = false : null;
            if (car.make) e.Montadora.toLowerCase() !== car.make.toLowerCase() ? bool = false : null;
            if (car.line) e.DescricaoTipoAplicacao.toLowerCase() !== car.line.toLowerCase() ? bool = false : null;
            if (bool) return e.DescricaoAplicacao
        })
        modelListTemp.sort()
        setModelList(modelListTemp.filter((e, index) => (
            modelListTemp.indexOf(e) === index
        )));
        let motorListTemp = filtersList.map(e => {
            let bool = true
            if (car.model) e.DescricaoAplicacao.toLowerCase() !== car.model.toLowerCase() ? bool = false : null;
            if (car.make) e.Montadora.toLowerCase() !== car.make.toLowerCase() ? bool = false : null;
            if (car.line) e.DescricaoTipoAplicacao.toLowerCase() !== car.line.toLowerCase() ? bool = false : null;
            if (bool) return e.Motor
        })
        motorListTemp.sort()
        setMotorList(motorListTemp.filter((e, index) => (
            motorListTemp.indexOf(e) === index
        )));
    }, [filtersList, car])


    useEffect(() => {
        if (location.pathname === '/products/search') {
            const params = new URLSearchParams(location.search);
            params.get('q') === 'null' ? searchQuery.current.value = '' : searchQuery.current.value = params.get('q').trim();
            let type = params.get('type')
            let make = params.get('make')
            let model = params.get('model')
            let motor = params.get('motor')
            let license = params.get('license')
            setCar({ line: type === 'null' ? '' : type, make: make === 'null' ? '' : make, model: model === 'null' ? '' : model, motor: motor === 'null' ? '' : motor })
            setLicense(license === 'null' ? '' : license)
        }
    }, [location])

    const toggleBurger = () => {
        setMenu(!menu);
    }


    const handleChange = () => {
        setPredBox(true)
        clearTimeout(timeout)
        if (!context.getApplications) return
        timeout = setTimeout(() => {
            // if emptying search value, return
            if (searchQuery.current.value == '') {
                setPredictions(null)
                return
            }
            setPredictions(GlobalFuncs.predictiveSearch(searchQuery.current.value, context.getApplications, context.getProducts, context.getWords, false))
        }, "300")
    }

    const submitSearch = (e, value) => {
        e.preventDefault();
        setPredBox(false)
        setPredictions(null)
        let term = '';
        if (value && value.trim() !== '') {
            term = value.trim();
            searchQuery.current.value = term
        } else {
            term = searchQuery.current.value.trim()
        }
        history.push(`/products/search?q=${term}&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=null&make=null&model=null&motor=null&license=null&available=0&alph=null`);
    }


    const preSubmit = (e) => {
        if (e.key === 'Enter') {
            submitAdvancedSearch(e)
        }
    }

    const submitAdvancedSearch = (e) => {
        e.preventDefault();
        if (car.line || car.make || car.model || car.motor) {
            history.push(`/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=${car.line ? car.line : 'null'}&make=${car.make ? car.make : 'null'}&model=${car.model ? car.model : 'null'}&motor=${car.motor ? car.motor : 'null'}&license=null&available=0&alph=null`);
        } else if (license) {
            history.push(`/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=null&make=null&model=null&motor=null&license=${license}&available=0&alph=null`);
        } else {
            return
        }
    }

    const handleCarChange = (e) => {
        let { name, value } = e.currentTarget;
        if (name === 'line') {
            setCar({ line: value, make: '', model: '', motor: '' })
        } else if (name === 'make') {
            setCar(prev => {
                return { ...prev, make: value, model: '', motor: '' }
            })
        } else if (name === 'model') {
            setCar(prev => {
                return { ...prev, model: value, motor: '' }
            })
        } else {
            setCar(prev => {
                return { ...prev, motor: value }
            })
        }
        setLicense('')
    }

    const handleLicense = (e) => {
        e.preventDefault();
        setLicense(e.currentTarget.value)
        if (car.line != '' || car.make != '' || car.model != '' || car.motor != '') {
            setCar({ line: '', make: '', model: '', motor: '' })
        }
    }

    const updateSearchValue = (e, el) => {
        e.preventDefault()
        el ? searchQuery.current.value = el.trim() + " " : null
        searchQuery.current.focus()
        setPredictions([searchQuery.current.value])
    }

    function getLanguage(value) {
        var lg = ''
        if(value == 'pt'){
            lg = "Português"
        }
        if(value == 'en'){
            lg = "English"
        }
        if(value == 'es'){
            lg = "Español"
        }

        return lg
    }

    const updateLanguage = (value) => {
        var lg = ''
        if(value == 'pt'){
            lg = "Português"
        }
        if(value == 'en'){
            lg = "English"
        }
        if(value == 'es'){
            lg = "Español"
        }
        
        setCurrentLanguage(lg)
        switchLanguage(value)
    }

    const switchCompany = (company_id) => {
        Swal.fire({
            html: '<p>Você está alternando o cliente da sessão. Isso</p><p>cancelará qualquer ação que esteja em andamento.</p><p></p>Deseja continuar mesmo assim?</p>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#289067",
            cancelButtonColor: "#6e6c6c",
            confirmButtonText: 'CONTINUAR',
            cancelButtonText: 'CANCELAR'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const cancelToken = API.cancelToken();
                API.switchCompany(cancelToken, context.getAddress.billing.company, company_id).then(res => {
                    if (res.data.reset_token) {
                        history.push(`/alterarsenha?ref=${res.data.reset_token}`)
                        return
                    }
                    setShowAccounts(false)
                    setLoading(false);
                    let name = res.data.user.toLowerCase();
                    let price_list = res.data.pricelist;
                    let sub_business_segment = res.data.sub_business_segment;
                    let user = `${res.data.skf_id} - ${name.charAt(0).toUpperCase() + name.slice(1)}`;
                    let address = {
                        mailing: {
                            id: res.data.address[0].address_id,
                            company: res.data.user.trim(),
                            address1: res.data.address[0].address,
                            address2: res.data.address[0].address_2,
                            number: res.data.address[0].number,
                            city: res.data.address[0].city,
                            state: res.data.address[0].uf,
                            postal: res.data.address[0].postal_code
                        },
                        billing: {
                            id: res.data.address[1].address_id,
                            company: res.data.user.trim(),
                            address1: res.data.address[1].address,
                            address2: res.data.address[1].address_2,
                            number: res.data.address[1].number,
                            city: res.data.address[1].city,
                            state: res.data.address[1].uf,
                            postal: res.data.address[1].postal_code
                        }
                    }
                    context.logIn(user, res.data.token, address, price_list, sub_business_segment, res.data.authorized_companies, `${res.data.skf_id} - ${res.data.cnpj}`);
                    // history.goBack();
                    history.push('/')
                }).catch(err => {
                    console.log(err)
                    if (err.message === 'cancelling') return
                    setLoading(false);
                    if (err.response && err.response.status === 401) {
                        Swal.fire({
                            title: 'Erro.',
                            text: 'Unauthorized.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        Swal.fire({
                            title: 'Erro.',
                            text: 'Tente novamente mais tarde.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    }
                });
            }
        });
    }


    return (
        <div className="nav-section">
            <div className="navbar-main">
                <Link className="navbar-brand" to="/">AZPartes.com</Link>
                {
                    context.getSub_business_segment !== "Stock Car" &&
                    <></>
                }
                <div className={`input-holder ${context.getSub_business_segment == "Stock Car" ? 'hidden' : ''}`} onBlur={(e) => e.relatedTarget === null ? setPredBox(false) : null} onFocus={() => setPredBox(true)}>
                    <form onSubmit={(e) => submitSearch(e)}>
                        <input type="text" ref={searchQuery} placeholder={translate('searchPlaceholder')} onChange={handleChange} />
                        <input tabIndex={-1} className="button" variant="buscar" type="submit" value={translate('searchLabel')} onSubmit={(e) => submitSearch(e)} />
                    </form>
                    {searchQuery && predBox && <ul className="predictions-holder" >
                        {
                            predictions && predictions.length > 0 ? predictions.map((el, index) => (
                                <li key={index + "prediction"}><button onClick={(e) => updateSearchValue(e, el)} >{el}</button></li>
                            )) :
                                <p>0 resultados</p>
                        }
                    </ul>}
                </div>
                {context.getLogin ?
                    <div className="icon-wrapper">
                        {/* <IconText image={require("../../assets/images/store.png").default}
                            text={context.getLogin} id="nav-location"
                            children={
                                [
                                    { text: 'Meus Pedidos', href: '/pedidos' },
                                    { text: 'Compras em lote', href: '/lote' },
                                    { text: 'Sair', action: context.logOut },
                                ]
                            }
                            href={'/account'} /> */}
                        <div className='user-holder' onClick={() => setShowAccounts(!showAccounts)}>
                            <div className='user-content-holder'>
                                {context.getCurrentUser}
                                <svg className={`${showAccounts ? "rotate-180" : ""}`} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.88027 7.20945L6.00027 3.32945L2.12027 7.20945C1.73027 7.59945 1.10027 7.59945 0.710273 7.20945C0.320272 6.81945 0.320272 6.18945 0.710273 5.79945L5.30027 1.20945C5.69027 0.819453 6.32027 0.819453 6.71027 1.20945L11.3003 5.79945C11.6903 6.18945 11.6903 6.81945 11.3003 7.20945C10.9103 7.58945 10.2703 7.59945 9.88027 7.20945Z" fill="black" />
                                </svg>
                            </div>

                            {loading ?
                                <div className="loader-wrapper">
                                    <Loader light={true} />
                                </div>
                                :
                                showAccounts && <div className='dropdown-content'>
                                    <div className="wrapper">

                                        {
                                            context.getAuthorizedUsers && context.getAuthorizedUsers.map((e, id) => (
                                                <div className='company-entry' onClick={() => switchCompany(e.company_id)} key={e.text + id}>{e.company_id} - {e.company_cnpj}</div>
                                            ))
                                        }
                                        <div className='company-entry' onClick={() => context.logOut()} >Logout</div>
                                    </div>
                                </div>}
                        </div>
                        <IconText image={require('../../assets/images/user-3.png').default} text={translate('myAccountLabel')} id="nav-orders" href={'/account'} />
                        {
                            context.getSub_business_segment !== "Stock Car" &&
                            <>
                                <IconText image={require('../../assets/images/cart.png').default} text={`${translate('cartLabel')} (${context.getCart ? context.getCart.length : '...'})`} href={'/cart'} />
                            </>

                        }
                        <IconText image={require('../../assets/images/orders.png').default} text={translate('myOrders')} id="nav-orders" href={'/pedidos'} />
                    </div> :
                    <div className="icon-wrapper">
                        <IconText image={require('../../assets/images/user.png').default} text="Faça seu login" href={'/login'} marginbottom0="true" />
                    </div>
                }
                <div class="dropdown-secondary">
                    <IconText image={require('../../assets/images/www.png').default} class="dropbtn" text={currentLanguage} id="nav-orders" href={'/account'} />
                    <div class="dropdown-content-secondary">
                        <a  onClick={() => updateLanguage('en')}>English</a>
                        <a  onClick={() => updateLanguage('pt')}>Portuguese</a>
                        <a  onClick={() => updateLanguage('es')}>Spanish</a>
                    </div>
                </div>

            </div>
            {
                context.getSub_business_segment !== "Private Label" && context.getSub_business_segment !== "Stock Car" &&
                <div className="navbar-secondary">
                    {predBox &&
                        <div className='overlay' onClick={(e) => e.relatedTarget === null ? setPredBox(false) : null}></div>}
                    <div className="categories-wrapper">
                        <div>
                            <p>{translate('searchBy').toUpperCase()}</p>
                            <p>{translate('category').toUpperCase()}</p>
                        </div>
                        <div className={`hamburger-container ${menu ? 'change' : null}`} onClick={() => toggleBurger()}>
                            <div className="hamburger-bar1"></div>
                            <div className="hamburger-bar2"></div>
                            <div className="hamburger-bar3"></div>
                        </div>
                    </div>
                    {(menu || desktop) && <div className="scroller">
                        <input className='navbar-input input-full' type="text" placeholder={translate('licensePlate')} onKeyDown={e => preSubmit(e)} value={license} onChange={handleLicense} />
                        <input type="text" name="line" list="line" className='navbar-input' placeholder={translate('line')} value={car.line} onChange={e => handleCarChange(e)} />
                        <datalist id="line" name="line" className={`navbar-input`}>
                            {
                                lineList && lineList.map((e, index) => (
                                    <option key={index + "line"} value={e}>{e}</option>
                                ))
                            }
                        </datalist>
                        <input type="text" list="make" name="make" className='navbar-input' placeholder={translate('manufacturer')} value={car.make} onChange={e => handleCarChange(e)} />
                        <datalist id="make" className={`navbar-input`}>
                            {
                                makeList && makeList.map((e, index) => (
                                    <option key={index + "make"} value={e}>{e}</option>
                                ))
                            }
                        </datalist>
                        <input type="text" list="model" name="model" className='navbar-input' placeholder={translate('vehicle')} value={car.model} onChange={e => handleCarChange(e)} />
                        <datalist id="model" className={`navbar-input`} >
                            <option value="">Veículo</option>
                            {
                                modelList && modelList.map((e, index) => (
                                    <option key={index + "model"} value={e}>{e}</option>
                                ))
                            }
                        </datalist>
                        <input type="text" list="motor" name="motor" className='navbar-input' placeholder={translate('engine')} value={car.motor} onChange={e => handleCarChange(e)} />
                        <datalist id="motor" className={`navbar-input`} >
                            <option value="">Motor</option>
                            {
                                motorList && motorList.map((e, index) => (
                                    <option key={index + "motor"} value={e}>{e}</option>
                                ))
                            }
                        </datalist>
                        <input className='navbar-input navbar-submit' type="submit" value={translate('searchLabel')} onClick={e => submitAdvancedSearch(e)} />
                        <input className='navbar-input navbar-submit reverse' value={translate('cleanLabel')} type="button" onClick={e => setCar({ line: '', make: '', model: '', motor: '' })} />
                    </div>}
                </div>
            }

        </div>
    )
}


export default Navigation;