import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import API from "utils/API";
import Loader from "components/Loader";
import Swal from 'sweetalert2';


function Security() {

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [twoFAStatus, setTwoFAStatus] = useState(false);
    const [loginWithEmail, setLoginWithEmail] = useState(false)
    const currentPassword = useRef();
    const newPassword = useRef();
    const confirmNewPassword = useRef();

    const update2FA = () => {
        const cancelToken = API.cancelToken();
        var status = !twoFAStatus ? 1 : 0;
        setTwoFAStatus(!twoFAStatus)
        API.setSecurityAuth(cancelToken, "2FA", status).then(res => {
        })
    }
    
    const updateLoginWithEmail = () => {
        const cancelToken = API.cancelToken();
        var status = !loginWithEmail ? 1 : 0;
        setLoginWithEmail(!loginWithEmail)
        API.setSecurityAuth(cancelToken, "email", status).then(res => {
        })
    }

    useEffect(() => {
    },[loginWithEmail])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        API.getSecurityParams(cancelToken).then(res => {
            if (res.data.user){
                let twoFa = res.data.user['2fa']
                let email_auth = res.data.user.email_auth
                
                setTwoFAStatus(twoFa ? true : false);
                setLoginWithEmail(email_auth ? true : false);

            }
        })
    }, [])

    const updatePass = (e) => {

        e.preventDefault();
        setError("");

        if (!currentPassword.current.value) {
            setError("Todos os campos exigem um valor");
            return;
        }
        if (!newPassword.current.value) {
            setError("Todos os campos exigem um valor");
            return;
        }
        if (!confirmNewPassword.current.value) {
            setError("Todos os campos exigem um valor");
            return;
        }
        if (newPassword.current.value !== confirmNewPassword.current.value) {
            setError("Nova senha e senha confirmada têm valores diferentes");
            return;
        }
        setLoading(true);
        const cancelToken = API.cancelToken();
        

        API.updatePassword(cancelToken, currentPassword.current.value, newPassword.current.value).then(res => {
            setLoading(false);
            Swal.fire({
                title: 'Sucesso!',
                text: 'Dados atualizados com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(s => {
                currentPassword.current.value = ''
                newPassword.current.value = ''
                confirmNewPassword.current.value = ''
            })
        }).catch(err => {
            console.log(err);
            if (err.message === 'cancelling') return
            setLoading(false);
            if (err.response && err.response.status === 401) {
                setError('Senha atual incorreta');
            } else {
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }

    }

    return (
        <div className="account-page-wrapper page-wrapper-white one-whole margin-top-50">
            <div className="section-wrapper-small one-whole">
                <div className="breadcrumbs flex-row-nowrap align-center justify-center">
                    <h4><Link to='/account' className='reset your-account'>Minha conta</Link></h4>
                    <svg className="svg-icon__arrow--right" viewBox="0 0 7 12" height="10px" width="10px" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#C3C4C5" fillRule="evenodd" d="M6.893 5.967c0-.268-.107-.524-.295-.713L1.724.37C1.33-.024.692-.024.3.37c-.394.394-.394 1.033 0 1.427l4.162 4.17L.3 10.137c-.394.394-.394 1.033 0 1.427.392.394 1.03.394 1.424 0L6.598 6.68c.188-.19.295-.445.295-.713z" />
                    </svg>
                    <p>Segurança</p>
                </div>

                <div className="seguranca-content margin-top-50">
                    <h4 className="margin-bottom-10">2FA | Login com E-mail</h4>
                    <div className="password-change">
                        <p className="paragraph  twofa-info">Ao ativar a autenticação de dois fatores, sempre que realizar o login no AZPartes.com , você receberá um código único de verificação no e-mail associado à sua conta. Vá ao seu e-mail e encontre uma mensagem com o código enviado pelo site. De volta ao site, insira o código fornecido no e-mail no local indicado e seu acesso ao AZPartes.com será liberado.</p>
                        <div className="div margin-top-20">
                            <div className="div-2">2FA</div>
                            <img
                                loading="lazy"
                                src={twoFAStatus ? "https://cdn.builder.io/api/v1/image/assets/TEMP/9363301ead81a147214bad1e08c8290eb01184cf4bc7d45fe4c21fdb4cb5431f?" : "https://cdn.builder.io/api/v1/image/assets/TEMP/696c1ec1509f98d5d91cc704c548ca7257506c0c20eb302e7ad3c35640db0c17?"}
                                className="img pointer"
                                onClick={update2FA}
                            />
                        </div>
                        <p className="paragraph  margin-top-20 twofa-info">Em vez de lembrar uma senha, você pode receber um código em seu e-mail sempre que precisar fazer login. Esse código temporário é único e só funciona por um curto período de tempo, a partir dele seu acesso ao AZPartes.com será liberado.</p>
                        <div className="div margin-top-20">
                            <div className="div-2">Login Sem Senha</div>
                            <img
                                loading="lazy"
                                src={loginWithEmail ? "https://cdn.builder.io/api/v1/image/assets/TEMP/9363301ead81a147214bad1e08c8290eb01184cf4bc7d45fe4c21fdb4cb5431f?" : "https://cdn.builder.io/api/v1/image/assets/TEMP/696c1ec1509f98d5d91cc704c548ca7257506c0c20eb302e7ad3c35640db0c17?"}
                                className="img pointer"
                                onClick={updateLoginWithEmail}
                            />
                        </div>
                        <style jsx>{`
        .div {
          justify-content: space-between;
          align-self: stretch;
          display: flex;
          gap: 20px;
          font-size: 20px;
          color: #151e26;
          font-weight: 700;
          white-space: nowrap;
          letter-spacing: 0.25px;
        }
        @media (max-width: 991px) {
          .div {
            flex-wrap: wrap;
            white-space: initial;
          }
        }
        .div-2 {
          font-family: SKF Chevin Catalogue, -apple-system, Roboto, Helvetica,
            sans-serif;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
          }
        }
        .img {
          aspect-ratio: 1.59;
          object-fit: auto;
          object-position: center;
          width: 40px;
          align-self: start;
        }
      `}</style>
                    </div>
                </div>

                <div className="seguranca-content margin-top-50">
                    <h4 className="margin-bottom-10">Alterar Senha</h4>
                    <div className="password-change">
                        <p className="paragraph margin-bottom-20">Use o formulário abaixo para efetuar a atualização da sua senha de acesso ao AZPartes.com</p>
                        <div className="senha margin-bottom-30">
                            <label className="senha margin-bottom-10">Senha Atual</label>
                            <input ref={currentPassword} placeholder="Senha" type="password" />
                        </div>
                        <div className="senha margin-bottom-30">
                            <label className="senha margin-bottom-10">Nova Senha</label>
                            <input ref={newPassword} placeholder="Nova Senha" type="password" />
                        </div>
                        <div className="senha margin-bottom-20">
                            <label className="senha margin-bottom-10">Confirmar Nova Senha</label>
                            <input ref={confirmNewPassword} placeholder="Confirmar Nova Senha" type="password" />
                        </div>
                        {error &&
                            <p className="error margin-bottom-20">
                                {error}
                            </p>
                        }
                        {
                            loading ?
                                <div className="loader-wrapper">
                                    <Loader light={true} />
                                </div> :
                                <input type="submit" className="action-button margin-bottom-20" onClick={(e) => updatePass(e)} value="ALTERAR SENHA" />

                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Security;