import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

function AccountCard(props) {

    const title = props.title;
    const content = props.content;
    const image = props.image;
    const link = props.link;
    const email = props.email;

    return (
        link ?
            <Link to={link} className="account-card">
                <div className="card-image">
                    <img className="image" src={image} />
                </div>
                <div className="card-body">
                    <div className="card-title">
                        {title}
                    </div>
                    <div className="card-content">
                        {content}
                    </div>
                </div>
            </Link> :
            <a href={email} className="account-card">
                <div className="card-image">
                    <img className="image" src={image} />
                </div>
                <div className="card-body">
                    <div className="card-title">
                        {title}
                    </div>
                    <div className="card-content">
                        {content}
                    </div>
                </div>
            </a>
    )
}

export default AccountCard;