import React, { useEffect, useState, useContext } from 'react';
import './style.css';
import { useHistory } from 'react-router';
import { AppContext } from 'utils/ContextProvider';
import Hero from 'components/Hero';
import MiniMenu from 'components/MiniMenu';
import Carousel from 'components/Carousel';
import Cards from 'components/Cards';
import API from 'utils/API';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { useLanguage } from '../../context/LanguageContext';

function Home() {
    const history = useHistory();
    const [promos, setPromos] = useState([]);
    const [recommended, setRecommended] = useState([]);
    const [searchHistory, setSearchHistory] = useState([]);
    const { translate } = useLanguage();
    const context = useContext(AppContext);

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Home',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })

        const cancelToken = API.cancelToken();
        API.getMaintenanceStatus(cancelToken).then(res => {
            if(res.data.system_status == 1){
                window.location.href = '/maintenance'
            }
        }).catch(err => {
            console.log(err);
        })

    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if(context.getSub_business_segment == "Stock Car"){
            history.push('/stockcar');
            return;
        }
        if(context.getSub_business_segment === "Private Label"){
            history.push('/private');
            return;    
        }
        const cancelToken = API.cancelToken();
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getHighlightedProducts(cancelToken).then(res => {
            setPromos(res.data.products);
            console.log(res.data.products); 
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.response);
        });
        API.getRecommendedProducts(cancelToken).then(res => {
            setRecommended(res.data.products);
        }).catch(err => {
            setRecommended(null);
            if (err.message === 'cancelling') return
            console.log(err.response);
        });
        API.getSearchHistory(cancelToken).then(res => {
            setSearchHistory(res.data.products);
        }).catch(err => {
            setSearchHistory(null);
            if (err.message === 'cancelling') return
            if (err.response && err.response.status === 404) return
        });
        return () => {
            API.cancel(cancelToken);

        }
    }, []);

    return (
        <div className="page-wrapper-grey one-whole">
            <Hero />
            <div className="section-wrapper one-whole mt--300">
                <div className="flex-row">
                    <MiniMenu />
                    <Carousel header={translate('highlightedProducts')} list={promos} />
                </div>
            </div>
            {(context.getLogin && recommended !== null) &&
                <div className="section-wrapper one-whole">
                    <Carousel header={`${translate('recomendedTo')} ${context.getLogin ? context.getLogin : 'você'}`} list={recommended} />
                </div>
            }
            {(context.getLogin && searchHistory !== null) &&
                <div className="section-wrapper one-whole">
                    <Carousel header={translate('navigationHistory')} list={searchHistory} />

                </div>
            }
            
            {context.getSub_business_segment == "SKF Center" &&
                <div className="section-wrapper one-whole">
                    <Cards title1="Rolamentos de Roda" search1='1' image1={require('../../assets/images/rolamentos-de-roda-534x444.png')}
                        title2="Atuadores Hidráulicos" search2="9" image2={require('../../assets/images/atuadores-hidraulicos-534x444.png')}
                        title3="Kits de Sincronismo" search3="7" image3={require('../../assets/images/kits-de-sincronismo-534x444.jpg')}
                        title4="Componentes de Suspensão" search4="10" image4={require('../../assets/images/componentes-para-suspensao-534x444.jpg')} />
                </div>
            }

            {context.getSub_business_segment == "VA Distributor" &&
                <div className="section-wrapper one-whole">
                    <Cards 
                        title1="Linha Agrícola" search1="0" type1="Linha%20Agrícola" image1={require('../../assets/images/linha-agricola-534x444.jpg')} 
                        title2="Linha Pesada" search2="0" type2="Linha%20Pesada" image2={require('../../assets/images/linha-pesada-534x444.jpg')}
                        title3="Atuadores Hidráulicos" search3="9" image3={require('../../assets/images/atuadores-hidraulicos-534x444.png')}
                        title4="Rolamentos de Roda" search4='1' image4={require('../../assets/images/rolamentos-de-roda-534x444.png')}/>
                </div>
            }
        </div>
    )
}

export default Home;