import { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import './style.css';
import * as XLSX from 'xlsx';
import API from 'utils/API';
import Loader from 'components/Loader';
import QuantityPicker from 'components/QuantityPicker';
import AutoCompleteInput from 'components/AutoCompleteInput';
import { AppContext } from 'utils/ContextProvider';
import Swal from 'sweetalert2';
import ReactGA, { set } from 'react-ga';
import { hotjar } from 'react-hotjar';
import GlobalFuncs from 'utils/GlobalFuncs';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

function ComprasEmLote() {

    const intro = introJs();
    const history = useHistory();
    const context = useContext(AppContext);
    const searchField = useRef();
    const [list, setList] = useState([]);
    const [hasSchedule, setHasSchedule] = useState(false);
    const [globalListOfUnavailables, setGlobalListOfUnavailables] = useState([]);
    const [listOfErrors, setListOfErrors] = useState([]);
    const [itemCheckedList, setItemCheckedList] = useState([]);
    const [gradientColor, setGradientColor] = useState('linear-gradient(to right, #131921 0%,#E4E4E4 0%)');
    const [percentageLoaded, setPercentageLoaded] = useState(0)
    const [loadingMessage, setLoadingMessage] = useState('Arraste e solte os arquivos para fazer o envio');
    const [submitButtonMessage, setSubmitButtonMessage] = useState('Adicionar ao carrinho');
    const [quoteButtonMessage, setQuoteButtonMessage] = useState('Cotar Indisponíveis');
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [itemLoading, setItemLoading] = useState(-1);
    const [submitted, setSubmitted] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState('');
    const [disableCartBtn, setDisableCartBtn] = useState('');
    const [nbOfItems, setNbOfItems] = useState(0);
    const [displayList, setDisplayList] = useState([]);
    const [displayListArray, setDisplayListArray] = useState([]);
    const [searchInput, setSearchInput] = useState(false);
    const [editing, setEditing] = useState(false);
    const [sortDirection, setSortDirection] = useState(0);
    const [suggestions, setSugestions] = useState([]);

    // WHEN FILE IS UPLOADED
    const processFile = async (file) => {
        setLoading(true)
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const base64 = btoa(evt.target.result);
            console.log("Uploading template...")
            const cancelToken = API.cancelToken();
            console.log(base64)
            API.uploadTemplate(cancelToken, base64).then(res => {
                console.log("Template uploaded successfully")
              })
            const binaryString = evt.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });
            /* Get first worksheet */
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
            /* Update state */
            setList(data);
        };
        reader.readAsBinaryString(file);
    }

    // UPDATING THE LIST
    useEffect(() => {
        // console.log(list);
        const cancelToken = API.cancelToken();
        if (percentageLoaded > 90) {
            if (list.length === 0) return;
            const cancelToken = API.cancelToken();
            loadList(cancelToken);
        } else {
            return () => {
                API.cancel(cancelToken);
            }
        }
    }, [list, percentageLoaded]);


    useEffect(() => {
        const variation = 12;
        const interval = setInterval(() => {
            if (percentageLoaded < 98 && loading) {
                console.log(`Replacing ${percentageLoaded}% by ${percentageLoaded + variation}%`)
                const newGradient = gradientColor.replace(new RegExp(`${percentageLoaded}%`, 'g'), `${percentageLoaded + variation}%`);
                setGradientColor(newGradient);
                setPercentageLoaded(percentageLoaded + variation)
            }
        }, Math.floor(Math.random() * (1200 - 10)) + 10);

        return () => {
            clearInterval(interval);
        };
    }, [percentageLoaded, loading]);

    useEffect(() => {
        var errors = new Set()
        var unavailableItems = []
        var item_quantity = 0
        for (let [index, e] of displayListArray.entries()) {
            if (e.quantity != undefined) {
                item_quantity += e.quantity
            }
            if (e.status != "Em Estoque") {
                unavailableItems.push(e)
                errors.add(e.status)
            }
        }
        if (unavailableItems) {
            setGlobalListOfUnavailables(unavailableItems)
        }
        setListOfErrors(errors)
        setNbOfItems(item_quantity)
        setItemLoading(-1)
    }, [displayListArray])

    function getStatus(product) {
        console.log(`Getting status of product ${product.skf_product_id}`)
        if (product.status == "NOT_FOUND") {
            console.log("Option 2")
            return ["Inválido", "SKU inválido."]
        }
        if (product.multiple_rule == "NOT_COMPLIANT") {
            console.log("Option 4")
            return ["Regra de Múltiplo", "4"]
        }
        if (product.status == "OUT_OF_STOCK") {
            console.log("Option 3")
            return ["Indisponível", "3"]
        }
        if (product.status == "PARTIAL_STOCK") {
            console.log("Option 6")
            return ["Parcialmente Disponível", "6"]
        }
        console.log("Option 5")
        return ["Em Estoque", "5"]
    }

    function startTour() {
        console.log("Starting tour")
        localStorage.removeItem('help_tour')
        intro.setOptions({
            nextLabel: 'Póximo',
            prevLabel: 'Anterior',
            doneLabel: 'OK',
            steps: [
                {
                    element: document.querySelector('#step_1'),
                    intro: "Clique aqui para baixar o modelo de planilha.",
                }, {
                    element: document.querySelector('#drop_zone'),
                    intro: "Após o preenchimento da planilha, arraste e solte nessa área ou clique e faça o upload",
                }
            ]
        });

        intro.start();
    }

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if(context.getSub_business_segment == "Stock Car"){
            history.push('/stockcar');
            return;
        }
        
        API.getProductList(cancelToken).then(res => {
            setSugestions(res.data.products)
        })

        if (!localStorage.getItem('help_tour')) {
            intro.setOptions({
                nextLabel: 'Póximo',
                prevLabel: 'Anterior',
                doneLabel: 'OK',
                steps: [
                    {
                        element: document.querySelector('#step_1'),
                        intro: "Clique aqui para baixar o modelo de planilha.",
                    }, {
                        element: document.querySelector('#drop_zone'),
                        intro: "Após o preenchimento da planilha, arraste e solte nessa área ou clique e faça o upload",
                    }
                ]
            });

            intro.start();
        }


    }, [])

    function isDateInFuture(dateStr) {
        // Parse the date string in dd/mm/yyyy format to a Date object
        const [day, month, year] = dateStr.split('/').map(Number);
        const inputDate = new Date(year, month - 1, day); // Month is 0-indexed in JS Date
      
        // Get today's date with time set to 00:00:00 for accurate comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to the start of the day
      
        // Check if the input date is in the past compared to today
        if (inputDate < today) {
          return false; // The input date is in the past
        }
        return true; // The input date is today or in the future
      }


    const formatDate = (date) => {
        if(date == undefined || date == null) return date
        var split_date = date.split("/")
        let day = split_date[0]
        let month = split_date[1]
        let year = split_date[2]
        return `${year}-${month}-${day}`
    }


    const loadList = async (cancelToken) => {
        // list[0] -> Excel Header
        // list[1] -> Item 1...
        // list[2] -> Item 2...
        var produt_list = []
        var newItemCheckedList = []
        var listOfUnavailables = []
        var item_quantity = 0
        for (let [index, e] of list.entries()) {
            if (index == 0) continue
            let res = await API.getId(cancelToken, encodeURIComponent(list[index][0]), list[index][1]);
            var product = res.data.product
            var obj = product
            obj.skf_product_id = product.skf_product_id
            obj.new_quantity = product.quantity
            item_quantity += product.quantity
            obj.delivery = product.delivery
            obj.multiple = product.multiple
            obj.editCode = false
            obj.status = getStatus(product)[0]
            obj.error_message = getStatus(product)[1]
            if (list[index][2] != undefined && list[index][2] != "") {
                if(isDateInFuture(list[index][2])){
                    setHasSchedule(true)
                    obj.scheduled_date = list[index][2]
                }
            }
            if (obj.status == "Indisponível") {
                listOfUnavailables.push(obj)
            }
            produt_list.push(obj)
            newItemCheckedList.push(false)
        }
        setGlobalListOfUnavailables(listOfUnavailables)
        setNbOfItems(item_quantity)
        setItemCheckedList(newItemCheckedList)
        setDisplayListArray(produt_list);
        setLoading(false);
        setPercentageLoaded(0)
        setSubmitted(true);

        if (!localStorage.getItem('help_tour')) {
            const intro_2 = introJs();
            intro_2.setOptions({
                nextLabel: 'Póximo',
                prevLabel: 'Anterior',
                doneLabel: 'OK',
                steps: [
                    {
                        element: document.querySelector('#step_2'),
                        intro: "Clique aqui caso deseje alterar o código do produto.",
                    }, {
                        element: document.querySelector('#step_3'),
                        intro: "Clique aqui caso deseje excluir o produto da sua lista.",
                    }, {
                        element: document.querySelector('#step_4'),
                        intro: "Verifique se existem produtos inválidos ou que não cumpram as regras de múltiplo.",
                    }, {
                        element: document.querySelector('#step_5'),
                        intro: "Após a validação, clique aqui para adicionar os produtos no carrinho.",
                    }, {
                        element: document.querySelector('#step_6'),
                        intro: "Ou solicite uma cotação para os produtos indisponíveis.",
                    }
                ]
            });

            intro_2.start();
            localStorage.setItem('help_tour', true);

        }

    }


    // HANDLE ACTIONS  

    function cancelar() {
        setList([]);
        setSubmitted(false);
        setEditing(false);
        setProceed(false);
    }

    function showHint() {
        setHint(true)
    }

    function hideHint() {
        setHint(false)
    }

    function editar() {
        if (editing === false) {
            setEditing(true);
        } else {
            setEditing(false);
        }
    }


    const onDrop = (e) => {
        e.preventDefault();
        let file = e.dataTransfer.files[0];
        setLoadingMessage('Carregando os dados do arquivo...')
        setBtnDisabled('import-disabled')

        processFile(file);
    }


    const onSelect = (e) => {
        e.preventDefault();
        let file = e.currentTarget.files[0];
        processFile(file);
    }

    const onDragEnter = (e) => {
        e.preventDefault();
        // e.currentTarget.style.backgroundColor = '#e9e6e6';
        e.currentTarget.style.border = '1px solid #D8D8D8';
    }


    const onDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.border = '1px dashed #D8D8D8';
    }

    function remove(index) {
        let newList = [...displayListArray]
        newList.splice(index, 1)
        setDisplayListArray(newList);
    }

    function removeList() {
        let newList = [...displayListArray]
        for (let index = itemCheckedList.length - 1; index >= 0; index--) {
            if (itemCheckedList[index]) {
                newList.splice(index, 1);
            }
        }
        setDisplayListArray(newList);
    }

    function setToEdit(index) {
        let newList = [...displayListArray]
        newList[index].editCode = true
        setDisplayListArray(newList);
    }

    function filterBySearch() {
        if (searchInput)
            setSearchInput(false);
        else
            setSearchInput(true);
    }

    function cotar(e) {
        if (quoteButtonMessage == "Solicitando cotação..." || submitButtonMessage == 'Adicionando ao carrinho...') return
        if (listOfErrors.has('Regra de Múltiplo') || listOfErrors.has('Inválido')) {
            return;
        }
        var submitMessage = "Solicitando cotação..."
        setQuoteButtonMessage(submitMessage)
        const cancelToken = API.cancelToken();
        var listOfUnavailables = []
        var listOfAvailables = []
        var partially_available = false
        for (let [index, e] of displayListArray.entries()) {
            if (e.status == "Parcialmente Disponível") {
                partially_available = true
                let amount = e.delivery['null']
                var product_to_add = e
                product_to_add.quantity = amount
                listOfUnavailables.push(product_to_add)

                Object.entries(e.delivery).forEach(([key, value]) => {
                    if (key != "null") {
                        var product_to_add = e
                        product_to_add.quantity = value
                        listOfAvailables.push(product_to_add)
                    }
                });
            }
            if (e.status == "Indisponível") {
                listOfUnavailables.push(e)
            }
            if (e.status == "Em Estoque") {
                listOfAvailables.push(e)
            }
        }

        if (listOfUnavailables.length == 0) {
            return
        }

        Swal.fire({
            html: 'Produtos com status Em Estoque e Parcialmente Disponível, serão adicionados ao carrinho as quantidades com Prazo de Entrega Estimado. </br></br>De acordo?',
            icon: 'info',
            iconColor: "#131921",
            showCancelButton: true,
            confirmButtonColor: "#131921",
            cancelButtonText: "Não",
            cancelButtonColor: "#a3a3a3",
            confirmButtonText: 'Sim',
            cancelButtonTextColor: "#ffffff",
            customClass: "swal-wide"
        }).then(async (result) => {
            if (result.isConfirmed) {
                API.requestMultiUnavailable(cancelToken, listOfUnavailables).then(res => {
                    var availability_request_id = res.data.availability_request_id
                    var infoMessage = listOfAvailables.length > 0 ? `Requisição de disponibilidade #${availability_request_id} enviada com sucesso.\n Os produtos disponíveis estão sendo adicionados ao carrinho.` : `Requisição de disponibilidade #${availability_request_id} enviada com sucesso.`
                    const str = infoMessage
                    Swal.fire({
                        html: '<pre>' + str + '</pre>',
                        icon: 'success',
                        iconColor: "#2C8F67",
                        showCancelButton: false,
                        confirmButtonColor: "#000000",
                        confirmButtonText: 'OK',
                        customClass: "swal-wide"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            if (listOfAvailables.length > 0) {
                                let nbOfItems = context.getCart.length;
                                if (nbOfItems > 0) {
                                    Swal.fire({
                                        text: "Deseja limpar o carrinho para adicionar os produtos da planilha?",
                                        icon: 'warning',
                                        iconColor: "#FAD0AB",
                                        showCancelButton: true,
                                        confirmButtonColor: "#131921",
                                        cancelButtonText: "Não",
                                        cancelButtonColor: "#8F2C2F",
                                        confirmButtonText: 'Sim',
                                        customClass: "swal-wide"
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            try {
                                                const cancelToken = API.cancelToken();
                                                await API.deleteFromCart(cancelToken, null, true)
                                                context.setCart([]);
                                            } catch (err) {
                                                console.log(err);
                                                Swal.fire({
                                                    title: 'Erro!',
                                                    text: 'Erro ao limpar carrinho. Tente novamente!',
                                                    icon: 'warning',
                                                    confirmButtonText: 'Ok'
                                                });
                                                return
                                            }
                                            const cancelToken = API.cancelToken();
                                            addItemToCart(cancelToken);
                                        } else {
                                            const cancelToken = API.cancelToken();
                                            addItemToCart(cancelToken);
                                        }
                                    })
                                } else {
                                    const cancelToken = API.cancelToken();
                                    addItemToCart(cancelToken);
                                }
                            } else {
                                history.push("/")
                            }
                        }
                    })
                }).catch(err => {
                    console.log("Failed")
                    console.log(err)
                    Swal.fire({
                        text: "Ops! Não foi possível fazer o pedido de cotação. Tente novamente mais tarde.",
                        icon: 'error',
                        iconColor: "#992636",
                        confirmButtonColor: "#000000",
                        confirmButtonText: 'OK',
                        customClass: "swal-wide"
                    })
                    setQuoteButtonMessage('Cotar Indisponíveis')
                })
            }
        })
    }


    function adicionar() {
        if (submitButtonMessage == 'Adicionando ao carrinho...' || quoteButtonMessage == "Solicitando cotação...") return
        if (listOfErrors.has('Regra de Múltiplo') || listOfErrors.has('Inválido')) {
            return;
        }
        var partially_available = false
        for (let [index, e] of displayListArray.entries()) {
            if (e.status == "Parcialmente Disponível" || e.status == "Indisponível") {
                partially_available = true
            }
        }
        setSubmitButtonMessage('Adicionando ao carrinho...')
        let nbOfItems = context.getCart.length;
        if (partially_available) {
            Swal.fire({
                title: 'Atenção',
                text: 'Os produtos disponíveis e indisponíveis serão adicionados ao carrinho. Para verificação do prazo de entrega dos produtos indisponíveis, basta acessar a guia de “Meus Pedidos” após o registro do pedido nos sistemas SKF. Deseja adicionar os produtos ao carrinho?',
                confirmButtonColor: "#131921",
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (nbOfItems > 0) {
                        Swal.fire({
                            text: "Deseja limpar o carrinho para adicionar os produtos da planilha?",
                            icon: 'warning',
                            iconColor: "#FAD0AB",
                            showCancelButton: true,
                            confirmButtonColor: "#131921",
                            cancelButtonText: "Não",
                            cancelButtonColor: "#8F2C2F",
                            confirmButtonText: 'Sim',
                            customClass: "swal-wide"
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                try {
                                    const cancelToken = API.cancelToken();
                                    await API.deleteFromCart(cancelToken, null, true)
                                    context.setCart([]);
                                    // reader.readAsBinaryString(file);
                                } catch (err) {
                                    console.log(err);
                                    Swal.fire({
                                        title: 'Erro!',
                                        text: 'Erro ao limpar carrinho. Tente novamente!',
                                        icon: 'warning',
                                        confirmButtonText: 'Ok'
                                    });
                                    return;
                                }
                                const cancelToken = API.cancelToken();
                                addItemToCart(cancelToken);
                            } else {
                                const cancelToken = API.cancelToken();
                                addItemToCart(cancelToken);
                            }
                        })
                    } else {
                        const cancelToken = API.cancelToken();
                        addItemToCart(cancelToken);
                    }
                } else {
                    setSubmitButtonMessage('Adicionar ao carrinho')
                    return
                }
            })
        } else {
            if (nbOfItems > 0) {
                Swal.fire({
                    text: "Deseja limpar o carrinho para adicionar os produtos da planilha?",
                    icon: 'warning',
                    iconColor: "#FAD0AB",
                    showCancelButton: true,
                    confirmButtonColor: "#131921",
                    cancelButtonText: "Não",
                    cancelButtonColor: "#8F2C2F",
                    confirmButtonText: 'Sim',
                    customClass: "swal-wide"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const cancelToken = API.cancelToken();
                            await API.deleteFromCart(cancelToken, null, true)
                            context.setCart([]);
                            // reader.readAsBinaryString(file);
                        } catch (err) {
                            console.log(err);
                            Swal.fire({
                                title: 'Erro!',
                                text: 'Erro ao limpar carrinho. Tente novamente!',
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            });
                            return;
                        }
                        const cancelToken = API.cancelToken();
                        addItemToCart(cancelToken);
                    } else {
                        const cancelToken = API.cancelToken();
                        addItemToCart(cancelToken);
                    }
                })
            } else {
                const cancelToken = API.cancelToken();
                addItemToCart(cancelToken);
            }
        }

    }


    const addItemToCart = async (cancelToken, onlyDisponiveis = false) => {
        var somethingWentWrong = false;
        for (let [index, e] of displayListArray.entries()) {
            try {
                if (e.status == "Em Estoque" || e.status == "Indisponível" || e.status == "Parcialmente Disponível") {
                    var res = await API.addToCart(e.product_id, e.new_quantity, 'no', null, formatDate(e.scheduled_date));
                }
            } catch (err) {
                console.log(`Something went wrong on adding product ${e.product_id} - ${e.new_quantity} to cart`);
                console.log(err);
                somethingWentWrong = true
            }
        }
        if (!somethingWentWrong) {
            history.push("/cart");
        } else {
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            setSubmitButtonMessage('Adicionar ao carrinho')
        }
    }

    const updateProduct = async (skf_product_id, index, amount) => {

        const cancelToken = API.cancelToken();
        console.log(skf_product_id)
        let newList = [...displayListArray]
        setItemLoading([index])
        let res = await API.getId(cancelToken, encodeURIComponent(skf_product_id), amount);
        var product = res.data.product
        var obj = product
        obj.skf_product_id = product.skf_product_id
        obj.new_quantity = product.new_quantity
        obj.delivery = product.delivery
        obj.multiple = product.multiple
        obj.editCode = false
        obj.status = getStatus(product)[0]
        obj.error_message = getStatus(product)[1]
        newList[index] = obj
        setDisplayListArray(newList)

    }

    const updateQuantity = async (amount, index) => {
        const cancelToken = API.cancelToken();
        let newList = [...displayListArray]
        setItemLoading([index])
        var scheduled_date = displayListArray[index].scheduled_date
        let res = await API.getId(cancelToken, encodeURIComponent(displayListArray[index].skf_product_id), amount);
        var product = res.data.product
        var obj = product
        obj.skf_product_id = product.skf_product_id
        obj.new_quantity = product.new_quantity
        obj.delivery = product.delivery
        obj.scheduled_date = scheduled_date
        obj.multiple = product.multiple
        obj.editCode = false
        obj.status = getStatus(product)[0]
        obj.error_message = getStatus(product)[1]
        newList[index] = obj
        setDisplayListArray(newList)
    }

    const handleChecked = (index) => (event) => {
        var newItemCheckedList = [...itemCheckedList]
        newItemCheckedList[index] = event.target.checked;
        setItemCheckedList(newItemCheckedList)
    };

    const selectAllChecboxes = (event) => {
        const isChecked = event.target.checked;
        setItemCheckedList(Array(displayListArray.length).fill(isChecked));
    };

    function sortList(parameter) {
        let newDirection = sortDirection === 1 ? 0 : 1;
        setSortDirection(newDirection)
        var newDisplayList = [...displayListArray].sort((a, b) => {
            const aValue = a.new_quantity !== undefined ? a.new_quantity : 0;
            const bValue = b.new_quantity !== undefined ? b.new_quantity : 0;
            if (newDirection == 1) {
                return aValue - bValue;
            } else {
                return bValue - aValue;
            }
        });
        setDisplayListArray(newDisplayList)
    }

    function addDaysFromDate1ToDate2(date1Str, date2Str) {

        if(date1Str == undefined || date1Str == null) return date2Str;
        // Helper function to parse dd/mm/yyyy formatted string to Date
        function parseDateString(dateStr) {
          const parts = dateStr.split('/');
          // Note: months are 0-based in JavaScript Date
          return new Date(parts[2], parts[1] - 1, parts[0]);
        }
      
        // Calculate the difference in days from today until date 1
        const today = new Date();
        const date1 = parseDateString(date1Str);
        const diffTime = date1 - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
      
        // Add the difference in days to date 2
        const date2 = parseDateString(date2Str);
        date2.setDate(date2.getDate() + diffDays);
      
        // Format the new date2 back to dd/mm/yyyy
        const day = String(date2.getDate()).padStart(2, '0');
        const month = String(date2.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date2.getFullYear();
      
        return `${day}/${month}/${year}`;
      }

    return (
        <div className="lot-purchase-wrapper page-wrapper-white one-whole margin-bottom-50">
            <input className="hidden" placeholder="Search..." ref={searchField} onChange={(e) => setSearchValue(e.currentTarget.value)} />
            <div className="flex-row space-between align-start one-whole">
                <div className="compras-em-lote">
                    <div className='flex-row-nowrap align-center'>
                        <h3 className="margin-bottom-20 mr-3">Compras Em Lote</h3>
                        {/* <button class="custom-button">Necessita de ajuda?</button> */}
                    </div>
                    {
                        !submitted &&
                        <div>
                            <p className="small-p">Essa função permite a compra de múltiplos produtos através de uma planilha.</p>
                            <p className="small-p margin-bottom-20">Para seguir, basta realizar o download da planilha modelo, preencher os produtos e quantidades que necessita comprar e carregar o arquivo.</p>
                        </div>
                    }
                </div>
                <div className="flex-row-nowrap download">
                    {/* <img src={require('../../assets/images/excel.png').default} alt="Excel" /> */}
                    {
                        !submitted &&
                        <div className='flex-row-nowrap align-center'>
                            <div className="action-button mr-3" id="step_1" onClick={() => window.open('https://skfbuy-public-assets.s3.amazonaws.com/cart_template.xlsx', '_blank')}>
                                BAIXAR MODELO
                            </div>
                            <div className="action-button mr-3 light" id="step_1" onClick={startTour}>
                                AJUDA
                            </div>


                        </div>

                    }
                </div>
            </div>
            {
                !submitted ?

                    <div id="drop_zone" className="margin-top-20 drop-zone"
                        onDrop={e => onDrop(e)}
                        onDragEnter={e => onDragEnter(e)}
                        onDragLeave={e => onDragLeave(e)}
                        onDragStart={e => e.preventDefault()}
                        onDragEnd={e => e.preventDefault()}
                        onDragOver={e => e.preventDefault()}
                        onDrag={e => e.preventDefault()}
                    >

                        <div className="upload-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-upload" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                <polyline points="7 9 12 4 17 9" />
                                <line x1="12" y1="4" x2="12" y2="16" />
                            </svg>
                        </div>
                        <p className="paragraph">{loadingMessage}</p>
                        <br /><br />
                        <label htmlFor="file-select" id='file-select-button' className={`action-button ${btnDisabled}`}>SELECIONAR ARQUIVOS</label>
                        <input type="file" id="file-select" className='file-select' name="myfile" onChange={(e) => onSelect(e)}></input>
                        {
                            loading &&
                            // <Loader />
                            <p className="skill" style={{
                                background: gradientColor,
                                marginTop: 60,
                                width: 1000
                            }}>
                                <span></span>
                            </p>
                        }


                    </div> :

                    <div className="after-submit">
                        <div className='main-content'>
                            <div className="table-container">
                                <div className='results-header'>
                                    Produtos Identificados
                                </div>
                                <div className="table">
                                    <div className="table-head border-bottom">
                                        <div className="selection-box ml-28">
                                            <input
                                                className="checkbox"
                                                type="checkbox"
                                                onChange={selectAllChecboxes}
                                            />
                                        </div>

                                        <div className="code pb-2 pt-2 pr-5 flex-row align-center">

                                            <div className='w-full mb-1'>
                                                {
                                                    searchInput ?
                                                        <input className="searchInput" placeholder="Search..." ref={searchField} onChange={(e) => setSearchValue(e.currentTarget.value)} />

                                                        :
                                                        <div className='flex-row-nowrap'>
                                                            <p>Código do Produto</p>
                                                            <div className="svg-box" onClick={filterBySearch}>
                                                                {GlobalFuncs.searchSVG()}
                                                            </div>
                                                        </div>

                                                }
                                            </div>
                                            <div className='flex-row-nowrap space-between editing-btn margin-top-10'>
                                                <p className='delete-item pointer' onClick={removeList}>Excluir Selecionados</p>
                                            </div>
                                        </div>

                                        <div className="quantity flex-center text-center">
                                            Quantidade
                                            <div className="svg-box" onClick={sortList}>
                                                {
                                                    sortDirection === 1 ?
                                                        GlobalFuncs.orderDescSVG()
                                                        :
                                                        GlobalFuncs.orderAscSVG()
                                                }
                                            </div>
                                        </div>
                                        <div className="status-row flex-center">
                                            Status
                                        </div>
                                        {
                                            hasSchedule &&
                                            <div className="status-div flex-center">
                                                Data de Entrega Desejada
                                            </div>
                                        }
                                        <div className="status-div flex-center">
                                            Prazo de Entrega Estimado
                                        </div>
                                    </div>
                                    {
                                        displayListArray.length < 1 ?

                                            <p>No results found.</p>
                                            :

                                            displayListArray.map((item, index) => {
                                                return (
                                                    <div className="table-row flex-center">
                                                        <div key={index} className="selection-box border-right pb-2 pt-2">
                                                            <input
                                                                className="checkbox"
                                                                type="checkbox"
                                                                onChange={handleChecked(index)}
                                                                checked={itemCheckedList[index]}
                                                            />
                                                        </div>
                                                        {/* ROW - Código Produto */}
                                                        <div className="w-20p border-right pb-2 pt-2 pr-5 flex-col-nowrap align-center">

                                                            <p className='w-full mb-5'>
                                                                {
                                                                    item.editCode ?
                                                                        // <input className="searchInput" index={index} placeholder="" onBlur={(e) => updateProduct(e.currentTarget.value, index, item.new_quantity)} />
                                                                        <AutoCompleteInput index={index} suggestions={suggestions} onBlur={(e) => updateProduct(e.currentTarget.value, index, item.new_quantity)} />
                                                                        :
                                                                        <p>{item.skf_product_id}</p>

                                                                }

                                                            </p>
                                                            <div className='flex-row-nowrap flex-left mt-2 editing-btn '>
                                                                <p className='mr-4 edit-item pointer' id="step_2" onClick={() => { setToEdit(index) }}>Editar código</p>
                                                                <p className='delete-item pointer' id="step_3" onClick={() => { remove(index) }}>Excluir Item</p>
                                                            </div>
                                                        </div>

                                                        {/* ROW - Amount */}
                                                        <div className="quantity border-right pb-2 pt-2 flex-center">
                                                            {
                                                                itemLoading == index &&
                                                                <p>Carregando...</p>
                                                            }

                                                            <QuantityPicker
                                                                quantity={item.new_quantity}
                                                                multiple={item && item.multiple ? item.multiple : 1}
                                                                index={index}
                                                                nolabel={true}
                                                                onChange={updateQuantity}
                                                                hidden={itemLoading == index}
                                                            />

                                                        </div>

                                                        {/* ROW - Status */}
                                                        <div className={`status-row border-right pb-2 pt-2 flex-center`}>
                                                            {
                                                                itemLoading == index ?
                                                                    <p>Carregando...</p>
                                                                    :
                                                                    <div className={` status-cell ${item.status == "Em Estoque" ? 'success' : item.status == "Parcialmente Disponível" ? 'warning' : item.status == "Inválido" || item.status == "Regra de Múltiplo" ? 'danger' : 'neutral'} `}>
                                                                        {item.status}
                                                                    </div>
                                                            }

                                                            {/* {item.status && (item.status == "Regra de Múltiplo" || item.status == "Inválido") && !itemLoading == index &&
                                                                <div className="tooltip ml-3">
                                                                    <img className='info-img pointer'
                                                                        height={20}
                                                                        width={20}
                                                                        src={require("../../assets/images/info-icon-lg.png").default}
                                                                        alt="" />
                                                                    <span className="tooltiptext">
                                                                        {
                                                                            item.status == "Regra de Múltiplo" ?
                                                                                'Produto só pode ser adquirido por múltiplos de 5. Por favor, corrija a quantidade.' :
                                                                                suggestions.includes(item.skf_product_id) ? 'Desculpe, esse produto não foi encontrado em nossa loja.' : 'SKU inválido.'
                                                                        }
                                                                    </span>
                                                                </div>
                                                            } */}
                                                        </div>
                                                        {
                                                            hasSchedule &&
                                                            <div className="status-div pb-2 pt-2">
                                                                <div className="status ">
                                                                    <div className="delivery-cell flex-center">
                                                                        <div className='flex-row flex-center border-right pb-2 pt-2'>
                                                                            <p>{item.scheduled_date}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* ROW - Prazo de Entrega */}
                                                        <div className="status-div pb-2 pt-2">
                                                            <div className="status ">
                                                                <div className="delivery-cell flex-center">
                                                                    {
                                                                        itemLoading == index &&
                                                                        <p>Carregando...</p>
                                                                    }

                                                                    {
                                                                        item.delivery && !(itemLoading == index) && (
                                                                            Object.entries(item.delivery).map(([key, value]) => (
                                                                                <div key={key} className='flex-row flex-center'>
                                                                                    <p className='mr-1' key={index + 'lines'}>{`${value} peças:`}</p>
                                                                                    <p>{key === 'null' ? 'Indisponível' : addDaysFromDate1ToDate2(item.scheduled_date, key)}</p>
                                                                                </div>
                                                                            ))
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                    }
                                    <div className="table-footer pr-4">
                                        <p className='mr-3'>Quantidade de produtos <strong>{displayListArray.length}</strong></p>
                                        <p>Quantidade de itens <strong>{nbOfItems}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="action-container">
                                <div className='header'>
                                    Status geral
                                </div>
                                <div className='content' id='step_4'>
                                    {
                                        listOfErrors.has('Regra de Múltiplo') || listOfErrors.has('Inválido') ?
                                            <div>
                                                <p>Por favor, corrigir o(s) produto(s) com status:</p>
                                                <ul>
                                                    {
                                                        listOfErrors.has('Inválido') &&
                                                        <li>Inválido</li>
                                                    }
                                                    {
                                                        listOfErrors.has('Regra de Múltiplo') &&
                                                        <li>Sem cumprir regras de múltiplo</li>
                                                    }
                                                </ul>
                                                <p>Após a correção será possível seguir com o processo de compra</p>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        (!listOfErrors.has('Regra de Múltiplo') && !listOfErrors.has('Inválido')) &&
                                        <p>Como deseja seguir com o pedido?</p>

                                    }
                                    <div className="buttons">
                                        <div onClick={adicionar} id='step_5' className={`action-button adicionar ${disableCartBtn} buttons margin-top-20 ${listOfErrors.has('Regra de Múltiplo') || listOfErrors.has('Inválido') ? 'disable' : ''}`}>
                                            {submitButtonMessage}
                                        </div>
                                        {
                                            globalListOfUnavailables.length > 0 &&
                                            <div onClick={cotar} id='step_6' className={`action-button adicionar ${disableCartBtn} buttons margin-top-10 ${listOfErrors.has('Regra de Múltiplo') || listOfErrors.has('Inválido') ? 'disable' : ''}`}>
                                                {quoteButtonMessage}
                                            </div>
                                        }
                                        <div onClick={cancelar} className="action-button cancelar margin-top-10">
                                            Cancelar
                                        </div>
                                        <div onClick={cancelar} className="action-button cancelar margin-top-10">
                                            Baixar Planilha
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            loading &&
                            // <Loader />
                            <p className="skill" style={{
                                background: gradientColor,
                                marginTop: 60,
                                width: '95%'
                            }}>
                                <span></span>
                            </p>
                        }
                    </div>

            }
        </div >
    )
}

export default ComprasEmLote;