import React, { useEffect, useState, useContext } from 'react';
import './style.css';
import imgcar from "../../assets/images/car_full.png"
import imgcarleft from "../../assets/images/car_left.png"
import imgcarright from "../../assets/images/car_right.png"
import CardBearing from 'components/CardBearing';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AppContext } from 'utils/ContextProvider';
import API from 'utils/API';
import Swal from 'sweetalert2';
import Loader from 'components/Loader';


export default function StockCar(props) {
    const [leftQtd, setLeftQtd] = useState(0)
    const [rightQtd, setRightQtd] = useState(0)
    const [checkingOut, setCheckingOut] = useState(false)
    const [finalPrice, setFinalPrice] = useState(null)
    const [leftBearing, setLeftBearing] = useState()
    const [leftBearingDelivery, setLeftBearingDelivery] = useState('Carregando...')
    const [rightBearingDelivery, setRightBearingDelivery] = useState('Carregando...')
    const [rightBearing, setRightBearing] = useState()
    const [selected, setSelected] = useState("none")
    const history = useHistory()
    const context = useContext(AppContext);
    const warehouses = { 'BZD': 2, 'MWH': 60 }
    const companyImages = {
        "A0588": require('../../assets/images/amattheis.png').default,
        "A0557": require('../../assets/images/blau.png').default,
        "A00055": require('../../assets/images/cavaleiro.png').default,
        "A0589": require('../../assets/images/crown_racing.png').default,
        "A0551": require('../../assets/images/full_time.png').default,
        "A0549": require('../../assets/images/ktf.png').default,
        "A0552": require('../../assets/images/pole_motorsport.png').default,
        "A0586": require('../../assets/images/pole_motorsport.png').default,
        "A0550": require('../../assets/images/rc_team.png').default,
        "A00222": require('../../assets/images/woking_garra.png').default,
        "default": require('../../assets/images/Stock_Car_BR.png').default
        // Add more mappings as needed
    };
    const companyName = {
        "A0588": "A. MATTHEIS",
        "A00076": "AUDACE TECH",
        "A0557":"BLAU MOTORSPORT",
        "A00055": "CAVALEIRO RACING",
        "A0604": "CJ MAXON RACING",
        "A0589": "CROWN RACING",
        "A0551": "FULL TIME RACING",
        "A0590": "HOTCAR RACING",
        "A0549": "KTF RACING",
        "A0553": "MOTORTECH",
        "A0591": "MX PIQUET SPORT",
        "A0558": "NAVARRO MEDICAMENTOS",
        "A0552": "POLE MOTORSPORT",
        "A0586": "R. MATTHEIS",
        "A0550": "RC MOTORSPORT",
        "A0592": "RCM RACING",
        "A0593": "RKL RACING",
        "A0637": "STOCK TECH",
        "A0507": "VICAR",
        "A0556": "VOGEL RACING",
        "A00102": "DC RACING T. RC",
        "A00103":"CAMPOS RACING",
        "A00066": "LJ MOTORSPORT",
        "A00109": "RACE CAR",
        "A00222": "GARRA MOTORS",
        "default": "Stock Car"
        // Add more mappings as needed
    };

    // useEffect(() => {
    //     const cancelToken = API.cancelToken();
    //     API.getAvailability(cancelToken, leftBearing.id).then(res => {
    //         if (res.data.availability.dimensions) {
    //             let auxLeftBearing = { ...leftBearing }
    //             auxLeftBearing.availability = res.data.availability.stock
    //             setLeftBearing(auxLeftBearing)
    //         } else {
    //             throw new Error("Data Unavailable");
    //         }
    //     }).catch(err => {
    //         if (err.message !== 'cancelling') setAvailability(0);
    //     });


    // return () => {
    //     API.cancel(cancelToken);
    //     }
    // }, []);

    
    useEffect(() => {
        const cancelToken = API.cancelToken();
        console.log(context.getCurrentUser)
        let auxRightBearing = {}
        let auxLeftBearing = {}

        API.getProduct(93168, cancelToken).then((resp) => {
            API.getAvailability(cancelToken, resp.data.product.skf_product_id).then(res => {
                auxLeftBearing = { availability: res.data.availability, ...resp.data.product }
                setLeftBearing(auxLeftBearing)
            }).catch(err => {
                if (err.message !== 'cancelling') setLeftBearing(null);
            });
        }).catch(err => {
            if (err.message !== 'cancelling') setLeftBearing(null);
        });
        API.getProduct(93167, cancelToken).then((resp) => {
            API.getAvailability(cancelToken, resp.data.product.skf_product_id).then(res => {
                auxRightBearing = { availability: res.data.availability, ...resp.data.product }
                console.log("RIGHT BEARING")
                console.log(auxRightBearing)
                setRightBearing(auxRightBearing)
            }).catch(err => {
                if (err.message !== 'cancelling') setRightBearing(null);
            });
        }).catch(err => {
            if (err.message !== 'cancelling') setRightBearing(null);
        });
    }, [])


    const handleChangeLeft = (amount) => {
        isNaN(amount) ? setLeftQtd(0) : setLeftQtd(amount)
        setFinalPrice((leftBearing.price * amount) + (rightBearing.price * rightQtd))
    }
    const handleChangeRight = (amount) => {
        isNaN(amount) ? setRightQtd(0) : setRightQtd(amount)
        setFinalPrice((leftBearing.price * leftQtd) + (rightBearing.price * amount))
    }

    function formatDate(date) {
        let day = date.getDate(); // Get the day as a number (1-31)
        let month = date.getMonth() + 1; // Get the month as a number (0-11)
        let year = date.getFullYear(); // Get the full year (e.g. 2021)
    
        // Add leading zeros if necessary
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
    
        return `${day}/${month}/${year}`;
    }

    useEffect(() => {
        let cancelToken;
        const fetchData = async () => {
            if (!leftBearing) return;
            setLeftBearingDelivery('Carregando...');
            try {
                cancelToken = API.cancelToken(); // Create a new cancel token
                const res = await API.getShipping(
                    cancelToken,
                    93168,
                    leftQtd,
                    leftBearing.price
                );

                let date = res.data.quote.estimatedDelivery.split('/');
                let tempDelivery = new Date(date[2], date[1] - 1, date[0]);
                let deliveryDelay = 2;
                while (deliveryDelay > 0) {
                    tempDelivery.setDate(tempDelivery.getDate() + 1);
                    deliveryDelay--;
                }
                if(leftBearing.availability < leftQtd){
                    setLeftBearingDelivery("Disponibilidade sujeita a confirmação");
                }else{
                    setLeftBearingDelivery(formatDate(tempDelivery));
                }
            } catch (err) {
                console.log(err);

            }
        };
        fetchData();
        return () => {
            if (cancelToken) {
                cancelToken.cancel(); // Cancel the ongoing request using the cancel token
            }
        };
    }, [leftQtd]);

    useEffect(() => {
        let cancelToken;
        const fetchData = async () => {
            if (!rightBearing) return;
            setLeftBearingDelivery('Carregando...');
            try {
                cancelToken = API.cancelToken(); // Create a new cancel token
                const res = await API.getShipping(
                    cancelToken,
                    93167,
                    rightQtd,
                    rightBearing.price
                );

                let date = res.data.quote.estimatedDelivery.split('/');
                let tempDelivery = new Date(date[2], date[1] - 1, date[0]);
                let deliveryDelay = 2;
                while (deliveryDelay > 0) {
                    tempDelivery.setDate(tempDelivery.getDate() + 1);
                    deliveryDelay--;
                }
                if(rightBearing.availability < rightQtd){
                    setRightBearingDelivery("Disponibilidade sujeita a confirmação");
                }else{
                    setRightBearingDelivery(formatDate(tempDelivery));
                }
            } catch (err) {
                console.log(err);

            }
        };
        fetchData();
        return () => {
            if (cancelToken) {
                cancelToken.cancel(); // Cancel the ongoing request using the cancel token
            }
        };
    }, [rightQtd]);

    const closeOrder = async () => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if (!leftQtd && !rightQtd) return;
        if (!checkingOut){
            try {
                setCheckingOut(true)
                if(context.getCart && context.getCart.length > 0){
                    await API.deleteFromCart(null, null, true)
                }
                if (leftQtd > 0)
                    await API.addToCart(leftBearing.product_id, leftQtd)
                if (rightQtd > 0)
                    await API.addToCart(rightBearing.product_id, rightQtd)
                context.updateCart();
                history.push('/checkout');
            } catch (error) {
                setCheckingOut(false)
                console.log(error);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        }
    }

    const handleMouseEnterRight = () => {
        setSelected('right');
        console.log("RIGHT RIGHT RIGHT")
      };
    
      const handleMouseEnterLeft = () => {
        setSelected('left');
      };
    
      const handleMouseLeave = () => {
        setSelected('none');  // Optionally reset on mouse leave
      };

      function toTitleCase(str) {
        try{
            return str
                .toLowerCase() // Convert the string to all lowercase
                .split(' ') // Split the string into an array of words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first character of each word
                .join(' '); // Rejoin all elements of the array into a string
        }catch{
            return str
        }
    }
    return (
        <div className='div-body'>
            <div className='banner'>
                {/* <div className={companyImages["sadsa"] || companyImages['default']}></div> */}
                <div class="div-container">
                    {/* <img src={require("../../assets/images/logo_tmg_racing.png").default} alt="Descriptive Alt Text"></img> */}
                    <img src={companyImages[context.getCurrentUser.split("-")[0].trim()] || companyImages['default']} alt="Team Logo" />
                </div>
                {/* <img src={require("../../assets/images/logo_tmg_racing.png").default}></img> */}
            </div>
            <div className='content-page'>
                <div className='wellcome-div'>
                    <p className='title'>
                        Boas-vindas equipe {toTitleCase(companyName[context.getCurrentUser.split("-")[0].trim()])}
                    </p>
                    <p className='text'>
                        Selecione abaixo os rolamentos que deseja comprar
                    </p>
                </div>
                <div className='img-car' >
                    <img src={selected == 'left' ? imgcarleft : selected == 'right' ? imgcarright : imgcar } />
                </div>
                {!(rightBearing && leftBearing) ?
                    <div className='products-div ' style={{ 'position': 'relative' }}>
                        <Loader light={true} />
                    </div>
                    :
                    <div className='products-div'>
                        <CardBearing 
                            title={"Rolamentos lado direito"} 
                            availability={rightBearing.availability} 
                            delivery={rightBearingDelivery} 
                            price={rightBearing.price} 
                            quantity={rightQtd} 
                            onChange={handleChangeRight} 
                            index={"bearingRight"} 
                            onMouseEnter={handleMouseEnterRight} 
                            onMouseLeave={handleMouseLeave}
                        />
                        <CardBearing 
                            title={`Rolamentos lado esquerdo`} 
                            availability={leftBearing.availability} 
                            price={leftBearing.price} 
                            delivery={leftBearingDelivery} 
                            quantity={leftQtd} 
                            onChange={handleChangeLeft} 
                            index={"bearingLeft"} 
                            onMouseEnter={handleMouseEnterLeft} 
                            onMouseLeave={handleMouseLeave}
                        />
                    </div>}
                <div className='final-text'>
                    <p>Ambos os produtos podem ser comprados em um único pedido.</p>
                </div>
                {
                    finalPrice && 
                    <div className='final-price'>
                        <p>Valor do pedido: <strong>{` R$ ${finalPrice.toFixed(2)}`}</strong></p>
                    </div>
                }
                {(leftQtd > 0 || rightQtd > 0) && < div className='flex-col flex-center align-center self-stretch gap-4 w-100'>
                    <button className='action-button' style={{ width: "240px", height: '44px' }} onClick={closeOrder}>{checkingOut ? 'Carregando produtos...' : 'Finalizar pedido'}</button>
                </div>}
            </div>

        </div >
    )
}