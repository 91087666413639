import { useState, useRef, useContext, useEffect } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import Loader from 'components/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

function Login() {

    const context = useContext(AppContext);
    const location = useLocation();
    const [error, setError] = useState(false);
    const [twofaError, set2faError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [twoFA, settwoFA] = useState("")
    const [require2FA, setRequire2FA] = useState(0)
    const [emailAuth, setEmailAuth] = useState(0)
    const history = useHistory();
    const [showPass, setShowPass] = useState(false)
    const [page, setPage] = useState(1)
    const [b2e, setB2e] = useState(false)


    const params = new URLSearchParams(location.search);

    

    const togglePass = (e) => {
        setShowPass(!showPass)
    }

    useEffect(() => {
        var token = params.get('token');

        if(token != undefined && token != ""){
            Swal.fire({
                title: 'Efetuando login...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                  Swal.showLoading()
                }
              })
              const cancelToken = API.cancelToken();
              API.noPassLogIn(cancelToken, token).then(res => {
                if (res.data.reset_token) {
                    history.push(`/alterarsenha?ref=${res.data.reset_token}`)
                    return
                }
                setLoading(false);
                let name = res.data.user.toLowerCase();
                let price_list = res.data.pricelist;
                let sub_business_segment = res.data.sub_business_segment;
                let type = res.data.type
                let user = ""
                if (type === "B2E") {
                    user = `${res.data.skf_id.replace("F", "")} 
                    ${name.charAt(0).toUpperCase() + name.split(" ")[0].slice(1)}`;
                }else{
                    user = `${res.data.skf_id} - ${name.charAt(0).toUpperCase() + name.slice(1)}`;
                }
                let address = {
                    mailing: {
                        id: res.data.address[0].address_id,
                        company: res.data.user.trim(),
                        address1: res.data.address[0].address,
                        address2: res.data.address[0].address_2,
                        number: res.data.address[0].number,
                        city: res.data.address[0].city,
                        state: res.data.address[0].uf,
                        postal: res.data.address[0].postal_code
                    },
                    billing: {
                        id: res.data.address[1].address_id,
                        company: res.data.user.trim(),
                        address1: res.data.address[1].address,
                        address2: res.data.address[1].address_2,
                        number: res.data.address[1].number,
                        city: res.data.address[1].city,
                        state: res.data.address[1].uf,
                        postal: res.data.address[1].postal_code
                    }
                }
                context.logIn(user, res.data.token, address, price_list, sub_business_segment ,res.data.authorized_companies, `${res.data.skf_id} - ${res.data.cnpj}`);
                // history.goBack();
                Swal.close()
                history.push('/')
            }).catch(err => {
                console.log(err.response)
                if (err.message === 'cancelling') return
                setLoading(false);
                if (err.response && err.response.data.error_type === "2fa") {
                    set2faError(true);
                }
                else if (err.response && err.response.status === 401) {
                    setError(true);
                } else {
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            });
    
        }
    }, [])

    useEffect(() => {
        setB2e(location.pathname === "/login/b2e");
    }, [location])

    useEffect(() => {
        if (b2e) setPage(2)
        else setPage(1)
    }, [b2e])

    const logInNoPass = (e) => {
        e.preventDefault();
        if (userName === "") return;
        if (!new RegExp(/\S+@\S+\.\S+/).test(userName)) {
            setError(true);
            return
        }
        const cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Enviando email...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading()
            }
          })
        API.postLoginNoAuth(cancelToken, userName).then(res => {
            Swal.fire({
              title: 'Autenticação via email.',
              text: 'Link de autenticação enviado para o seu email.',
              confirmButtonColor: "#131921",
              icon: 'success',
              confirmButtonText: 'OK'
              })
        }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                  title: 'Erro.',
                  text: 'Tente novamente mais tarde.',
                  confirmButtonColor: "#131921",
                  icon: 'warning',
                  confirmButtonText: 'OK'
                  })
            });

    }

    const logIn = (e) => {
        e.preventDefault();
        const cancelToken = API.cancelToken();
        if (page === 1) {
            if (userName === "") return;
            if (!new RegExp(/\S+@\S+\.\S+/).test(userName)) {
                setError(true);
                return
            }
            setLoading(true);
            API.checkEmail(cancelToken, userName).then(res => {
                setLoading(false);
                if (res.data.required_twofa) {
                    if(res.data.required_twofa === 1){
                        setRequire2FA(1);
                    }
                }
                if(res.data.email_auth === 1){
                    setEmailAuth(1);
                }
                if (res.data.requires_validation) {
                    Swal.fire({
                        html: '<p>O seu cadastro está pendente de validação.</p><p>Para concluir, basta clicar no botão abaixo</p>',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: "#000000",
                        confirmButtonText: 'VALIDAR CADASTRO',
                        cancelButtonText: 'VOLTAR'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            history.push(`/registration?email=${userName}`)
                            return
                        }
                    });
                } else {
                    setPage(2)
                }
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                setLoading(false);
                if (err.response && err.response.status === 404) {
                    Swal.fire({
                        html: '<p>E-mail inválido, inativo ou não cadastrado.</p><p>Por favor, tente novamente ou entre em contato com o time SKF.</p>',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: "#000000",
                        confirmButtonText: 'FALAR COM A SKF',
                        cancelButtonText: 'VOLTAR'
                    }).then(result => {
                        if (result.isConfirmed) {
                            window.location.href = "mailto:suporte@azpartes.com"
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            });

        }
        if (page === 2) {
            if (password === "") return;
            setLoading(true);
            let user = ""
            if (b2e) user = "F" + userName
            else user = userName
            API.logIn(cancelToken, user, password, twoFA).then(res => {
                if (res.data.reset_token) {
                    history.push(`/alterarsenha?ref=${res.data.reset_token}`)
                    return
                }
                setLoading(false);
                let name = res.data.user.toLowerCase();
                let price_list = res.data.pricelist;
                let sub_business_segment = res.data.sub_business_segment;
                let type = res.data.type
                let user = ""
                if (type === "B2E") {
                    user = `${res.data.skf_id.replace("F", "")} 
                    ${name.charAt(0).toUpperCase() + name.split(" ")[0].slice(1)}`;
                }else{
                    user = `${res.data.skf_id} - ${name.charAt(0).toUpperCase() + name.slice(1)}`;
                }
                let address = {
                    mailing: {
                        id: res.data.address[0].address_id,
                        company: res.data.user.trim(),
                        address1: res.data.address[0].address,
                        address2: res.data.address[0].address_2,
                        number: res.data.address[0].number,
                        city: res.data.address[0].city,
                        state: res.data.address[0].uf,
                        postal: res.data.address[0].postal_code
                    },
                    billing: {
                        id: res.data.address[1].address_id,
                        company: res.data.user.trim(),
                        address1: res.data.address[1].address,
                        address2: res.data.address[1].address_2,
                        number: res.data.address[1].number,
                        city: res.data.address[1].city,
                        state: res.data.address[1].uf,
                        postal: res.data.address[1].postal_code
                    }
                }
                context.logIn(user, res.data.token, address, price_list, sub_business_segment ,res.data.authorized_companies, `${res.data.skf_id} - ${res.data.cnpj}`);
                // history.goBack();
                history.push('/')
            }).catch(err => {
                console.log(err.response)
                if (err.message === 'cancelling') return
                setLoading(false);
                if (err.response && err.response.data.error_type === "2fa") {
                    set2faError(true);
                }
                else if (err.response && err.response.status === 401) {
                    setError(true);
                } else {
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            });
        }
        return () => {
            API.cancel(cancelToken);

        }
    }


    return (
        <div className="login-page">
            <div className="form-container">
                <div className={`section-one ${b2e ? "b2e-color" : "b2b-color"}`}>
                    <Link className="reset" to={'/home'}>
                        <h1 className="margin-bottom-20">AZPartes.com</h1>
                    </Link>
                </div>
                <div className="section-two">
                    <form>
                        {/* {b2e && <img className="margin-bottom-20" src={require(`../../assets/images/esporte-club.png`).default} alt="success" />} */}
                        <h3>Entrar</h3>
                        {
                            b2e ?
                                <p className="description">Por favor, utilizar a mesma matrícula e senha do seu cadastro no site do <strong>Clube SKF</strong></p>
                                :
                                <p className="description">Valide suas informações para acessar o <strong>AZPartes.com</strong>, e-commerce B2B da SKF.</p>
                        }
                        {/* {page === 1 && !b2e &&
                            <p className="label">É colaborador da SKF?&nbsp;
                                <Link className="reset blue-link" to={'/login/b2e'}>
                                    Clique aqui.
                                </Link>
                            </p>} */}
                        {page === 1 && !b2e && <br />}
                        <p className="label">{b2e ? "Matrícula" : "E-mail"}</p>
                        <input disabled={!b2e && page === 2} className={`input ${page === 1 && error ? "error-input" : ""}`} type="email" placeholder={b2e ? "000000000" : "exemplo@servidor.com"} onChange={e => setUserName((e.currentTarget.value).trim())} />
                        {page === 1 && error &&
                            <p className="error">
                                E-mail inválido. Por favor, revise e digite novamente.
                            </p>
                        }
                        {
                            page === 2 &&
                            <div>
                                <p className="label">Senha</p>
                                <div className={`pass-holder input ${error ? "error-input" : ""}`}>
                                    <input className='mask-password' type={showPass ? "text" : "password"} placeholder="Digite a senha" onChange={e => setPassword((e.currentTarget.value).trim())} />
                                    <img className='pointer' onClick={togglePass} src={require(`../../assets/images/${showPass ? 'hide_pass' : 'show_pass'}.png`).default} alt="success" />
                                </div>
                                {error &&
                                    <p className="error margin-bottom-10">
                                       Senha incorreta. Por favor, tente novamente.
                                    </p>
                                }
                                <Link className="reset" to={'/recuperacao'}>
                                    <p className='text-center margin-bottom-20'>Esqueci minha senha</p>
                                </Link>
                                {
                                    require2FA ?
                                        <div>
                                            <p className="label">2FA - Digite o token enviado para o seu email</p>
                                            <div className={`pass-holder input ${error ? "error-input" : ""}`}>
                                                <input className='mask-password' type="text" placeholder="2FA Token" onChange={e => settwoFA((e.currentTarget.value).trim())} />
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                }
                                {twofaError &&
                                    <p className="error margin-bottom-10">
                                        Autenticação de dois fatores incorreta.
                                    </p>
                                }
                            </div>
                        }
                        {page === 1 || b2e && <p className="label light-grey">Ao continuar, você concordou com as condições de uso do AZPartes.</p>}
                        {
                            loading &&
                            <div className="loader-wrapper">
                                <div className='rotator-wrapper'>
                                    <Loader color="#b0b1b2" />
                                </div>
                                {page === 1 && <h3>Verificando dados...</h3>}
                            </div>
                        }
                        <input type="submit" className={`action-button margin-top-10 input ${userName === "" || (page === 2 && password === "") ? "disabled" : ""}`} onClick={(e) => logIn(e)} value={page === 1 ? "CONTINUAR" : "ENTRAR"} disabled={userName === "" || (page === 2 && password === "")} />
                        {
                            page === 2 && emailAuth ?
                            <div>
                                <p className='text-center or-txt'>ou</p>
                                <input type="submit" className={`action-button margin-top-10 input`} onClick={(e) => logInNoPass(e)} value="AUTENTICAR VIA E-MAIL" />
                            </div>
                            :
                            <div></div>
                        }
                        {/* <p className='text-center pointer' onClick={() => history.push('/')}>Voltar ao site</p> */}

                        {/* <Link className="reset" to={'/'}>
                        <p>Outros problemas com o acesso</p>
                    </Link> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;