import React, {useEffect, useContext} from 'react';
import AccountCard from "components/AccountCard";
import { AppContext } from 'utils/ContextProvider';
import ReactGA from 'react-ga';
import "./style.css";
import { hotjar } from 'react-hotjar';
import { useLanguage } from '../../context/LanguageContext';

function MyAccount() {

    const context = useContext(AppContext);
    const { translate } = useLanguage();

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Minha Conta',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <div className="page-wrapper-white one-whole margin-top-50">
            <div className="section-wrapper-medium flex-row space-between align-center one-whole">
                <div className="flex-row space-between align-center margin-bottom-20">
                    <h3>{translate('myAccountLabel')}</h3>
                </div>
            </div>
            <div className="section-wrapper-medium suaconta-container">
                <AccountCard
                    title="Meus Pedidos"
                    content="Acompanhe o status dos seus pedidos"
                    image="./pedidos_icon.png"
                    link = "./pedidos"
                />
                <AccountCard
                    title="Segurança"
                    content="Alterar senha, informações de acesso."
                    image="./seguranca_icon.png"
                    link="./validarsenha"
                />
                {
                    context.getSub_business_segment !== "Stock Car" &&
                    <AccountCard
                        title="Financeiro"
                        content="Consulte o seu limite de crédito na SKF."
                        image="./financeiro_icon.png"
                        link = "./financeiro"
                    />
                }
                <AccountCard
                    title="Meus Dados"
                    content="Consulte o seus dados e endereço."
                    image="./seus_dados_icon.png"
                    link = "./seusdados"
                />
                <AccountCard
                    title="Notificações"
                    content="Gerencie suas notificações."
                    image="./notificao_icon.png"
                    link = "./notificacoes"
                />

                {
                    context.getSub_business_segment !== "Stock Car" &&
                    <AccountCard
                        title="Ajuda"
                        content="Precisando de ajuda? Clique aqui!"
                        image="./ajuda_icon.png"
                        email="mailto:suporte@azpartes.com"
                    />

                }
            </div>
        </div>
    )
}

export default MyAccount;