import React, { useEffect, useState, useContext } from 'react';
import './style.css';
import { useHistory } from 'react-router';
import { AppContext } from 'utils/ContextProvider';
import Hero from 'components/Hero';
import MiniMenu from 'components/MiniMenu';
import Carousel from 'components/Carousel';
import Cards from 'components/Cards';
import API from 'utils/API';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useLanguage } from '../../context/LanguageContext';

function Home() {
  const history = useHistory();
  const [promos, setPromos] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const context = useContext(AppContext);
  const { translate } = useLanguage();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: 'AZPartes - Home',
      page_path: location.pathname + location.search,
      page_location: window.location.href
    })

    const cancelToken = API.cancelToken();
    API.getMaintenanceStatus(cancelToken).then(res => {
      if (res.data.system_status == 1) {
        window.location.href = '/maintenance'
      }
    }).catch(err => {
      console.log(err);
    })

  }, []);

  useEffect(() => {
    if (!context.getLogin) {
      history.push('/login');
      return;
    }
    if (context.getSub_business_segment !== "Private Label") {
      history.push('/home');
      return;
    }
    const cancelToken = API.cancelToken();
    hotjar.initialize('3361828')
    ReactGA.pageview(window.location.pathname);
    API.getHighlightedProducts(cancelToken).then(res => {
      setPromos(res.data.products);
      console.log(res.data.products);
    }).catch(err => {
      if (err.message === 'cancelling') return
      console.log(err.response);
    });
    API.getRecommendedProducts(cancelToken).then(res => {
      setRecommended(res.data.products);
    }).catch(err => {
      setRecommended(null);
      if (err.message === 'cancelling') return
      console.log(err.response);
    });
    API.getSearchHistory(cancelToken).then(res => {
      setSearchHistory(res.data.products);
    }).catch(err => {
      setSearchHistory(null);
      if (err.message === 'cancelling') return
      if (err.response && err.response.status === 404) return
    });
    return () => {
      API.cancel(cancelToken);

    }
  }, []);

  return (
    <div className="page-wrapper-grey one-whole">
      <Hero />
      <div className="section-wrapper one-whole mt--300 relative">
        <div className="div-4">
          <div className="div-5">{translate('quickMenu')}</div>
          <div className="div-6">
            <div className="div-7">
              <div className="column">
                <a href="/pedidos">
                  <div className="div-8">
                    <img
                      loading="lazy"
                      srcSet={require("../../assets/images/meus_pedidos.png").default}
                      className="img-3"
                    />
                    <div className="div-9">{translate('myOrders')}</div>
                  </div>
                </a>
              </div>
              <div className="column-2">
              <a href="/cart">
                <div className="div-10">
                  <img
                    loading="lazy"
                    srcSet={require("../../assets/images/carrinho.png").default}
                    className="img-4"
                  />
                  <div className="div-11">{translate('cartLabel')}</div>
                </div>
                </a>
              </div>
              <div className="column-3">
              <a href="/lote">
                <div className="div-12">
                  <img
                    loading="lazy"
                    srcSet={require("../../assets/images/rolamentos.png").default}
                    className="img-5"
                  />
                  <div className="div-13">{translate('batchPurchases')}</div>
                </div>
                </a>
              </div>
              <div className="column-4">
              <a href="/account">
                <div className="div-14">
                  <img
                    loading="lazy"
                    srcSet={require("../../assets/images/minha_conta.png").default}
                    className="img-6"
                  />
                  <div className="div-15">{translate('myAccountLabel')}</div>
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {(context.getLogin && recommended !== null) &&
                <div className="section-wrapper one-whole">
                    <Carousel header={`Recomendados para ${context.getLogin ? context.getLogin : 'você'}`} list={recommended} />
                </div>
            } */}
      {(context.getLogin && searchHistory !== null) &&
        <div className="section-wrapper one-whole">
          <Carousel header={translate('navigationHistory')} list={searchHistory} />

        </div>
      }

      {context.getSub_business_segment == "SKF Center" &&
        <div className="section-wrapper one-whole">
          <Cards title1="Rolamentos de Roda" search1='1' image1={require('../../assets/images/rolamentos-de-roda-534x444.png')}
            title2="Atuadores Hidráulicos" search2="9" image2={require('../../assets/images/atuadores-hidraulicos-534x444.png')}
            title3="Kits de Sincronismo" search3="7" image3={require('../../assets/images/kits-de-sincronismo-534x444.jpg')}
            title4="Componentes de Suspensão" search4="10" image4={require('../../assets/images/componentes-para-suspensao-534x444.jpg')} />
        </div>
      }

      {context.getSub_business_segment == "VA Distributor" &&
        <div className="section-wrapper one-whole">
          <Cards
            title1="Linha Agrícola" search1="0" type1="Linha%20Agrícola" image1={require('../../assets/images/linha-agricola-534x444.jpg')}
            title2="Linha Pesada" search2="0" type2="Linha%20Pesada" image2={require('../../assets/images/linha-pesada-534x444.jpg')}
            title3="Atuadores Hidráulicos" search3="9" image3={require('../../assets/images/atuadores-hidraulicos-534x444.png')}
            title4="Rolamentos de Roda" search4='1' image4={require('../../assets/images/rolamentos-de-roda-534x444.png')} />
        </div>
      }
    </div>
  )
}

export default Home;