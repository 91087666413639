import { useEffect, useContext, useState } from 'react'
import './style.css';

function SelectObject(props) {
    const [showItems, setShowItems] = useState(false)

    useEffect(() => {
        setShowItems(false)
    }, [props.selected])

    return (
        <div className='sel-obj-master'>
            <div className='select-object w-full pointer'>
                <div onClick={(e) => props.block ? null : setShowItems(!showItems)}
                    className={`select-box flex-row-nowrap align-center w-full`}>
                    <svg className={`arrow margin-left ${showItems ? "" : "upside-down"}`} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.31016 0.553242L6.19016 4.43324L10.0702 0.553242C10.4602 0.163242 11.0902 0.163242 11.4802 0.553242C11.8702 0.943242 11.8702 1.57324 11.4802 1.96324L6.89016 6.55324C6.50016 6.94324 5.87016 6.94324 5.48016 6.55324L0.890156 1.96324C0.500156 1.57324 0.500156 0.943242 0.890156 0.553242C1.28016 0.173242 1.92016 0.163242 2.31016 0.553242Z" fill="#323232" />
                    </svg>
                    <div className={`margin-left w-full`}>
                        {
                            props.selected ? props.selected : <p className='placeholder extra-padding'>{props.placeholder}</p>
                        }
                    </div>
                </div>
            </div>
            <div className='anchor w-full margin-bottom-20'>
                <div className='options-holder w-full'>
                    {
                        showItems && props.objects.length > 0 &&
                        props.objects.map((obj, index) => (
                            <div className='w-full' key={"saved-cards" + index}>
                                {obj}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
export default SelectObject