import './style.css';
import { Link } from 'react-router-dom';

function Cards(props) {
    
    const url = (val)=>`/products/search?q=null&cat=${val}&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=null&license=null&alph=null`
    const url2 = (val)=>`/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&type=${val}&page=null&sorting=null&type=null&license=null&alph=null`
    return (
        <div className="flex-row one-whole cards-box">
            <div className="card">
                {
                    props.search1 && props.search1 !== "0" &&
                    <Link className="reset" to={url(props.search1)}>
                        <div className="first">
                            <h3>{props.title1}</h3>
                        </div>
                        <div className="image-holder" style={{ 'backgroundImage': `url(${props.image1.default})` }}></div>
                        <div className="third">
                            <p>Veja mais</p>
                        </div>
                    </Link>
                } 
                {
                    props.type1 &&
                    <Link className="reset" to={url2(props.type1)}>
                        <div className="first">
                            <h3>{props.title1}</h3>
                        </div>
                        <div className="image-holder" style={{ 'backgroundImage': `url(${props.image1.default})` }}></div>
                        <div className="third">
                            <p>Veja mais</p>
                        </div>
                    </Link>

                }
            </div>
            <div className="card">
            {
                    props.search1 && props.search2 !== "0" &&
                    <Link className="reset" to={url(props.search2)}>
                        <div className="first">
                            <h3>{props.title2}</h3>
                        </div>
                        <div className="image-holder" style={{ 'backgroundImage': `url(${props.image2.default})` }}></div>
                        <div className="third">
                            <p>Veja mais</p>
                        </div>
                    </Link>
            } 
            {   
                props.type2 &&
                 <Link className="reset" to={url2(props.type2)}>
                 <div className="first">
                     <h3>{props.title2}</h3>
                 </div>
                 <div className="image-holder" style={{ 'backgroundImage': `url(${props.image2.default})` }}></div>
                 <div className="third">
                     <p>Veja mais</p>
                 </div>
             </Link>
            }
            </div>
            <div className="card">
                <Link className="reset" to={url(props.search3)}>
                    <div className="first">
                        <h3>{props.title3}</h3>
                    </div>
                    <div className="image-holder" style={{ 'backgroundImage': `url(${props.image3.default})` }}></div>
                    <div className="third">
                        <p>Veja mais</p>
                    </div>
                </Link>
            </div>
            <div className="card">
                <Link className="reset" to={url(props.search4)}>
                    <div className="first">
                        <h3>{props.title4}</h3>
                    </div>
                    <div className="image-holder" style={{ 'backgroundImage': `url(${props.image4.default})` }}></div>
                    <div className="third">
                        <p>Veja mais</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Cards;