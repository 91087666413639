import './style.css';
import { useEffect, useState } from 'react';
import GlobalFuncs from 'utils/GlobalFuncs';
import API from 'utils/API';

function OrderLine(props) {
    const [statusColor, setStatusColor] = useState(null);
    const [status, setStatus] = useState('')
    const [delivery, setDelivery] = useState(null);
    const [lineStatus, setSetLineStatus] = useState(null);
    const statusRanking = new Map([
        ['RPI', [0, 'Processo de Faturamento']],
        ['PIC', [1, 'Em Separação']],
        ['ALL', [2, 'Processando Pagamento']],
        ['SHS', [3, 'Importação em Trânsito']],
        ['ENT', [4, 'Sem Previsão de Entrega']],
        ['BOK', [5, 'Entrega Programada']],
        ['RBK', [6, 'Entrega Reprogramada']],
        ['REJ', [7, 'Rejeitado']],
        ['INV', [8, 'Faturado']],
        ['CAN', [9, 'Cancelado']]
    ]);

    useEffect(() => {
        if (props.info.status) {
            // setStatus(props.info.status)
            setStatus(statusRanking.get(props.info.status_code)[1])
            switch (props.info.delivery){
                case "Entregue":
                    setStatus('Entregue')
                    setStatusColor('label label-success');
                    return;
                case "Enviado":
                    setStatus('Enviado')
                    setStatusColor('label label-warning');
                    return;
                case "Faturado":
                    setStatus('Faturado')
                    setStatusColor('label label-warning');
                    return;
                case "Devolvido":
                    setStatus('Devolvido')
                    setStatusColor('label label-success');
                    return;
                case "Devolvido Parcial":
                    setStatus('Devolvido Parcial')
                    setStatusColor('label label-success');
                    return;
            }
            switch (statusRanking.get(props.info.status_code)[0]) {
                case 0:
                    setStatusColor('label label-warning');
                    return;
                case 1:
                    setStatusColor('label label-warning');
                    return;
                case 2:
                    setStatusColor('label label-warning');
                    return;
                case 3:
                    setStatusColor('label label-warning');
                    return;
                case 4:
                    setStatusColor('label label-warning');
                    return;
                case 5:
                    setStatusColor('label label-warning');
                    return;
                case 6:
                    setStatusColor('label label-warning');
                    return;
                case 7:
                    setStatusColor('label label-danger');
                    return;
                case 8:
                    setStatusColor('label label-warning');
                    return;
                case 9:
                    setStatusColor('label label-danger');
                    return;
                default:
                    setStatusColor('label label-warning');
                    break;
            }
        } else if (props.ccStatus != 0 && props.ccStatus != 1) {
            switch (props.ccStatus) {
                case 3:
                    setStatus('Aguardando Pagamento');
                    setStatusColor('label label-yellow');
                    break;
                case -1:
                    setStatus('Pedido Cancelado');
                    setStatusColor('label label-danger');
                    break;
                case -2:
                    setStatus('Pagamento Não Autorizado');
                    setStatusColor('label label-yellow');
                    break;
                case -3:
                    setStatus('Pedido Expirado');
                    setStatusColor('label label-danger');
                    break;
            }

        } else {
            setStatus('Em Processamento')
            setStatusColor('label label-warning');
        }

    }, [props.info])

    useEffect(() => {
        if (!props.open || delivery !== null) return;
        if (!props.info.line_id) {
            setDelivery('Em Preparo')
        } else {
            const cancelToken = API.cancelToken();
            API.getShippingStatus(cancelToken, props.info.line_id).then(res => {
                setDelivery(res.data.body.delivery_status)
            }).catch(err => {
                console.log(err);
                setDelivery('Não Disponível')
            })
        }
    }, [props.open])
    return (
        <div className="order-line one-whole">
            {
                props.info.line && !props.info.product[0].product_id &&
                    <div className="warning">
                    <svg
                        className="warning-img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#68654F"
                        role="img"
                        aria-label="Warning"
                    >
                        <path d="M1,21h22L12,2L1,21z M13,18h-2v-2h2V18z M13,14h-2v-4h2V14z"/>
                    </svg>
                        <div className="warning-2">
                        <span>Atenção! Produto com informações incompletas pois está fora do catálogo e/ou fora</span>
                        <span>da lista de preços.</span>
                        </div>
                    </div>
            }

            <div className="one-whole text-right">
                {
                    (props.info.line && props.info.split_id) &&
                    <p className="title">{`${props.info.line} ${props.info.split_id}`}</p>
                }
            </div>
            <div className="flex-row align-bottom">
                <div className="details-box-1">
                    <p className="title">Quantidade: <span>{props.info.quantity ? props.info.quantity : 0}</span></p>
                    <p className="title wrap">Previsão de Faturamento: <span className="no-wrap">{props.info.billing_expected_date && props.info.billing_expected_date !== '0000-00-00' ? GlobalFuncs.databaseToDate(props.info.billing_expected_date) : '-'}</span></p>
                    <p className="title wrap">Data de Faturamento: <span className="no-wrap">{props.info.billing_date && props.info.billing_date !== '0000-00-00' ? GlobalFuncs.databaseToDate(props.info.billing_date) : '-'}</span></p>
                </div>
                <div className="details-box-2">
                    <p className="title">Status: <span className={props.info.status || props.ccStatus ? statusColor : 'label label-warning'}>{status}</span></p>
                    {/* {
                        props.info.NF &&
                        <p className="title">Nota Fiscal: <a className="nota-fiscal" href={props.info.NF.pdf_url} target="_blank">PDF</a> <span className="nota-fiscal">|</span> <a className="nota-fiscal" href={props.info.NF.xml_url} target="_blank">XML</a></p>
                    } */}
                    {
                        props.info.NF && props.info.NF.number &&
                        <p className="title">Nota Fiscal: <a className="nota-fiscal" target="_blank">{props.info.NF.number}</a></p>
                    }
                    {/* <p className="title">Status da entrega: <span className="no-wrap">{props.info.delivery === null ? 'Carregando' : props.info.delivery}</span></p> */}
                    {/* {
                        props.info.logistics &&
                        <p className="title"><a className="entrega" href={`https://www.dhl.com/br-pt/home/tracking/tracking-express.html?submit=1&tracking-id=${props.info.logistics.tracking_number}`} target="_blank">Acompanhar Entrega</a></p>
                    } */}
                </div>
            </div>
            {
                props.info.line &&
                <hr className="margin-bottom-20" />
            }
        </div>
    )
}

export default OrderLine;