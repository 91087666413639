import { useState, useEffect } from 'react';
import './style.css';

function TableRow(props) {

    const [open, setOpen] = useState(false);
    const [hasSearch, setHasSearch] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (props.search !== '') {
            setOpen(true);
            setHasSearch(false);
        } else {
            setOpen(false);
            setHasSearch(true);
        }
        let arr = [];
        if (Array.isArray(props.content[1])) {
            if (props.content[1][1] instanceof Object) {
                props.content[1].forEach(e => (
                    Object.entries(e).forEach(ele => {
                        if ((props.expandable && ele.toUpperCase().includes(props.search)) || (props.expandable && props.content[0].toUpperCase().includes(props.search))) {
                            setHasSearch(true);
                            arr.push(`${ele[0]}: ${ele[1]}`);
                        }
                    })
                ));
            } else {
                props.content[1].forEach(e => {
                    if ((props.expandable && e.toUpperCase().includes(props.search)) || (props.expandable && props.content[0].toUpperCase().includes(props.search))){
                        setHasSearch(true);
                        arr.push(e);
                    }
                });
            }
        } else {
            arr.push(props.content[1]);
        }
        setItems(arr);
    }, [props.search]);

    return (
        <tr className="product-table-row" style={{ 'display': props.expandable && (!hasSearch && 'none') }} >
            <td className="table-col1 uppercase"
                style={{ 'cursor': `${props.expandable && 'pointer'}` }}
                onClick={() => props.expandable ? setOpen(!open) : null}>
                <div className={props.expandable ? 'flex-row-nowrap cell-arrow' : null}>
                    <p>{props.content[0]}</p>
                    {props.expandable && <svg className={open ? 'rotate' : null} xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 7.071 12">
                        <path id="Path_15629" data-name="Path 15629" d="M542.479,7.021,538.3,11.2l-4.178-4.179a1.036,1.036,0,0,0-1.5,0,1.036,1.036,0,0,0,0,1.5l4.928,4.929a1.036,1.036,0,0,0,1.5,0l4.928-4.929a1.061,1.061,0,1,0-1.5-1.5Z" transform="translate(-6.7 544.3) rotate(-90)" fill="#232F3E" />
                    </svg>
                    }
                </div>
            </td>
            <td>
                <div className={props.expandable ? (!open ? "hidden" : null) : null}>
                    {items.map((e,id) => (
                        <p  key={e+id}>{e}</p>
                    ))}
                </div>
            </td>
        </tr>
    )
}


export default TableRow;