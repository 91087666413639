import { useEffect, useState } from 'react';
import './style.css';
import Loader from 'components/Loader';
import ImageMagnifier from 'components/ImageMagnifier';


function ImgSelector(props) {

    const [image, setImage] = useState(0);


    return (
        props.images ?
            <div className="img-selector-wrapper flex-row">
                <div className="img-holder">
                    {
                        props.images.map((e, index) => (
                            <div key={`${index}-${e.alt}`}
                                className={`selector-images ${image === index && 'focused'}`}
                                tabIndex={index}>
                                <img src={e.src ? e.src : require("../../assets/images/placeholder.png").default}
                                    onError={(event) => {
                                        event.target.src = require("../../assets/images/placeholder.png").default;
                                }}
                                    alt={e.alt}
                                    onClick={() => setImage(index)} />
                            </div>
                        ))
                    }
                </div>
                <ImageMagnifier
                    src={props.images.length > 0 ? props.images[image].src : ''}
                    onError={(event) => event.target.src = require("../../assets/images/placeholder.png").default}
                />
            </div> :
            <div className="img-selector-wrapper" style={{ 'position': 'relative' }}>
                <Loader light={true} />
            </div>
    )
}

export default ImgSelector;