import Axios from "axios";

// var URL = "https://lvcu43ajr2.execute-api.us-east-1.amazonaws.com/developer/";
URL = "https://t8kbwk0f78.execute-api.us-east-1.amazonaws.com/production/";
// var URL = "http://127.0.0.1:3000/";
const { REACT_APP_ENV } = process.env;


if(REACT_APP_ENV === "developer"){
  // URL = "http://127.0.0.1:3000/";
  URL = "https://lvcu43ajr2.execute-api.us-east-1.amazonaws.com/developer/";
}
if(REACT_APP_ENV === "staging"){
  URL = "https://7ixd1xvw7k.execute-api.us-east-1.amazonaws.com/staging/";
}
if(REACT_APP_ENV === "production"){
  URL = "https://t8kbwk0f78.execute-api.us-east-1.amazonaws.com/production/";
}

const API = {
  checkEmail: function (source, userName) {
    return Axios.get(
      ` ${URL}auth1?username=${encodeURIComponent(userName)}`,
      { cancelToken: source.token }
    );
  },
  logIn: function (source, userName, password, twoFA) {
    return Axios.get(
      `${URL}auth?username=${encodeURIComponent(userName)}&password=${encodeURIComponent(password)}&input_2fa=${twoFA}`,
      { cancelToken: source.token }
    );
  },
  noPassLogIn: function (source, token) {
    return Axios.get(
      `${URL}authnopass?token=${token}`,
      { cancelToken: source.token }
    );
  },
  switchCompany: function (source, username, cohId) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      `${URL}auth?username=${encodeURIComponent(username)}&company_coh_id=${encodeURIComponent(cohId)}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }

    );
  },
  isActive: function () {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}auth/company/status`,
      { 'headers': { 'Authorization': auth } }
    )
  },
  getRegistrationForm: function (source, email) {
    return Axios.get(
      ` ${URL}company/info?email=${email}`,
      { cancelToken: source.token }
    )
  },
  submitRegistrationForm: function (source, payload) {
    return Axios.post(
      ` ${URL}company/confirm`,
      payload,
      { cancelToken: source.token }
    )
  },
  recoverPassword: function (source, email) {
    return Axios.post(
      ` ${URL}auth/recover`,
      { email: email },
      { cancelToken: source.token }
    );
  },
  changePassword: function (source, token, password) {
    return Axios.put(
      ` ${URL}auth/recover`,
      {
        token: token,
        password: password
      },
      { cancelToken: source.token }
    );
  },
  checkPassword: function (source, password) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}auth/confirm?password=${password}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }

    );
  },
  uploadImage: function (source, image, id){
    let auth = localStorage.getItem('2r23ef55');
    return Axios.post(
      ` ${URL}order/image`,
      { "image": image, "order_id":id },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  uploadTemplate: function (source, template){
    let auth = localStorage.getItem('2r23ef55');
    return Axios.post(
      ` ${URL}order/template`,
      { "template": template },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  updatePassword: function (source, password, newpassword){
    let auth = localStorage.getItem('2r23ef55');
    return Axios.put(
      ` ${URL}auth/update?password=${password}&newpassword=${newpassword}`,
      {data:''},
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  getCompany: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}auth/user`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  updateContact: function (contact) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.put(
      ` ${URL}company/contact`,
      contact,
      { 'headers': { 'Authorization': auth } }
    );
  },
  getPolicyStatus: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}policy/outdated`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  getMaintenanceStatus: function (source) {
    return Axios.get(
      `${URL}system/status`
    )
  },
  getPolicyInfo: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}policy/info`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  getMarketing: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}company/marketing`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  updateMarketing: function (source, checked){
    let auth = localStorage.getItem('2r23ef55');
    return Axios.put(
      ` ${URL}company/marketing`,
      { "is_marketing_allowed": checked },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  setPolicyStatus: function (source, id) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.post(
      ` ${URL}policy/agree`,
      { "policy_id": id },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  setSecurityAuth: function (source, type, value) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.post(
      ` ${URL}security`,
      { "type": type, "value":value },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  getSecurityParams: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}security`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  postLoginNoAuth: function (source, email) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.post(
      ` ${URL}/auth/nopass/login?email=${email}`,
      { },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    )
  },
  getHighlightedProducts: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
       `${URL}products/highlighted`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getPredictions:function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}pred`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  validateOrderPricelist:function (source,orderId) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}pricelist/check/${orderId}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },

  getProduct: function (id, source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}product/${id}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getRating: function (productName, source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}rating/product?item=${productName}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  setRating: function (source, id, rating, comment_str) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}rating/product/user`,
      {
        skf_product_id: id,
        rating: rating,
        comment: comment_str
      },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  setProductNotification: function (source, id, stock, availability) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}product/availability/notification`,
      {
        skf_product_id: id,
        stock: stock,
        availability:availability
      },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getId: function (source, id, quantity) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}product/validate-id?skf_product_id=${id}&quantity=${quantity}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getProductList: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}product/list`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getCategories: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}product/pricelist/categories`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  searchLicense: function (query, source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}license/search${query}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  searchProducts: function (query, payload, source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.post(
      ` ${URL}products/search${query}`,
      {payload: payload},
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getFilterValues: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    return Axios.get(
      ` ${URL}filter/values`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getOrderNF: function (id,source) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return fetch(`${URL}order/${id}/nf`, { 
    // return fetch(`${URL}order/${id}/nf`, { 
      method: 'get', 
      headers: new Headers({
          'Authorization': auth
      })
  });
},
  getOrder: function (id, source) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}order/${id}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getOrders: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}orders`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getCredit: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}limit`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getOrderByToken: function (source, token) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}order/token?t=${token}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getCart: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}cart`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getPriceChange: function (source, skf_product_id, product_id) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}product/price/verify?skf_product_id=${skf_product_id}&product_id=${product_id}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getSearchHistory: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}products/history`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getRecommendedProducts: function (source) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}products/recommended`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getShipping: function (source, product, quantity, price) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}quote?product_id=${product}&quantity=${quantity}&total_amount=${price}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getShippingStatus: function (source, lineId) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}order/track/${lineId}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getAvailability: function (source, product) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(`${URL}product/availability?item=${encodeURIComponent(product)}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  setCartAmount: function (source, id, amount) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.put(` ${URL}cart/${id}`,
      { amount: amount },
      { 'headers': { 'Authorization': auth, 'Content-Type': "application/json" }, cancelToken: source.token }
    );
  },
  addToCart: function (id, amount,bypass = 'no', from_availability = null, scheduled = null) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}cart/${id}`,
      { amount: amount, bypass: bypass, from_availability: from_availability, scheduled: scheduled },
      { 'headers': { 'Authorization': auth } }
    );
  },
  verifyFail: function (source, token) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}/product/verify/fail`,
      { order_token: token },
      { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  // requestUnavailable: function (payload) {
  //   let auth = localStorage.getItem('2r23ef55');
  //   if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
  //   return Axios.post(` ${URL}product/request`,
  //     payload,
  //     { 'headers': { 'Authorization': auth } }
  //   );
  // },
  requestUnavailable: function (payload) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}availability/create`,
      payload,
      { 'headers': { 'Authorization': auth } }
    );
  },
  requestMultiUnavailable: function (source, payload) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}availability/create`,
      {
        "products": payload,
        "message":"From batch purchase"
      },
      { 'headers': { 'Authorization': auth } }
    );
  },
  postInvalidProduct: function (source, sku) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}product/invalid`,
      {
        "product_from": sku
      },
      { 'headers': { 'Authorization': auth } }
    );
  },
  deleteFromCart: function (source, id, all) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.delete(` ${URL}cart/${id}?all=${all}`,
      { 'headers': { 'Authorization': auth }, cancelToken: source && source.token }
    );
  },
  submitOrder: function (payload) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}order`,
      payload,
      { 'headers': { 'Authorization': auth } }
    );
  },
  submitSugestion: function (payload) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}suggestion`,
      payload,
      { 'headers': { 'Authorization': auth } }
    );
  },
  verifyCustomerOrder: function (source, customer_id, order_number) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(` ${URL}validateCustomerOrderNumber?company_id=${customer_id}&order_number=${order_number}`,
    { 'headers': { 'Authorization': auth }, cancelToken: source.token }
    );
  },
  getPaymentLink: function (orderId) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}order/payment/url`,
      {order_id: orderId},
      { 'headers': { 'Authorization': auth } }
    );
  },  
  insufficientFunds: function (source, payload) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.post(` ${URL}limit/request`,
      payload,
      { 'headers': { 'Authorization': auth }, cancelToken: source && source.token }
    );
  },
  rejectAvailability: function (token) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.put(` ${URL}availability/reject?ref=${token}`,
      { data: '' },
      { 'headers': { 'Authorization': auth } }
    );
  },
  acceptAvailability: function (token) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.put(` ${URL}availability/accept?ref=${token}`,
      { data: '' },
      { 'headers': { 'Authorization': auth } }
    );
  },
  getAvailabilityResponse: function (token) {
    let auth = localStorage.getItem('2r23ef55');
    if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
    return Axios.get(`${URL}availability/response?ref=${token}`,
      { 'headers': { 'Authorization': auth } }
    );
  },
  getNps: function (source, token) {
    return Axios.get(
      `${URL}nps?ref=${token}`,
      { cancelToken: source.token }
    )
  },
  putNps: function (source, payload) {
    return Axios.put(`${URL}nps`,
      payload,
      { cancelToken: source.token }
    );
  },
  cancelToken: function () {
    const cancelToken = Axios.CancelToken;
    return cancelToken.source();
  },
  cancel: function (token) {
    if (token) token.cancel('cancelling');
  }
}

export default API;