import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import "./style.css";
import API from "utils/API";
import Loader from "components/Loader";
import Swal from 'sweetalert2';


function Survey() {


    const location = useLocation();
    const history = useHistory();
    const [ref, setRef] = useState("")
    const [validRef, setValidRef] = useState(false)
    const [score, setScore] = useState(null)
    const [comment, setComment] = useState("")
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let tempRef = params.get('ref')
        tempRef && tempRef !== "" ? setRef(tempRef) : null
    }, [])

    useEffect(() => {
        console.log(ref)
        if (ref.trim() == "") return
        const cancelToken = API.cancelToken();
        API.getNps(cancelToken, ref).then(res => {
            setValidRef(true)
        }).catch(err => {
            console.log(err)
            history.push(`/`);
        });
    }, [ref])

    const submitSurvey = () => {
        if (score == null) return
        setSubmitting(true)
        let payload = {
            "token": ref,
            "score": score,
            "comment": comment
        }
        const cancelToken = API.cancelToken();
        API.putNps(cancelToken, payload).then(res => {
            history.push(`/`);
        }).catch(err => {
            console.log(err)
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(() => {
                history.push(`/`);
            });
        })
    }

    return (
        <div className="survey-page">
            {
                validRef ? <div style={{ overflow: "auto", width: "100%" }}>
                    <table role="presentation" style={{ background: "#fafafa", fontSize: "0px", width: "100%" }} cellSpacing="0" cellPadding="0"
                        border="0">
                        <tbody>
                            <tr>
                                <td data-nl-format="datetime">
                                    <div style={{ margin: "0px auto", maxWidth: "570px" }}>
                                        <table role="presentation" style={{ fontSize: "0px", width: "100%" }} cellSpacing="0" cellPadding="0"
                                            border="0" align="center">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{ textAlign: "center", verticalAlign: "top", direction: "ltr", fontSize: "0px", padding: "0px" }}>

                                                        <div style={{ margin: "0px auto", maxWidth: "570px" }}>
                                                            <table role="presentation" style={{ fontSize: "0px", width: "100%" }}
                                                                cellSpacing="0" cellPadding="0" border="0" align="center">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ textAlign: "center", verticalAlign: "top", direction: "ltr", fontSize: "0px", padding: "0px 0px", paddingBottom: "15px", paddingTop: "3%" }}
                                                                            data-nl-format="datetime">

                                                                            <div className="mj-column-per-100 outlook-group-fix"
                                                                                style={{ verticalAlign: "top", display: "inline-block", direction: "ltr", fontSize: "13px", textAlign: "left", width: "100%" }}>
                                                                                <table role="presentation" width="100%" cellSpacing="0"
                                                                                    cellPadding="0" border="0">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style={{ wordWrap: "break-word", fontSize: "0px", padding: "20px", align: "center" }}>
                                                                                                <table role="presentation"
                                                                                                    style={{ borderCollapse: "collapse", borderSpacing: "0px" }}
                                                                                                    cellSpacing="0" cellPadding="0"
                                                                                                    border="0" align="center">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td style={{ width: "100%" }}>
                                                                                                                <img
                                                                                                                    alt="AZPartes"
                                                                                                                    title="AZPartes"
                                                                                                                    src="https://skfbuy-public-assets.s3.us-east-1.amazonaws.com/email_header.png"
                                                                                                                    style={{ border: "none", borderRadius: "0px", display: "block", fontSize: "13px", outline: "none", textDecoration: "none", width: "100%", maxWidth: "530px" }}
                                                                                                                    width="190"
                                                                                                                    height="" />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <table role="presentation" style={{ fontSize: "0px", width: "100%" }} cellSpacing="0"
                                                            cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div style={{ margin: "0px auto", maxWidth: "570px" }}>
                                                                            <table role="presentation"
                                                                                style={{ fontSize: "0px", width: "100%" }} cellSpacing="0"
                                                                                cellPadding="0" border="0" align="center">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{ textAlign: "center", verticalAlign: "top", direction: "ltr", fontSize: "0px", padding: "0px 0px 10px" }}>

                                                                                            <table role="presentation"
                                                                                                style={{ fontSize: "0px", width: "100%" }}
                                                                                                cellSpacing="0" cellPadding="0"
                                                                                                border="0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <div
                                                                                                                style={{ margin: "0px auto", maxWidth: "570px" }}>
                                                                                                                <table
                                                                                                                    role="presentation"
                                                                                                                    style={{ fontSize: "0px", width: "100%" }}
                                                                                                                    cellSpacing="0"
                                                                                                                    cellPadding="0"
                                                                                                                    border="0"
                                                                                                                    align="center">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td
                                                                                                                                style={{ textAlign: "center", verticalAlign: "top", direction: "ltr", fontSize: "0px", padding: "0px 0px 20px" }}>



                                                                                                                                <div className="mj-column-per-100 outlook-group-fix"
                                                                                                                                    style={{ verticalAlign: "top", display: "inline-block", borderTop: "15px solid #131921", direction: "ltr", fontSize: "13px", textAlign: "left", width: "100%" }}>


                                                                                                                                    <table
                                                                                                                                        role="presentation"
                                                                                                                                        style={{ background: "#fff", border: "1px solid #e8e8e8" }}
                                                                                                                                        width="100%"
                                                                                                                                        cellSpacing="0"
                                                                                                                                        cellPadding="0"
                                                                                                                                        border="0">
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td style={{ wordWrap: "break-word", fontSize: "0px", padding: "0px" }}
                                                                                                                                                    align="center">
                                                                                                                                                    <table
                                                                                                                                                        role="presentation"
                                                                                                                                                        style={{ borderCollapse: "collapse", borderSpacing: "0px" }}
                                                                                                                                                        cellSpacing="0"
                                                                                                                                                        cellPadding="0"
                                                                                                                                                        border="0"
                                                                                                                                                        align="center">
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr>

                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td style={{ wordWrap: "break-word", fontSize: "0px", padding: "5% 10% 6.5%" }}
                                                                                                                                                    align="center"
                                                                                                                                                    data-nl-format="datetime">
                                                                                                                                                    <div
                                                                                                                                                        style={{ cursor: "auto", color: "#272727", fontFamily: "'Open Sans', sans-serif", fontSize: "15px", lineHeight: "24px", textAlign: "left", maxWidth: "550px" }}>

                                                                                                                                                        <table
                                                                                                                                                            cellPadding="0"
                                                                                                                                                            cellSpacing="0"
                                                                                                                                                            style={{ fontFamily: "'Open Sans', sans-serif", fontWeight: "normal", width: "100%" }}>
                                                                                                                                                            <tbody>

                                                                                                                                                                <tr>
                                                                                                                                                                    <td align="center"
                                                                                                                                                                        colSpan="11"
                                                                                                                                                                        style={{ fontFamily: "'Open Sans', sans-serif" }}>
                                                                                                                                                                        <p style={{ textAlign: "center", fontSize: "20px", marginBottom: "20px" }}
                                                                                                                                                                            align="center">
                                                                                                                                                                            O quão provavel é que você recomende o <strong>AZPartes</strong> para outra pessoa?
                                                                                                                                                                        </p>
                                                                                                                                                                    </td>
                                                                                                                                                                </tr>

                                                                                                                                                                <tr>
                                                                                                                                                                    {
                                                                                                                                                                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(e => (
                                                                                                                                                                            <td
                                                                                                                                                                                style={{ width: "9%", minWidth: "25px" }} key={e + "-rate"}>
                                                                                                                                                                                <table
                                                                                                                                                                                    cellPadding="0"
                                                                                                                                                                                    cellSpacing="0"
                                                                                                                                                                                    style={{ width: "90%" }}>
                                                                                                                                                                                    <tbody>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td align="center"
                                                                                                                                                                                                bgcolor="#fff"
                                                                                                                                                                                                height="40"
                                                                                                                                                                                                style={{ display: "block", textAlign: "center" }}
                                                                                                                                                                                                width="100%">
                                                                                                                                                                                                <a onClick={() => setScore(e)}
                                                                                                                                                                                                    id={`num-${e}`}
                                                                                                                                                                                                    className={score == e ? `num-${e}-active` : ""}
                                                                                                                                                                                                    style={{ border: "1px solid #cccccc", borderRadius: "8px", fontWeight: "bold", fontFamily: "sans-serif", textDecoration: "none", lineHeight: "40px", width: "100%", minWidth: "25px", display: "inline-block", color: "#222222", msoHide: "all" }}>{e}
                                                                                                                                                                                                </a>
                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </tbody>
                                                                                                                                                                                </table>
                                                                                                                                                                            </td>
                                                                                                                                                                        ))
                                                                                                                                                                    }

                                                                                                                                                                </tr>
                                                                                                                                                                <tr>
                                                                                                                                                                    <td align="left"
                                                                                                                                                                        colSpan="6"
                                                                                                                                                                        style={{ fontFamily: "'Open Sans', sans-serif", color: "#81263b", fontSize: "13px", paddingTop: "20px" }}>
                                                                                                                                                                        Extremamente Improvável
                                                                                                                                                                    </td>
                                                                                                                                                                    <td align="right"
                                                                                                                                                                        colSpan="5"
                                                                                                                                                                        style={{ fontFamily: "'Open Sans', sans-serif", color: "#131921", fontSize: "13px", paddingTop: "20px", paddingRight: "5px" }}>
                                                                                                                                                                        Muito Provável
                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                                <tr>
                                                                                                                                                                    <td colSpan="12"
                                                                                                                                                                        style={{ fontFamily: "'Open Sans', sans-serif" }}>
                                                                                                                                                                        <p style={{ textAlign: "center", fontSize: "16px", marginTop: "20px", marginBottom: "15px" }}
                                                                                                                                                                            align="left">
                                                                                                                                                                            Explique, em poucas palavras, o motivo da sua nota
                                                                                                                                                                        </p>
                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                                <tr>
                                                                                                                                                                    <td style={{ width: "100%", minWidth: "100%", fontFamily: "'Open Sans', sans-serif" }}
                                                                                                                                                                        colSpan="20">
                                                                                                                                                                        <textarea
                                                                                                                                                                            rows="6"
                                                                                                                                                                            style={{ width: "100%", minWidth: "100%", padding: "5px" }}
                                                                                                                                                                            onChange={e => setComment(e.currentTarget.value)}></textarea>
                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                        {
                                                                                                                                                            submitting ?
                                                                                                                                                                <div className="surver-loader-wrapper">
                                                                                                                                                                    <Loader light={true} />
                                                                                                                                                                </div>
                                                                                                                                                                :
                                                                                                                                                                <div
                                                                                                                                                                    className="enviar">
                                                                                                                                                                    <div
                                                                                                                                                                        onClick={submitSurvey}
                                                                                                                                                                        className="enviar-button">
                                                                                                                                                                        ENVIAR
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        style={{ cursor: "auto", color: "#272727", fontFamily: "'Open Sans', sans-serif", fontSize: "15px", lineHeight: "24px", textAlign: "left", maxWidth: "550px" }}>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                        <table
                                                                                                                                            role="presentation"
                                                                                                                                            style={{ background: "#fafafa", fontSize: "0px", width: "100%" }}
                                                                                                                                            cellSpacing="0"
                                                                                                                                            cellPadding="0"
                                                                                                                                            border="0">
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td>
                                                                                                                                                        <div
                                                                                                                                                            style={{ margin: "0px auto", maxWidth: "470px" }}>
                                                                                                                                                            <table
                                                                                                                                                                role="presentation"
                                                                                                                                                                style={{ fontSize: "0px", width: "100%" }}
                                                                                                                                                                cellSpacing="0"
                                                                                                                                                                cellPadding="0"
                                                                                                                                                                border="0"
                                                                                                                                                                align="center">
                                                                                                                                                                <tbody>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td
                                                                                                                                                                            style={{ textAlign: "center", verticalAlign: "top", direction: "ltr", fontSize: "0px", padding: "25px" }}>

                                                                                                                                                                            <div className="mj-column-per-100 outlook-group-fix"
                                                                                                                                                                                style={{ verticalAlign: "top", display: "inline-block", direction: "ltr", fontSize: "13px", textAlign: "left", width: "100%" }}>
                                                                                                                                                                                <table
                                                                                                                                                                                    role="presentation"
                                                                                                                                                                                    width="100%"
                                                                                                                                                                                    cellSpacing="0"
                                                                                                                                                                                    cellPadding="0"
                                                                                                                                                                                    border="0">
                                                                                                                                                                                    <tbody>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td
                                                                                                                                                                                                style={{ wordWrap: "break-word", fontSize: "0px", padding: "18px 0px" }}>
                                                                                                                                                                                                <p
                                                                                                                                                                                                    style={{ fontSize: "1px", margin: "0px auto", borderTop: "1px solid #c9c9c9", width: "75%" }}>
                                                                                                                                                                                                </p>

                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </tbody>
                                                                                                                                                                                </table>
                                                                                                                                                                            </div>

                                                                                                                                                                        </td>
                                                                                                                                                                    </tr>
                                                                                                                                                                </tbody>
                                                                                                                                                            </table>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>


                                                                                                                                        <table
                                                                                                                                            role="presentation"
                                                                                                                                            style={{ background: "#fafafa", fontSize: "0px", width: "100%" }}
                                                                                                                                            cellSpacing="0"
                                                                                                                                            cellPadding="0"
                                                                                                                                            border="0">
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td>
                                                                                                                                                        <div
                                                                                                                                                            style={{ margin: "0px auto", maxWidth: "570px" }}>
                                                                                                                                                            <table
                                                                                                                                                                role="presentation"
                                                                                                                                                                style={{ fontSize: "0px", width: "100%" }}
                                                                                                                                                                cellSpacing="0"
                                                                                                                                                                cellPadding="0"
                                                                                                                                                                border="0"
                                                                                                                                                                align="center">
                                                                                                                                                                <tbody>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td
                                                                                                                                                                            style={{ textAlign: "center", verticalAlign: "top", direction: "ltr", fontSize: "0px", padding: "3px 0px", paddingBottom: "0px" }}>

                                                                                                                                                                            <div className="mj-column-per-100 outlook-group-fix"
                                                                                                                                                                                style={{ verticalAlign: "top", display: "inline-block", direction: "ltr", fontSize: "13px", textAlign: "left", width: "100%" }}>
                                                                                                                                                                                <table
                                                                                                                                                                                    role="presentation"
                                                                                                                                                                                    width="100%"
                                                                                                                                                                                    cellSpacing="0"
                                                                                                                                                                                    cellPadding="0"
                                                                                                                                                                                    border="0">
                                                                                                                                                                                    <tbody>
                                                                                                                                                                                        <tr>
                                                                                                                                                                                            <td style={{ wordWrap: "break-word", fontSize: "0px", padding: "0px 0px" }}
                                                                                                                                                                                                align="center">
                                                                                                                                                                                                <div
                                                                                                                                                                                                    style={{ cursor: "auto", color: "#b2b2b2", fontFamily: "'Gotham Narrow SSm A','Gotham Narrow SSm B', Helvetica, sans-serif", fontSize: "13px", lineHeight: "22px", textAlign: "center" }}>

                                                                                                                                                                                                    <p
                                                                                                                                                                                                        style={{ color: "#b2b2b2" }}>
                                                                                                                                                                                                        AZPartes
                                                                                                                                                                                                        <br />
                                                                                                                                                                                                        Dúvidas
                                                                                                                                                                                                        ou
                                                                                                                                                                                                        sugestões?
                                                                                                                                                                                                        Entre
                                                                                                                                                                                                        em
                                                                                                                                                                                                        contato
                                                                                                                                                                                                        com:
                                                                                                                                                                                                        <a href="%cotact%"
                                                                                                                                                                                                            style={{ color: "#b2b2b2", textDecoration: "underline" }}>luana.dias@azpartes.com</a>
                                                                                                                                                                                                    </p>

                                                                                                                                                                                                </div>
                                                                                                                                                                                            </td>
                                                                                                                                                                                        </tr>
                                                                                                                                                                                    </tbody>
                                                                                                                                                                                </table>
                                                                                                                                                                            </div>

                                                                                                                                                                        </td>
                                                                                                                                                                    </tr>
                                                                                                                                                                </tbody>
                                                                                                                                                            </table>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                        <table
                                                                                                                                            role="presentation"
                                                                                                                                            style={{ msoHide: "all", background: "url('') top center / cover no-repeat", fontSize: "0px", width: "100%" }}
                                                                                                                                            cellSpacing="0"
                                                                                                                                            cellPadding="0"
                                                                                                                                            border="0"
                                                                                                                                            background="https://dripemailtemplates.com">
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td>
                                                                                                                                                        <div
                                                                                                                                                            style={{ margin: "0px auto", maxWidth: "570px" }}>

                                                                                                                                                            <table
                                                                                                                                                                role="presentation"
                                                                                                                                                                style={{ fontSize: "0px", width: "100%" }}
                                                                                                                                                                cellSpacing="0"
                                                                                                                                                                cellPadding="0"
                                                                                                                                                                border="0"
                                                                                                                                                                align="center">
                                                                                                                                                                <tbody>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td style={{ textAlign: "center", verticalAlign: "top", direction: "ltr", fontSize: "0px", padding: "10px 0px", paddingBottom: "45px", paddingTop: "0px" }}
                                                                                                                                                                            data-nl-format="datetime">
                                                                                                                                                                        </td>
                                                                                                                                                                    </tr>
                                                                                                                                                                </tbody>
                                                                                                                                                            </table>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </table>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    :
                    <Loader />
            }
        </div>
    )
}

export default Survey;