import React, { useState, useRef, useContext, useEffect } from 'react';
import './style.css';
import Loader from 'components/Loader';
import { Link, useHistory } from 'react-router-dom';
import API from 'utils/API';

function ValidarSenha() {

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const password = useRef();
    const history = useHistory();

    const checkPass = (e) => {

        e.preventDefault();
        setError("");
        if (!password.current.value) {
            setError("MISSING");
            return;
        }
        setLoading(true);
        const cancelToken = API.cancelToken();

        API.checkPassword(cancelToken, password.current.value).then(res => {
            setLoading(false);
            if (res.data.Status === "Password Correct") {
                history.push("/seguranca");
            }
        }).catch(err => {
            if (err.message === 'cancelling') return
            setLoading(false);
            if (err.response && err.response.status === 401) {
                setError('Invalid password');
            } else {
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }

    }


    return (
        <div className="validar-page">
            <div className="form-container">
                <Link className="reset" to={'/home'}>
                    <h3 className="margin-bottom-20">AZPartes.com</h3>
                </Link>
                <form>
                    <p className="label">Senha</p>
                    <input ref={password} type="password" />
                    {error &&
                        <p className="error margin-bottom-20">
                            Senha inválido
                        </p>
                    }
                    {
                        loading ?
                            <div className="loader-wrapper">
                                <Loader light={true} />
                            </div> :
                            <input type="submit" className="action-button margin-bottom-20" onClick={(e) => checkPass(e)} value="Entrar" />

                    }

                    <p className="disclaimer margin-bottom-30">
                        Ao continuar, você concorda com as Condições de Uso da AZPartes. Por favor verifique a Notificação de Privacidade, Notificação de Cookies e a Notificação de Anúncios Baseados em Interesse.
                    </p>
                    <h5>Precisa de ajuda?</h5>
                    <Link className="reset" to={'/recuperacao'}>
                        <p>Esqueci a senha</p>
                    </Link>
                </form>
            </div>
        </div>
    )
}

export default ValidarSenha;