import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useEffect, useState } from 'react';
import "./App.css";
import ContextProvider from 'utils/ContextProvider';
import Navigation from 'components/Navigation';
import Home from 'pages/Home';
import HomePrivate from 'pages/HomePrivate';
import Product from 'pages/Product';
import Orders from 'pages/Orders';
import OrderData from 'pages/OrderData';
import Cart from 'pages/Cart';
import Checkout from "pages/Checkout";
import Login from "pages/Login";
import SearchResults from "pages/SearchResults";
import ComprasEmLote from "pages/ComprasEmLote";
import Footer from 'components/Footer';
import GlobalFuncs from 'utils/GlobalFuncs';
import Recover from "pages/Recovery";
import ChangePassword from "pages/ChangePassword";
import ClientData from "pages/ClientData";
import About from "pages/About";
import ExchangePolicy from "pages/ExchangePolicy";
import Terms from "pages/Terms";
import Privacy from "pages/Privacy";
import Registration from "pages/Registration";
import RejectAvailability from "pages/RejectAvailability";
import AcceptAvailability from "pages/AcceptAvailability";
import MyAccount from "pages/MyAccount";
import Financeiro from "pages/Financeiro";
import Notifications from "pages/Notifications";
import ValidarSenha from "pages/ValidarSenha";
import Security from "pages/Security";
import Survey from "pages/Survey";
import PayOrder from "pages/PayOrder";
import MaintenancePage from "pages/Maintenance";
import API from 'utils/API';
import StockCar from "pages/Stockcar";
import DemandRequest from "pages/DemandRequest";
import { LanguageProvider } from "context/LanguageContext";




function App() {

  const [inMaintenanceMode, setInMaintenanceMode] = useState(false);
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    const cancelToken = API.cancelToken();
    API.getMaintenanceStatus(cancelToken).then(res => {
      console.log(res)
      setInfoLoaded(true)
      if (res.data.system_status == 1) {
        setInMaintenanceMode(true)
      } else {
        setInMaintenanceMode(false)
      }
    }).catch(err => {
      console.log(err);
      setInfoLoaded(true)
      setInMaintenanceMode(true)
    })
  }, [])


  return (
    <Router>
      <LanguageProvider>
        <ContextProvider>
          {!inMaintenanceMode && infoLoaded && <Navigation />}
          <GlobalFuncs.ScrollToTop />
          {infoLoaded &&
            <Switch>
              <Route path="/maintenance" component={MaintenancePage} />
              {inMaintenanceMode && <Redirect from="*" to="/maintenance" />}
              <Route exact path="/"><Redirect to="/home" /></Route>
              <Route path="/produto/:name/:id" component={Product} />
              <Route path="/stockcar" component={StockCar} />
              <Route path="/pedidos" component={Orders} />
              <Route path="/pedido/:id" component={OrderData} />
              <Route path="/cart" component={Cart} />
              <Route path="/improvements" component={DemandRequest} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/login" component={Login} />
              <Route path="/recuperacao" component={Recover} />
              <Route path="/alterarsenha" component={ChangePassword} />
              <Route path="/products/search" component={SearchResults} />
              <Route path="/lote" component={ComprasEmLote} />
              <Route path="/seusdados" component={ClientData} />
              <Route path="/about" component={About} />
              <Route path="/exchange" component={ExchangePolicy} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/registration" component={Registration} />
              <Route path="/rejeitarsolicitacao" component={RejectAvailability} />
              <Route path="/aprovarsolicitacao" component={AcceptAvailability} />
              <Route path="/account" component={MyAccount} />
              <Route path="/financeiro" component={Financeiro} />
              <Route path="/notificacoes" component={Notifications} />
              <Route path="/validarsenha" component={ValidarSenha} />
              <Route path="/seguranca" component={Security} />
              <Route path="/payorder" component={PayOrder} />
              <Route path="/nps" component={Survey} />
              <Route path="/private" component={HomePrivate} />
              <Route path="*" component={Home} />
            </Switch>}
          {!inMaintenanceMode && infoLoaded && <Footer />}
        </ContextProvider>
      </LanguageProvider>
    </Router>
  );
}


export default App;
