import { useState, useRef, useContext, useEffect } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import Loader from 'components/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

function Login() {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [success, setSuccess] = useState(null);
    // const [strength, setStrength] = useState(null);
    const password = useRef();
    const confirmPassword = useRef();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('comp') !== null) history.push('/')
        params.get('ref') ? setToken(params.get('ref')) : setToken(false);
    }, [])

    useEffect(() => {
        if (token === false) history.push('/')
    }, [token])

    function setPassword(a){

    }

    const changePassword = (e) => {
        e.preventDefault();
        setError(null);
        if (!password.current.value) return;
        if (!confirmPassword.current.value) return;
        if (password.current.value !== confirmPassword.current.value) {
            setError('Senhas não conferem');
            return;
        }
        setLoading(true);
        const cancelToken = API.cancelToken();
        
        API.changePassword(cancelToken, token, password.current.value).then(res => {
            setSuccess(true);
            setLoading(false);
            if(res.data.message == "err"){
                Swal.fire({
                    title: 'Atenção',
                    text: res.data.error,
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }else{
                Swal.fire({
                    title: 'Senha alterada com sucesso.',
                    text: '',
                    confirmButtonColor: "#131921",
                    confirmButtonText: 'IR PARA O SITE'
                }).then((result) => {
                    history.push('/login')
                })
            }
        }).catch(err => {
            if(err.message==='cancelling') return
            setError(err.response.message);
            console.log(err.message);
        });
        return () => {
            API.cancel(cancelToken);

        }
    }

    return (
        <div className="change-password-page">
            <div className="form-container">
                <div className={`section-one b2b-color`}>
                    <Link className="reset" to={'/home'}>
                        <h1 className="margin-bottom-20">AZPartes.com</h1>
                    </Link>
                </div>
                <div className="section-two">
                    <form>
                        <h2 className="margin-bottom-30 title">Alterar Senha</h2>
                        {/* <p className="description">Valide suas informações para acessar o <strong>AZPartes.com</strong>, e-commerce B2B da SKF.</p> */}
                        <div>
                                <p className="label">Senha</p>
                                <div className={`pass-holder input ${error ? "error-input" : ""}`}>
                                    <input className='mask-password' type="password" ref={password} placeholder="Digite a senha"/>
                                </div>
                                <p className="label">Confirmar Senha</p>
                                <div className={`pass-holder input ${error ? "error-input" : ""}`}>
                                    <input className='mask-password' type="password" ref={confirmPassword} placeholder="Digite a senha"/>
                                </div>
                                <p className="margin-bottom-10 info-message margin-top-10">
                                        Ao continuar, você concordou com as condições de uso do AZPartes.
                                    </p>
                                {error &&
                                    <p className="error margin-bottom-10">
                                       {error}
                                    </p>
                                }
                            </div>
                            {
                            loading &&
                            <div className="loader-wrapper">
                                <div className='rotator-wrapper'>
                                    <Loader color="#b0b1b2" />
                                </div>
                            </div>
                        }
                        <input type="submit" className="action-button margin-bottom-20 margin-top-20 input" onClick={(e) => changePassword(e)} value="Continuar" />
                        
                        {/* <p className='text-center pointer' onClick={() => history.push('/')}>Voltar ao site</p> */}

                        {/* <Link className="reset" to={'/'}>
                        <p>Outros problemas com o acesso</p>
                    </Link> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;