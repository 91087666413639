import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './style.css';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';
import GlobalFuncs from 'utils/GlobalFuncs';
import Loader from 'components/Loader';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

function ClientData() {

    const history = useHistory();
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({});
    const [contact, setContact] = useState({ name: "", phone: "" })
    const [mask, setMask] = useState("+55 (99) 99999-9999");
    const [error, setError] = useState(false);

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Meus Dados',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        setLoading(true);
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        const cancelToken = API.cancelToken();
        API.isActive().then(none => {
            hotjar.initialize('3361828')
            ReactGA.pageview(window.location.pathname);
            API.getCompany(cancelToken).then(res => {
                setInfo(res.data.company)
                setLoading(false);
                setContact({ name: res.data.company.name, phone: res.data.company.phone })
                if(res.data.company.phone.length == 19){
                    setMask("+55 (99) 99999-9999")
                }else{
                    setMask("+55 (99) 9999-9999")
                }
            }).catch(err => {
                if (err.message === 'cancelling') return
                console.log(err.response);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(sel => {
                    window.location.href = "/"
                })
            });
        }).catch(err => {
            context.logOut()
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    const validatePhone = (phone) => {
        phone = `${phone}`.replace(/\D/g,'');
        if (phone.length === 13 || phone.length === 12){
            return true
        }else{
            return false
        }
      };

    const handleUpdate = (event) => {
        const { name, value } = event.target;
        var phone = ``
        if (name == "phone"){
            phone = `${value}`.replace(/\D/g,'');
        }
        if (!validatePhone(phone)){
            setError(true)
        }else{
            setError(false)
        }
        setContact(preValue => {
            return {
                ...preValue,
                [name]: value
            }
        });
    }

    const updateContact = (e)=>{
        e.preventDefault()
        if(error){
            return
        }
        let button = e.currentTarget
        button.disabled = true
        API.updateContact(contact).then(res => {
            Swal.fire({
                title: 'Sucesso!',
                text: 'Dados atualizados com sucesso!',
                // confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(s=>{
                button.disabled = false
            })
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.response);
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(f=>{
                button.disabled = false
            })
        })
    }

    return (
        <div className="account-page-wrapper page-wrapper-white one-whole margin-top-50">
            <div className="section-wrapper-small one-whole">
                <div className="breadcrumbs flex-row-nowrap align-center justify-center">
                <h4 className='margin-right-10'><Link to='/account' className='reset your-account'>Minha conta</Link></h4>
                    <svg className="svg-icon__arrow--right" viewBox="0 0 7 12" height="10px" width="10px" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#C3C4C5" fillRule="evenodd" d="M6.893 5.967c0-.268-.107-.524-.295-.713L1.724.37C1.33-.024.692-.024.3.37c-.394.394-.394 1.033 0 1.427l4.162 4.17L.3 10.137c-.394.394-.394 1.033 0 1.427.392.394 1.03.394 1.424 0L6.598 6.68c.188-.19.295-.445.295-.713z" />
                    </svg>
                    <p>Meus Dados</p>
                </div>
                <div className="main-section">
                    <div className="user-wrapper margin-bottom-20 margin-top-20">
                        {
                            loading ?
                                <div className="loader-wrapper">
                                    <Loader light={true} />
                                </div> :
                                <div>
                                    <div className='first-section'>
                                        <div className='id-section'>
                                            <p>AZPartes ID</p>
                                            <input type="text" placeholder={info ? info.skf_id : 'ID'} disabled={true} />
                                        </div>
                                        <div className='name-section'>
                                            <p>Razão Social</p>
                                            <input type="text" placeholder={info ? info.corporate_name : 'Empresa'} disabled={true} />
                                        </div>
                                        <div className='cnpj-section'>
                                            <p>CNPJ</p>
                                            <input type="text" placeholder={info ? info.cnpj : 'CNPJ'} disabled={true} />
                                        </div>
                                    </div>
                                    <div className='address-section'>
                                        <p>Endereço</p>
                                        <input type="text" placeholder={info && info.address ? `${info.address[0].address}, ${info.address[0].number} - ${info.address[0].city}` : 'Endereço'} disabled={true} />
                                    </div>
                                    <div className='second-section'>
                                        <p className='margin-bottom-20'>Informação de Contato</p>
                                        <form>
                                            <div className='section-1'>
                                                <div className='form-section'>
                                                    <p>Nome</p>
                                                    <input type="text" name="name" placeholder="Nome" onChange={handleUpdate} value={contact.name} />
                                                </div>
                                                <div className='form-section'>
                                                    <p>Telefone</p>
                                                    <InputMask
                                                     mask={mask}
                                                     onBlur={e => {
                                                         if (e.target.value.replace("_", "").length === 18) {
                                                           setMask("+55 (99) 9999-9999");
                                                         }
                                                       }}
                                                       onFocus={e => {
                                                         if (e.target.value.replace("_", "").length === 18) {
                                                           setMask("+55 (99) 99999-9999");
                                                         }
                                                       }}
                                                       type="text" 
                                                       className={`${error ? "border border-amber-900" : "border-0"}`}
                                                       name="phone" 
                                                       placeholder="Telefone" 
                                                       onChange={handleUpdate} 
                                                       value={contact.phone} />
                                                </div>
                                                <div className='form-section'>
                                                    <p>Email</p>
                                                    <input type="text" placeholder={info ? info.email : 'Email'} disabled />
                                                </div>
                                            </div>
                                            <div className='section-2'>
                                                <button onClick={e=>updateContact(e)} className='action-button' disabled={!contact.name || !contact.phone}>EDITAR CONTATO</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ClientData;