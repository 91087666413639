import { useEffect, useState, useContext } from 'react';
import Carousel from 'components/Carousel';
import ImgSelector from 'components/ImgSelector';
import ProductInfo from 'components/ProductInfo';
import PurchaseBox from 'components/PurchaseBox';
import ProductTables from 'components/ProductTables';
import { useHistory } from 'react-router-dom';
import API from "utils/API";
import { useParams } from 'react-router-dom';
import { AppContext } from 'utils/ContextProvider';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Swal from 'sweetalert2';
import Loader from 'components/Loader';
import Rating from 'components/Rating';


function Product() {

    const context = useContext(AppContext);
    const history = useHistory();
    const tempArr = [];
    const [recommended, setRecommended] = useState([]);
    const [product, setProduct] = useState({});
    const [loader, setLoader] = useState(true);
    const [images, setImages] = useState([]);
    const params = useParams();

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Produto',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
        const cancelToken = API.cancelToken();
        API.getMaintenanceStatus(cancelToken).then(res => {
            if(res.data.system_status == 1){
                window.location.href = '/maintenance'
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);

    function replaceSpaceWithPlus(inputString) {
        const replacedString = inputString.replace(/ /g, '+');
        return replacedString;
    }


    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if(context.getSub_business_segment == "Stock Car"){
            history.push('/stockcar');
            return;
        }
        const cancelToken = API.cancelToken();
        // API.isActive().then(none => {
        //     context.setAuthCompanies(none.data.authorized_companies);
            hotjar.initialize('3361828')
            ReactGA.pageview(window.location.pathname);
            setImages([])
            setProduct({})
            context.getLogin &&
                API.getRecommendedProducts(cancelToken).then(res => {
                    setRecommended(res.data.products);
                }).catch(err => {
                    if(err.message==='cancelling') return
                    console.log(err.response);
                });
            API.getProduct(params.id, cancelToken).then(res => {
                setLoader(false)
                let prod = res.data.product
                setProduct(prod);
                let temp = [...tempArr];
                prod.images.forEach(image => {
                    var img = new Image();
                    img.src = `https://azpartes-catalog-images-production.s3.amazonaws.com/${image.image_url}`
                    img.onload = function() {
                        // You can perform further actions here if the image loaded successfully
                        image.image_url = replaceSpaceWithPlus(image.image_url)
                        if (!image.image_url.includes('thumb')) temp.unshift({ src: image.image_url.includes("http") ? image.image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${image.image_url}`, alt: prod.name });
                        setImages(temp);
                    };
                    img.onerror = function() {
                        console.log("Error loading image.");
                        image.image_url = replaceSpaceWithPlus(image.image_url)
                        if (!image.image_url.includes('thumb')) temp.unshift({ src: require("../../assets/images/placeholder.png").default, alt: prod.name });
                        // You can handle the error condition here
                        setImages(temp);
                    };
                });
            }).catch(err => {
                if(err.message==='cancelling') return
                console.log(err);
                console.log(err.response);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(sel=>{
                    window.location.href = "/"
                })
            });
        // }).catch(err => {
        //     context.logOut()
        // });
        return () => {
            API.cancel(cancelToken);
        }
    }, [params]);

    return (
        <div className="product-page page-wrapper-white one-whole margin-top-50">
            {
                loader ?
                    <div className="loader-wrapper">
                        <Loader light={true} />
                    </div> :
                    <div className="section-wrapper one-whole">
                        <div className="flex-row flex-center margin-bottom-50">
                            <ImgSelector images={images} />
                            <ProductInfo product={product} />
                            <PurchaseBox key={product.product_id} product={product} />
                        </div>
                        <ProductTables
                            specifications={product.specifications && product.specifications}
                            applications={product.applications && product.applications}
                            reference={product.reference && product.reference}
                            details={product.details && product.details}
                        />
                        <div className="margin-top-50">
                            <hr />
                        </div>
                    </div>
            }
            {/* {
                context.getLogin &&
                <div className="section-wrapper one-whole">
                    <Carousel header="Produtos Recomendados" list={recommended} />
                </div>
            } */}
        </div>
    )
}

export default Product;