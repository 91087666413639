import './style.css';
import { useEffect, useState, useContext, useRef } from 'react';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';

function Privacy() {
    const context = useContext(AppContext);
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        API.isActive().then(none => {
        }).catch(err => {
            context.logOut()
        });
    }, [])
    return (
        <div className="privacy-page margin-top-40 margin-bottom-40 section-wrapper">
            <h1 className="margin-bottom-30">
                Política de Privacidade
            </h1>
            <p>
            Como seus dados pessoais são tratados
            </p><br/>
            <p>
            A SKF leva muito a sério a privacidade pessoal e a proteção da integridade. Os
            representantes de clientes, fornecedores e outras partes interessadas da SKF,
            como parceiros ou estudantes, em contato com a SKF (“Parceiro”) devem estar
            seguros de que a empresa respeita a privacidade deles e obedece a leis e normas
            aplicáveis ao coletar e processar informações que podem ser usadas para
            identificar uma pessoa (“Dados pessoais”). As informações fornecidas neste
            documento serão aplicadas se a SKF não tiver comunicado o contrário no
            momento da coleta dos Dados pessoais.
            </p><br/>
            <p>
            Que informações pessoais são coletadas
            </p><br/>
            <p>
        A SKF pode coletar Dados pessoais diretamente de um Parceiro que se reúne com
            funcionários da SKF, acessa sites da SKF, abre boletins informativos, faz download
            de materiais da SKF ou usa produtos ou serviços da SKF.
            </p><br/>
            <p>
            A SKF coleta e processa Dados pessoais de Parceiros para as seguintes finalidades:
            </p><br/>
            <p>
            1. Cumprir com os deveres estabelecidos em uma relação contratual entre a SKF e
            um Parceiro ou ao entrar em uma.
            </p><br/>
            <p>
            2. Facilitar a comunicação efetiva e as relações entre a SKF e o Parceiro (Gestão de
            relacionamento com o cliente).
            </p><br/>
            <p>
            3. Gerenciar consultas e outras solicitações relacionadas a produtos e serviços da
            SKF.
            </p><br/>
            <p>
            4. Garantir a conformidade com as obrigações legais e o cumprimento dos
            compromissos contratuais.
            </p><br/>
            <p>
            5. Gerenciar a segurança de produtos, serviços, direitos de propriedade intelectual e
            outras ofertas da SKF.
            </p><br/>
            <p>
            6. Analisar os dados de vendas e como um Parceiro interage com os produtos e
            serviços da SKF, a fim de aprimorar a experiência do usuário e o conteúdo desses
            produtos e serviços.
            </p><br/>
            <p>
            7. Marketing, pesquisas e comunicação.
            </p><br/>
            <p>
            8. Permitir que usuários com senha acessem novamente determinadas páginas da
            Web sem precisar digitar de novo a informação já fornecida por meio do uso de
            cookies.
            </p><br/>
            <p>
            9. Compilar estatísticas relacionadas a padrões e tendências de navegação nas
            páginas da Web da SKF ou ao uso de outros aplicativos ou software.
            </p><br/>
            <p>
            Divulgação das informações pessoais a terceiros
            </p><br/>
            <p>
            A SKF não divulgará os Dados pessoais de um Parceiro a terceiros se isso não for
            exigido para atender a uma finalidade listada acima. Qualquer transferência de
            Dados pessoais a um terceiro dentro do Brasil ocorrerá somente se o destinatário
            estiver em conformidade com as normas internas da SKF que regem a proteção de
            Dados pessoais. Qualquer transferência de Dados pessoais a um terceiro fora do
            Brasil será baseada nas disposições da Lei Geral de Proteção de Dados (“LGPD”) –
            Lei 13.709, de 14 de agosto de 2018..
            Page 2 of 2
            </p><br/>
            <p>
            Seus direitos
            </p><br/>
            <p>
            Quando o processamento dos Dados pessoais depender do consentimento, um
            Parceiro pode revogar o consentimento dado a qualquer momento, desde que isso
            não afete a legitimidade do processamento anterior ou que não haja outra base
            legal para o processamento.
            </p><br/>
            <p>
            Um Parceiro pode, de acordo com a lei de proteção de dados aplicável, ter o direito
            de: (i) receber informações sobre quais Dados pessoais estão relacionados aos
            processos da SKF no Parceiro; (ii) receber acesso a esses dados e, se necessário,
            alterá-los para que estejam corretos; (iii) excluir os Dados pessoais relacionados ao
            Parceiro; (iv) restringir ou limitar o processamento, por parte da SKF, dos Dados
            pessoais do Parceiro; (v) obter da SKF uma cópia dos Dados pessoais fornecidos
            pelo Parceiro em um formato legível por computador, estruturado e popular.
            </p><br/>
            <p>
            Como proteger seus dados
            </p><br/>
            <p>
            A SKF processa os Dados pessoais de acordo com as leis aplicáveis para proteção
            de dados
            e segurança de dados e com a norma ISO 27001 sobre segurança das
            informações.
            </p><br/>
            <p>
            Período de retenção
            </p><br/>
            <p>
            A SKF retém os Dados pessoais do Parceiro apenas pelo período necessário para
            cumprir com as finalidades da coleta. A SKF pode manter os Dados pessoais em
            conformidade com uma obrigação legal ou contratual que exija a retenção dos
            dados por períodos mais longos.
            </p><br/>
            <p>
            Informações de contato
            </p><br/>
            <p>
            Se você tiver dúvidas sobre esta política, fique à vontade para entrar em contato
            conosco usando as informações abaixo. Além disso, você tem o direito de entrar
            em contato com a autoridade local de proteção de dados ou registrar uma
            reclamação.
            </p><br/>
            <p>
            Diretor de Proteção de Dados SKF
            </p><br/>
            <p>
            Sede do Grupo SKF
            </p><br/>
            <p>
            SE-415 15 Gotemburgo - Suécia
            </p><br/>
            <p>
            Telefone: +46 31 337 10 00
            </p><br/>
            <p>
            Fax: +46 31 337 17 22
            </p><br/>
            <p>
            SKF Data Protection Officer - Brasil
            </p><br/>
            <p>
            Elder Tozarini
            </p><br/>
            <p>
            E-mail: elder.tozarini@skf.com
            </p><br/>
            <p>
            Tel: +55 (11) 4448-8200
            </p><br/>
        </div>
    )

}

export default Privacy;