import React, { useState } from "react";
import styled from "styled-components";

const PaymentMethod = ({ onPaymentChange }) => {
const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const handleChange = (value) => {
    console.log(value);
    setSelectedPaymentMethod(value);
    onPaymentChange(value);
  };

  return (
    <PaymentMethodContainer>
      <PaymentMethodHeader>Método de pagamento</PaymentMethodHeader>
      <PaymentMethodContent>
        <PaymentMethodList>
          <PaymentMethodItem>
          <PaymentMethodInput
              type="radio"
              id="creditCard"
              name="paymentMethod"
              value="2"
              onChange={() => handleChange(2)}
            />
            <PaymentMethodLabel htmlFor="creditCard">Cartão de Crédito</PaymentMethodLabel>
          </PaymentMethodItem>
          <PaymentMethodItem>
          <PaymentMethodInput
              type="radio"
              id="advancePayment"
              name="paymentMethod"
              value="1"
              onChange={() => handleChange(1)}
            />
            <PaymentMethodLabel htmlFor="advancePayment">Pagamento Antecipado</PaymentMethodLabel>
          </PaymentMethodItem>
        </PaymentMethodList>
        {selectedPaymentMethod === 2 && <PaymentMethodNote>
          A liberação ocorrerá após a aprovação do crédito pela operadora do
          cartão.
        </PaymentMethodNote>}
        {selectedPaymentMethod === 1 && <PaymentMethodNote>
            A liberação para processo de faturamento do pedido ocorre após a comprovação da entrada do valor na conta corrente da SKF.
        </PaymentMethodNote>}
      </PaymentMethodContent>
    </PaymentMethodContainer>
  );
};

const PaymentMethodContainer = styled.section`
`;

const PaymentMethodHeader = styled.h2`
  background-color: #f6f6f6;
  border-radius: 4px 4px 0 0;
  border: 1px solid rgba(216, 216, 216, 1);
  color: #151e26;
  font: 600 18px SKF;
  font-size: 22px !important;
  justify-content: center;
  letter-spacing: 0.3px;
  padding: 18px 24px 16px;
`;

const PaymentMethodContent = styled.div`
  align-items: start;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  border: 1px solid rgba(216, 216, 216, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 130%;
  padding: 32px 24px;
  width: 100%;
`;

const PaymentMethodList = styled.ul`
  color: #000;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: 20px;
  justify-content: space-between;
  margin-left: 0px;
  padding-left: 0px;
`;

const PaymentMethodItem = styled.li`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const PaymentMethodInput = styled.input`
  cursor: pointer;
`;

const PaymentMethodLabel = styled.label`
  font-family: SKF Sans, sans-serif;
  cursor: pointer;
`;

const PaymentMethodNote = styled.p`
  color: #7e7e7e;
  font: 500 12px SKF Sans, sans-serif;
  margin-top: 24px;
`;

export default PaymentMethod;