import { useEffect, useContext, useState } from 'react'
import { AppContext } from 'utils/ContextProvider';
import './style.css';

function PaymentCard(props) {
    const context = useContext(AppContext)
    const ccFormat = (value) => {
        var v = value.replace(/\s+/g , '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []
        if(match.charAt(0) === "3"){
            parts.push(match.substring(0, 4))
            if (match.length > 4) parts.push(match.substring(4, 10))
            if (match.length > 10) parts.push(match.substring(10, 15))
        }else{
            for (let i = 0, len = match.length; i < len; i += 4) {
                parts.push(match.substring(i, i + 4))
            }
        }
        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    }

    const cpfFormat = (value) => {
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/, function (_, p1, p2, p3, p4) {
            let masked = '';
            masked += p1;
            if (p2) {
                masked += '.' + p2;
            }
            if (p3) {
                masked += '.' + p3;
            }
            if (p4) {
                masked += '-' + p4;
            }
            return masked;
        })
        return value
    }

    const handleCcChange = (event) => {
        let { name, value } = event.target;
        if (name === 'card_number') {
            let num = ccFormat(value)
            event.target.value = num
            value = num
        }
        if (name === 'cpf') {
            let num = cpfFormat(value)
            event.target.value = num
            value = num
        }
        props.updateInfo(prevValue => {
            return {
                ...prevValue, [name]: value
            }
        })
    };
    return (
        <div className='cc-section'>
            {props.disabled && <div className='cover'></div>}
            <div className="mt-6 lg:mt-16 w-full">
                <form>
                    {/* <h4 className='normal-weight grey-font margin-bottom-20'>
                            Adicionar novo cartão de crédito
                        </h4> */}
                    <div className="my-5 md:max-w-md w-full">
                        <div className='flex-row align-center space-between'>
                            <div className="flex-col w-half">
                                <label className='margin-bottom-10'>Número do cartão</label>
                                <input name="card_number" value={props.ccInfo.card_number ? props.ccInfo.card_number : ""} onChange={handleCcChange} placeholder="Exemplo: 1234 5678 9012 3456"
                                    className={`card-input w-full ${props.error && !/\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/.test(props.ccInfo.card_number) ? 'border border-red-500' : ''}`}
                                    required />
                            </div>
                            <div className="flex-col w-half">
                                <label className='margin-bottom-10'>Nome completo do titular</label>
                                <input name="card_name" value={props.ccInfo.card_name ? props.ccInfo.card_name : ""} onChange={handleCcChange} placeholder="Exemplo: Natália Santos de Oliveira"
                                    className={`card-input w-full ${props.error && (!props.ccInfo.card_name || !props.ccInfo.card_name.trim()) ? 'border border-red-500' : ''}`}
                                    required />
                            </div>
                        </div>

                        <div className='flex flex-row flex-nowrap space-between align-center w-full'>
                            <div className='w-full'>
                                <label className='margin-bottom-10 w-full display-block'>Data de validade</label>
                                <div className="flex-row-nowrap w-full space-between">
                                    <div className="flex-col w-half">
                                        <select name="card_exp_month" value={props.ccInfo.card_exp_month ? props.ccInfo.card_exp_month : ""} onChange={handleCcChange} required
                                            className={`card-input w-full ${!props.ccInfo.card_exp_month ? "placeholder" : ""} ${props.error && (!/[0-9]/.test(props.ccInfo.card_exp_month) || parseInt(props.ccInfo.card_exp_month) > 12 || parseInt(props.ccInfo.card_exp_month) < 1) ? 'border border-red-500' : ''}`}>
                                            <option value="" disabled hidden>Mês</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                    <div className="flex-col w-half">
                                        <select name="card_exp_year" value={props.ccInfo.card_exp_year ? props.ccInfo.card_exp_year : ""} onChange={handleCcChange} required
                                            className={`card-input w-full ${!props.ccInfo.card_exp_year ? "placeholder" : ""} ${props.error && (!/[0-9]/.test(props.ccInfo.card_exp_year) || parseInt(props.ccInfo.card_exp_year) < new Date().getFullYear()) ? 'border border-red-500' : ''}`}>
                                            <option value="" disabled hidden>Ano</option>
                                            {
                                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((e, index) => (
                                                    <option key={"yearkey" + index} value={new Date().getFullYear() + e} > {new Date().getFullYear() + e}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-col w-half">
                                <label className='margin-bottom-10 w-half'>CVV</label>
                                <input name="card_cvv" maxLength="3" value={props.ccInfo.card_cvv ? props.ccInfo.card_cvv : ""} onChange={handleCcChange} placeholder="Exemplo: 123"
                                    className={`card-input w-full  ${props.error && (!props.ccInfo.card_cvv || !/^\d{3}$/.test(props.ccInfo.card_cvv)) ? 'border border-red-500' : ''}`}
                                    required />
                            </div>
                            <div className="flex-col w-half">
                                <label className='margin-bottom-10 w-half'>CPF</label>
                                <input name="cpf" maxLength="14" value={props.ccInfo.cpf ? props.ccInfo.cpf : ""} onChange={handleCcChange} placeholder="Exemplo: 123.123.123-12"
                                    className={`card-input w-full  ${props.error && (!props.ccInfo.cpf || !/^\d{3}[.]\d{3}[.]\d{3}[-]\d{2}$/.test(props.ccInfo.cpf) || !/^\d{11}$/.test(props.ccInfo.cpf)) ? 'border border-red-500' : ''}`}
                                    required />
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </div >)
}
export default PaymentCard