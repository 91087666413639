import React from 'react';
import './style.css';

const MaintenancePage = () => {
    return (
        <div className="maintenance-container">
            <h1>Atenção: Manutenção de Sistema</h1>
            <p>Buscando constante evolução no AZPartes.com, informamos que
                realizaremos uma manutenção programada a partir de 20/01/2024 às 16:00
                com retorno previsto para 21/01/2024 às 08:00.</p>
            {/* <p className='margin-top-30'><strong>Durante este período nenhum novo pedido será processado.</strong></p> */}
            <p className='margin-top-10'>Agradecemos sua compreensão</p>
            {/* <p className='margin-top-30'>Feliz 2024!</p> */}
            <p>Equipe AZPartes.com</p>
        </div>
    );
};

export default MaintenancePage;