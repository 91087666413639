import './style.css';

function Loader(props) {

    return (
        <div className="flex-wrapper flex-row-nowrap align-center flex-center">
            <div className={`loader ${props.light===true? 'loader-light' : 'loader-bold'}`}>
                <div style={{'borderColor': props.color? `${props.color} transparent transparent transparent` : '#232F3E transparent transparent transparent'}}></div>
                <div style={{'borderColor': props.color? `${props.color} transparent transparent transparent` : '#232F3E transparent transparent transparent'}}></div>
                <div style={{'borderColor': props.color? `${props.color} transparent transparent transparent` : '#232F3E transparent transparent transparent'}}></div>
                <div style={{'borderColor': props.color? `${props.color} transparent transparent transparent` : '#232F3E transparent transparent transparent'}}></div>
            </div>
        </div>
    )
}

export default Loader;