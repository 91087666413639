import { useState, createContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import './style.css';
import API from 'utils/API';
import Swal from 'sweetalert2';
import Loader from 'components/Loader';

export const AppContext = createContext();

export const ContextProvider = (props) => {


    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState(null);
    const [login, setLogin] = useState(localStorage.getItem('comp'));
    const [auth, setAuth] = useState();
    const [pricelist, setPricelist] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [authorizedUsers, setAuthorizedUsers] = useState();
    const [sub_business_segment, setSub_business_segment] = useState();

    const [address, setAddress] = useState();
    const [policies, setPolicies] = useState([]);
    const [denied, setDenied] = useState(false);
    const [applications, setApplications] = useState(null)
    const [products, setProducts] = useState(null)
    const [words, setWords] = useState(null)
    const history = useHistory();

    useEffect(() => {
        getPolicy();
    }, [login])

    useEffect(() => {
        if (!login) return;
        updateCart();
    }, [login]);

    useEffect(() => {
        let user = localStorage.getItem('comp');
        let token = localStorage.getItem('2r23ef55');
        let tempPricelist = localStorage.getItem('pricelist');
        let tempSub_business_segmentt = localStorage.getItem('sub_business_segment');
        let tempAddress = localStorage.getItem('address')
        let tempAuthUsers = localStorage.getItem('authorizedUsers')
        let tempCurrentUser = localStorage.getItem('currentUser')
        try {
            tempAddress = JSON.parse(tempAddress)
        } catch {
            tempAddress = null
        }
        try {
            tempAuthUsers = JSON.parse(tempAuthUsers)
        } catch {
            tempAuthUsers = null
        }
        setInterval(() => {
            let list = ['/login', '/registration', '/registration', '/recuperacao', '/alterarsenha', '/nps']
            if (!list.includes(history.location.pathname)) {
                API.isActive().then(none => {
                }).catch(err => {
                    logOut()
                });
            }
        }, 20000)


        if (!tempSub_business_segmentt && tempPricelist){
            if(tempPricelist == "CAR18"){
                tempSub_business_segmentt = "SKF Center"
                localStorage.setItem('sub_business_segment', tempSub_business_segmentt);
            }
            if(tempPricelist == "BPL2W"){
                tempSub_business_segmentt = "Two Wheeler"
                localStorage.setItem('sub_business_segment', tempSub_business_segmentt);
            }
        }        
        if (token && user && tempAddress && tempPricelist && tempSub_business_segmentt && tempAuthUsers && tempCurrentUser) {
            setAddress(tempAddress)
            setLogin(user);
            setPricelist(tempPricelist)
            setSub_business_segment(tempSub_business_segmentt)
            setCurrentUser(tempCurrentUser)
            setAuthorizedUsers(tempAuthUsers)
        } else {
            let list = ['/login', '/registration', '/registration', '/recuperacao', '/alterarsenha']
            if (!list.includes(history.location.pathname)) {
                logOut()
            }
        }
    }, []);

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (!login) return
        API.getPredictions(cancelToken).then(res => {
            setProducts(res.data.products)
            setApplications(res.data.list)
            setWords(res.data.words)
        }).catch(err => {
            console.log(err)
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [login])
    const updateCart = () => {
        setCart(null);
        const cancelToken = API.cancelToken();
        API.getCart(cancelToken).then(res => {
            if (res.data) {
                // Add availability_quantity property to each item in the cart
                const updatedCart = res.data.map(item => ({
                    ...item
                }));
                setCart(updatedCart);
            } else {
                setCart([]);
            }
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.message);
        });
    
        return () => {
            API.cancel(cancelToken);
        }
    }


    const getPolicy = () => {
        const cancelToken = API.cancelToken();
        API.getPolicyStatus(cancelToken).then(res => {
            setPolicies(res.data.policies);
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.message);
        });

        return () => {
            API.cancel(cancelToken);
        }
    }

    const acceptPolicy = (e, id) => {
        const cancelToken = API.cancelToken();
        setLoading(true)
        setDenied(false)
        API.setPolicyStatus(cancelToken, id).then(res => {
            setLoading(false)
            let tempPolicies = [...policies]
            tempPolicies.splice(0, 1);
            setPolicies(tempPolicies);
        }).catch(err => {
            setLoading(false)
            if (err.message === 'cancelling') return
            console.log(err.message);
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
        return () => {
            API.cancel(cancelToken);
        }
    }

    const logOut = () => {
        localStorage.removeItem('2r23ef55');
        localStorage.removeItem('comp');
        localStorage.removeItem('address');
        localStorage.removeItem('pricelist')
        localStorage.removeItem('authorizedUsers')
        localStorage.removeItem('currentUser')
        localStorage.removeItem('sub_business_segment')
        setLogin(false);
        history.push('/login');
    }


    const logIn = (user, token, address, pricelist, sub_business_segment, authorizedUsers, currentUser) => {
        localStorage.setItem('2r23ef55', token);
        localStorage.setItem('comp', user);
        localStorage.setItem('pricelist', pricelist);
        localStorage.setItem('sub_business_segment', sub_business_segment);
        localStorage.setItem('address', JSON.stringify(address));
        localStorage.setItem('authorizedUsers', JSON.stringify(authorizedUsers));
        localStorage.setItem('currentUser', currentUser);
        setLogin(user);
        setAddress(address)
        setPricelist(pricelist)
        setAuthorizedUsers(authorizedUsers)
        setCurrentUser(currentUser)
        setSub_business_segment(sub_business_segment)
    }
    
    const setAuthCompanies = ( authorizedUsers ) => {
        localStorage.setItem('authorizedUsers', JSON.stringify(authorizedUsers));
        setAuthorizedUsers(authorizedUsers)
    }

    return (
        <AppContext.Provider value={
            {
                setLoading: setLoading,
                getCart: cart,
                setCart: (cart) => setCart(cart),
                updateCart: updateCart,
                getApplications: applications,
                getProducts: products,
                getWords: words,
                getLogin: login,
                getAddress: address,
                getPricelist: pricelist,
                getCurrentUser: currentUser,
                getAuthorizedUsers: authorizedUsers,
                logIn: (user, token, address, pricelist, sub_business_segment ,authorizedUsers, currentUser) => logIn(user, token, address, pricelist, sub_business_segment, authorizedUsers, currentUser),
                setAuthCompanies: (authorizedUsers) => setAuthCompanies(authorizedUsers),
                getSub_business_segment: sub_business_segment,
                logOut: logOut,
                auth: auth,
                setAuth: setAuth
            }
        }>
            {/* <div className="loader-wrapper" style={{ 'display': `${loading ? 'block' : 'none'}` }}></div> */}
            {props && props.children.map(element => (
                element
            ))}

            {
                policies.length > 0 &&
                <div class="policy-modal">
                    <div class="modal-content">
                        {loading &&
                            <div className="loader-wrapper">
                                <Loader light={true} />
                            </div>
                        }
                        <h3 className='margin-bottom-30'>Alteração - {policies[0].name}</h3>
                        <div className='policy-text' dangerouslySetInnerHTML={{ __html: policies[0].content }}></div>
                        <p className='error-message error flex-row align-center flex-center'>
                            {denied && 'Para seguir utilizando o AZPartes.com precisamos do seu aceite no documento atualizado'}
                        </p>
                        <div className='flex-row one-whole align-center flex-center'>
                            <div className='action-button' onClick={(e) => acceptPolicy(e, policies[0].id)}>Concordo</div>
                            <div className='action-button' onClick={() => setDenied(true)}>Não Concordo</div>
                        </div>
                    </div>
                </div>
            }
        </AppContext.Provider>
    )
}

export default ContextProvider;