import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import GlobalFuncs from 'utils/GlobalFuncs';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

function CheckoutItem(props) {
    const [taxInfo, setTaxInfo] = useState(false);
    const { translate } = useLanguage();

    const showTaxInfo = async (cancelToken) => {
        setTaxInfo(true)
    }
    const hideTaxInfo = async (cancelToken) => {
        setTaxInfo(false)        
    }

    return (
        <div className="checkout-item-wrapper flex-row-nowrap margin-bottom-40">
            <img src={props.item.image_url.includes("http") ? props.item.image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${props.item.image_url}`} alt={props.item.name} onError={(event) => {
                                        event.target.src = require("../../assets/images/placeholder.png").default;
                                }}/>
            <div className="item-info">
                <Link className="reset" to={`/produto/${GlobalFuncs.cleanString(props.item.name)}/${props.item.product_id}`}>
                    <p className="margin-bottom-10 name">{`${props.item.name}`}</p>
                </Link>
                <div className='flex-row-nowrap'>
                    <p className="price margin-bottom-10">{GlobalFuncs.priceToReal(props.item.price)}</p>
                    <img className='info-img pointer'
                                src={require("../../assets/images/info-icon-lg.png").default}
                                onError={(event) => {
                                    event.target.src = require("../../assets/images/placeholder.png").default;
                            }}
                                alt=""
                                onMouseEnter={showTaxInfo}
                                onMouseLeave={hideTaxInfo} />
                                {taxInfo && 
                                    <div className='taxa-info-box'>
                                        <div className='container mt-0'>
                                            <p>PIS</p>
                                            <p className='price'>{`${props.item.vlaliqPis}%`}</p>
                                        </div>
                                        <div className='container'>
                                            <p>COFINS</p>
                                            <p className='price'>{`${props.item.vlaliqCofins}%`}</p>
                                        </div>
                                        <div className='container'>
                                            <p>ICMS</p>
                                            <p className='price'>{`${props.item.aliqIcms}%`}</p>
                                        </div>
                                        <div className='container'>
                                            <p>ICMS - ST</p>
                                            <p className='price'>{`${props.item.aliqStf}%`}</p>
                                        </div>
                                        <div className='container'>
                                            <p>IPI</p>
                                            <p className='price'>{`${props.item.aliqIpi}%`}</p>
                                        </div>
                                    </div>
                                }

                </div>
                <div className="qtt-holder flex-row align-center">
                    <span className="name">Quantidade:</span>
                    &nbsp;
                    <span className="amount">{props.item.amount}</span>
                    &nbsp;
                    {!props.static && <Link to="/cart">alterar</Link>}
                </div>
            </div>
        </div >
    );
}

export default CheckoutItem;