import { useEffect, useRef, useState } from 'react';
import './style.css';
import GlobalFuncs from 'utils/GlobalFuncs';
import { useLanguage } from '../../context/LanguageContext';

function QuantityPicker(props) {

    //props:
    //index: product index
    //quantity: initial quantity
    //onChange: callback on quantity change

    const input = useRef();
    const { translate } = useLanguage();
    let timeout;
    const [multiple, setMultiple] = useState()
    const [minWarning, setMinWarning] = useState(false)
    const [isEditing, setIsEditing] = useState(false)


    useEffect(() => {
        props.quantity && (input.current.value = props.quantity);
        setMultiple(parseInt(props.multiple))
    }, [props])

    useEffect(() => {
    }, [multiple])

    const handleChangeBtn = () => {
        if (!isEditing) {
            // Extract the numeric value from the input, or use 0 if the input is invalid or empty
            let rawValue = input.current.value.replace(/\D/g, '');
            let value = rawValue === '' ? multiple : parseInt(rawValue);
    
            // Special handling for '0' to allow it as a valid input
            if (rawValue === '0') {
                value = 0;
            }
    
            // Default to 'multiple' if the value is NaN or not specified
            if (isNaN(value) || value === '') {
                value = multiple;
            }
    
            // Find the closest multiple up from the value, unless the value is explicitly 0
            let tempValue = value === 0 ? 0 : GlobalFuncs.findClosestMultipleUp(value, multiple);
    
            // Display a warning if the adjusted value is different from the input, except for '0'
            if (value !== 0 && value !== tempValue) {
                setMinWarning(true);
            }
    
            // Apply a debounce mechanism before calling the onChange prop with the new value
            clearTimeout(timeout);
            timeout = setTimeout(async () => {
                props.onChange(tempValue, props.index);
                input.current.value = tempValue;
            }, 1000);
        }
    }
    const handleChange = () => {
        setIsEditing(true)
    }


    const focustOut = () => {
        setIsEditing(false)
        if (!new RegExp(/^(\s*|\d+)$/).test(input.current.value)) {
            let val = parseInt(input.current.value.replace(/\D/g, ''));
            val ? input.current.value = val : input.current.value = multiple
        }

        let value = parseInt(input.current.value.trim());
        if (value === 0) {
            input.current.value = multiple;
            value = multiple;
        }
        if (isNaN(value)) value = multiple

        let tempValue = GlobalFuncs.findClosestMultipleUp(value, multiple)

        if (value !== tempValue) setMinWarning(true)

        props.onChange(tempValue, props.index);
        input.current.value = tempValue;
    }


    const handleBlank = () => {
        // if(input.current.value.trim()==='') input.current.value = multiple;
        // handleChange();
    }

    const handleButtons = (type) => {
        setMinWarning(false)
        switch (type) {
            case 'plus':
                input.current.value = parseInt(input.current.value) + multiple;
                break;
            case 'minus':
                if (parseInt(input.current.value) > 1) input.current.value = parseInt(input.current.value) - multiple;
                break;
            default:
                break;
        }
        handleChangeBtn();
    }

    useEffect(() => {
        // Explicitly check for undefined or null to allow '0' as a valid value
        if (props.quantity !== undefined && props.quantity !== null) {
            input.current.value = props.quantity;
        } else {
            input.current.value = props.multiple;
        }
    }, [props.quantity, props.multiple]);

    return (

        < div className={`quantity-wrapper flex-row justify-center align-center ${props.hidden ? 'hidden' : ''}`} >

            {
                props.nolabel ? null : <p>{translate('quantity')}:</p>
            }

            <div className="flex-row-nowrap space-between align-center min-width">
                <div className="quantity-button" onClick={() => handleButtons('minus')}>
                    -
                </div>
                <input type="text" ref={input} onChange={handleChange} onBlur={focustOut}></input>
                <div className="quantity-button" onClick={() => handleButtons('plus')}>
                    +
                </div>
            </div>
            {
                minWarning && <p className="error margin-10">
                {translate('multipleRuleLabel')}
            </p>
            }
        </div>
    )
}


export default QuantityPicker;