import { useState, useEffect } from 'react';
import './style.css'

function PageNum(props){

    const [pages, setPages] = useState(null)

    useEffect(() => {
        let pages = []
        if (props.maxPages <= 7) {
            for (let i = 1; i <= props.maxPages; i++) {
                pages.push(<div key={'page' + i} className={`page-num ${props.currentPage === i && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: i }])}>{i}</div>)
            }
        } else {
            pages.push(<div key={'page1'} className={`page-num ${props.currentPage === 1 && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: 1 }])}>{1}</div>)
            if (props.currentPage - 1 >= 4) {
                pages.push(<div key={'pagedots1'} className="page-num">...</div>)
                if (props.maxPages - props.currentPage >= 4) {
                    pages.push(<div key={'page' + props.currentPage - 1} className={`page-num`} onClick={() => props.updateUrl([{ field: 'CP', value: props.currentPage - 1 }])}>{props.currentPage - 1}</div>)
                    pages.push(<div key={'page' + props.currentPage} className={`page-num active`} onClick={() => props.updateUrl([{ field: 'CP', value: props.currentPage }])}>{props.currentPage}</div>)
                    pages.push(<div key={'page' + props.currentPage + 1} className={`page-num`} onClick={() => props.updateUrl([{ field: 'CP', value: props.currentPage + 1 }])}>{props.currentPage + 1}</div>)
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {
                    for (let i = props.maxPages - 4; i <= props.maxPages - 1; i++) {
                        pages.push(<div key={'page' + i} className={`page-num ${props.currentPage === i && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: i }])}>{i}</div>)
                    }
                }
            } else {
                pages.push(<div key={'page2'} className={`page-num ${props.currentPage === 2 && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: 2 }])}>{2}</div>)
                pages.push(<div key={'page3'} className={`page-num ${props.currentPage === 3 && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: 3 }])}>{3}</div>)
                pages.push(<div key={'page4'} className={`page-num ${props.currentPage === 4 && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: 4 }])}>{4}</div>)
                pages.push(<div key={'page5'} className={`page-num ${props.currentPage === 5 && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: 5 }])}>{5}</div>)
                if (props.maxPages - props.currentPage >= 4) {
                    pages.push(<div key={'pagedots2'} className="page-num">...</div>)
                } else {

                }
            }
            pages.push(<div key={'pagelast'} className={`page-num ${props.currentPage === props.maxPages && 'active'}`} onClick={() => props.updateUrl([{ field: 'CP', value: props.maxPages }])}>{props.maxPages}</div>)
        }

        setPages(pages);
    }, [props.maxPages, props.currentPage])

    return(
        pages
    )
}

export default PageNum;