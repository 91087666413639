import React, { useEffect } from "react";
import "./style.css";

function ImageMagnifier(props) {
    useEffect(() => {
        const slide = document.querySelector(".magnifier");
        slide.style.backgroundImage = `url(${props.src})`;
        slide.addEventListener("mousemove", zoom);
        slide.addEventListener("mouseleave", reset);
        function zoom(event) {
            let xPosition = event.offsetX * 100 / slide.offsetWidth;
            let yPosition = event.offsetY * 100 / slide.offsetHeight;
            slide.style.backgroundPosition = `${xPosition}% ${yPosition}%`;
        }
        function reset() {
            slide.style.backgroundPosition = "center";
            slide.style.backgroundSize = "contain";
        }
    }, [props.src])
    return (
        <div className="magnifier">
        </div>
    )
}

export default ImageMagnifier;