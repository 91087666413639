import { useState, useRef, useContext, useEffect } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import Loader from 'components/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

function RejectAvailability() {

    const context = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        const params = new URLSearchParams(location.search);
        if (params.get('ref') && params.get('ref') !== "null") {
            let token = params.get('ref')
            Swal.fire({
                title: 'Atenção.',
                text: 'Tem certeza de que deseja rejeitar esta proposta? Essa ação não pode ser desfeita.',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonColor: "#131921",
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    reject(token)
                }
            });;
        } else {
            Swal.fire({
                title: 'Erro.',
                text: 'URL Invalido.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(res=>{
                history.push('/home')
            });
        }
    },[location])

    const reject = (token) => {
        API.rejectAvailability(token).then(res => {
            Swal.fire({
                title: 'Rejeitado',
                text: 'A proposta foi rejeitada',
                confirmButtonColor: "#131921",
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(val=>{
                history.push('/')
            });
        }).catch(err => {
            if (err.message === 'cancelling') return
            let message = 'Tente novamente mais tarde.'
            if (err.response.status === 403){
                message = 'Este link não é mais válido.'
            }
            Swal.fire({
                title: 'Erro.',
                text: message,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(val=>{
                history.push('/')
            });
        });
    }


    return (
        <div className="login-page">
            <div className="form-container">
                <h3 className="margin-bottom-20">AZPartes.com</h3>
                <div className="loader-wrapper">
                    <Loader light={true} />
                </div>
            </div>
        </div>
    )
}

export default RejectAvailability;