import './style.css';
import { useEffect, useState, useContext, useRef } from 'react';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';

function ExchangePolicy() {
    const context = useContext(AppContext);
    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        API.isActive().then(none => {
        }).catch(err => {
            context.logOut()
        });
    }, [])

    return (
        <div className="exchange-page margin-top-40 margin-bottom-40 section-wrapper">
            <div className="institucional">
            <h2>Pol&iacute;tica de Troca e Garantia</h2>

                <p>Objetivo: A SKF coloca &agrave; disposi&ccedil;&atilde;o dos seus Clientes a devolu&ccedil;&atilde;o ou troca de um produto e realizar um correto acompanhamento, facilitando os processos. Entende-se como Cliente, para os fins desta Pol&iacute;tica, a pessoa f&iacute;sica ou jur&iacute;dica que adquire ou utiliza produto ou servi&ccedil;o com finalidade comercial (de venda ou presta&ccedil;&atilde;o de servi&ccedil;os).</p>

                <p>Defini&ccedil;&atilde;o: Produtos adquiridos atrav&eacute;s do portal da SKF do Brasil Ltda. www.azpartes.com.</p>

                <p>Motivos da devolu&ccedil;&atilde;o: Essa pol&iacute;tica direciona os motivos que autorizam a troca ou a devolu&ccedil;&atilde;o dos produtos: arrependimento da compra, defeito no produto, produto divergente do especificado (falta de produto ou produto errado), embalagens visualmente avariadas ou incorretas (e desde que n&atilde;o tenham sido abertas pelo Cliente), prazo de entrega, erros de pre&ccedil;os e venda errada.</p>

                <p>Procedimento:</p>

                <p>Arrependimento da Compra</p>

                <p>Caso o Cliente se arrependa de uma compra efetuada, poder&aacute; entrar em contato com a Central de Atendimento da SKF: 0800 014 11 52, ou pelo e-mail carisma@skf.com e manifestar seu interesse na devolu&ccedil;&atilde;o ou troca do produto, no prazo improrrog&aacute;vel de at&eacute; 7 (sete dias) corridos, a contar da data do recebimento. Durante este prazo, os custos com transporte correm por conta da SKF.</p>

                <p>O produto dever&aacute; estar dentro das condi&ccedil;&otilde;es m&iacute;nimas para troca ou devolu&ccedil;&atilde;o e poder&aacute; ser reenviado a SKF atrav&eacute;s do m&eacute;todo informado pela central de atendimento da SKF. Por &ldquo;condi&ccedil;&otilde;es m&iacute;nimas&rdquo; entende-se, conforme descrito na Nota 1.</p>

                <p>Caso o produto n&atilde;o esteja dentro das condi&ccedil;&otilde;es adequadas previstas dentro das condi&ccedil;&otilde;es gerais de devolu&ccedil;&atilde;o dessa Pol&iacute;tica, o Cliente ser&aacute; notificado informando que o seu direito ao arrependimento n&atilde;o poder&aacute; ser exercido podendo retirar o produto ou arcar com os custos de frete para a sua nova entrega.</p>

                <p>Devolu&ccedil;&atilde;o ou troca por defeito no produto</p>

                <p>A SKF efetuar&aacute; a troca e ou corre&ccedil;&atilde;o do produto adquirido por seus Clientes de acordo com a legisla&ccedil;&atilde;o civil aplic&aacute;vel. O Cliente poder&aacute; devolver ou trocar o produto se identificar defeito t&eacute;cnico ou de fabrica&ccedil;&atilde;o, devendo o Cliente comunicar a SKF atrav&eacute;s do site https://www.skf.com/br - Portal da Qualidade Unificado. Em caso de primeiro acesso, no site est&atilde;o os contatos das pessoas respons&aacute;veis por tipo de desvios e deve-   se seguir as instru&ccedil;&otilde;es recebidas para que o pedido de devolu&ccedil;&atilde;o ou troca seja analisado e processado.</p>

                <p>Se o problema for identificado no ato do recebimento ou antes do uso e havendo disponibilidade do material no estoque da SKF, o Cliente poder&aacute; optar conforme abaixo:</p>

                <p>a) Envio de produto id&ecirc;ntico, de mesma marca, linha, modelo e vers&atilde;o;</p>

                <p>b) Emiss&atilde;o de cr&eacute;dito no valor do produto.</p>

                <p>Havendo indisponibilidade do produto a ser trocado, o Cliente poder&aacute; optar pela:</p>

                <p>a) Troca imediata por qualquer produto dispon&iacute;vel similar na SKF com compensa&ccedil;&atilde;o e ou pagamento em caso de diferen&ccedil;a de pre&ccedil;o;</p>

                <p>b) Estorno do valor da compra, ou seja, pre&ccedil;o do produto e do frete (se pago pelo Cliente).</p>

                <p>Caso o defeito t&eacute;cnico ou de fabrica&ccedil;&atilde;o seja identificado de imediato, o Cliente dever&aacute; encaminhar o produto para a assist&ecirc;ncia t&eacute;cnica da SKF, no prazo m&aacute;ximo de at&eacute; 30 (trinta) dias, conforme instru&ccedil;&otilde;es disponibilizadas pela equipe t&eacute;cnica.</p>

                <p>Caso o defeito t&eacute;cnico ou de fabrica&ccedil;&atilde;o seja identificado ap&oacute;s a montagem e/ou uso do produto, no prazo m&aacute;ximo de at&eacute; 180 (cento e oitenta) dias da data do recebimento, o Cliente dever&aacute; encaminhar o produto para a assist&ecirc;ncia t&eacute;cnica da SKF, com instru&ccedil;&otilde;es disponibilizada pela equipe t&eacute;cnica para an&aacute;lise.</p>

                <p>O prazo de retorno da an&aacute;lise t&eacute;cnica &eacute; de 30 dias para qualquer dos casos, a contar do recebimento pela SKF.</p>

                <p>Produto divergente do especificado</p>

                <p>Caso o Cliente receba produto diferente do originalmente adquirido, seja em rela&ccedil;&atilde;o a: falta, produto errado, embalagens visualmente avariadas ou incorretas (e desde que n&atilde;o tenham sido abertas pelo Cliente), prazo de entrega, erros de pre&ccedil;os, o Cliente dever&aacute; recusar o produto no ato do recebimento, ou no prazo m&aacute;ximo de 07 dias. Caso j&aacute; o tenha recebido, dever&aacute; solicitar a troca do produto em at&eacute; 7 (sete) dias corridos a contar da data do seu recebimento.</p>

                <p>A SKF retirar&aacute; o produto em local indicado pelo cliente, mediante pr&eacute;vio agendamento. Nota 1.: Condi&ccedil;&otilde;es m&iacute;nimas para devolu&ccedil;&atilde;o e/ou troca do produto Ao receber qualquer produto reenviado pelo Cliente, a SKF somente aceitar&aacute; se as condi&ccedil;&otilde;es abaixo foram atendidas:   a) O produto dever&aacute; ser encaminhado em sua embalagem original, com todos os manuais, certificados de garantia e acess&oacute;rios;</p>
                <p>b) O produto n&atilde;o poder&aacute; ter sido testado, usado ou consumido, exceto para os casos de defeito t&eacute;cnico ou de fabrica&ccedil;&atilde;o constatado ap&oacute;s utiliza&ccedil;&atilde;o;</p>

                <p>c) O produto deve estar acompanhado do Cupom ou Nota Fiscal de Venda para casos de recusa no momento da entrega;</p>

                <p>d) Se o produto for recusado no ato da entrega, a nota fiscal dever&aacute; ser recusada, caso o recebimento j&aacute; tenha sido efetuado, o Cliente dever&aacute; emitir uma nota fiscal de devolu&ccedil;&atilde;o.</p>

                <p>O Cliente poder&aacute; reenviar o produto para a SKF atrav&eacute;s do m&eacute;todo informado pela central de atendimento da SKF.</p>

                <p>Reembolso / Ressarcimento ao Cliente</p>

                <p>Ap&oacute;s o recebimento das pe&ccedil;as juntamente com a nota de devolu&ccedil;&atilde;o e as condi&ccedil;&otilde;es de devolu&ccedil;&atilde;o forem avaliadas e aprovadas, o cr&eacute;dito e/ou ressarcimento &eacute; gerado ao Cliente em at&eacute; 30 dias.</p>

                <p>O cliente se responsabiliza pelos dados da conta de destino do ressarcimento.</p>

                <p>Garantia dos produtos adquiridos</p>

                <p>A SKF atuar&aacute; na qualidade de distribuidor ou fabricante que garantam que os produtos disponibilizados para venda no site funcionem corretamente e n&atilde;o apresentam defeitos que possam faz&ecirc;-los perigosos ou inadequados para um uso normal.</p>

                <p>A Garantia dos produtos adquiridos &eacute; de at&eacute; 6 meses, ap&oacute;s data da compra. Uma vez que o Cliente tenha recebido o produto, obter&aacute; acesso &agrave;s instru&ccedil;&otilde;es suficientes para o correto uso e instala&ccedil;&atilde;o do produto e toda a informa&ccedil;&atilde;o sobre a garantia. Nenhuma garantia mais ampla do que a estipulada ser&aacute; concedida, sob qualquer circunst&acirc;ncia. O Cliente dever&aacute; dirigir-se ao Servi&ccedil;o de Assist&ecirc;ncia T&eacute;cnica da SKF ou ao CARISMA (Servi&ccedil;o de Atendimento ao Consumidor). A SKF realizar&aacute; as a&ccedil;&otilde;es destinadas a proporcionar aos Clientes que solicitarem os dados de contato dos referidos servi&ccedil;os, e facilitar&aacute; a estes o acesso &agrave; informa&ccedil;&atilde;o suficiente para a apresenta&ccedil;&atilde;o das reclama&ccedil;&otilde;es pertinentes.</p>

                <p>A garantia perder&aacute; sua vig&ecirc;ncia no caso de defeitos ou deteriora&ccedil;&otilde;es causados por fatores externos, instala&ccedil;&otilde;es e utiliza&ccedil;&otilde;es inadequadas, em desacordo com as instru&ccedil;&otilde;es do fabricante.</p>

                <p>Ficam exclu&iacute;dos da garantia os produtos modificados ou reparados pelo Cliente ou qualquer outra pessoa n&atilde;o autorizada pelo fabricante. A garantia n&atilde;o cobrir&aacute; os produtos danificados por um uso inadequado.   SKF do Brasil Ltda.</p>
            </div>
        </div>
    )

}

export default ExchangePolicy;