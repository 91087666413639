import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {search} from 'fast-fuzzy';

const GlobalFuncs = {
    databaseToDate: (value) => {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return `${date[2]}/${date[1]}/${date[0]}`;
    },

    getYear: (value) => {
        if (!value) return;
        let date = value.split(' ')[0].split('-');
        return date[0];
    },

    priceToReal: (value) => {
        if (value === null) return;
        let temp = value.toFixed(2).toString().replace('.', ',');
        return 'R$ ' + temp.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    cleanString: (value) => {
        let temp = value.replaceAll(/[^A-Za-z0-9-]/g, '').replaceAll('/', '');
        return temp;
    },

    ScrollToTop: () => {
        const location = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location]);

        return null;
    },

    findClosestMultipleUp: (a, b) => {
        let remainder = a % b;
        if (remainder === 0) {
            return a;
        } else {
            let closestMultiple = a + (b - remainder);
            return closestMultiple;
        }
    },
    findClosestMultipleDown: (a, b) => {
        const quotient = Math.floor(a / b); 
        const closest = quotient * b; 

        return closest;
    },

    addThousandSeparator: (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },

    searchSVG: () => {
        return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4996 11.3151H11.7096L11.4296 11.0451C12.6296 9.64508 13.2496 7.73508 12.9096 5.70508C12.4396 2.92508 10.1196 0.705079 7.31965 0.365079C3.08965 -0.154921 -0.470351 3.40508 0.049649 7.63508C0.389649 10.4351 2.60965 12.7551 5.38965 13.2251C7.41965 13.5651 9.32965 12.9451 10.7296 11.7451L10.9996 12.0251V12.8151L15.2496 17.0651C15.6596 17.4751 16.3296 17.4751 16.7396 17.0651C17.1496 16.6551 17.1496 15.9851 16.7396 15.5751L12.4996 11.3151ZM6.49965 11.3151C4.00965 11.3151 1.99965 9.30508 1.99965 6.81508C1.99965 4.32508 4.00965 2.31508 6.49965 2.31508C8.98965 2.31508 10.9996 4.32508 10.9996 6.81508C10.9996 9.30508 8.98965 11.3151 6.49965 11.3151Z" fill="#323232" />
    </svg>;
    },

    orderDescSVG: () => {
        return <svg className="upside-down" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99973 1.31494V12.4849L2.11973 7.60494C1.72973 7.21494 1.08973 7.21494 0.699727 7.60494C0.309727 7.99494 0.309727 8.62494 0.699727 9.01494L7.28973 15.6049C7.67973 15.9949 8.30973 15.9949 8.69973 15.6049L15.2897 9.01494C15.6797 8.62494 15.6797 7.99494 15.2897 7.60494C14.8997 7.21494 14.2697 7.21494 13.8797 7.60494L8.99973 12.4849V1.31494C8.99973 0.764941 8.54973 0.314941 7.99973 0.314941C7.44973 0.314941 6.99973 0.764941 6.99973 1.31494Z" fill="#323232" />
    </svg>
    },

    orderAscSVG: () => {
        return <svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99973 1.31494V12.4849L2.11973 7.60494C1.72973 7.21494 1.08973 7.21494 0.699727 7.60494C0.309727 7.99494 0.309727 8.62494 0.699727 9.01494L7.28973 15.6049C7.67973 15.9949 8.30973 15.9949 8.69973 15.6049L15.2897 9.01494C15.6797 8.62494 15.6797 7.99494 15.2897 7.60494C14.8997 7.21494 14.2697 7.21494 13.8797 7.60494L8.99973 12.4849V1.31494C8.99973 0.764941 8.54973 0.314941 7.99973 0.314941C7.44973 0.314941 6.99973 0.764941 6.99973 1.31494Z" fill="#323232" />
    </svg>
    },


    predictiveSearch: (query, applications, products, words, isSearch) => {        
        // Used to track corrected search query
        let cleanedSearchQuery = ''

        // Array of arrayus used to track the list of attributes applicable to the previous word
        let tracker = []

        // List of attributes
        let skf_terms = applications

        // Search products
        let prod_tracker = []
        let prodSearch = search(query, products, { returnMatchData: true })
        for (let item of prodSearch){
            if(item.score >= 0.75){
               isSearch ? prod_tracker.push([item.item]) : prod_tracker.push(item.item)
            }
        }


        // Divide search query into array of words
        let list_of_terms = query.split(' ')
        
        // Loop through each word
        for (let word of list_of_terms) {
            let clean_word = null;

            // Trim, lowercase, strip accents from each word, and check that it's not de do da e
            word = word.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            if (['de', 'do', 'da', 'e', "d'", ''].includes(word)) {
                cleanedSearchQuery += word + " "
            } else {
                let skip = false

                // If number, check if it is potentially a year, if so, add to cleaned query, add to tracker on its own and skip the rest
                if (!isNaN(word)) {
                    if (parseInt(word) <= new Date().getFullYear() && parseInt(word) >= 1950) {
                        let temp_arr = []
                        temp_arr.push(word)
                        tracker.push(temp_arr)
                        cleanedSearchQuery += word + " "
                        skip = true
                    }
                } 
                let skipFuzzy = false
                for (let item in words){
                    if (item.toLowerCase().trim() === word.toLowerCase().trim()) skipFuzzy = true 
                }
                if (!skipFuzzy){
                    // Cleanup word if mispelled, and add to cleaned query
                    let tempWord = search(word, words, { returnMatchData: true })[0]
                    if (tempWord && tempWord.score > 0.5) clean_word = tempWord.item
                    cleanedSearchQuery += clean_word + " "
                }

                // If last word had potential matches, compare current word to those matches
                if (tracker.length > 0) {
                    let temp_list_backup = [...tracker[tracker.length - 1]]
                    temp_list_backup = temp_list_backup.filter(e => e.includes(word.toLowerCase()))
                    if (temp_list_backup.length == 0 && clean_word) temp_list_backup = temp_list_backup.filter(e => e.includes(clean_word.toLowerCase()))

                    // If current word has matches in tracker, filter the tracker futher, skip the rest
                    if (temp_list_backup.length > 0) {
                        tracker[tracker.length - 1] = [...temp_list_backup]
                        skip = true
                        // If current word has no matches in tracker, but more than 1 word in search query,
                        // check to see if any of the tracker sentences are already there, if so, compile them
                        // If the current word does not exist in any of the tracker terms, 
                        // don't skip (to make sure a new tracker array for this word is created), else skip
                    } else if (list_of_terms.length > 1) {
                        let temp_list_backup = []
                        for (let item of tracker[tracker.length - 1]) {
                            if (query.toLowerCase().trim().includes(item.toLowerCase().trim())) {
                                temp_list_backup.push(item)
                            } else if (cleanedSearchQuery.toLowerCase().trim().includes(item.toLowerCase().trim())) {
                                temp_list_backup.push(item)
                            }
                            if (item.toLowerCase().trim().includes(word.toLowerCase().trim())) {
                                skip = true
                            } else if (clean_word && item.toLowerCase().trim().includes(clean_word.toLowerCase().trim())) {
                                skip = true
                            }
                        }
                        // If no matches are found, add empty array to tracker to prompt 0 results, else, add filtered list
                        tracker[tracker.length - 1] = [...temp_list_backup]
                        // console.log(tracker)
                    }
                }
                // Check the word against the list of attributes, filter by potential matches 
                // and create a new tracker array for them 
                if (skip) continue
                let temp_arr = []
                skf_terms.forEach((dict_val, index) => {
                    if (dict_val.toLowerCase().trim().includes(word.toLowerCase().trim())) {
                        temp_arr.push(dict_val.toLowerCase().trim())
                    } else if (clean_word && dict_val.toLowerCase().trim().includes(clean_word.toLowerCase().trim())) {
                        temp_arr.push(dict_val.toLowerCase().trim())
                    }
                })
                tracker.push(temp_arr)
            }
        }

        let final_list = []
        if (tracker.length === 0){
            final_list = [[query]]
        } else if (tracker.length > 1) {
            let r = GlobalFuncs.cartesian(tracker);
            if(!isSearch){
                for (let item of r) {
                    final_list.push(item.join(' '))
                }
            }else{
                final_list = [...r]
            }
        } else {
            if (isSearch){
                for(let item of tracker[0]){
                    final_list.push([item])
                }
            }else{
                final_list = [...tracker[0]]
            }
        }
        final_list = [...new Set(final_list)];

        return prod_tracker.concat(final_list)
    },
    cartesian: (a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))
}

export default GlobalFuncs;