import { useState, useContext, useEffect, useRef } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import { useHistory } from 'react-router-dom';
import API from 'utils/API';
import GlobalFuncs from 'utils/GlobalFuncs';
import QuantityPicker from 'components/QuantityPicker';
import Loader from 'components/Loader';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLanguage } from '../../context/LanguageContext';

function PurchaseBox(props) {

    const [count, setCount] = useState(1);
    const [clicked, setClicked] = useState(false);
    const [availability, setAvailability] = useState(null);
    const [showDate, setShowDate] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [user, setUser] = useState(null);
    const [distribution, setDistribution] = useState(null);
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [shipping, setShipping] = useState(null);
    const [availableProducts, setAvailableProducts] = useState(0);
    const [unavailableProducts, setUnavailableProducts] = useState(0);
    const [delivery, setDelivery] = useState(null);
    const message = useRef();
    const history = useHistory();
    const context = useContext(AppContext);
    const warehouses = { 'BZD': 2, 'MWH': 60 }
    const [lines, setLines] = useState([])
    const { translate } = useLanguage();

    useEffect(() => {
        if (!props.product.skf_product_id) return;
        props && props.product.multiple ? setCount(props.product.multiple) : null
        const cancelToken = API.cancelToken();
        API.getAvailability(cancelToken, props.product.skf_product_id).then(res => {
            if (res.data.availability.dimensions) {
                // setAvailability({ dimensions: true, MWH: 16920, BZD: 1000 })
                setAvailability(res.data.availability)
                console.log(res.data.availability)
            } else {
                throw new Error("Data Unavailable");
            }
        }).catch(err => {
            if (err.message !== 'cancelling') setAvailability(
                {
                    "dimensions": true,
                    "stock": {
                        "BZD": 0,
                        "ARG": 0,
                        "others": 0,
                        "total": 0
                    },
                    "delivery": {
                        "null": count
                    },
                }
            );
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [props]);

    const handleChange = (amount) => {
        isNaN(amount) ? setCount(false) : setCount(amount)
    }

    const displayNotification = (amount) => {
        const cancelToken = API.cancelToken();
        if (notification) {
            return
        }
        Swal.fire({
            title: translate('notifyByEmail'),
            // text:`Ao clicar em "SIM" será utilizado o email ${user}`,
            showCancelButton: true,
            confirmButtonColor: "#131921",
            cancelButtonColor: "#6e6c6c",
            icon: "warning",
            confirmButtonText: 'SIM',
            cancelButtonText: 'NÃO'
        }).then(async result => {
            if (result.isConfirmed) {
                setNotification(true)
                Swal.fire({
                    title: 'Atenção',
                    text: 'Serão adicionados ao carrinho as quantidades com prazo de entrega estimado. \n \n De acordo?',
                    confirmButtonColor: "#131921",
                    showCancelButton: true,
                    icon: 'warning',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(result => {
                    if (result.isConfirmed) {
                        API.addToCart(props.product.product_id, (count - amount)).then(res => {
                            // history.push('/cart');
                            API.setProductNotification(cancelToken, props.product.skf_product_id, count, availability).then(res => {
                                Swal.fire({
                                    title: 'Sua solicitação foi enviada com sucesso! \n Assim que o produto estiver disponível \n você será avisado por email.',
                                    confirmButtonColor: "#131921",
                                    icon: 'success',
                                    confirmButtonText: 'OK'
                                }).then(result => {
                                    history.push('/cart');
                                })
                            }).catch(err => {
                                Swal.fire({
                                    title: 'Erro.',
                                    text: err,
                                    confirmButtonColor: "#131921",
                                    icon: 'warning',
                                    confirmButtonText: 'Ok'
                                });
                            })
                        }).catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'Erro.',
                                text: 'Tente novamente mais tarde.',
                                confirmButtonColor: "#131921",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            });
                        })
                    }
                })
            }
        });
    }

    const addToCart = async (all = true) => {
        console.log("Adicionando ao carrinho " + count)
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if (!count) return;
        var date_to_schedule = null
        if(startDate){
            const isScheduled = await Swal.fire({
                title: 'Atenção',
                    text: `Confirmação de pedido/atendimento sujeita a disponibilidade de estoque para o período.`,
                    confirmButtonColor: "#131921",
                    showCancelButton: false,
                    icon: 'info',
                    confirmButtonText: 'OK'
                })
            
            if (!isScheduled.isConfirmed){
                setClicked(false);
                return
            }else{
                date_to_schedule = startDate.split("T")[0]
            }
        }
        
        var amount = count
        console.log(availability.stock.total)
        var alertText = 'Os produtos disponíveis e indisponíveis serão adicionados ao carrinho. Para verificação do prazo de entrega dos produtos indisponíveis, basta acessar a guia de “Meus Pedidos” após o registro do pedido nos sistemas SKF. Deseja adicionar os produtos ao carrinho?'
        if (all !== true) {
            amount = availableProducts
            alertText = 'Serão adicionados ao carrinho as quantidades com Prazo de Entrega Estimado. </br></br> De acordo?'
        }
        if (availability && availability.stock.total < count) {
            Swal.fire({
                title: 'Atenção',
                text: alertText,
                confirmButtonColor: "#131921",
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'OK',
                cancelButtonText: 'Não'
            }).then(result => {
                if (result.isConfirmed) {
                    if (availability && lines !== false) {
                        setClicked(true);
                        API.addToCart(props.product.product_id, amount, 'no', null, date_to_schedule).then(res => {
                            history.push('/cart');
                        }).catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'Erro.',
                                text: 'Tente novamente mais tarde.',
                                confirmButtonColor: "#131921",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            });
                            setClicked(false);
                        })
                    } else {
                        setOpen(!open)
                    }
                }
            })
        } else {
            setClicked(true);
            API.addToCart(props.product.product_id, amount, 'no', null, date_to_schedule).then(res => {
                history.push('/cart');
            }).catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
                setClicked(false);
            })
        }

    }

    const sendQuoteRequest = (amount) => {
        let body = {
            message: "",
            products: [{ "product_id": props.product.product_id, "quantity": parseInt(count) }]
        }
        Swal.fire({
            title: 'Atenção',
            text: 'Serão adicionados ao carrinho as quantidades com prazo de entrega estimado. \n \n De acordo?',
            confirmButtonColor: "#131921",
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(result => {
            if (result.isConfirmed) {
                API.addToCart(props.product.product_id, (count - amount)).then(res => {
                    history.push('/cart');
                    console.log(`Adding to cart ${amount}`)
                    API.requestUnavailable(body).then(res => {
                        setSent(true);
                        history.push('/cart');
                    }).catch(err => {
                        console.log(err);
                        Swal.fire({
                            title: 'Erro.',
                            text: 'Tente novamente mais tarde.',
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                    })
                })
            }
        })
    }

    function getDelayedDate(tempDelivery, deliveryDelay) {
        while (deliveryDelay > 0) {
            tempDelivery.setDate(tempDelivery.getDate() + 1);
            if (tempDelivery.getDay() != 0 && tempDelivery.getDay() != 1) deliveryDelay--;
        }
        var delivery = tempDelivery.toLocaleDateString("pt-BR", {
            // weekday: 'long',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
        });

        return delivery

    }
    useEffect(async () => {
        try {
            var cancelToken = API.cancelToken();
            const res = await API.getShipping(
                cancelToken,
                props.product.product_id,
                count,
                props.product.price
            );
            let date = res.data.quote.estimatedDelivery.split('/');
            let tempDelivery = new Date(date[2], date[1] - 1, date[0]);
            let deliveryBZD = getDelayedDate(tempDelivery, 2)
            let deliveryARG = getDelayedDate(tempDelivery, 30)
            let deliveryOthers = getDelayedDate(tempDelivery, 60)
            var shipping_obj = {
                "price": res.data.quote.price,
                "unavailable": false
            }
            Object.entries(res.data.product_information.delivery).forEach(([key, value]) => {
                if (key == null) {
                    setUnavailableProducts(parseInt(value))
                } else {
                    setAvailableProducts(parseInt(value))
                }
            });
            setDelivery(res.data.product_information.delivery);
            setShipping(shipping_obj)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err);
        }
    }, [distribution]);


    useEffect(() => {

        if (!availability || !count) {
            setLoading(false)
            return
        }
        if (availability.total < count) {
            setLoading(false)
            return
        }
        setShipping(null)
        var remainder = count
        var distribution_obj = {
            "BZD": 0,
            "ARG": 0,
            "OTHERS": 0,
            "MISSING": 0,
        }

        if (count <= availability.stock.BZD) {
            distribution_obj.BZD = count
            setDistribution(distribution_obj)
            return
        }
        else {
            distribution_obj.BZD = GlobalFuncs.findClosestMultipleDown(availability.stock.BZD, props.product.multiple)
            remainder = count - distribution_obj.BZD
        }

        if (remainder <= availability.stock.ARG) {
            distribution_obj.ARG = remainder
            setDistribution(distribution_obj)
            return
        }
        else {
            distribution_obj.ARG = GlobalFuncs.findClosestMultipleDown(availability.stock.ARG, props.product.multiple)
            remainder = remainder - distribution_obj.ARG
        }

        if (remainder <= availability.stock.others) {
            distribution_obj.OTHERS = remainder
            setDistribution(distribution_obj)
            return
        }
        else {
            distribution_obj.OTHERS = GlobalFuncs.findClosestMultipleDown(availability.stock.others, props.product.multiple)
            remainder = remainder - distribution_obj.OTHERS
        }
        if (remainder > 0) {
            distribution_obj.MISSING = remainder
            setDistribution(distribution_obj)
            return
        }

        setDistribution(distribution_obj)

        return


    }, [count, availability]);

    // useEffect(() => {
    //     if (!availability || !count) return
    //     setClicked(true)
    //     let tempCount = count
    //     let tempLines = []
    //     for (let [value, delay] of Object.entries(warehouses)) {
    //         if (availability && availability[value] >= tempCount) {
    //             setOpen(false);
    //             tempLines.push({ "warehouse": value, "amount": tempCount });
    //             tempCount = 0
    //             break
    //         } else {
    //             if (value in availability) {
    //                 tempLines.push({ "warehouse": value, "amount": availability[value] });
    //                 tempCount -= GlobalFuncs.findClosestMultipleDown(availability[value], props.product.multiple)
    //             }
    //         }
    //     }
    //     if (tempCount != 0) {
    //         setLines(false)
    //         setClicked(false)
    //         return
    //     }
    //     setLines(tempLines)
    //     setClicked(false)
    //     if (!props.product.product_id || !count) return;
    // }, [count, availability]);


    useEffect(() => {
        let cancelToken;
        const fetchData = async () => {
            if (!lines || lines.length === 0 || 'shipping' in lines[0]) return;
            let tempLines = [];

            try {
                cancelToken = API.cancelToken(); // Create a new cancel token
                for (let line of lines) {
                    let tempLine = { ...line };
                    const res = await API.getShipping(
                        cancelToken,
                        props.product.product_id,
                        line.amount,
                        props.product.price
                    );
                    let date = res.data.quote.estimatedDelivery.split('/');
                    let tempDelivery = new Date(date[2], date[1] - 1, date[0]);
                    let deliveryDelay = warehouses[line.warehouse];
                    while (deliveryDelay > 0) {
                        tempDelivery.setDate(tempDelivery.getDate() + 1);
                        if (tempDelivery.getDay() != 0 && tempDelivery.getDay() != 1) deliveryDelay--;
                    }
                    tempLine.shipping = res.data.quote.price;
                    tempLine.allocateOrder = res.data.quote.allocate_order;
                    tempLine.delivery = res.data.quote.delivery;
                    tempLines.push(tempLine);
                }
                setLines(tempLines);
            } catch (err) {
                console.log(err);

            }
        };

        fetchData();

        return () => {
            if (cancelToken) {
                cancelToken.cancel(); // Cancel the ongoing request using the cancel token
            }
        };
    }, [lines]);

    const showDateAction = () => {
        setShowDate(!showDate)
    }

    const handleDateSelection = (date) => {
        setStartDate(date.toISOString())
        showDateAction()
    }

    const formatDate = (date) => {
        var date_only = date.split("T")[0]
        var split_date = date_only.split("-")
        let day = split_date[2]
        let month = split_date[1]
        let year = split_date[0]
        return `${day}/${month}/${year}`
    }


    return (
        Object.keys(props.product).length ?
            <div className="purchase-box">
                <div className="margin-bottom-10">
                    <QuantityPicker onChange={handleChange} multiple={props && props.product.multiple ? props.product.multiple : 1} />
                </div>
                {
                    props.product.multiple && props.product.multiple != 1 &&
                    <p className='min-desc margin-bottom-30'>Este produto só é adquirido em múltiplos de {props.product.multiple}</p>
                }
                <div className="margin-bottom-30">
                    {
                        context.getLogin ?
                            <div>
                                <div className='justify-between flex-row justify-center align-center margin-bottom-20'>
                                    <p>{translate('unitPrice')}: </p>
                                    <p><strong>{GlobalFuncs.priceToReal(props.product.price)}</strong></p>
                                </div>
                                <div className='justify-between flex-row justify-center align-center'>
                                    <p>{translate('totalPrice')}: </p>
                                    <p><strong>{GlobalFuncs.priceToReal(props.product.price * count)}</strong></p>
                                </div>
                                <div className='justify-between flex-row justify-center align-center margin-top-20'>
                                    <p>{translate('status')}: </p>
                                    <div className={!availability ? '' : availability && count > availability.stock.total ? availability.stock.total == 0 ? "stock-label-danger" : "stock-label-warning" :
                                        "stock-label-success"}>
                                        {
                                            availability &&
                                                count > availability.stock.total ?
                                                availability.stock.total == 0 ? translate('unavailable')
                                                    : translate('partiallyAvailable')
                                                : translate('inStock')}
                                    </div>
                                </div>
                            </div>
                            :
                            <p className="price margin-bottom-30" style={{ 'color': '#232F3E' }}>
                                'Entrar para ver preço'
                            </p>
                    }
                </div>
                <hr className="margin-bottom-20 margin-top-10" />
                {
                    availability ?
                        <div className='justify-between flex justify-center align-center margin-bottom-20'>
                            {!startDate &&

                                <p className="shipping margin-bottom-10">
                                    <strong>{translate('delivery')}:</strong>
                                </p>

                            }
                            {shipping && delivery ?

                                <div>
                                    {!startDate &&
                                        Object.entries(delivery).map(([key, value]) => (
                                            <div key={key} className='flex-row space-between'>
                                                <p className={'margin-bottom-10'} key={key + 'lines'}>{`${value} ${translate('pieces')} ${key === 'null' ? ' indisponíveis' : ': ' + key}`}</p>
                                                {/* <p>{key === 'null' ? 'Indisponível' : key}</p> */}
                                                {
                                                    key === 'null' &&
                                                    <div className='flex-row space-between'>
                                                        <div className='action-button margin-bottom-10 cotar_btn' onClick={() => sendQuoteRequest(value)}>
                                                            Cotar
                                                        </div>
                                                        <button class={`bell-button ${notification ? 'disabled' : ''}`} onClick={() => displayNotification(value)}>
                                                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 22c1.1 0 2-.9 2-2H10c0 1.1.9 2 2 2zm6-4V11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v7l-2 2v1h16v-1l-2-2zm-2 1H8v-7c0-2.48 1.51-4.5 3.5-4.5S15 9.52 15 12v7z" />
                                                            </svg>
                                                            {
                                                                !notification &&
                                                                <span className="tooltip">{translate('notifyMeWhenAvailable')}</span>
                                                            }
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div>{!startDate ? translate('calculatingDeliveryTime') : ""}</div>
                            }
                            {
                                startDate &&
                                <div className='justify-between flex-row justify-center align-center'>
                                    <p>Agendado Para: <strong>{formatDate(startDate)}</strong></p>
                                    <div class="parent-div  margin-top-10">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ebac1186a1fee29a010c7bbc0eab09c2b106bce450fa06627f3da3bfd21b04d1?"
                                            class="image"
                                        />
                                        <div class="text-div">
                                            {translate('orderConfirmation')}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div> :
                        null
                }
                {
                    clicked ?
                        <div className="loader-wrapper">
                            <Loader light={true} />
                        </div>
                        :
                        !open &&
                        <div>
                            <div style={{ 'display': `${availability !== null ? 'flex' : 'none'}` }} className='action-button margin-bottom-10 margin-top-30' onClick={() => addToCart()}>
                                {/* {availability && availability.stock.total >= count ? 'Adicionar ao carrinho' : 'Informação em tempo real'} */}
                                {translate('addToCart')}
                            </div>
                            <div className='action-button margin-bottom-10 margin-top-10' style={{ 'display': `${availability !== null ? 'flex' : 'none'}` }} onClick={() => showDateAction()}>
                                {
                                    startDate ?
                                        'Alterar Agendamento' :
                                        translate('scheduleOrder')
                                }
                            </div>
                            {
                                showDate &&
                                <DatePicker
                                    hidden={true}
                                    selected={startDate ? new Date(startDate) : null}
                                    onBlur={() => showDateAction()}
                                    minDate={new Date()}
                                    // onChange={(date) => setStartDate(date.toISOString())}
                                    onChange={(date) => handleDateSelection(date)}
                                    dateFormat="dd/MM/yyyy"
                                    autoFocus={true}
                                    defaultValue=""
                                    placeholderText="Selecione uma data"
                                    className={`w-full h-10 bg-white-200 rounded-md p-5 datepicker`} />
                            }
                            {
                                notification == false ?
                                    <div style={{ 'display': `${availability && availability.stock.total <= count ? 'flex' : 'none'}` }} className='action-button margin-bottom-10 margin-top-10' onClick={() => displayNotification()}>
                                        {translate('notifyMeWhenAvailable')}
                                    </div>
                                    :
                                    <div style={{ 'display': `${availability && availability.stock.total <= count ? 'flex' : 'none'}` }} className='action-button margin-bottom-10 margin-top-10 disabled'>
                                        {translate('notificationActive')}
                                    </div>
                            }
                        </div>
                }

                {
                    loading ?
                        <div className="loader-wrapper">
                            <Loader light={true} />
                        </div>
                        :
                        open && !sent ?
                            <div>
                                <textarea className="quote-message margin-bottom-10" type="text" rows="5" placeholder="Informações adicionais" ref={message} />
                                <div className='action-button' onClick={() => sendQuoteRequest()}>Enviar</div>
                            </div> :
                            (open && sent) &&
                            <p className="text-center">{translate('requestReceived')}</p>

                }
            </div> :
            <div className="purchase-box" style={{ 'position': 'relative' }}>
                <Loader light={true} />
            </div>
    )
}

export default PurchaseBox;