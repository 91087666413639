import { useState, useContext, useEffect } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import Loader from 'components/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckoutItem from 'components/CheckoutItem';
import GlobalFuncs from 'utils/GlobalFuncs';
import PaymentCard from 'components/PaymentCard';
import SelectObject from 'components/SelectObject';


function PayOrder() {

    const context = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();
    const [hide, setHide] = useState(false);
    const [info, setInfo] = useState(null);
    const [tempCart, setTempCart] = useState(null);
    const [tempTotal, setTempTotal] = useState(null);
    const [shipping, setShipping] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [id, setId] = useState(null);
    const [token, setToken] = useState(null)
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(null)
    const [credit, setCredit] = useState(null);
    const paymentMethod = new Map([[null, 'Carregando'], [1, 'Limite de crédito'], [2, 'Cartão Credito']])
    const [total, setTotal] = useState(0);
    const [orderDetails, setOrderDetails] = useState(false);
    const [expectedShippingDate, setExpectedShippingDate] = useState(null);
    const [paymentLink, setPaymentLink] = useState(null)
    const [taxaInfo, setTaxaInfo] = useState(false)
    const [step, setStep] = useState(1)
    const [paymentMonths, setPaymentMonths] = useState(null)
    const [tax, setTax] = useState(0)
    const [creditCardInfo, setCrediCardInfo] = useState({})
    const [error, setError] = useState(false)
    const [savedCcs, setSavedCcs] = useState([])
    const [selectedCc, setSelectedCc] = useState(null)
    const neededKeys = ['card_number', 'card_name', 'card_exp_month', 'card_exp_year', 'card_cvv', 'cpf']

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if(context.getSub_business_segment == "Stock Car"){
            history.push('/stockcar');
            return;
        }
        API.isActive().then(none => {
            const params = new URLSearchParams(location.search);
            if (params.get('ref') && params.get('ref') !== "null") {
                let token = params.get('ref')
                setToken(token)
                const cancelToken = API.cancelToken();
                API.getOrderByToken(cancelToken, token).then(res => {
                    let tempList = []
                    for (let item of res.data.products) {
                        tempList.push({ ...item, amount: item.quantity, price: item.item_price })
                    }
                    setTempCart(tempList)
                }).catch(err => {
                    if (err.message === 'cancelling') return
                    console.log(err.message);
                    setCredit(0)
                });

                API.getCredit(cancelToken).then(res => {
                    res.data.saldo_total >= 0 ? setCredit(res.data.saldo_total) : setCredit(0);
                }).catch(err => {
                    if (err.message === 'cancelling') return
                    console.log(err.message);
                    setCredit(0)
                });
            } else {
                Swal.fire({
                    title: 'Erro.',
                    text: 'URL Invalido.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(res => {
                    history.push('/home')
                });
            }
        }).catch(err => {
            console.log(err)
            context.logOut()
        });
    }, [location])

    useEffect(() => {
        let tempTotal = 0;
        let hasUnavailable = false
        tempCart && tempCart.forEach(e => {
            console.log(e)
            tempTotal += e.amount * e.price;
            if (e.amount > e.stock) {
                hasUnavailable = true
            }
        })
        setTotal(tempTotal);
        const cancelToken = API.cancelToken();
        async function updateCart() {
            let { tempDelivery, tempShipping } = await getDelivery(cancelToken)
            let deliveryDelay = 2
            if (tempDelivery) {
                while ([0, 6].includes(tempDelivery.getDay() + deliveryDelay <= 6 ? tempDelivery.getDay() + deliveryDelay : tempDelivery.getDay() + deliveryDelay - 7)) {
                    deliveryDelay++
                }
                tempDelivery.setDate(tempDelivery.getDate() + deliveryDelay);
                setShipping(tempShipping)
                var c0 = ''
                if (tempDelivery.getMonth() < 10) {
                    c0 = '0'
                }
                setExpectedShippingDate(`${tempDelivery.getFullYear()}-${c0}${tempDelivery.getMonth() + 1}-${tempDelivery.getDate()}`)
                if (hasUnavailable == true) {
                    setDelivery(-1)
                } else {
                    setDelivery(tempDelivery)
                }
            }
        }

        if (tempCart && tempCart.length > 0) updateCart();
        return () => {
            API.cancel(cancelToken);
        }
    }, [tempCart]);

    const getDelivery = async (cancelToken) => {
        let tempShipping = null;
        let tempDelivery = null;
        for (let e of tempCart) {
            let unitShipping;
            try {
                unitShipping = await API.getShipping(cancelToken, e.product_id, e.amount, e.price)
                // tempShipping += unitShipping.data.quote.products[0].totalPrice[0].price
                tempShipping += unitShipping.data.quote.price;
                let temp1 = unitShipping.data.quote.estimatedDelivery.split('/')
                let temp2 = new Date(temp1[2], temp1[1] - 1, temp1[0]);
                if (!tempDelivery || temp2 > tempDelivery) tempDelivery = new Date(temp2)
            } catch (err) {
                console.log(err.message)
            }
        }
        return { tempDelivery: tempDelivery, tempShipping: tempShipping }
    }

    useEffect(async () => {
        if (!info) return;
        const cancelToken = API.cancelToken();
        let temp_cart = []
        for (let item of info) {
            try {
                let prod = await API.getProduct(item.product_id, cancelToken)
                temp_cart.push({ ...item, image_url: prod.data.product.image_url, name: prod.data.product.name, price: prod.data.product.price, amount: item.quantity })
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(val => {
                    history.push('/')
                });
            }
        }
        setTempCart(temp_cart)
        return () => {
            API.cancel(cancelToken);
        }
    }, [info])

    useEffect(() => {
        if (paymentMonths === null) setTax(null)
        else if (payment === 2) {
            let vindiFee = {
                1: 0.0299,
                2: 0.0508,
                3: 0.0578,
                4: 0.0649,
                5: 0.0720,
                6: 0.0791,
                7: 0.0863,
                8: 0.0935,
                9: 0.1007,
                10: 0.1079,
                11: 0.1152,
                12: 0.1225,
            }
            let totalAmount = tempTotal ? tempTotal : total
            let taxAmount = (totalAmount / (1 - vindiFee[paymentMonths])) - totalAmount
            setTax(taxAmount)
        } else {
            setTax(0)
        }
    }, [paymentMonths, tempTotal, payment])

    const verifyPrice = async () => {
        var list_of_wrong_products = []
        var attempts = 0
        if (tempCart != null) {
            var tempTempCart = [...tempCart]
            for (const product of tempTempCart) {
                while (attempts < 10) {
                    try {
                        var price_changed = await API.getPriceChange(cancelToken, product['skf_product_id'], product['product_id'])
                        if (price_changed.data.price_match != true) {
                            list_of_wrong_products = [...list_of_wrong_products, price_changed.data.response.itens[0].mercCodigo]
                            product.price = price_changed.data.new_price
                        }
                        break
                    } catch (err) {
                        attempts += 1
                    }
                }
                if (attempts == 11) return false
            }
            setTempCart(tempTempCart)
            return { data: { products: list_of_wrong_products } };
        } else {
            return false
        }
    }

    useEffect(() => {
        if (step == 3) {
            handleSubmit(true)
        } else {
            handleSubmit()
        }
    }, [payment])

    const handleSubmit = async (skip) => {
        let payload = {}
        const cancelToken = API.cancelToken();
        switch (step) {
            case 1:
                if (shipping === null) return
                payload = {
                    products: tempCart.map(e => ({ product_id: e.product_id, quantity: e.amount, price: e.price }))
                }
                try {
                    setStep(2)
                } catch (e) {
                    setStep(1)
                    console.log(e)
                    API.verifyFail(cancelToken, token)
                    Swal.fire({
                        html: '<p>Erro no processamento do pedido.</p><p>Em breve a equipe SKF entrará em contato.</p>',
                        confirmButtonColor: "#000000",
                        icon: 'warning',
                        confirmButtonText: 'VOLTAR À HOME'
                    }).then(e => {
                        history.push("/")
                    });
                }
                break
            case 2:
                if (payment === null) return
                setStep(3)
                break
            case 3:
                if (skip === true) return
                if (payment === null || (payment === 2 && (paymentMonths === null || (selectedCc === null && !neededKeys.every(key => Object.keys(creditCardInfo).includes(key)))))) return
                setLoading(true);
                setTempCart(tempCart);
                creditCardInfo.card_installments = paymentMonths
                payload = {
                    order: {
                        payment_method: payment,
                        total_amount: total,
                        fees: tax,
                        order_token: token,
                        delivery_address: context.getAddress.mailing.id,
                        products: tempCart.map(e => ({ product_id: e.product_id, quantity: e.amount, price: e.price })),
                        // existing_creditcard: selectedCc,
                        user_input: { name: creditCardInfo.card_name, cpf: creditCardInfo.cpf, card_information: creditCardInfo },
                    }
                }
                API.submitOrder(payload).then(async res => {
                    setId(res.data.order_id);
                    if (payment === 2) {
                        if (res.data.payment == "paid") {
                            setLoading(false);
                            setSuccess(true);
                            await API.deleteFromCart(null, null, true)
                            context.setCart([]);
                            Swal.close();
                        } if (res.data.payment == "not paid") {
                            setLoading(false);
                            setSuccess(false);
                            Swal.fire({
                                title: 'Compra não aprovada. ',
                                html: "Houve um problema com a sua compra e você precisa revisar os dados preenchidos. </br> Acesse o AZPartes.com e tente novamente. Você tem 3 dias úteis. </br> </br> Caso demore mais que isso para refazer a compra, seu pedido será cancelado.",
                                confirmButtonColor: "#131921",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })

                        }
                    }
                }).catch(err => {
                    setLoading(false);
                    setSuccess(false);
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                });
                break
        }
    }

    const showInfo = () => {
        setTaxaInfo(true)
    }

    const hideInfo = () => {
        setTaxaInfo(false)
    }


    return (
        <div className="checkout-page-wrapper page-wrapper-white one-whole">
            <div className="section-wrapper">
                <h3 className="margin-bottom-30 margin-top-50">{step === 1 ? "Confira seu pedido" :
                    step === 2 ? "Escolha a forma de pagamento" :
                        step === 3 ? "Pagamento selecionado: " + paymentMethod.get(payment) : ""}</h3>
            </div>
            {step === 1 &&
                <div className="section-wrapper">
                    <h4 className="normal-weight grey-font">Endereços e entrega</h4>
                </div>}
            {step === 3 && payment === 2 &&
                <div className="section-wrapper">
                    <h4 className="normal-weight grey-font">Adicionar novo cartão de crédito</h4>
                </div>
            }
            <div className="section-wrapper one-whole flex-row-nowrap space-between wrap">
                {step === 1 &&
                    <div className="main-section">
                        <div className="user-wrapper flex-row space-between margin-bottom-20">
                            {
                                context.getAddress &&
                                <div className="limit-width margin-bottom-20">
                                    <h4 className="margin-bottom-10">Endereço de Entrega</h4>
                                    <p className="info">{context.getAddress.mailing.company && context.getAddress.mailing.company}</p>
                                    <p className="info">
                                        {context.getAddress.mailing.address1 && context.getAddress.mailing.address1},
                                        {context.getAddress.mailing.number && ` ${context.getAddress.mailing.number}`}
                                        {context.getAddress.mailing.address2 && ' - ' + context.getAddress.mailing.address2}
                                    </p>
                                    <p className="info">
                                        {context.getAddress.mailing.city && context.getAddress.mailing.city},
                                        {context.getAddress.mailing.state && ` ${context.getAddress.mailing.state}`}
                                    </p>
                                    <p className="info">{context.getAddress.mailing.postal && context.getAddress.mailing.postal}</p>
                                </div>
                            }
                            {
                                context.getAddress &&
                                <div className="limit-width margin-bottom-20">
                                    <h4 className="margin-bottom-10">Endereço de Cobrança</h4>
                                    <p className="info">{context.getAddress.billing.company && context.getAddress.billing.company}</p>
                                    <p className="info">
                                        {context.getAddress.billing.address1 && context.getAddress.billing.address1},
                                        {context.getAddress.billing.number && ` ${context.getAddress.billing.number}`}
                                        {context.getAddress.billing.address2 && ' - ' + context.getAddress.billing.address2}
                                    </p>
                                    <p className="info">
                                        {context.getAddress.billing.city && context.getAddress.billing.city},
                                        {context.getAddress.billing.state && ` ${context.getAddress.billing.state}`}
                                    </p>
                                    <p className="info">{context.getAddress.billing.postal && context.getAddress.billing.postal}</p>
                                </div>
                            }
                            <div className="shipping">
                                <h4 className="margin-bottom-10">Frete</h4>
                                <p>{shipping !== null ? (shipping > 0 ? `Intecom - ${GlobalFuncs.priceToReal(shipping)}` : 'Grátis') : 'Carregando'}</p>
                                <h4 className="margin-top-20 margin-bottom-10">Prazo de entrega estimado</h4>
                                <p className="delivery-date">{delivery ? delivery == -1 ? 'Verificar na guia Meus Pedidos, após o registro do pedido nos sistemas SKF' : delivery.toLocaleDateString("pt-BR", { weekday: 'long', month: 'long', day: 'numeric' }) : 'Carregando'}</p>
                            </div>
                        </div>
                        <h4 className="normal-weight grey-font margin-top-20">Itens selecionados</h4>
                        <div className="checkout-cart-wrapper flex-row spacebetween margin-top-20 margin-bottom-40">
                            <div className="listing margin-top-10 margin-bottom-20">
                                {tempCart ?
                                    tempCart.map((e, index) => (
                                        <CheckoutItem key={e.product_id * e.price + index} item={e} static={true} />
                                    )) :
                                    <Loader light={true} />
                                }
                            </div>
                        </div>
                    </div>
                }
                {step > 1 &&
                    <div className="main-section">
                        <div className="payment-selector">
                            <div className="checkbox-holder">
                                <div>
                                    <input className="checkbox" type="checkbox" onChange={e => payment === 1 ? setPayment(null) : setPayment(1)} checked={payment === 1} disabled={credit === 0 || success === true}/>
                                    Crédito SKF
                                </div>
                                <p className="">{credit !== null ? GlobalFuncs.priceToReal(credit) : "Carregando"}</p>
                            </div>
                        </div>
                        <div className="payment-selector">
                            <div className="checkbox-holder">
                                <div>
                                    <input className="checkbox" type="checkbox" onChange={e => payment === 2 ? setPayment(null) : setPayment(2)} checked={payment === 2} disabled={success === true} />
                                    Cartão de crédito
                                </div>
                            </div>
                            {step === 3 && payment === 2 && <PaymentCard disabled={selectedCc !== null || success === true} ccInfo={creditCardInfo} updateInfo={setCrediCardInfo} error={error} />}
                            {step === 3 && payment === 2 && <label className='margin-bottom-10 w-half display-block'>Parcelamento</label>}
                            {step === 3 && payment === 2 &&
                                <div className="monthly-section flex-row-nowrap w-full space-between">
                                    <div className="flex-col w-full">
                                        <SelectObject
                                            selected={paymentMonths ? <p className='months-parcelas' >{paymentMonths}</p> : null}
                                            objects={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((e, index) => (
                                                <p className='months-parcelas' key={index + "monthsparcelas"} onClick={() => setPaymentMonths(e)}>{e}</p>
                                            ))}
                                            placeholder="Selecione o número de parcelas" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* {step === 3 && payment === 2 &&
                    <div className='main-section'>
                        <div className="">
                            {step === 3 && payment === 2 &&
                                <div className="monthly-section flex-row-nowrap w-full space-between margin-bottom-10">
                                    <div className="flex-col w-full">
                                        <SelectObject
                                            selected={selectedCc ? <VisaLine reset={setSelectedCc} selected={true} creditCard={savedCcs.find(e => e.id === selectedCc)} /> : null}
                                            objects={savedCcs.map((e, index) => (
                                                <VisaLine key={index + "visalines"} onSelect={setSelectedCc} delete={handleDeleteCc} selectedCc={selectedCc} creditCard={e} />
                                            ))}
                                            placeholder="Selecione"
                                            block={selectedCc !== null} />
                                        {
                                            savedCcs.length === 0 &&
                                            <p className='error margin-bottom-40'>
                                                Não há cartões salvos. Adicione um abaixo e assinale ao fim para salvá-lo e facilitar suas próximas compras.
                                            </p>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            step === 3 && payment === 2 &&
                            <div className="checkbox-holder margin-bottom-50 flex-row align-center space-between">
                                <div className="save-cc">
                                    {selectedCc && <div className="cover"></div>}
                                    <input className="checkbox" type="checkbox" checked={creditCardInfo.saveInfo} onChange={e => setCrediCardInfo(prevValue => {
                                        return {
                                            ...prevValue, saveInfo: e.target.checked
                                        }
                                    })} />
                                    <strong>Quero salvar esse cartão para as próximas compras</strong>
                                </div>
                                <p className='underline pointer' onClick={() => setCrediCardInfo({})}>Limpar dados preenchidos</p>
                            </div>
                        }
                    </div>
                } */}
                <div className="checkout-purchase-box margin-bottom-20 checkout-box-margin">
                    <h4 className="margin-bottom-20">Resumo do Pedido</h4>
                    <div className="flex-row space-between margin-bottom-10">
                        <p className="">Itens</p>
                        <p className="">{tempTotal ? GlobalFuncs.priceToReal(tempTotal) : GlobalFuncs.priceToReal(total)}</p>
                    </div>
                    {payment && payment == 2 &&
                        <div className="flex-row space-between margin-bottom-10">
                            <div className="flex-row flex-center align-center relative">
                                <p>Taxa</p>
                                <img className='info-img pointer'
                                    src={require("../../assets/images/info.png").default}
                                    alt=""
                                    onMouseEnter={showInfo}
                                    onMouseLeave={hideInfo} />
                                {taxaInfo && <div className='taxa-info-box'>
                                    Esse valor não será incluido/destacado na nota fiscal.
                                </div>}
                            </div>
                            <p className="">{tax ? GlobalFuncs.priceToReal(tax) : " - "}</p>
                        </div>
                    }
                    <div className="flex-row space-between margin-bottom-20">
                        <p className="">Frete</p>
                        <p className="">{shipping !== null ? (shipping > 0 ? GlobalFuncs.priceToReal(shipping) : 'Grátis') : 'Carregando'}</p>
                    </div>
                    <hr />
                    <div className="flex-row space-between margin-top-30 margin-bottom-20">
                        <p className="bold-red">Total</p>
                        <p className="bold-red">{tempTotal ? GlobalFuncs.priceToReal(tempTotal + tax + (shipping && shipping)) : GlobalFuncs.priceToReal(total + tax + (shipping && shipping))}</p>
                    </div>
                    {loading ?
                        <div className="loader-wrapper margin-bottom-10">
                            <Loader light={true} />
                        </div> :
                        success ?
                            <Link className="reset" to={`/pedido/${id}`}>
                                <div className='action-button margin-bottom-20 success'>Pedido Efetuado</div>
                            </Link> :
                            step === 1 ?
                                <div className={`action-button margin-bottom-20 ${shipping === null || credit === null ? 'disabled' : ''}`}
                                    onClick={handleSubmit}>IR PARA O PAGAMENTO
                                </div>
                                :
                                step === 2 ?
                                    <div className={`action-button margin-bottom-20 ${payment === null ? 'disabled' : ''}`}
                                        onClick={handleSubmit}>CONTINUAR
                                    </div>
                                    :
                                    <div className={`action-button margin-bottom-20 success ${payment === null || (payment === 2 && (paymentMonths === null || (selectedCc === null && !neededKeys.every(key => Object.keys(creditCardInfo).includes(key))))) ? 'disabled-green' : ''}`}
                                        onClick={handleSubmit}>FINALIZAR COMPRA
                                    </div>
                    }
                    {
                        success ?
                            (payment == 2 ?
                                (paymentLink ?
                                    <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                        <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                        <div>
                                            <p>O seu pedido foi efetuado com sucesso. </p>
                                            <a className="bold blue pointer" target="_blank" rel="noopener noreferrer" href={paymentLink && paymentLink}>
                                                <p className='underline bold blue pointer'>Clique aqui para acessar a página de pagamento</p>
                                            </a>
                                        </div>
                                    </div> :
                                    <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                        <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                        <div>
                                            <p>Seu pedido foi enviado, mas um link de pagamento não foi gerado. Entre em contato conosco para obter suporte.</p>

                                        </div>
                                    </div>
                                )
                                :
                                <div className="success-box flex-row-nowrap align-center margin-bottom-20">
                                    <img src={require('../../assets/images/checkmark.png').default} alt="success" />
                                    <p>O seu pedido foi efetuado com sucesso. Após aprovação ele será encaminhado para faturamento.</p>
                                </div>)
                            : step === 1 && <p className="disclaimer margin-bottom-10 text-center line-24">
                                Ao prosseguir, você concorda com as Condições de Uso da <strong>AZPartes</strong>.
                            </p>
                    }
                    {!success && <div className='blue text-center display-block pointer' onClick={() => step > 1 ? setStep(step - 1) : history.push('/cart')}>Voltar</div>}
                </div>
            </div >
        </div>
    )
}

export default PayOrder;