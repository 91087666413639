import { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import ProductBox from 'components/ProductBox';
import Carousel from 'components/Carousel';
import PageNum from 'components/PageNum';
import Loader from 'components/Loader';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import GlobalFuncs from 'utils/GlobalFuncs';

function SearchResults() {

    const history = useHistory();
    const location = useLocation();
    const ave = useRef();
    const context = useContext(AppContext);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [recommended, setRecommended] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [alphabetical, setAlphabetical] = useState(null);
    const [openCat, setOpenCat] = useState(null);
    const [category, setCategory] = useState('');
    const [priceCat, setPriceCat] = useState(null);
    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);
    const [tempMin, setTempMin] = useState(null);
    const [tempMax, setTempMax] = useState(null);
    const [results, setResults] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);
    const [resultSize, setResultSize] = useState(null);
    const [sorting, setSorting] = useState(0);
    const [type, setType] = useState(null);
    const [make, setMake] = useState(null);
    const [model, setModel] = useState(null);
    const [motor, setMotor] = useState(null);
    const [license, setLicense] = useState(null);
    const [car, setCar] = useState(null);
    const [filterTracker, setFilterTracker] = useState(null);
    const [availableOnly, setAvailableOnly] = useState(null);

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Busca',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        if(context.getSub_business_segment == "Stock Car"){
            history.push('/stockcar');
            return;
        }
        API.isActive().then(none => {
            context.setAuthCompanies(none.data.authorized_companies);
        }).catch(err => {
            context.logOut()
        });
    }, [])

    useEffect(() => {
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        setResults(null);
        setResultSize(null);
        setCar(null)
        setMobile(window.innerWidth <= 768);
        const params = new URLSearchParams(location.search);
        params.get('q') && params.get('q') !== "null" ? setSearchTerm(params.get('q')) : setSearchTerm(null);
        params.get('alph') && params.get('alph') !== "null" ? setAlphabetical(params.get('alph')) : setAlphabetical(null);
        params.get('type') && params.get('type') !== "null" ? setType(params.get('type')) : setType(null);
        params.get('make') && params.get('make') !== "null" ? setMake(params.get('make')) : setMake(null);
        params.get('model') && params.get('model') !== "null" ? setModel(params.get('model')) : setModel(null);
        params.get('motor') && params.get('motor') !== "null" ? setMotor(params.get('motor')) : setMotor(null);
        params.get('license') && params.get('license') !== "null" ? setLicense(params.get('license')) : setLicense(null);
        parseInt(params.get('openCat')) ? setOpenCat(parseInt(params.get('openCat'))) : setOpenCat(null);
        parseInt(params.get('cat')) ? setCategory(parseInt(params.get('cat'))) : params.get('q') == "VKWY" ? setCategory(30) : setCategory(null);
        parseInt(params.get('page')) ? setCurrentPage(parseInt(params.get('page'))) : setCurrentPage(1);
        parseInt(params.get('sorting')) ? setSorting(parseInt(params.get('sorting'))) : setSorting(0);
        parseInt(params.get('min')) ? setMin(parseInt(params.get('min'))) : setMin(null);
        parseInt(params.get('max')) ? setMax(parseInt(params.get('max'))) : setMax(null);
        setAvailableOnly(parseInt(params.get('available')));

        if (parseInt(params.get('priceCat')) >= 0) {
            setPriceCat(parseInt(params.get('priceCat')));
        } else {
            setPriceCat(null);
            parseInt(params.get('min')) ? setTempMin(parseInt(params.get('min'))) : setTempMin(null);
            parseInt(params.get('max')) ? setTempMax(parseInt(params.get('max'))) : setTempMax(null);
        }

        const cancelToken = API.cancelToken();

        if (params.get('license') && params.get('license') !== "null") {
            API.searchLicense(location.search, cancelToken).then(res => {
                setResults(res.data.body.products);
                setCurrentPage(res.data.body.current_page);
                setMaxPages(res.data.body.max_pages);
                setResultSize(res.data.body.n_occurrences)
                res.data.car.brand !== "" ? setCar(`${res.data.car.brand} ${res.data.car.model} ${res.data.car.year}`) : setCar(null);
            }).catch(err => {
                if (err.message === 'cancelling') return
                console.log(err.response);
                setResults([]);
                setCurrentPage(1);
                setMaxPages(1);
                setResultSize(0)
            });
        } else {
            if (!context.getApplications || !context.getWords) return
            let payload = null
            if (params.get('q') !== "null") payload = GlobalFuncs.predictiveSearch(params.get('q'), context.getApplications, context.getProducts, context.getWords, true)
            API.searchProducts(location.search, payload, cancelToken).then(res => {
                if (res.data.body.n_occurrences == 0) {
                    API.getRecommendedProducts(cancelToken).then(res => {
                        setResults(res.data.products);
                        setCurrentPage(1);
                        setMaxPages(1);
                        setResultSize(-1)
                    }).catch(err => {
                        setRecommended(null);
                        if (err.message === 'cancelling') return
                        console.log(err.response);
                    });
                } else {
                    setResults(res.data.body.products);
                    setCurrentPage(res.data.body.current_page);
                    setMaxPages(res.data.body.max_pages);
                    setResultSize(res.data.body.n_occurrences)
                    res.data.car ? setCar(`${res.data.car.brand} ${res.data.car.model} ${res.data.car.year}`) : setCar(null);
                }
            }).catch(err => {
                if (err.message === 'cancelling') return
                console.log(err.response);
                setResults([]);
                setCurrentPage(1);
                setMaxPages(1);
                setResultSize(0)
            });
        }




        API.getRecommendedProducts(cancelToken).then(res => {
            setRecommended(res.data.products);
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.message);
        });
        return () => {
            API.cancel(cancelToken);

        }
    }, [location, context.getApplications, context.getWords]);

    useEffect(() => {
        var priceString = false;
        var resultString = false;
        var avString = false
        if (min && max) priceString = `de R$${min} até R$${max}`;
        if (min && !max) priceString = `a partir de R$${min}`;
        if (max && !min) priceString = `até R$${max}`;
        if (category) resultString = allCategories.get(category);
        if (availableOnly === 1) avString = `em estoque`;
        priceString || resultString || avString ? setFilterTracker(` (${resultString ? resultString : ''}${resultString && priceString ? ' ' : ''}${priceString ? priceString : ''}${(resultString && avString) || (priceString && avString) ? ' ' : ''}${avString ? avString : ''})`) : setFilterTracker(null)
    }, [min, max, category, availableOnly])

    useEffect(() => {
        ave.current.checked = availableOnly === 1
    }, [availableOnly])

    const updateUrl = (arr) => {
        let st = searchTerm;
        let cat = category;
        let mn = min;
        let mx = max;
        let tp = type;
        let mk = make;
        let md = model;
        let mt = motor;
        let pc = priceCat;
        let oc = openCat;
        let cp = currentPage;
        let srt = sorting;
        let lp = license;
        let av = availableOnly;
        let alph = alphabetical
        arr.forEach(e => {
            switch (e.field) {
                case 'CAT':
                    cat = e.value == 30 ? null : e.value;
                    st = e.value == 30 ? "VKWY" : null;
                    mn = null;
                    mx = null;
                    pc = null;
                    cp = 1;
                    srt = null;
                    tp = null;
                    mk = null;
                    md = null;
                    mt = null;
                    lp = null;
                    alph = null;
                    break;
                case 'MIN':
                    mn = e.value;
                    cp = 1;
                    break;
                case 'MAX':
                    mx = e.value;
                    cp = 1;
                    break;
                case 'PC':
                    pc = e.value;
                    cp = 1;
                    break;
                case 'OC':
                    oc = e.value;
                    break;
                case 'CP':
                    cp = e.value;
                    break;
                case 'SRT':
                    srt = e.value;
                    cp = 1;
                    alph = null;
                    break;
                case 'AV':
                    av = e.value;
                    break;
                case 'ALPH':
                    alph = e.value;
                    break;
                default:
                    break;
            }
        })
        history.push(`/products/search?q=${st}&cat=${cat}&min=${mn}&max=${mx}&priceCat=${pc}&openCat=${oc}&page=${cp}&sorting=${srt}&type=${tp}&make=${mk}&model=${md}&motor=${mt}&license=${lp}&available=${av}&alph=${alph}`);
    }

    const setMinMax = (min, max, id) => {
        let mn;
        let mx;
        setTempMin(null);
        setTempMax(null);
        min !== -1 ? mn = min : mn = null;
        max !== -1 ? mx = max : mx = null;
        updateUrl([{ field: 'PC', value: id }, { field: 'MIN', value: mn }, { field: 'MAX', value: mx }]);
    }

    const handleChange = (e, limit) => {
        let value = e.currentTarget.value;
        if (value !== "" && !(new RegExp(/[0-9]+$/).test(value))) {
            return;
        } else {
            switch (limit) {
                case 'min':
                    value === "" ? setTempMin(null) : setTempMin(parseInt(value));
                    break;
                case 'max':
                    value === "" ? setTempMax(null) : setTempMax(parseInt(value));
                    break;
                default:
                    break;
            }
        }
    }

    const handleSubmitMinMax = (e) => {
        e.preventDefault();
        let mn = null;
        let mx = null;
        if (tempMin && parseInt(tempMin)) mn = parseInt(tempMin);
        if (tempMax && parseInt(tempMax)) mx = parseInt(tempMax);
        updateUrl([{ field: 'PC', value: null }, { field: 'MIN', value: mn }, { field: 'MAX', value: mx }]);
    }

    const changeSorting = (e) => {
        updateUrl([{ field: 'SRT', value: e.currentTarget.value }]);
    }

    window.addEventListener('resize', function (event) {
        setMobile(window.innerWidth <= 768);
    }, true);

    useEffect(() => {
        setOpenFilter(false);
    }, [mobile, location])

    const allCategories = new Map(
        [
            [1, 'Rolamentos'],
            [2, 'Juntas homocinéticas'],
            [7, 'Kit de Tensor & Correia'],
            [8, 'Produtos de embreagem'],
            [9, 'Hidráulica de Embreagem'],
            [10, 'Componentes de suspensão'],
            [11, 'Kits para junta homocinética'],
            [12, 'Produtos de correia'],
            [13, 'Kit de Tensor & Bomba d\'àgua'],
            [14, 'Kit de Corrente de Distribuição'],
            [16, 'Bombas d\'água'],
            [17, 'Cardan'],
            [18, 'Cruzetas'],
            [19, 'Componentes de direção'],
            [20, 'Retentor'],
            [25, 'Ferramentas'],
            [30, 'Kits de caixa de direção']
        ]
    )

    const alphabeticalFilter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    useEffect(() => {
        const cancelToken = API.cancelToken();
        API.getCategories(cancelToken).then(res => {
            let availableCodes = res.data.available_codes;
            let tempList = [];
            availableCodes.forEach((e)=>{
                tempList.push([e, allCategories.get(e)])
            })
            tempList.push([30, allCategories.get(30)])
            setCategories(tempList);
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.message);
        });
        return () => {
            API.cancel(cancelToken);

        }
    }
        , [])

    return (
        <div className="search-page page-wrapper-white one-whole">
            <div className="sorting-section flex-row-nowrap space-between margin-bottom-20">
                <p>
                {resultSize > 0 ? resultSize > 12 ? ((currentPage - 1) * 12 + 1) + '-' + ((currentPage - 1) * 12 + 12) + ' de mais de ' + resultSize + ' resultados' : ((currentPage - 1) * 12 + 1) + '-' + (resultSize) + ' de mais de ' + resultSize + ' resultados' : resultSize == null ? '' : `Nenhum resultado encontrado para ${searchTerm}. Veja resultados sugeridos.`}{(filterTracker && resultSize !== null) && filterTracker}
                </p>
                {
                    !mobile &&
                    <p className="sorting" >Ordenar por: <select name="Filtrar" value={sorting ? sorting : 0} onChange={(e) => changeSorting(e)}>
                        <option value="0" defaultValue>Selecionar</option>
                        <option value="5">Mais Relevantes</option>
                        <option value="1">Menor Preço</option>
                        <option value="2">Maior Preço</option>
                        <option value="3">A-Z</option>
                        <option value="4">Z-A</option>
                    </select>
                    </p>
                }
                {
                    mobile &&
                    <div className="flex-row-nowrap align-center mobile-filter-button" onClick={() => setOpenFilter(true)}>
                        <img src={require('../../assets/images/funnel.png').default} alt="Filtro" />
                        <p className="sorting">Filtro</p>
                    </div>
                }
            </div>
            <div className="flex-row-nowrap">
                <div className="filters-section" style={{ 'display': `${!openFilter && mobile ? 'none' : 'block'}` }}>
                    {
                        mobile &&
                        <img className="close" src={require('../../assets/images/close.png').default} alt="close" onClick={() => setOpenFilter(false)} />
                    }
                    <h3 className="margin-bottom-20 sub-cat">Categorias</h3>
                    <div className="categories margin-bottom-20">
                        {/* <Accordion updateurl="true" openId={openCat && openCat} headerOpenStyle={{ 'marginLeft': '20px' }} headerClosedStyle={{ 'marginLeft': '0px' }}>
                            <h4 header="true" headerid={1}>Acessórios &#38; Tuning</h4>
                            <h4 header="true" headerid={2}>Autopeças</h4>
                            <h4 header="true" headerid={3}>Componentes Industriais</h4>
                            <p className={category === 'Correntes e Correias' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Correntes e Correias' }, { field: 'OC', value: 3 }])} headerid={3}>Correntes e Correias</p>
                            <p className={category === 'Fixações Industriais' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Fixações Industriais' }, { field: 'OC', value: 3 }])} headerid={3}>Fixações Industriais</p>
                            <p className={category === 'Infraestrutura e Organização' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Infraestrutura e Organização' }, { field: 'OC', value: 3 }])} headerid={3}>Infraestrutura e Organização</p>
                            <p className={category === 'Mancais Industriais' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Mancais Industriais' }, { field: 'OC', value: 3 }])} headerid={3}>Mancais Industriais</p>
                            <p className={category === 'Montagens Industriais' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Montagens Industriais' }, { field: 'OC', value: 3 }])} headerid={3}>Montagens Industriais</p>
                            <p className={category === 'Produtos Quimicos' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Produtos Quimicos' }, { field: 'OC', value: 3 }])} headerid={3}>Produtos Quimicos</p>
                            <p className={category === 'Rolamentos' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Rolamentos' }, { field: 'OC', value: 3 }])} headerid={3}>Rolamentos</p>
                            <p className={category === 'Rótulas' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Rótulas' }, { field: 'OC', value: 3 }])} headerid={3}>Rótulas</p>
                            <p className={category === 'Selantes e Colas' ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: 'Selantes e Colas' }, { field: 'OC', value: 3 }])} headerid={3}>Selantes e Colas</p>
                            <h4 header="true" headerid={4}>Equipamentos de Proteção &#38; Uniformes</h4>
                            <h4 header="true" headerid={5}>Máquinas &#38; Ferramentas</h4>
                            <h4 header="true" headerid={6}>Óleos &#38; Fluidos</h4>
                        </Accordion> */}
                        {categories.map(([key, value]) => (
                            <p key={key + value} className={category === key ? 'active' : null} onClick={() => updateUrl([{ field: 'CAT', value: key }, { field: 'OC', value: null }])}>{value}</p>
                        ))}
                    </div>
                    <input type="checkbox" name="available" id="availableOnly" className="margin-bottom-20 av-check" onClick={(e) => updateUrl([{ field: 'AV', value: e.currentTarget.checked ? 1 : 0 }])} ref={ave} /><label htmlFor="availableOnly">Produto em Estoque</label>
                    {
                        context.getLogin && <div>
                            <h3 className="margin-bottom-20 sub-cat">Preço</h3>
                            <div className="prices margin-bottom-20">
                                <p className={priceCat === 0 ? 'active' : null} onClick={() => setMinMax(-1, 25, 0)}>Até R$25,00</p>
                                <p className={priceCat === 1 ? 'active' : null} onClick={() => setMinMax(25, 50, 1)}>R$25,00 a R$ 50,00</p>
                                <p className={priceCat === 2 ? 'active' : null} onClick={() => setMinMax(50, 100, 2)}>R$50,00 a R$ 100,00</p>
                                <p className={priceCat === 3 ? 'active' : null} onClick={() => setMinMax(100, 200, 3)}>R$100,00 a R$ 200,00</p>
                                <p className={priceCat === 4 ? 'active' : null} onClick={() => setMinMax(200, -1, 4)}>Acima de R$200,00</p>
                            </div>
                        </div>
                    }
                    {
                        mobile &&
                        <p className="sorting margin-bottom-20" >Ordenar por: <select name="Filtrar" value={sorting ? sorting : 0} onChange={(e) => changeSorting(e)}>
                            <option value="0" defaultValue>Selecionar</option>
                            <option value="5">Mais Relevantes</option>
                            <option value="1">Menor Preço</option>
                            <option value="2">Maior Preço</option>
                            <option value="3">A-Z</option>
                            <option value="4">Z-A</option>
                        </select>
                        </p>
                    }
                    <div className="manual-price-box flex-row-nowrap space-between">
                        <div className="range-box flex-row-nowrap">
                            {/* <p>R$</p> */}
                            <input type="text" placeholder="R$ Min" onChange={(e) => handleChange(e, 'min')} value={tempMin ? tempMin : ''} />
                        </div>
                        <div className="range-box flex-row-nowrap">
                            {/* <p>R$</p> */}
                            <input type="text" placeholder="R$ Max" onChange={(e) => handleChange(e, 'max')} value={tempMax ? tempMax : ''} />
                        </div>
                        <button className="range-box action-button" onClick={(e) => handleSubmitMinMax(e)}>
                            Buscar
                        </button>
                    </div>
                </div>
                <div className="products-section one-whole">
                    <div className='alphabetical-filter'>
                        {alphabeticalFilter && alphabeticalFilter.map((elem) => (
                            <p className={`letter-button ${alphabetical === elem && 'active'}`} key={elem} onClick={() => updateUrl([{ field: 'ALPH', value: elem }])} >{elem}</p>
                        ))}
                        <p className={`letter-button ${alphabetical === null && 'active'}`} key={"all"} id='showAll' onClick={() => updateUrl([{ field: 'ALPH', value: null }])}>Mostrar Todos</p>
                    </div>
                    <h3 className="margin-bottom-30">{
                        resultSize == null ?
                            'Buscando...' :
                            category && category !== "null" ?
                                allCategories.get(category) :
                                license !== null ?
                                    `(${license}) ${car !== null ? car : ''}` :
                                    (`Produtos${type && type !== 'null' ?
                                        (' da ' + type) : ''}${make && make !== 'null' || model && model !== 'null' || motor && motor !== 'null' ? ' de ' : ''}${make && make !== 'null' ? make + ' ' : ''}${model && model !== 'null' ? model + ' ' : ''}${motor && motor !== 'null' ? motor + ' ' : ''}${resultSize === -1 ? " Sugeridos" : ""}`)}</h3>
                    {results !== null ? (resultSize === 0 && license ? <p>Não localizado produtos para o veículo</p> : resultSize === 0 ? <p>0 resultados</p> : <p>{resultSize} resultados encontrados</p>) : <Loader />}
                    <div className="flex-row margin-bottom-30 flex-center">
                        {Array.isArray(results) && results.map((e, id) => (
                            // {Array.isArray(results) && sortedList.map((e, id) => (
                            <ProductBox classes="margin-bottom-30" key={e.product_id + id + id.toString()} name={e.name} productId={e.product_id} product={e} price={e.price} image={e.image_url && e.image_url.includes("http") ? e.image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${e.image_url}`} />
                        ))
                        }
                    </div>
                    {
                        resultSize > 0 &&
                        <div className="page-selector flex-row-nowrap space-between">
                            {(currentPage != 1) && <div onClick={() => currentPage > 1 ? updateUrl([{ field: 'CP', value: currentPage - 1 }]) : null} className="control-buttons flex-row-nowrap space-between align-center">
                                <img src={require('../../assets/images/leftBlue.png').default} alt="previous" />
                                {
                                    !mobile && <p>Anterior</p>
                                }
                            </div>
                            }
                            <div className="pages-section flex-row-nowrap">
                                {
                                    <PageNum maxPages={maxPages} currentPage={currentPage} updateUrl={updateUrl} />
                                }
                            </div>
                            {(currentPage != maxPages) && <div onClick={() => currentPage < maxPages ? updateUrl([{ field: 'CP', value: currentPage + 1 }]) : null} className="control-buttons flex-row-nowrap space-between align-center">
                                {
                                    !mobile && <p>Próxima</p>
                                }
                                <img src={require('../../assets/images/rightBlue.png').default} alt="next" />
                                </div>}
                        </div>
                    }
                </div>
            </div>
            <div className="section-wrapper one-whole">
                <div className="">
                    <hr />
                </div>
                {
                    context && context.getSub_business_segment !== "Private Label" &&
                    <Carousel header="Produtos Recomendados" list={recommended} />

                }
            </div>
        </div>
    )
}

export default SearchResults;