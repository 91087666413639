import { useState, useEffect } from 'react';
import './style.css';
import OrderLine from 'components/OrderLine';


function OrderDetails(props) {
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(true);
    // const [status, setStatus] = useState(null);
    // const [statusColor, setStatusColor] = useState(null);
    // const statusRanking = new Map([
    //     ['RPI', [0, 'Processo de faturamento']],
    //     ['PIC', [1, 'Processo de separação']],
    //     ['ALL', [2, 'Ordem aguardando liberação do crédito']],
    //     ['SHS', [3, 'Item importado já embarcado, em trânsito']],
    //     ['ENT', [4, 'Linha sem previsão de entrega']],//yellow
    //     ['BOK', [5, 'Linha com previsão de entrega']],
    //     ['RBK', [6, 'Linha com previsão de entrega reprogramada']], //yellow
    //     ['REJ', [7, 'Linha rejeitada']], //red
    //     ['INV', [8, 'Linha Faturada']] //green
    // ]);

    // useEffect(() => {
    //     if (props.product) {
    //         console.log('')
    //     } else {
    //         props.line.forEach(e => {
    //             switch (statusRanking.get(e.status_code)[0]) {
    //                 case 7:
    //                     console.log("here")
    //                     setStatus('Linha rejeitada');
    //                     setStatusColor('label label-danger');
    //                     return;
    //                 case 4:
    //                 case 6:
    //                     setStatus(e.status);
    //                     setStatusColor('label label-warning');
    //                     return;
    //                     default:
    //                     setStatus(e.status);
    //                     setStatusColor('label label-success');
    //                     break;
    //             }
    //         })
    //     }
    // }, [props])

    useEffect(() => { 
        console.log("***************")
        console.log([props])
        console.log("***************")
    }, [props])

    useEffect(() => {
        if (props.filter === null) {
            setVisible(true);
            setOpen(false);
        } else if (props.product) {
            if(props.filter.includes('RECEIVED')){
                setVisible(true);
                setOpen(true);
            }else {
                setVisible(false);
                setOpen(false);
            }
        } else {
            let arr = props.line.filter(e => props.filter.includes(e.status_code));
            if (arr.length > 0) {
                setVisible(true);
                setOpen(true);
            } else {
                setVisible(false);
                setOpen(false);
            }
        }
    }, [props.filter])

    return (
        visible &&
        (props.product ?
            <div className="order-details-box">
                <div className="title-box flex-row-nowrap" onClick={() => setOpen(!open)}>
                    <svg className={`no-shrink ${open ? 'rotate' : null}`} xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 7.071 12">
                        <path id="Path_15629" data-name="Path 15629" d="M542.479,7.021,538.3,11.2l-4.178-4.179a1.036,1.036,0,0,0-1.5,0,1.036,1.036,0,0,0,0,1.5l4.928,4.929a1.036,1.036,0,0,0,1.5,0l4.928-4.929a1.061,1.061,0,1,0-1.5-1.5Z" transform="translate(-6.7 544.3) rotate(-90)" fill="#232F3E" />
                    </svg>
                    <p className="title">{`${props.product.name ? props.product.name : props.product.skf_product_id}`}</p>
                    {/* <span className="label label-success">
                        Recebido
                    </span> */}
                </div>
                <div style={{ 'display': `${open ? 'flex' : 'none'}` }} className="lines-box flex-row space-between">
                    <div className="data">
                        {props.filter ?
                            props.filter.includes('RECEIVED') && <OrderLine info={props.product} open={open} ccStatus={props.ccStatus} /> :
                            <OrderLine info={props.product} open={open} ccStatus={props.ccStatus}  />}
                    </div>
                    <img src={props.product.image_url && props.product.image_url.includes("http") ? props.line[0].product[0].image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${props.line[0].product[0].image_url}`} alt={props.product.skf_product_id} />
                </div>
                <hr className="margin-top-20 margin-bottom-20" />
            </div>
            :
            <div className="order-details-box">
                <div className="title-box flex-row space-between align-center" onClick={() => setOpen(!open)}>
                    <div className="flex-row-nowrap align-center">
                        <svg className={`no-shrink ${open ? 'rotate' : null}`} xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 7.071 12">
                            <path id="Path_15629" data-name="Path 15629" d="M542.479,7.021,538.3,11.2l-4.178-4.179a1.036,1.036,0,0,0-1.5,0,1.036,1.036,0,0,0,0,1.5l4.928,4.929a1.036,1.036,0,0,0,1.5,0l4.928-4.929a1.061,1.061,0,1,0-1.5-1.5Z" transform="translate(-6.7 544.3) rotate(-90)" fill="#232F3E" />
                        </svg>
                        <p className="title">{`${props.line[0].product[0].skf_product_id}`}</p>
                    </div>
                    {/* <span className={statusColor}>
                        {status}
                    </span> */}
                </div>
                <div style={{ 'display': `${open ? 'flex' : 'none'}` }} className="lines-box flex-row space-between">
                    <div className="data">
                        {props.line.map((e, id) => (
                            props.filter ?
                                props.filter.includes(e.status_code) && <OrderLine ccStatus={props.ccStatus}  key={e.product.product_id + e.split_id + id} info={e} open={open} /> :
                                <OrderLine ccStatus={props.ccStatus}  key={e.product.product_id + e.split_id + id} info={e} open={open} />
                        ))}
                    </div>
                    <img src={props.line[0].product.image_url && props.line[0].product.image_url.includes("http") ? props.line[0].product[0].image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${props.line[0].product[0].image_url}`} alt={props.line[0].product.product_id} />
                </div>
                <hr className="margin-top-20 margin-bottom-20" />
            </div>)

    )
}

export default OrderDetails;