import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import './style.css';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';
import Loader from 'components/Loader';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import { useDropzone } from 'react-dropzone';

function DemandRequest() {
    const history = useHistory();
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({});
    const [contact, setContact] = useState({ name: "", phone: "" });
    const [mask, setMask] = useState("+55 (99) 99999-9999");
    const [error, setError] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [files, setFiles] = useState([]);
    const [submissionType, setSubmissionType] = useState('Sugestão');
    const [message, setMessage] = useState('');

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Meus Dados',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        // Swal.fire({
        //     title: 'Obrigado',
        //     text: 'Sua solicitação foi enviada com sucesso!',
        //     confirmButtonColor: "#131921",
        //     icon: 'success',
        //     confirmButtonText: 'OK'
        // });
        const cancelToken = API.cancelToken();
        API.isActive().then(none => {
            hotjar.initialize('3361828');
            ReactGA.pageview(window.location.pathname);
            API.getCompany(cancelToken).then(res => {
                setInfo(res.data.company);
                setLoading(false);
                setContact({ name: res.data.company.name, phone: res.data.company.phone });
                if (res.data.company.phone.length === 19) {
                    setMask("+55 (99) 99999-9999");
                } else {
                    setMask("+55 (99) 9999-9999");
                }
            }).catch(err => {
                if (err.message === 'cancelling') return;
                console.log(err.response);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(sel => {
                    window.location.href = "/";
                });
            });
        }).catch(err => {
            context.logOut();
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    const validatePhone = (phone) => {
        phone = `${phone}`.replace(/\D/g, '');
        return phone.length === 13 || phone.length === 12;
    };

    const handleUpdate = (event) => {
        const { name, value } = event.target;
        let phone = ``;
        if (name === "phone") {
            phone = `${value}`.replace(/\D/g, '');
        }
        setError(!validatePhone(phone));
        setContact(prevValue => ({
            ...prevValue,
            [name]: value
        }));
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Enviando solicitação...',
            text: 'Aguarde um momento.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        if (error) {
            return;
        }

        let base64Files = [];
        if (files.length > 0) {
            base64Files = await Promise.all(files.map(file => convertFileToBase64(file)));
        }

        const payload = {
            tipo: submissionType,
            mensagem: message,
            files: base64Files
        };

        console.log(payload)
        API.submitSugestion(payload).then(res => {
          Swal.fire({
            title: 'Obrigado',
            text: 'Sua solicitação foi enviada com sucesso!',
            confirmButtonColor: "#131921",
            icon: 'success',
            confirmButtonText: 'OK'
        });
        }).catch(err => {
          console.log(err.response);
          Swal.fire({
            title: 'Erro.',
            text: 'Tente novamente mais tarde.',
            confirmButtonColor: "#131921",
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        });
    };

    return (
        <div className="account-page-wrapper page-wrapper-white one-whole margin-top-50">
            <div className="section-wrapper-small one-whole">
                <div className="breadcrumbs flex-row-nowrap align-center justify-center">
                    <h4 className='margin-right-10'><Link to='/account' className='reset your-account'>Minha conta</Link></h4>
                    <svg className="svg-icon__arrow--right" viewBox="0 0 7 12" height="10px" width="10px" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#C3C4C5" fillRule="evenodd" d="M6.893 5.967c0-.268-.107-.524-.295-.713L1.724.37C1.33-.024.692-.024.3.37c-.394.394-.394 1.033 0 1.427l4.162 4.17L.3 10.137c-.394.394-.394 1.033 0 1.427.392.394 1.03.394 1.424 0L6.598 6.68c.188-.19.295-.445.295-.713z" />
                    </svg>
                    <p>Melhorias e Sugestões</p>
                </div>
                <div className="main-section">
                    <div className="user-wrapper margin-bottom-20 margin-top-20">
                        <section class="">
                            <h4>Ajude-nos a melhorar</h4>
                            <section class="section suggestion-section">
                            <p className="paragraph">Sua contribuição é fundamental para aprimorarmos nosso propósito.</p>
                                <label class="label-type" for="typeOfSubmission">Tipo de envio</label>
                                <div id="typeOfSubmission" class="input-group" role="group" aria-labelledby="typeOfSubmission">
                                    <select
                                        value={submissionType}
                                        onChange={(e) => setSubmissionType(e.target.value)}
                                        className="input-select"
                                    >
                                        <option value="SUGGESTION">Sugestão</option>
                                        <option value="COMPLAINT">Reclamação</option>
                                        <option value="COMPLIMENT">Elogio</option>
                                    </select>
                                </div>
                                <label class="message" for="messageInput">Mensagem</label>
                                <textarea id="messageInput" class="textarea" aria-label="Escreva aqui sua mensagem..." value={message} onChange={handleMessageChange}></textarea>
                                <div class="file-upload" role="group" aria-labelledby="file-upload">
                                    <div {...getRootProps({ className: 'file-upload-inner' })}>
                                        <input {...getInputProps()} />
                                        <span class="file-upload-title">Arraste e solte os arquivos para anexar documentos</span>
                                        <button class="file-upload-button" type="button" onClick={() => document.querySelector('input[type="file"]').click()}>SELECIONAR ARQUIVO</button>
                                    </div>
                                </div>
                                <div className='margin-top-20'>
                                    {files.map(file => (
                                        <div key={file.path}>
                                            {file.path}
                                        </div>
                                    ))}
                                </div>
                                <button class="submit-button" type="submit" onClick={handleSubmit}>Enviar Mensagem</button>
                            </section>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DemandRequest;
