import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "utils/ContextProvider";
import { Link } from "react-router-dom";
import "./style.css";
import API from "utils/API";
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import GlobalFuncs from "utils/GlobalFuncs";

function Financeiro() {

    const context = useContext(AppContext)
    const [credit, setCredit] = useState({ available: null, limit: null })
    const [stamp, setStamp] = useState("")

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Financeiro',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        const cancelToken = API.cancelToken();
        API.isActive().then(none => {
            hotjar.initialize('3361828')
            ReactGA.pageview(window.location.pathname);
            API.getCredit(cancelToken).then(res => {
                
                setCredit({ available: res.data.saldo_total, limit: res.data.limite_total, condition:res.data.payment_condition })
                
                let date = new Date();
                setStamp(`${String(date.getHours()).padStart(2, '0')}h${String(date.getMinutes()).padStart(2, '0')} ${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`)
            }).catch(err => {
                if (err.message === 'cancelling') return
                console.log(err.response);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            });
        }).catch(err => {
            context.logOut()
        });

        return () => {
            API.cancel(cancelToken);

        }
    }, []);



    return (
        <div className="account-page-wrapper page-wrapper-white one-whole margin-top-50">
            <div className="section-wrapper-small one-whole">


                <div className="breadcrumbs flex-row-nowrap align-center justify-center">
                    <h4><Link to='/account' className='reset your-account'>Minha conta</Link></h4>
                    <svg className="svg-icon__arrow--right" viewBox="0 0 7 12" height="10px" width="10px" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#C3C4C5" fillRule="evenodd" d="M6.893 5.967c0-.268-.107-.524-.295-.713L1.724.37C1.33-.024.692-.024.3.37c-.394.394-.394 1.033 0 1.427l4.162 4.17L.3 10.137c-.394.394-.394 1.033 0 1.427.392.394 1.03.394 1.424 0L6.598 6.68c.188-.19.295-.445.295-.713z" />
                    </svg>
                    <p>Financeiro</p>
                </div>
                <div className="financeiro-content margin-top-50">
                    <h4>Informações Financeiras</h4>
                    <div className="informacoes">
                        <p className="paragraph">Abaixo você encontra o seu Crédito Disponível para compra no AZPartes.com e o Limite de Crédito.</p>
                        <div className="credito">
                            <label className="margin-bottom-10">Crédito Disponível</label>
                            <input className="margin-bottom-10" placeholder="Carregando..." disabled={true} value={credit.available && GlobalFuncs.priceToReal(credit.available)} />
                            {stamp && <p className="margin-bottom-10">Crédito consultado às {stamp}</p>}
                        </div>
                        {
                            (context.getSub_business_segment === "Two Wheeler" || context.getSub_business_segment === "VA Distributor")   &&
                            <div className="credito">
                                <label className="margin-bottom-10">Condição de Pagamento</label>
                                <input className="margin-bottom-10" placeholder="Carregando..." disabled={true} value={credit.condition} />
                            </div>
                        }
                        <div className="credito">
                            <label className="margin-bottom-10">Limite de Crédito</label>
                            <input placeholder="Carregando..." disabled={true} value={credit.available && GlobalFuncs.priceToReal(credit.limit)} />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Financeiro;