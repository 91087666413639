import { useEffect, useState } from 'react';
import './style.css';
import TableRow from 'components/TableRow';
import { useLanguage } from '../../context/LanguageContext';


function ProductTables(props) {

    const [tableNum, setTableNum] = useState(0);
    const [searchVal, setSearchVal] = useState('');
    const { translate } = useLanguage();

    useEffect(() => {
        console.log(props)
        if (!props.specifications && !props.applications) return;
        Object.entries(props.specifications).length > 0 && Object.entries(props.applications).length > 0 ?
            setTableNum(2) :
            Object.entries(props.specifications).length > 0 || Object.entries(props.applications).length > 0 ?
                setTableNum(1) :
                setTableNum(0);
    }, [props])

    const searchApplications = (e) => {
        setSearchVal(e.currentTarget.value.toUpperCase());
    }

    return (
        <div className="tables-wrapper flex-row">
            {props.specifications && (Object.entries(props.specifications).length > 0 &&
                (<div className="product-table-wrapper" style={{ 'width': `${tableNum === 1 ? '100%' : '44%'}` }}>
                    <h3 className="margin-bottom-30">{translate('manufacturerSpecifications')}</h3>
                    <div className="product-table-scroller">
                        <table>
                            <tbody>
                                {Object.entries(props.specifications).map((el, id) => (
                                    <TableRow key={`${el[0] + el[0] + id}`} content={el} expandable={false} />
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>)
            )
            }
            {props.applications && (Object.entries(props.applications).length > 0 &&
                (<div className="product-table-wrapper" style={{ 'width': `${tableNum === 1 ? '100%' : '50%'}` }}>
                    <h3 className="margin-bottom-30">{translate('applications')}</h3>
                    <input className="search-applications" type="text" placeholder={translate('searchApplication')} onChange={(e) => searchApplications(e)} />
                    <div className="product-table-scroller-short">
                        <table>
                            <tbody>
                                {Object.entries(props.applications).map((el, id) => (
                                    <TableRow key={`${el[0] + el[0] + id}`} content={el} expandable={true} search={searchVal} />
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>)
            )
            }
            {props.details &&
                (<div className="product-table-wrapper margin-top-30" style={{ 'width': `${tableNum === 1 ? '100%' : '44%'}` }}>
                    <h3 className="margin-bottom-30">Informações Complementares</h3>
                    <div className="product-table-scroller">
                        <table>
                            <tbody>
                            {props.details.map((details, index) => {
                               return  <TableRow key={[details.key,details.value]} content={[details.key,details.value]} expandable={false}/>

                            })
                        }
                                
                            </tbody>
                        </table>
                    </div>
                </div>)
            
            }
            {props.reference && props.reference.length > 0 &&
                (<div className="product-table-wrapper margin-top-30" style={{ 'width': `${tableNum === 1 ? '100%' : '50%'}` }}>
                    <h3 className="margin-bottom-30">Equivalências</h3>
                    <div className="product-table-scroller">
                        <table>
                            <tbody>
                            {props.reference.map((reference, index) => {
                               return  <TableRow key={[reference.DescricaoFabricante,reference.NumeroReferencia]} content={[reference.DescricaoFabricante,reference.NumeroReferencia]} expandable={false}/>

                            })

                            }
                            {/* <TableRow key={["Cod. Barras","7316572027141"]} content={["Cod. Barras","7316572027141"]} expandable={false}/>
                                <TableRow key={["FAG","K 11590.11520"]} content={["FAG","K 11590.11520"]} expandable={false}/>
                                <TableRow key={["IRB","IR12590"]} content={["IRB","IR12590"]} expandable={false}/>
                                <TableRow key={["NSK","11590/11520"]} content={["NSK","11590/11520"]} expandable={false}/>
                                <TableRow key={["Peer","11590-11520"]} content={["Peer","11590-11520"]} expandable={false}/>
                                <TableRow key={["Timken","11590 / 11520"]} content={["Timken","11590 / 11520"]} expandable={false}/> */}
                            </tbody>
                        </table>
                    </div>
                </div>)
            
            }
        </div>
    )
}

export default ProductTables;