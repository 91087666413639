import { useContext } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import GlobalFuncs from 'utils/GlobalFuncs';
import Rating from 'components/Rating';
import Loader from 'components/Loader';
import { AppContext } from 'utils/ContextProvider';

function ProductBox(props) {
    
    const context = useContext(AppContext);
    return (
        props.loader === true ?
            <div className="product-box" style={{ 'position': 'relative' }}>
                <Loader light={true} />
            </div>
            :
            <Link className="reset" to={`/produto/${GlobalFuncs.cleanString(props.name)}/${props.productId}`}>
                <div className={`product-box ${props.classes}`} data-id={props.productId}>
                    <div className='image-wrapper'>
                        <img src={props.image ? props.image : require("../../assets/images/placeholder.png").default} alt={props.name} onError={(event) => event.target.src = require("../../assets/images/placeholder.png").default} />
                    </div>
                    <div className='info-wrapper'>
                        <p className="limiter">{props.name}</p>
                        <Rating product={props.product} reviews="130" width="12" height="11" fontSize="12" />
                        <p className="price" style={{ 'color': context.getLogin ? 'black' : '#232F3E' }}>{context.getLogin ? GlobalFuncs.priceToReal(props.price) : 'Entrar para ver preço'}</p>
                    </div>
                </div>
            </Link>
    )
}

export default ProductBox;