import { useContext, useEffect, useState } from 'react';
import './style.css';
import Rating from 'components/Rating';
import GlobalFuncs from 'utils/GlobalFuncs';
import Loader from 'components/Loader';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import { useLanguage } from '../../context/LanguageContext';

function ProductInfo(props) {
    const { translate } = useLanguage();
    const context = useContext(AppContext);
    const [taxInfo, setTaxInfo] = useState(false);
    const [netPriceDescription, setNetPriceDescription] = useState(false);
    const [grossPriceDescription, setGrossPriceDescription] = useState(false);
    const [finalPriceDescription, setFinalPriceDescription] = useState(false);

    const showTaxInfo = async (cancelToken) => {
        setTaxInfo(true)
    }
    const hideTaxInfo = async (cancelToken) => {
        setTaxInfo(false)
    }

    const showNetPriceDescription = async (cancelToken) => {
        setNetPriceDescription(true)
        setGrossPriceDescription(false)
        setFinalPriceDescription(false)
    }

    const hideNetPriceDescription = async (cancelToken) => {
        setNetPriceDescription(false)
    }

    const showGrossPriceDescription = async (cancelToken) => {
        setNetPriceDescription(false)
        setGrossPriceDescription(true)
        setFinalPriceDescription(false)
    }

    const hideGrossPriceDescription = async (cancelToken) => {
        setGrossPriceDescription(false)
    }

    const showFinalPriceDescription = async (cancelToken) => {
        setNetPriceDescription(false)
        setGrossPriceDescription(false)
        setFinalPriceDescription(true)
    }

    const hideFinalPriceDescription = async (cancelToken) => {
        setFinalPriceDescription(false)
    }

    return (
        Object.keys(props.product).length ?
            <div className="product-info-wrapper">
                <h2 className="margin-bottom-10">
                    {`${props.product.name}`}
                </h2>
                <p className="brand-name margin-bottom-10">
                    {translate('brand')}: SKF
                </p>
                <Rating status="enabled" name="enabled" product={props.product} reviews="130" width="20" height="20" fontSize="14" />
                <hr className="margin-bottom-20 margin-top-10" />
                {context.getSub_business_segment === "Private Label" ?
                    <>
                        <div className='flex-row-nowrap'>
                            <p className="price margin-bottom-20" style={{ 'color': context.getLogin ? 'black' : '#232F3E' }}>
                                Net Price {context.getLogin ? GlobalFuncs.priceToReal((props.product.price - props.product.vlIcms - props.product.vlIpi - props.product.vlIStf - props.product.vlPis - props.product.vlCofins)) : 'Entrar para ver preço'}
                            </p>
                            <img className='info-img pointer'
                                src={require("../../assets/images/info-icon-lg.png").default}
                                alt=""
                                onMouseEnter={showNetPriceDescription}
                                onMouseLeave={hideNetPriceDescription} />
                            {
                                netPriceDescription &&
                                <>
                                    <div className="price-desc">O Preço Net é o valor do produto sem impostos</div>
                                </>
                            }
                        </div>
                        <div className='flex-row-nowrap'>
                            <p className="price margin-bottom-20" style={{ 'color': context.getLogin ? 'black' : '#232F3E' }}>
                                Gross Price {context.getLogin ? GlobalFuncs.priceToReal((props.product.price - props.product.vlIcms - props.product.vlIpi - props.product.vlIStf)) : 'Entrar para ver preço'}
                            </p>
                            <img className='info-img pointer'
                                src={require("../../assets/images/info-icon-lg.png").default}
                                alt=""
                                onMouseEnter={showGrossPriceDescription}
                                onMouseLeave={hideGrossPriceDescription} />
                            {
                                grossPriceDescription &&
                                <>
                                    <div className="price-desc">O Preço Gross é o Preço Net acrescido dos impostos PIS e COFINS</div>
                                </>
                            }
                        </div>
                        <div className='flex-row-nowrap'>
                            <p className="price margin-bottom-20" style={{ 'color': context.getLogin ? 'black' : '#232F3E' }}>
                                Final Price {context.getLogin ? GlobalFuncs.priceToReal(props.product.price) : 'Entrar para ver preço'}
                            </p>
                            <img className='info-img pointer'
                                src={require("../../assets/images/info-icon-lg.png").default}
                                alt=""
                                onMouseEnter={showFinalPriceDescription}
                                onMouseLeave={hideFinalPriceDescription} />
                            {
                                finalPriceDescription &&
                                <>
                                    <div className="price-desc">O Preço Final é o Preço NET acrescido de todos os impostos (PIS, COFINS, ICMS, ICMS-ST, IPI e Imposto de Auxílio à Pobreza)</div>
                                </>
                            }
                        </div>
                    </>
                    :
                    <div className='flex-row-nowrap'>
                        <p className="price margin-bottom-20" style={{ 'color': context.getLogin ? 'black' : '#232F3E' }}>
                            {context.getLogin ? GlobalFuncs.priceToReal(props.product.price) : 'Entrar para ver preço'}
                        </p>
                        <img className='info-img pointer'
                            src={require("../../assets/images/info-icon-lg.png").default}
                            alt=""
                            onMouseEnter={showTaxInfo}
                            onMouseLeave={hideTaxInfo} />
                        {taxInfo &&
                            <div className='taxa-info-box'>
                                <div className='container mt-0'>
                                    <p>PIS</p>
                                    <p className='price'>{`${props.product.vlaliqPis}%`}</p>
                                </div>
                                <div className='container'>
                                    <p>COFINS</p>
                                    <p className='price'>{`${props.product.vlaliqCofins}%`}</p>
                                </div>
                                <div className='container'>
                                    <p>ICMS</p>
                                    <p className='price'>{`${props.product.aliqIcms}%`}</p>
                                </div>
                                <div className='container'>
                                    <p>ICMS - ST</p>
                                    <p className='price'>{`${props.product.aliqStf}%`}</p>
                                </div>
                                <div className='container'>
                                    <p>IPI</p>
                                    <p className='price'>{`${props.product.aliqIpi}%`}</p>
                                </div>
                            </div>
                        }
                    </div>


                }
                <h3 className="margin-bottom-20">
                    {translate('aboutProduct')}
                </h3>
                <p>
                    {props.product.description}
                </p>
            </div> :
            <div className="product-info-wrapper" style={{ 'position': 'relative' }}>
                <Loader light={true} />
            </div>
    )
}

export default ProductInfo;