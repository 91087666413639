import { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import GlobalFuncs from 'utils/GlobalFuncs';
import Carousel from 'components/Carousel';
import OrderBox from 'components/OrderBox';
import OrderBoxMobile from 'components/OrderBoxMobile';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import Swal from 'sweetalert2';

function Orders() {

    const history = useHistory();
    const context = useContext(AppContext);
    const [mobile, setMobile] = useState(window.innerWidth <= 555);
    const [recommended, setRecommended] = useState([]);
    const [suggestionList, setSuggestionList] = useState([]);
    const [tempSuggestionList, setTempSuggestionList] = useState([]);
    const searchField = useRef();
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [orders, setOrders] = useState(null);
    const [originalList, setOriginalList] = useState(null);
    const [predBox, setPredBox] = useState(false)
    const statusRanking = new Map([
        ['RPI', [0, 'Processo de Faturamento']],
        ['PIC', [1, 'Em Separação']],
        ['ALL', [2, 'Processando Pagamento']],
        ['SHS', [3, 'Importação em Trânsito']],
        ['ENT', [4, 'Sem Previsão de Entrega']],
        ['BOK', [5, 'Entrega Programada']],
        ['RBK', [6, 'Entrega Reprogramada']],
        ['REJ', [7, 'Rejeitado']],
        ['INV', [8, 'Faturado']],
        ['CAN', [9, 'Cancelado']]
    ]);

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Pedidos',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        const cancelToken = API.cancelToken();
        hotjar.initialize('3361828')
        ReactGA.pageview(window.location.pathname);
        API.getOrders(cancelToken).then(res => {
            if (res.data === "") {
                processList([]);
            } else {
                processList(res.data);
            }
        }).catch(err => {
            console.log(err);
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Erro.',
                text: err,
                // text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(sel => {
                window.location.href = "/"
            })
        });

        API.getRecommendedProducts(cancelToken).then(res => {
            setRecommended(res.data.products);
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.message);
        });
        API.isActive().then(none => {
            context.setAuthCompanies(none.data.authorized_companies);
        }).catch(err => {
            context.logOut()
        });

        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    const processList = (list) => {

        let tempOrders = [];
        let suggestion = []

        list.forEach(e => {
            let prod = new Map();
            let status = "Em Processamento";
            let skf_order_id = e.skf_order_id ? e.skf_order_id : "-";
            let client_oder_id = e.client_order_id ? e.client_order_id : "-";
            var count_finished = null;
            var count_cancelled = null;
            var count_delivered = null;
            var could_returned = null;
            var search_string = `${skf_order_id}|`
            suggestion.push(`${skf_order_id}`.trim())
            e.lines.length > 0 && e.lines.forEach(el => {
                search_string += `${el.NF.number}|`
                suggestion.push(el.NF.number)
                if (el.delivery == "Entregue") count_delivered++;
                if (el.delivery == "Devolvido") could_returned++;
                if (el.delivery == "Devolvido Parcial") could_returned++;
                if (statusRanking.get(el.status_code)[0] == 8) {
                    count_finished++;
                }
                if (statusRanking.get(el.status_code)[0] == 7 || statusRanking.get(el.status_code)[0] == 9) {
                    count_cancelled++;
                }
                let id = el.product.product_id;
                if (prod.has(id)) {
                    let old = prod.get(id);
                    prod.set(id, { ...old, quantity: old.quantity + el.quantity });
                } else {
                    prod.set(id, { quantity: el.quantity, name: el.product.name, image: el.product.image_url && el.product.image_url.includes("http") ? el.product.image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${el.product.image_url}` });
                }
            });
            e.products.forEach(product => {
                search_string += `${product.skf_product_id}|`
                suggestion.push(product.skf_product_id)
            });
            if (e.lines.length > 0) {
                if (e.lines.length == count_delivered || e.lines.length == could_returned) {
                    status = "Pedido Concluído"
                }
                else if (count_delivered > 0 && (count_cancelled + count_delivered + could_returned) >= e.lines.length) {
                    status = "Pedido Concluído"
                }
                else if (count_cancelled == e.lines.length && e.lines.length > 0) {
                    status = "Pedido Cancelado"
                }
                else {
                    if ((count_cancelled + count_delivered + could_returned) != e.lines.length) {
                        status = "Em Processamento"
                    }
                }
            }
            // status = ( (count_finished === e.lines.length) || ((count_finished + count_cancelled == e.lines.length) && count_finished > 0 )) ? 'Pedido Concluído' : 'Em Processamento'
            // if (count_cancelled === e.lines.length) status = 'Pedido Cancelado'
            if (e.is_processed === 3) status = "Aguardando Pagamento"
            if (e.is_processed === 5) status = "Pagamento em Análise"
            if (e.is_processed === -1) status = "Pedido Cancelado"
            if (e.is_processed === -2) status = "Pagamento Não Autorizado"
            if (e.is_processed === -3) status = "Pagamento Expirado"
            let tempName = '';
            let tempImage = '';
            prod.forEach((obj, key) => {
                if (e.lines.length > 1) {
                    tempName = `${e.products.length} diferentes produtos`
                } else {
                    tempName = obj.name
                }
                if (!tempImage || obj.quantity > tempImage.quantity) tempImage = { image: obj.image, quantity: obj.quantity };
            });

            let name = tempName;
            let image = tempImage.image;

            if (e.lines.length === 0) {
                // status = 'Pedido Recebido'
                name = e.products.length > 1 ? `${e.products.length} diferentes produtos` : e.products[0].skf_product_id
                image = e.products[0].image_url && e.products[0].image_url.includes("http") ? e.products[0].image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${e.products[0].image_url}`
            } else {
                image = e.products[0].image_url && e.products[0].image_url.includes("http") ? e.products[0].image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${e.products[0].image_url}`
            }

            suggestion = suggestion.filter((entry) => entry !== undefined && entry.length >= 2);
            setSuggestionList([...suggestionList, ...suggestion].filter((value, index, self) => self.indexOf(value) === index))
            setTempSuggestionList(suggestionList)
            tempOrders.push({
                order_id: e.order_id,
                created_at: e.created_at,
                total: e.total_amount,
                skf_order_id: skf_order_id,
                client_order_id: client_oder_id,
                name: name,
                status: status,
                image: image,
                content: e.products,
                search_string: search_string
            });
        });
        setOriginalList(tempOrders);
    }

    //Filters
    useEffect(() => {
        if (!originalList) return;
        console.log("----- ORIGINAL LIST -------")
        console.log(originalList)
        console.log("----- ORIGINAL LIST -------")
        let tempOrders = [];
        //Search Filter
        let value = searchField.current.value;
        if (value !== '') {
            originalList.forEach(e => {
                if (e.search_string.toUpperCase().includes(value.toUpperCase())) {
                    tempOrders.push(e);
                }
            });
        } else {
            tempOrders = originalList
        }

        //Status Filter
        if (filter !== null) {
            tempOrders = tempOrders.filter(e => e.status.trim().toUpperCase() === filter.trim().toUpperCase())
        }

        setTempSuggestionList(suggestionList.filter((str) => str.includes(searchValue)))

        //Year Filters
        if (searchValue == undefined || searchValue == "") {
            tempOrders = tempOrders.filter(e => parseInt(GlobalFuncs.getYear(e.created_at)) === parseInt(year));
        }
        setOrders(tempOrders);
    }, [originalList, filter, year, searchValue]);

    window.addEventListener('resize', function (event) {
        setMobile(window.innerWidth <= 555);
    }, true);

    const updateSearchValue = (e, el) => {
        e.preventDefault()
        el ? searchField.current.value = el.trim() : null
        searchField.current.focus()
        // setPredictions([searchField.current.value])
        setSearchValue(searchField.current.value)
        setPredBox(false)
    }


    return (
        <div className="order-pages page-wrapper-white one-whole margin-top-50">
            {predBox &&
                <div className='overlay' onClick={(e) => e.relatedTarget === null ? setPredBox(false) : null}></div>
            }
            <div className="section-wrapper-small one-whole">
                <div className="flex-row space-between align-center margin-bottom-60">
                    <h3>Meus Pedidos</h3>
                    <div className='input-holder' onBlur={(e) => e.relatedTarget === null ? setPredBox(false) : null} onFocus={() => setPredBox(true)}>
                        <form className="flex-row-nowrap">
                            <input type="text" ref={searchField} placeholder="Buscar Pedido" onChange={(e) => setSearchValue(e.currentTarget.value)} />
                        </form>
                        <ul className="predictions-holder" >
                            {
                                predBox && tempSuggestionList && tempSuggestionList.length > 0 ? tempSuggestionList.map((el, index) => (
                                    <li key={index + "prediction"}><button onClick={(e) => updateSearchValue(e, el)} >{el}</button></li>
                                )) :
                                    <p></p>
                            }
                        </ul>
                    </div>
                </div>

                <div className="tab-wrapper flex-row-nowrap">
                    <div className="tab" onClick={() => setFilter(null)}>
                        <p className={filter === null ? 'active' : null} tabIndex="2">Pedidos</p>
                    </div>
                    {/* <div className="tab" onClick={() => setFilter('Pedido Recebido')}>
                        <p className={filter === 'Recebido' ? 'active' : null} tabIndex="3">Recebido</p>
                    </div> */}
                    <div className="tab" onClick={() => setFilter('Em Processamento')}>
                        <p className={filter === 'Em Processamento' ? 'active' : null} tabIndex="3">Em Processamento</p>
                    </div>
                    <div className="tab" onClick={() => setFilter('Pedido Concluído')}>
                        <p className={filter === 'Pedido Concluído' ? 'active' : null} tabIndex="4">Concluído</p>
                    </div>
                </div>
                {
                    searchValue == "" ?
                        <div className="number-year margin-top-40 margin-bottom-30">
                            <span className="number">{orders ? orders.length : 0} {orders ? (orders.length === 1 ? 'Pedido' : 'Pedidos') : 'Pedidos'}</span>
                            <span> feitos em </span>
                            <select name="" id="" onChange={(e) => setYear(e.currentTarget.value)}>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                            </select>
                        </div>
                        :
                        <div className="number-year margin-top-40 margin-bottom-30">
                            {/* <span className="number">{orders ? orders.length : 0} {orders ? (orders.length === 1 ? 'Pedido' : 'Pedidos') : 'Pedidos'}</span>
                            <span> encontrados </span> */}
                            <span className="number">{orders.length == 0 ? "Nenhum Resultado Encontrado" : orders.length}</span>
                            <span> {orders.length == 0 ? "" : ' pedido(s) encontrado(s)'} </span>

                        </div>
                }
                {orders ?
                    orders.map(e => (
                        mobile ? <OrderBoxMobile key={e.order_id} order={e} /> : <OrderBox key={e.order_id} order={e} />
                    )) :
                    mobile ? <OrderBoxMobile loader={true} /> : <OrderBox loader={true} />
                }
                <div className="one-whole position-relative">

                </div>
            </div>
            <div className="section-wrapper one-whole">
                <div className="margin-top-50">
                    <hr />
                </div>
                {
                    context && context.getSub_business_segment !== "Private Label" &&
                    <Carousel header="Produtos Recomendados" list={recommended} />

                }
            </div>
        </div>
    )
}

export default Orders;