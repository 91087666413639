import './style.css';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import GlobalFuncs from 'utils/GlobalFuncs';

function OrderBoxMobile(props) {
    
    return (
        props.loader === true?
        <div className="order-listing-mobile margin-top-30 margin-bottom-20" style={{'position':'relative', 'border': '0', 'height': '80px'}}>
            <Loader light={true}/>
        </div>
        :
        <Link className="reset" to={`/pedido/${props.order.order_id}`}>
            <div className="order-listing-mobile flex-row-nowrap space-between align-center margin-bottom-20">
                <div className="flex-row-nowrap align-center">
                <img src={props.order.image} alt={props.order.order_id} />
                <div>
                    <p>Nº {props.order.skf_order_id}</p>
                    <p>{props.order && GlobalFuncs.databaseToDate(props.order.created_at)}</p>
                </div>
                </div>
                <img className="arrow" src={require('../../assets/images/right.png').default} alt="next" />
            </div>
        </Link>
    )
}

export default OrderBoxMobile;