import './style.css';
import { useEffect, useState, useContext, useRef } from 'react';
import API from 'utils/API';
import { AppContext } from 'utils/ContextProvider';

function About() {
    const context = useContext(AppContext);

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Sobre o AZPartes',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        API.isActive().then(none => {
            
        }).catch(err => {
            context.logOut()
        });
    }, [])

    return (
        <div className="about-page margin-top-40 section-wrapper">
            <h2 className="margin-bottom-20">SOBRE O SKF CONECTA</h2>
            <p>O SKF Conecta é o primeiro e-commerce oficial da SKF do Brasil. Por meio dessa plataforma, nossos clientes CNPJ podem comprar online, rolamentos originais e autopeças SKF diretamente da SKF. O esforço da SKF está na capacidade de desenvolver continuamente novas tecnologias e, em seguida, utilizá-las para criar produtos que oferecem vantagens competitivas para os nossos clientes.</p><br />
            <p>O processo de digitalização da SKF começou há alguns anos e faz parte de um dos pilares da Tríade da Economia Sustentável da empresa, a Economia Circular. O enfoque da SKF em desenvolvimento de tecnologias atualmente está na redução do impacto ambiental de ativos durante seu ciclo de vida, tanto em nossas operações quanto nas dos clientes. Para esse futuro digital, a SKF oferece serviços de última geração para o acompanhamento de equipamentos rotativos com o objetivo de ser também uma economia colaborativa. </p><br />
            <p>A SKF é uma das maiores indústrias de rolamentos do mundo e líder no segmento. A empresa nasceu na Suécia em 1907 e chegou ao Brasil em 1915. Uma das primeiras revoluções da SKF, foi a invenção do rolamento autocompensador de duas carreiras de esferas, o que permitiu maior durabilidade, economia e redução de uso dos insumos para as máquinas das indústrias na época. Desde então, a SKF está presente em 130 países e possui experiência prática em mais de 40 setores, como automotivo, construção, metalúrgico, ferroviário, agricultura, papel e celulose, energia eólica, entre outros. </p><br />
            <p>Mais que rolamentos, a SKF oferece um mundo de rotação confiável. Desenvolvemos produtos como vedações, lubrificação, ferramentas de monitoramento, soluções para transmissão e peças de reposição automotiva. E também serviços, como o de repotencialização de rolamentos, análise de óleo, monitoramento de equipamentos e a mais nova tecnologia de reutilização de lubrificantes industriais, o RecondOil. A inovação, qualidade, simplicidade, velocidade e sustentabilidade são o que movem a SKF e fazem da companhia uma referência de cuidado com os ativos dos clientes.  </p><br />
            <div className="one-whole text-center margin-top-30 margin-bottom-40">
                <iframe className="video margin-auto margin-top-30 margin-bottom-50" src="https://www.youtube.com/embed/3vnXx_mK7sk" frameborder="0"></iframe>
            </div>
        </div>
    )

}

export default About;