import { useRef, useState, useEffect } from 'react';
import './style.css';
import ProductBox from 'components/ProductBox';
import API from 'utils/API';

function Carousel(props) {

    const [lastItemView, setLastItemView] = useState(null)
    const el = useRef();
    const placeholder = [0, 1, 2, 3, 4, 5]
    const [rating, setRating] = useState(0);

    useEffect(() => {
        if (props.list.length > 0 && lastItemView == null) {
            setLastItemView(props.list.length > 5 ? 5 : props.list.length)
        }
    }), [props.list.length]

    const scrollRight = () => {
        let count = 0;
        setLastItemView(lastItemView + 1)
        let timer = setInterval(function () {
            el.current.scrollLeft = el.current.scrollLeft + 5;
            count++;
            if (count === 44) clearInterval(timer)
        }, 1);
    }

    const scrollLeft = () => {
        let count = 0;
        setLastItemView(lastItemView - 1)
        let timer = setInterval(function () {
            el.current.scrollLeft = el.current.scrollLeft - 5;
            count++;
            if (count === 44) clearInterval(timer)
        }, 1);
    }

    return (
        <div className="carousel-wrapper one-whole">
            <h3 className="margin-bottom-20">{props.header}</h3>
            <div className="scroller">
                {lastItemView > 5 && <div className="prev" onClick={() => scrollLeft()}>
                    <img alt="left" src={require('../../assets/images/left.png').default} />
                </div>}
                {(lastItemView != props.list.length && props.list.length > 5) && <div className="next" onClick={() => scrollRight()}>
                    <img alt="right" src={require('../../assets/images/right.png').default} />
                </div>}
                <div className="flex-row flex-carousel" ref={el}>
                    {props.list.length > 0 ? props.list.map((e, id) => (
                        <ProductBox key={e.product_id + e.id + id.toString()} product={e} name={e.name} productId={e.product_id} price={e.price} image={e.image_url.includes("http") ? e.image_url : `https://azpartes-catalog-images-production.s3.amazonaws.com/${e.image_url}`} />
                    )) :
                        placeholder.map((e, id) => (
                            <ProductBox key={e.product_id + e.id + id.toString()} loader={true} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Carousel;