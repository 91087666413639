import { useState, useRef, useContext, useEffect } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import Loader from 'components/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

function Login() {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [success, setSuccess] = useState(false);
    // const [strength, setStrength] = useState(null);
    const email = useRef();



    useEffect(() => {
        if (token === false) history.push('/')
    }, [token])

    const recover = (e) => {
        e.preventDefault();
        setError(null);
        if (!email.current.value) return;
        setLoading(true);
        const cancelToken = API.cancelToken();
        API.recoverPassword(cancelToken, email.current.value).then(res => {
            setLoading(false);
            if (res.data.status === "NOT_OK") {
                setError(res.data.message);
            }
            else {
                setSuccess(true);
                Swal.fire({
                    title: 'Email de recuperação enviado com sucesso.',
                    text: '',
                    confirmButtonColor: "#131921",
                    confirmButtonText: 'OK'
                }).then((result) => {
                    history.push('/login')
                })
            }
        }).catch(err => {
            if(err.message==='cancelling') return
            console.log(err.response);
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
        return () => {
            API.cancel(cancelToken);

        }
    }

    return (
        <div className="change-password-page">
            <div className="form-container">
                <div className={`section-one b2b-color`}>
                    <Link className="reset" to={'/home'}>
                        <h1 className="margin-bottom-20">AZPartes.com</h1>
                    </Link>
                </div>
                <div className="section-two">
                    <form>
                        <h2 className="margin-bottom-30 title">Recuperar Senha</h2>
                        {/* <p className="description">Valide suas informações para acessar o <strong>AZPartes.com</strong>, e-commerce B2B da SKF.</p> */}
                        <div>
                                <p className="label">Email</p>
                                <div className={`pass-holder input ${error ? "error-input" : ""}`}>
                                    <input className={`mask-password`} type="text" ref={email} placeholder="Digite seu email"/>
                                </div>
                                <p className="margin-bottom-30 info-message margin-top-10">Você receberá um email com as instruções de recuperação de senha.</p>
                                <a className="margin-bottom-30 info-message margin-top-30" href="mailto:suporte@azpartes.com">Precisa de ajuda?</a>
                                {error &&
                                    <p className="error margin-bottom-10">
                                       {error}
                                    </p>
                                }
                            </div>
                            {
                            loading &&
                            <div className="loader-wrapper">
                                <div className='rotator-wrapper'>
                                    <Loader color="#b0b1b2" />
                                </div>
                            </div>
                        }
                        <input type="submit" className={`action-button margin-bottom-20 margin-top-20 input ${success ? "disabled" : ""}`} onClick={(e) => recover(e)} value="Enviar" />
                        
                        {/* <p className='text-center pointer' onClick={() => history.push('/')}>Voltar ao site</p> */}

                        {/* <Link className="reset" to={'/'}>
                        <p>Outros problemas com o acesso</p>
                    </Link> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;