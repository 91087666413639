import React from 'react';
import './style.css';
import IconText from 'components/IconText';
import { useLanguage } from '../../context/LanguageContext';

function MiniMenu() {
    const { translate } = useLanguage();
    return (
        <div className="minimenu-wrapper one-quarter padding30">
            <h3>{translate('myAccountLabel')}</h3>
            <br />
            <div className="flex-row flex-center minimenu-max">
                <div className="minimenu-group">
                    <div className="minimenu-item">
                        <IconText image={require('../../assets/images/orders.png').default} text="Meus Pedidos" href={'/pedidos'} />
                    </div>
                    <div className="minimenu-item">
                        <IconText image={require('../../assets/images/cart.png').default} text="Carrinho" href={'/cart'} />
                    </div>
                </div>
                <div className="minimenu-group">
                    <div className="minimenu-item">
                        <IconText image={require("../../assets/images/location.png").default} text="Compras em lote" href={'/lote'} />
                    </div>
                    <div className="minimenu-item">
                        <IconText image={require("../../assets/images/store.png").default} text="Minha Conta" href={'/account'} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MiniMenu;