const translations = {
    "en": {
        "loadingInformation":"Loading information...",
        "productsLabel":"Products",
        "estimatedDeliveryLabel":"Estimated Delivery Time",
        "finalizeOrderLabel":"Finalize your order",
        "deliveryAddressLabel":"Delivery Address",
        "chargingAddressLabel":"Charging Address",
        "paymentMethodLabel":"Payment Method",
        "selectPaymentMethodLabel":"Select a payment method",
        "confirmOrderLabel":"Confirm Order",
        "confirmOrderMessage":"By placing your order, you agree to the AZPartes Terms of Use. Please check the Privacy Notice, Cookies Notice and Interest-Based Ads Notice.",
        "orderSummary":"Order Summary",
        "shippingLabel":"Shipping",
        "availableCredit":"Available Credit",
        "insuficientCreditMessage":"Insufficient Balance. Payment will be made via credit card",
        "paymentConditionLabel":"Payment Condition",
        "emptyCart":"Empty Cart",
        "noInformation":"No information",
        "sendLabel":"Send",
        "checkoutLabel":"Checkout",
        "keepBuying":"Keep Buying",
        "excludeItemLabel":"Delete Item",
        "multipleRuleLabel":"Product with multiple rule",
        "netPrice":"Net Price",
        "grossPrice":"Gross Price",
        "finalPrice":"Final Price",
        "netComment":"The Net Price is the product value without taxes",
        "grossComment":"The Gross Price is the Net Price plus PIS and COFINS taxes",
        "finalComment":"The Final Price is the Net Price plus all taxes (PIS, COFINS, ICMS, ICMS-ST, IPI and Poverty Aid Tax)",
        "totalLabel":"Total",
        "shoppingCart":"Shopping Cart",
        "selectedItems":"Selected Items",
        "uncheckedItems":"Uncheck all items",
        "checkAllItems":"Check all items",
        "excludeSelected":"Exclude selected",
        "batchPurchases":"Batch Purchases",
        "quickMenu":"Quick Menu",
        "myAccountLabel":"My Account",
        "searchPlaceholder":"Search by name or code",
        "searchLabel":"Search",
        "cartLabel":"Cart",
        "myOrders":"My Orders",
        "searchBy":"Search by",
        "category":"Category",
        "licensePlate":"License Plate",
        "line":"Line",
        "manufacturer":"Manufacturer",
        "vehicle":"Vehicle",
        "engine":"Engine",
        "cleanLabel":"Clean",
        "highlightedProducts":"Highlighted Products",
        "recomendedTo":"Recommended to",
        "you":"you",
        "navigationHistory":"Navigation History",
        "noReviews":"No reviews",
        "loading":"Loading...",
        "review":"Review",
        "reviews":"Reviews",
        "backtotop":"Back to top",
        "organization":"Organization",
        "siteMap":"Site Map",
        "technicalSupport":"Technical Support",
        "contactUs":"Contact Us",
        "suggestions":"Suggestions and Improvements",
        "policies":"Policies",
        "exchangePolicy":"Exchange Policy",
        "termsOfUse":"Terms of Use",
        "privacy":"Privacy",
        "manufacturerSpecifications":"Manufacturer Specifications",
        "applications":"Applications",
        "searchApplication":"Search application",
        "aboutProduct":"About the product",
        "brand":"Brand",
        "quantity":"Quantity",
        "unitPrice":"Unit Price",
        "totalPrice":"Total Price",
        "status":"Status",
        "delivery":"Delivery",
        "piece":"piece",
        "pieces":"pieces",
        "addToCart":"Add to cart",
        "scheduleOrder":"Schedule order",
        "scheduledFor":"Scheduled for",
        "orderConfirmation":"Order/attendance confirmation subject to stock availability for the period.",
        "calculatingDeliveryTime":"Calculating delivery time...",
        "notifyByEmail":"Would you like to be notified by email when the unavailable quantity is back in stock?",
        "inStock":"In stock",
        "partiallyAvailable":"Partially Available",
        "unavailable":"Unavailable",
        "notifyMeWhenAvailable":"Notify me when available",
        "notificationActive":"Notification Active",
        "requestReceived":"Request received. We will respond to your request by email soon."
    },
    "pt": {
        "loadingInformation":"Carregando informações...",
        "productsLabel":"Produtos",
        "estimatedDeliveryLabel":"Prazo de Entrega Estimado",
        "finalizeOrderLabel":"Finalize seu pedido",
        "deliveryAddressLabel":"Endereço de Entrega",
        "chargingAddressLabel":"Endereço de Cobrança",
        "paymentMethodLabel":"Método de Pagamento",
        "selectPaymentMethodLabel":"Selecione um método de pagamento",
        "confirmOrderLabel":"Confirmar Pedido",
        "confirmOrderMessage":"Ao fazer seu pedido, você concorda com as Condições de Uso da AZPartes.Por favor verifique a Notificação de Privacidade, Notificação de Cookies e a Notificação de Anúncios Baseados em Interesse.",
        "orderSummary":"Resumo do Pedido",
        "shippingLabel":"Frete",
        "availableCredit":"Crédito disponível",
        "insuficientCreditMessage":"Saldo Insuficiente. Pagamento seguirá via cartão de crédito",
        "paymentConditionLabel":"Condição de Pagamento",
        "emptyCart":"Carrinho Vazio",
        "noInformation":"Sem informação",
        "sendLabel":"Send",
        "checkoutLabel":"Fechar Pedido",
        "keepBuying":"Continuar Comprando",
        "excludeItemLabel":"Exclude Item",
        "multipleRuleLabel":"Produto com regra de múltiplo",
        "netPrice":"Preço Net",
        "grossPrice":"Preço Gross",
        "finalPrice":"Preço Final",
        "netComment":"O Preço Net é o valor do produto sem impostos",
        "grossComment":"O Preço Gross é o Preço Net acrescido dos impostos PIS e COFINS",
        "finalComment":"O Preço Final é o Preço NET acrescido de todos os impostos (PIS, COFINS, ICMS, ICMS-ST, IPI e Imposto de Auxílio à Pobreza)",
        "totalLabel":"Total",
        "shoppingCart":"Carrinho de Compras",
        "selectedItems":"Itens Selecionados",
        "uncheckedItems":"Desmarcar todos os itens",
        "checkAllItems":"Selecionar todos os itens",
        "excludeSelected":"Excluir selecionados",
        "batchPurchases":"Compras em lote",
        "quickMenu":"Menu Rápido",
        "myAccountLabel":"Minha Conta",
        "searchPlaceholder":"Busque por nome ou código",
        "searchLabel":"Pesquisar",
        "cartLabel":"Carrinho",
        "myOrders":"Meus Pedidos",
        "searchBy":"Pesquisar por",
        "category":"Categoria",
        "licensePlate":"Placa",
        "line":"Linha",
        "manufacturer":"Montadora",
        "vehicle":"Veículo",
        "engine":"Motor",
        "cleanLabel":"Limpar",
        "highlightedProducts":"Produtos em Destaque",
        "recomendedTo":"Recomendados para",
        "you":"você",
        "navigationHistory":"Histórico de Navegação",
        "noReviews":"Sem avaliações",
        "loading":"Carregando...",
        "review":"Avaliação",
        "reviews":"Avaliações",
        "backtotop":"Voltar ao topo",
        "organization":"Organização",
        "siteMap":"Mapa do Site",
        "technicalSupport":"Suporte Técnico",
        "contactUs":"Fale Conosco",
        "suggestions":"Melhorias e Sugestões",
        "policies":"Políticas",
        "exchangePolicy":"Política de Troca",
        "termsOfUse":"Termos de Uso",
        "privacy":"Privacidade",
        "manufacturerSpecifications":"Especificações do Fabricante",
        "applications":"Aplicações",
        "searchApplication":"Buscar aplicação",
        "aboutProduct":"Sobre o produto",
        "brand":"Marca",
        "quantity":"Quantidade",
        "unitPrice":"Valor unitário",
        "totalPrice":"Valor total",
        "status":"Status",
        "delivery":"Entrega",
        "piece":"peça",
        "pieces":"peças",
        "addToCart":"Adicionar ao carrinho",
        "scheduleOrder":"Agendar pedido",
        "scheduledFor":"Agendado Para",
        "orderConfirmation":"Confirmação de pedido/atendimento sujeita a disponibilidade de estoque para o período.",
        "calculatingDeliveryTime":"Calculando prazo de entrega...",
        "notifyByEmail":"Deseja ser avisado por e-mail quando a quantidade indisponível estiver em estoque?",
        "inStock":"Em estoque",
        "partiallyAvailable":"Parcialmente Disponível",
        "unavailable":"Indisponível",
        "notifyMeWhenAvailable":"Avise-me quando chegar",
        "notificationActive":"Notificação Ativa",
        "requestReceived":"Solicitação recebida. Em breve, responderemos a sua solicitação por e-mail."
    },
    "es": {
        "loadingInformation":"Cargando información...",
        "productsLabel":"Productos",
        "estimatedDeliveryLabel":"Tiempo de Entrega Estimado",
        "finalizeOrderLabel":"Finalice su pedido",
        "deliveryAddressLabel":"Dirección de Entrega",
        "chargingAddressLabel":"Dirección de Facturación",
        "paymentMethodLabel":"Método de Pago",
        "selectPaymentMethodLabel":"Seleccione un método de pago",
        "confirmOrderLabel":"Confirmar Pedido",
        "confirmOrderMessage":"Al realizar su pedido, usted acepta los Términos de Uso de AZPartes. Por favor, consulte el Aviso de Privacidad, el Aviso de Cookies y el Aviso de Anuncios Basados en Intereses.",
        "orderSummary":"Resumen del Pedido",
        "shippingLabel":"Envío",
        "availableCredit":"Crédito disponible",
        "insuficientCreditMessage":"Saldo insuficiente. El pago se realizará con tarjeta de crédito",
        "paymentConditionLabel":"Condición de Pago",
        "emptyCart":"Carrito Vacío",
        "noInformation":"Sin información",
        "sendLabel":"Enviar",
        "checkoutLabel":"Checkout",
        "keepBuying":"Seguir comprando",
        "excludeItemLabel":"Exclude Item",
        "multipleRuleLabel":"Producto con regla de múltiplo",
        "netPrice":"Precio Neto",
        "grossPrice":"Precio Bruto",
        "finalPrice":"Precio Final",
        "netComment":"El Precio Neto es el valor del producto sin impuestos",
        "grossComment":"El Precio Bruto es el Precio Neto más los impuestos PIS y COFINS",
        "finalComment":"El Precio Final es el Precio Neto más todos los impuestos (PIS, COFINS, ICMS, ICMS-ST, IPI y Impuesto de Ayuda a la Pobreza)",
        "totalLabel":"Total",
        "shoppingCart":"Carrito de Compras",
        "selectedItems":"Ítems Seleccionados",
        "uncheckedItems":"Desmarcar todos los ítems",
        "checkAllItems":"Seleccionar todos los ítems",
        "excludeSelected":"Excluir seleccionados",
        "batchPurchases":"Compras en lote",
        "quickMenu":"Menú Rápido",
        "myAccountLabel":"Mi Cuenta",
        "searchPlaceholder":"Buscar por nombre o código",
        "searchLabel":"Buscar",
        "cartLabel":"Cesta",
        "myOrders":"Mis Pedidos",
        "searchBy":"Buscar por",
        "category":"Categoría",
        "licensePlate":"Placa",
        "line":"Línea",
        "manufacturer":"Fabricante",
        "vehicle":"Vehículo",
        "engine":"Motor",
        "cleanLabel":"Limpiar",
        "highlightedProducts":"Productos Destacados",
        "recomendedTo":"Recomendados para",
        "you":"usted",
        "navigationHistory":"Historial de Navegación",
        "noReviews":"Sin evaluaciones",
        "loading":"Cargando...",
        "review":"Evaluación",
        "reviews":"Evaluaciones",
        "backtotop":"Volver arriba",
        "organization":"Organización",
        "siteMap":"Mapa del Sitio",
        "technicalSupport":"Soporte Técnico",
        "contactUs":"Contáctenos",
        "suggestions":"Mejoras y Sugerencias",
        "policies":"Políticas",
        "exchangePolicy":"Política de Cambio",
        "termsOfUse":"Términos de Uso",
        "privacy":"Privacidad",
        "manufacturerSpecifications":"Especificaciones del Fabricante",
        "applications":"Aplicaciones",
        "searchApplication":"Buscar aplicación",
        "aboutProduct":"Sobre el producto",
        "brand":"Marca",
        "quantity":"Cantidad",
        "unitPrice":"Valor unitario",
        "totalPrice":"Valor total",
        "status":"Estado",
        "delivery":"Entrega",
        "piece":"pieza",
        "pieces":"piezas",
        "addToCart":"Agregar al carrito",
        "scheduleOrder":"Programar pedido",
        "scheduledFor":"Programado Para",
        "orderConfirmation":"Confirmación de pedido/atención sujeta a disponibilidad de stock para el período.",
        "calculatingDeliveryTime":"Calculando tiempo de entrega...",
        "notifyByEmail":"¿Desea ser notificado por correo electrónico cuando la cantidad no disponible esté en stock?",
        "inStock":"En stock",
        "partiallyAvailable":"Parcialmente Disponible",
        "unavailable":"No disponible",
        "notifyMeWhenAvailable":"Avísame cuando llegue",
        "notificationActive":"Notificación Activa",
        "requestReceived":"Solicitud recibida. Responderemos a su solicitud por correo electrónico pronto."
    }
};

export default translations;
