import { useEffect, useState, useContext } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import OrderDetails from 'components/OrderDetails';
import API from "utils/API";
import GlobalFuncs from 'utils/GlobalFuncs';
import { saveAs } from 'file-saver';
import Loader from 'components/Loader';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

function OrderData() {

    const history = useHistory();
    const context = useContext(AppContext);
    const [order, setOrder] = useState(null);
    const [reorderStatus, setReorderStatus] = useState(true);
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [downloadingNF, setDownloadingNF] = useState(false);
    const [hasNF, setDownloadNF] = useState(false);
    const [filter, setFilter] = useState(null);
    const [ccStatus, setCcStatus] = useState(0)
    const params = useParams();

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Pedido',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        if (!context.getLogin) {
            history.push('/login');
            return;
        }
        const cancelToken = API.cancelToken();
        API.isActive().then(none => {
            context.setAuthCompanies(none.data.authorized_companies);
            hotjar.initialize('3361828')
            ReactGA.pageview(window.location.pathname);
            API.getOrder(params.id, cancelToken).then(res => {
                let ord = res.data
                setOrder(ord);
            }).catch(err => {
                if (err.message === 'cancelling') return
                console.log(err.response);
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(sel => {
                    window.location.href = "/"
                })
            });
        }).catch(err => {
            context.logOut()
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, []);

    useEffect(() => {
        if (!order) return;
        if (order.lines.length === 0) {
            let prod = order.products;
            setCcStatus(order.is_processed)
            setProducts(prod);
        } else {
            let prod = new Map();
            order.lines.forEach((e) => {
                let id = e.line;
                if (e.status_code == "INV"){
                    setDownloadNF(true)
                }
                if (prod.has(id)) {
                    prod.get(id).push(e);
                } else {
                    prod.set(id, [e]);
                }
            });
            setProducts(prod);
        }
        console.log(order)
        if(order.products[0] && order.products[0].product_id){
            setReorderStatus(false)
        }
    }, [order]);

    const reorder = (e) => {
        if(!order.products[0].product_id){
            return
        }
        let arr = []
        e.preventDefault();
        const cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Verificando pedido...',
            showConfirmButton: false,
            allowOutsideClick: false
        })
        var productArray = []
        API.validateOrderPricelist(cancelToken, order.order_id).then(async res => {
            if (res.data.products !== "") {
                productArray = res.data.products.split(', ').map(productId => {
                    return productId;
                });
                Swal.fire({
                    html: `Informamos que o(s) produto(s) abaixo não pertence(m) ao seu portfolio disponível. </br>
                    ${res.data.products} </br>
                    Deseja continuar sem o registro deste(s)?
                    `,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: "#131921",
                    cancelButtonColor: "#9badc4",
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async result => {
                    if (result.isConfirmed) {
                        console.log(productArray)
                        setLoading(true);
                        for (let item of order.products) {
                            try {
                                if (item.skf_product_id in productArray) {
                                    console.log(`Adding Product ${item.skf_product_id}...`)
                                    await API.addToCart(item.product_id, item.quantity);
                                } else {
                                    console.log(`Product ${item.skf_product_id} not in array`)
                                }
                            } catch (e) {
                                arr.push(item.product_id)
                            }
                        }
                        if (arr.length > 0) {
                            Swal.fire({
                                html: `Falha ao adicionar: ${arr.map(e => `<p>${e}</p>`).join('')}`,
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: "#131921",
                                confirmButtonText: 'Ok',
                            }).then(async result => {
                                history.push('/cart');
                            });
                        } else {
                            history.push('/cart');
                        }
                    }
                });
            } else {
                Swal.close();
                setLoading(true);
                for (let item of order.products) {
                    try {
                         await API.addToCart(item.product_id, item.quantity);
                    } catch (e) {
                        arr.push(item.product_id)
                    }
                }
                if (arr.length > 0) {
                    Swal.fire({
                        html: `Falha ao adicionar: ${arr.map(e => `<p>${e}</p>`).join('')}`,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: "#131921",
                        confirmButtonText: 'Ok',
                    }).then(async result => {
                        history.push('/cart');
                    });
                } else {
                    history.push('/cart');
                }
            }
        }).catch(err => {
            Swal.fire({
                html: `Falha ao adicionar: ${arr.map(e => `<p>${e}</p>`).join('')}`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: "#131921",
                confirmButtonText: 'Ok',
            })
        })
    }

    const getNF = (e) => {
        setDownloadingNF(true)
        const cancelToken = API.cancelToken();
        if (!downloadingNF){
            API.getOrderNF(order.order_id, cancelToken).then(response => {
                if(response.status != 200){
                    setDownloadingNF(false)
                    console.log(err)
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return;
                }
                response.blob().then(blob => {
                    saveAs(blob, `NF_ORDER_${order.order_id}.zip`)
                    setDownloadingNF(false)
                }).catch(err => {
                    setDownloadingNF(false)
                    console.log(err)
                    Swal.fire({
                        title: 'Erro.',
                        text: 'Tente novamente mais tarde.',
                        confirmButtonColor: "#131921",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                })
            }).catch(err => {
                setDownloadingNF(false)
                console.log(err)       
                Swal.fire({
                    title: 'Erro.',
                    text: 'Tente novamente mais tarde.',
                    confirmButtonColor: "#131921",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }) 
            })
        }
    }

    return (
        <div className="order-page page-wrapper-white one-whole margin-top-50">
            <div className="section-wrapper-small one-whole">
                <div className="flex-row space-between align-bottom margin-bottom-20">
                    <div>
                        <h3 className="margin-bottom-10">Detalhes do Pedido</h3>
                        <p className="order-num">Pedido {order && order.skf_order_id ? `# ${order.skf_order_id}` : null} - {order && GlobalFuncs.databaseToDate(order.created_at)}</p>
                        {
                            (context.getSub_business_segment === "VA Distributor")   &&
                            <p className="order-num">Número do Pedido do Cliente: {order && order.client_order_id ? `${order.client_order_id}` : '-'}</p> 
                        }
                    </div>
                    <div>
                    { order && !loading && order.payment_url && (order.is_processed == 3 || order.is_processed == -2)  &&

                        <a target="_blank" href={order.payment_url}><button className="pay-again">Efetuar Pagamento</button></a>
                    }
                    <button className={`buy-again ${reorderStatus ? 'disabled' : ''}`} disabled={reorderStatus} onClick={(e) => reorder(e)}>Comprar Novamente</button>
                    {
                        hasNF &&
                        <button className="download-nf" onClick={(e) => getNF(e)}>{downloadingNF ? `Baixando NF-e...` : `Baixar NF-e`}</button>
                    }
                    </div>
                </div>
                {
                    order && !loading ?
                        <div className="flex-row space-between order-details-header one-whole">
                            <div className="order-detail-section">
                                <p className="title margin-bottom-10">Endereço</p>
                                <p className="info"><span>{order.delivery_address.number && order.delivery_address.number}</span><span>{order.delivery_address.address && order.delivery_address.address}</span></p>
                                <p className="info">{order.delivery_address.address_2 && order.delivery_address.address_2}</p>
                                <p className="info">{order.delivery_address.city && order.delivery_address.city}</p>
                                <p className="info">{order.delivery_address.postal_code && order.delivery_address.postal_code}</p>
                            </div>
                            <div className="order-detail-section">
                                <p className="title margin-bottom-10">Método de Pagamento</p>
                                {order.payment_url ?
                                <a className="info" href={order.payment_url} target="_blank">{order.payment_method.description && order.payment_method.description}</a>: 
                                <p className="info">{order.payment_method.description && order.payment_method.description}</p>
                                }
                            </div>
                            <div className="order-detail-section">
                                <p className="title margin-bottom-10">Data do Pedido</p>
                                <p className="info">{order.created_at && GlobalFuncs.databaseToDate(order.created_at)}</p>
                            </div>
                            <div className="order-detail-section">
                                <p className="title margin-bottom-10">Valores</p>
                                <p className="info">Valor dos Itens: {order.total_amount >= 0 && GlobalFuncs.priceToReal(order.total_amount)}</p>
                                <p className="info">Frete: {order.shipping_cost >= 0 && GlobalFuncs.priceToReal(order.shipping_cost)}</p>
                                <p className="info">Taxa: {order.fee ? (order.fee >= 0 && GlobalFuncs.priceToReal(order.fee)) : "R$ 0.00"}</p>
                                <p className="info">Total: <span className="bold-total">{(order.shipping_cost >= 0 && order.total_amount >= 0) && GlobalFuncs.priceToReal(order.shipping_cost + order.total_amount + order.fee)}</span></p>
                            </div>
                        </div> :
                        <div className="one-whole order-details-header" style={{ 'height': '145px' }}>
                            <Loader light={true} />
                        </div>
                }
                <div className="order-details one-whole">
                    <div className="tab-wrapper flex-row-nowrap margin-bottom-40">
                        <div className="tab" onClick={() => setFilter(null)}>
                            <p className={filter === null ? 'active' : null} tabIndex="2">Produtos</p>
                        </div>
                        <div className="tab" onClick={() => setFilter(['INV'])}>
                            <p className={(filter !== null && filter.some(r => ['INV'].includes(r))) ? 'active' : null} tabIndex="3">Faturado</p>
                        </div>
                        <div className="tab" onClick={() => setFilter(['RPI', 'ENT', 'BOK', 'RBK', 'SHS', 'PIC', 'ALL', 'RECEIVED'])}>
                            <p className={filter !== null && filter.some(r => ['RPI', 'ENT', 'BOK', 'RBK', 'SHS', 'PIC', 'ALL', 'RECEIVED'].includes(r)) ? 'active' : null} tabIndex="4">Em Processamento</p>
                        </div>
                        <div className="tab" onClick={() => setFilter(['REJ', 'CAN'])}>
                            <p className={filter !== null && filter.some(r => ['REJ', 'CAN'].includes(r)) ? 'active' : null} tabIndex="5">Rejeitado</p>
                        </div>

                    </div>
                    {products &&
                        (
                            Array.isArray(products) && products.length > 0 ?
                                products.map((e, id) => (
                                    <OrderDetails key={e.quantity + id} product={e} filter={filter} ccStatus={ccStatus} />
                                )) :
                                Array.from(products).map(([key, value]) => (
                                    <OrderDetails key={key + value} line={value} filter={filter} ccStatus={ccStatus} />
                                ))
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default OrderData;