import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

function Footer() {

    const [size, setSize] = useState();
    const { translate } = useLanguage();
    const footer = useRef();
    useEffect(() => {
        setSize(footer.current.offsetHeight)
    }, [])

    window.addEventListener('resize', function () {
        if (footer.current) setSize(footer.current.offsetHeight);
    });

    return (
        <div>
            <div className="footer-position" ref={footer}>
                <div className="footer-arrow" onClick={()=>window.scrollTo({top: 0, behavior: 'smooth'})}>
                    <svg className="svg-icon__arrow--up" viewBox="0 0 12 7" height="20px" width="20px" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#fff" fillRule="evenodd" d="M5.95.03c-.267 0-.523.106-.712.294L.355 5.198c-.395.394-.395 1.03 0 1.424.394.393 1.032.393 1.426 0L5.95 2.46l4.172 4.162c.394.393 1.032.393 1.426 0 .394-.393.394-1.03 0-1.424L6.665.324C6.475.136 6.22.03 5.95.03z" />
                    </svg>
                </div>
                <div className="back-to-top" onClick={()=>window.scrollTo({top: 0, behavior: 'smooth'})}>
                    {translate('backtotop')}
                </div>
                <div className="flex-row footer-wrapper">
                    <div className="footer-col margin-bottom-30">
                        <h3>{translate('organization')}</h3>
                        <Link to='/about'>AZPartes</Link>
                        <a href="/">{translate('siteMap')}</a>
                    </div>
                    <div className="footer-col margin-bottom-30">
                        <h3>{translate('technicalSupport')}</h3>
                        <a href="/">0800 014 11 52</a>
                        <a href="https://api.whatsapp.com/send?phone=5511942000072" target="_blank">Whatsapp (11) 94200-0072</a>
                    </div>
                    <div className="footer-col margin-bottom-30">
                        <h3>{translate('contactUs')}</h3>
                        <a href="mailto:suporte@azpartes.com">suporte@azpartes.com</a>
                        <a href="/improvements">{translate('suggestions')}</a>
                    </div>
                    <div className="footer-col margin-bottom-30">
                        <h3>Políticas</h3>
                        <Link to='/exchange'>{translate('exchangePolicy')}</Link>
                        <Link to='/terms'>{translate('termsOfUse')}</Link>
                        <Link to='/privacy'>{translate('privacy')}</Link>
                    </div>
                    <div className="footer-social margin-bottom-30">
                        <h3>Social</h3>
                        <div className="flex-row-nowrap">
                            <a href="https://www.facebook.com/SKFBrasil" target="_blank"><img alt="facebook" className="social" src={require('../../assets/images/facebook.png').default} /></a>
                            {/* <a href="/"><img alt="instagram" className="social" src={require('../../assets/images/instagram.png').default} /></a>
                            <a href="/"><img alt="twitter" className="social" src={require('../../assets/images/twitter.png').default} /></a> */}
                            <a href="https://www.linkedin.com/company/skf" target="_blank"><img alt="linkedin" className="social" src={require('../../assets/images/linkedin.png').default} /></a>
                            {/* <a href="/"><img alt="youtube" className="social" src={require('../../assets/images/youtube.png').default} /></a> */}
                        </div>
                    </div>
                </div>
                <div className="footer-logo">
                    <Link to="/">AZPartes.com | <span className="lisa-text">Powered By LISA 2.0</span></Link>
                </div>
            </div>
            <div className="footer-buffer" style={{ 'height': size + 'px' }}></div>
        </div>
    )
}


export default Footer;