import React, { useState } from 'react';
import './style.css';
const AutoCompleteInput = ({ suggestions, onBlur }) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(true);

    const handleInputChange = (e) => {
        const userInput = e.target.value;
        setInputValue(userInput);
        setShowSuggestions(true); // Show suggestions when typing
    
        const filtered = suggestions.filter(
            suggestion => suggestion.toLowerCase().includes(userInput.toLowerCase())
        );
    
        setFilteredSuggestions(filtered);
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion);
        setShowSuggestions(false); // Hide suggestions on selection
        onBlur({ currentTarget: { value: suggestion } });
    };

    return (
        <div className="autocomplete-container">
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className="autocomplete-input"
                onBlur={onBlur}
            />
            {inputValue && showSuggestions &&  filteredSuggestions.length > 0 && (
                <ul className="autocomplete-list">
                    {filteredSuggestions.map((suggestion, index) => (
                        <li key={index} className="autocomplete-item" onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
export default React.memo(AutoCompleteInput);

