import { useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

function IconText(props) {
    const [mobile, setMobile] = useState(window.innerWidth <= 768);

    window.addEventListener('resize', function (event) {
        setMobile(window.innerWidth <= 768);
    }, true);

    return (
        <div id={props.id} className="icon-text">
            {
                props.children && props.children.length > 0 ?
                    <div className={!mobile ? 'dropdown' : 'mobile-dropdown'} tabIndex={mobile ? '2' : null}>
                        {
                            props.href ?
                                <Link className="reset" to={props.href}>
                                    <img alt={props.text} className="nav-icon" src={props.image} />
                                    <p className="nav-text">{props.text}</p>
                                </Link> :
                                <div>
                                    <img alt={props.text} className="nav-icon" src={props.image} />
                                    <p className="nav-text">{props.text}</p>
                                </div>
                        }
                        <div className='dropdown-content'>
                            <div className="wrapper">
                                {
                                    props.children.map((e, id) => (
                                        e.href ?
                                            <Link className="reset" to={e.href} key={e.text + id}>
                                                <p className="nav-text">{e.text}</p>
                                            </Link> :
                                            <p onClick={e.action} key={e.text + id}>{e.text}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    :
                    props.href ?
                        <Link className="reset" to={props.href}>
                            <img alt={props.text} className={ props.marginbottom0 === "true" ? "nav-icon margin-bottom-0" : "nav-icon"} src={props.image} />
                            <p className="nav-text">{props.text}</p>
                        </Link> :
                        <div>
                            <img alt={props.text} className="nav-icon" src={props.image} />
                            <p className="nav-text">{props.text}</p>
                        </div>
            }
        </div>
    )
}

export default IconText;