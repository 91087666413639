import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import API from 'utils/API';
import './style.css';
import Swal from 'sweetalert2';
import { useLanguage } from '../../context/LanguageContext';

function Rating(props) {
    const stars = [1, 2, 3, 4, 5];
    const [displayRating, setDisplayRating] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [numberOfRatings, setNumberOfRatings] = useState(false);
    let ratedByUser = false;
    let userRating = 0;
    const status = props.status;
    let averageRating = 0;
    const { translate } = useLanguage();
    
    useEffect(() => {
        const cancelToken = API.cancelToken();
        API.getRating(props.product.skf_product_id, cancelToken).then(res => {
            setLoaded(true);
            setDisplayRating(res.data.avg_rating);
            averageRating = res.data.avg_rating;
            setNumberOfRatings(res.data.num_of_ratings);
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err.message);
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [])

    useEffect(() => {
        if (status === "enabled") {
            const ratingSection = document.querySelector(".trial");
            ratingSection.addEventListener("mousemove", displayChange);
            ratingSection.addEventListener("mouseleave", reset);
            ratingSection.addEventListener("click", setNewRating);
            function displayChange(event) {
                let boxWidth = ratingSection.offsetWidth;
                let position = event.offsetX;
                setDisplayRating(Math.round(((position / boxWidth) * 5) * 10) / 10);
            }
            function reset() {
                if (!ratedByUser) {
                    setDisplayRating(averageRating);
                } else {
                    setDisplayRating(userRating);
                }
            }
            function setNewRating(event) {
                ratedByUser = true;
                let boxWidth = ratingSection.offsetWidth;
                let position = event.offsetX;
                userRating = Math.ceil((position / boxWidth) * 5);
                setDisplayRating(Math.ceil((position / boxWidth) * 5));

                const cancelToken = API.cancelToken();
                API.setRating(cancelToken, props.product.skf_product_id, Math.ceil((position / boxWidth) * 5),"").then(res => {
                    Swal.fire({
                        title: 'Avaliação registrada com sucesso! Você deseja deixar um comentário?',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        confirmButtonColor: "#131921",
                        denyButtonText: `Não`,
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Deixe o seu comentário',
                                input: 'text',
                                inputAttributes: {
                                  autocapitalize: 'off'
                                },
                                showCancelButton: true,
                                confirmButtonText: 'Enviar',
                                confirmButtonColor: "#131921",
                                showLoaderOnConfirm: true,
                                allowOutsideClick: () => !Swal.isLoading()
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  // Swall success
                                  Swal.fire({
                                    title:'Enviando comentário...',
                                    showConfirmButton: false,
                                    allowOutsideClick: false
                                })
                                    API.setRating(cancelToken, props.product.skf_product_id, Math.ceil((position / boxWidth) * 5),result.value).then(res => {
                                        Swal.fire({
                                            title: 'Sucesso',
                                            text: 'Comentário enviado com sucesso!',
                                            icon: 'success',
                                            confirmButtonText: 'Ok'
                                        })
                                    }).catch(err => {
                                        Swal.showValidationMessage(
                                            `Request failed: ${err}`
                                            )
                                    })
                                }
                              })
                        } else if (result.isDenied) {
                            
                        }
                      })
                }).catch(err => {
                    console.log(err);
                    Swal.fire({
                        title: 'Erro.',
                        confirmButtonColor: "#131921",
                        text: 'Tente novamente mais tarde.',
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                });
            }
        }
    }, [])

    return (
        <div className="flex-row-nowrap rating-wrapper">

            <div className={`${status === "enabled"}`}>
                {
                    stars.map((e, index) => (
                        <svg className="star" fill={displayRating > index ? '#F5C623' : '#D8D8D8'} key={`${index}star${index}`} width={props.width} height={props.height} viewBox="0 0 12 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>Star {index + 1}</title>
                            <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                                <g id="Home---V3-RC=4" transform="translate(-489.000000, -638.000000)">
                                    <g id="Products-1" transform="translate(409.000000, 383.000000)">
                                        <g id="Stars" transform="translate(44.000000, 255.000000)">
                                            <polygon id="Star-4" points="42 9 38.4732885 10.854102 39.1468305 6.92705098 36.2936609 4.14589803 40.2366442 3.57294902 42 0 43.7633558 3.57294902 47.7063391 4.14589803 44.8531695 6.92705098 45.5267115 10.854102"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    ))
                }
            </div>

            {
                status === "enabled" &&
                <div className="trial">
                </div>
            }

            <p style={{ fontSize: `${props.fontSize}px` }}>{!loaded ? `${translate('loading')}` : numberOfRatings ? numberOfRatings == 1 ? `(${numberOfRatings} ${translate('review')})` : `(${numberOfRatings} ${translate('reviews')})` : `(${translate('noReviews')})`}</p>
        </div>
    )

}

export default Rating;