import { useEffect, useState, useContext, useRef } from "react";
import "./style.css";
import API from "utils/API";
import { AppContext } from "utils/ContextProvider";
import GlobalFuncs from "utils/GlobalFuncs";
import Carousel from "components/Carousel";
import CartItem from "components/CartItem";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import Swal from "sweetalert2";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga";
import CartItemPl from "components/CartItemPl";
import { useLanguage } from '../../context/LanguageContext';

function Cart() {
  const history = useHistory();
  const context = useContext(AppContext);
  const { translate } = useLanguage();
  const [recommended, setRecommended] = useState([]);
  const [submittingCart, setSubmittingCart] = useState(false);
  const [sendButtonText, setSendButtonText] = useState("Fechar Pedido");
  const [allLabel, setAllLabel] = useState(translate('uncheckedItems'));
  const [cart, setCart] = useState(null);
  const [total, setTotal] = useState(0);
  const [balance, setBalance] = useState(total ? total <= 2000 : false);
  const [amount, setAmount] = useState(0);
  const [uncheckedList, setUncheckedList] = useState([]);
  const [allChecked, setAllChecked] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [credit, setCredit] = useState(translate('loading'));
  const [paymentCondition, setPaymentCondition] = useState(translate('loading'));
  const [loading, setLoading] = useState([true, false]);
  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);
  const [overage, setOverage] = useState([]);
  const message = useRef();

  useEffect(() => {
    console.log("GA", window.location.pathname);
    window.gtag("event", "page_view", {
      page_title: "AZPartes - Cart",
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, []);


  useEffect(() => {
    if (!context.getLogin) {
      history.push("/login");
      return;
    }
    if (context.getSub_business_segment == "Stock Car") {
      history.push('/stockcar');
      return;
    }
    const cancelToken = API.cancelToken();
    hotjar.initialize("3361828");
    ReactGA.pageview(window.location.pathname);
    context.updateCart();
    API.getCredit(cancelToken)
      .then((res) => {
        res.data.saldo_total >= 0
          ? setCredit(res.data.saldo_total)
          : setCredit(translate('noInformation'));
        setPaymentCondition(res.data.payment_condition);
      })
      .catch((err) => {
        if (err.message === "cancelling") return;
        console.log(err.message);
        setCredit(translate('noInformation'));
        setPaymentCondition(translate('noInformation'));
      });
    API.getRecommendedProducts(cancelToken)
      .then((res) => {
        setRecommended(res.data.products);
      })
      .catch((err) => {
        if (err.message === "cancelling") return;
        console.log(err.message);
      });

    return () => {
      API.cancel(cancelToken);
    };
  }, []);

  useEffect(() => {
    setCart(context.getCart);
    if (context.getCart) {
      let tempLoading = [...loading];
      tempLoading[0] = false;
      setLoading(tempLoading);
    } else {
      let tempLoading = [...loading];
      tempLoading[0] = true;
      setLoading(tempLoading);
    }
  }, [context.getCart]);

  useEffect(() => {
    let tempTotal = 0;
    let tempAmount = 0;
    let tempOverage = [];
    cart &&
      cart.forEach((e, index) => {
        tempTotal += e.amount * e.price;
        tempAmount += e.amount;
        if (e.amount > e.stock)
          tempOverage.push({
            index: index,
            amount: e.amount,
            availability: e.stock,
          });
      });
    setTotal(tempTotal);
    setAmount(tempAmount);
    setOverage(tempOverage);
  }, [cart]);

  useEffect(() => {
    if (total <= credit) {
      setBalance(true);
      setOpen(false);
    } else {
      setBalance(false);
    }
  }, [total, credit]);

  const handleDelete = (index) => {
    let tempLoading = [...loading];
    tempLoading[0] = true;
    setLoading(tempLoading);
    let tempCart = [...cart];
    tempCart.splice(index, 1);
    context.setCart(tempCart);
  };

  const handleCheck = (id) => {
    let tempChecked = [...checkedList];
    tempChecked.push(id)
    var setTempChecked = new Set(tempChecked);
    console.log(`${cart.length} - ${setTempChecked.size} - ${tempChecked}`)
    if (cart.length + 1 == setTempChecked.size) {
      setAllLabel(translate('uncheckedItems'))
    } else {
      setAllLabel(translate('checkAllItems'))
    }

    let tempunchecked = [...uncheckedList];
    tempunchecked.splice(tempunchecked.indexOf(id), 1)
    setUncheckedList(new Set(tempunchecked));
    if (tempunchecked.length == 0) {
      setAllLabel(translate('uncheckedItems'))
    }
    setCheckedList(new Set(tempChecked));
  }

  const handleUncheck = (id) => {
    setAllChecked(2)
    let tempunchecked = [...uncheckedList];
    tempunchecked.push(id)
    setUncheckedList(tempunchecked);

    if (tempunchecked.length == 0) {
      setAllLabel(translate('uncheckedItems'))
    } else {
      setAllLabel(translate('checkAllItems'))
    }

    let tempChecked = [...checkedList];
    console.log(`Previuos checkedList - ${tempChecked}`)
    tempChecked.splice(tempChecked.indexOf(id), 1)
    console.log(`After Splice - ${tempChecked}`)
    setCheckedList(new Set(tempChecked));

    console.log(new Set(tempChecked))

  }

  useEffect(() => {
    console.log("**** UNCHECKED LIST START****")
    console.log(uncheckedList)
    console.log("**** UNCHECKED LIST END****")
  }, [uncheckedList])

  useEffect(() => {
    let tempChecked = [];
    let tempunChecked = [];
    if (allChecked) {
      cart &&
        cart.forEach((e) => {
          tempChecked.push(e.product_id);
        });
      setCheckedList(tempChecked);
      setUncheckedList([]);
    } else {
      setCheckedList([]);
      cart &&
        cart.forEach((e) => {
          tempunChecked.push(e.product_id);
        });
      setUncheckedList(tempunChecked);
    }
  }, [allChecked]);

  const deleteAll = async () => {
    if (checkedList.length === 0) return;
    Swal.fire({
      text: "Excluir item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#131921",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let tempLoading = [...loading];
        tempLoading[0] = true;
        setLoading(tempLoading);
        let cancelToken;
        let tempCart = [...cart];
        for (let e of checkedList) {
          cancelToken = API.cancelToken();
          try {
            let res = await API.deleteFromCart(cancelToken, e, false);
            tempCart.splice(tempCart.indexOf(e), 1);
          } catch (err) {
            console.log(err);
            if (err !== "canceling") {
              Swal.fire({
                title: "Erro.",
                text: "Tente novamente mais tarde.",
                confirmButtonColor: "#131921",
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }
          }
        }
        window.location.reload();
        return () => {
          API.cancel(cancelToken);
        };
      }
    });
  };

  const handleAmountChange = async (amount, index) => {
    if (isNaN(amount)) return "";
    const cancelToken = API.cancelToken();
    try {
      let db = updateAmount(amount, index);
      if (db) updateCart([{ amount: amount, index: index }]);
    } catch (e) {
      let content = "Tente novamente mais tarde.";
      Swal.fire({
        title: "Erro.",
        html: content,
        icon: "warning",
        confirmButtonColor: "#131921",
        confirmButtonText: "Ok",
      });
      return cart[index].amount;
    }
  };

  const updateCart = (arr) => {
    let tempCart = [...cart];
    arr.forEach((e) => {
      tempCart[e.index].amount = e.amount;
    });
    context.setCart(tempCart);
  };

  const updateAmount = async (amount, index) => {
    try {
      const cancelToken = API.cancelToken();
      let update = await API.setCartAmount(
        cancelToken,
        cart[index].product_id,
        amount
      );
      return true;
    } catch (err) {
      console.log(err.message);
      if (err.message !== "cancelling") {
        Swal.fire({
          title: "Erro.",
          text: "Tente novamente mais tarde.",
          confirmButtonColor: "#131921",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
      return false;
    }
  };

  useEffect(() => {
    if (overage && overage.length === 0) return;
    // let content =
    //     `<p>A quantidade dos seguintes produtos excede a disponibilidade.</p><br/>
    //     <table>
    //         <tr>
    //             <th>Produto</th>
    //             <th>Qtd solicitada </th>
    //         </tr>
    //         <tr>
    //         ${overage.map(e => `<td>${cart[e.index].name}</td><td>${e.amount}</td>`).join('')}
    //         </tr>
    //     </table>
    //     `
    // Swal.fire({
    //     title: 'Erro.',
    //     html: content,
    //     confirmButtonColor: "#131921",
    //     icon: 'warning',
    //     confirmButtonText: 'Ok'
    // });
  }, [overage]);

  const submitCart = async () => {
    if (context.getSub_business_segment === "SKF Center" && total < 300) return;
    if (context.getSub_business_segment === "Two Wheeler" && total < 2000)
      return;
    // console.log("Passed the minimum value test")
    console.log("Submitting cart...")
    console.log(`Unchecked list: ${uncheckedList}`)
    setSendButtonText("Enviando Pedido...");
    setSubmittingCart(true)
    let tempCart = [...cart];
    for (let e of uncheckedList) {
      try {
        const cancelToken = API.cancelToken();
        let res = await API.deleteFromCart(cancelToken, e, false);
        tempCart.splice(tempCart.indexOf(e), 1);
      } catch (err) {
        console.log(err);
        if (err !== "canceling") {
          console.log('Error on removing item from cart')
        }
      }
    }
    Swal.fire({
      html:
        "<p>Você confirma a inclusão do pedido na conta " +
        context.getCurrentUser +
        "?</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#289067",
      cancelButtonColor: "#880808",
      confirmButtonText: "SIM",
      cancelButtonText: "NÃO",
    })
      .then((result) => {
        if (result.isConfirmed) {
          history.push("/checkout");
          return;
        } else {
          setSendButtonText("Fechar Pedido");
          setSubmittingCart(false)
        }
      })
      .catch((err) => {
        console.log(err);
        setSendButtonText("Fechar Pedido");
        setSubmittingCart(false)
      });
  };

  const sendLowBalance = () => {
    let tempLoading = [...loading];
    tempLoading[1] = true;
    setLoading(tempLoading);
    let body = {
      message: message.current.value,
      total_price: total,
      current_credit: credit,
      products: cart.map((e) => ({
        skf_product_id: e.skf_product_id,
        quantity: e.amount,
      })),
    };
    const cancelToken = API.cancelToken();
    API.insufficientFunds(cancelToken, body)
      .then((res) => {
        setSent(true);
        let tempLoading = [...loading];
        tempLoading[1] = false;
        setLoading(tempLoading);
      })
      .catch((err) => {
        console.log(err);
        setSendButtonText("Fechar Pedido");
        setSubmittingCart(false)
        Swal.fire({
          title: "Erro.",
          confirmButtonColor: "#131921",
          text: "Tente novamente mais tarde.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
        let tempLoading = [...loading];
        tempLoading[1] = false;
        setLoading(tempLoading);
      });
  };

  const keepBuying = () => {
    history.push(
      `/products/search?q=null&cat=null&min=null&max=null&priceCat=null&openCat=null&page=null&sorting=null&type=null&make=null&model=null&motor=null&license=null&available=0&alph=null`
    );
  };


  const handleAll = (id) => {
    if (allLabel == translate('uncheckedItems')) {
      setAllChecked(0);
      setAllLabel(translate('checkAllItems'));

      let tempChecked = [...checkedList];
      setUncheckedList(new Set(tempChecked));
      setCheckedList(new Set([]));
    }

    if (allLabel == translate('checkAllItems')) {
      setCheckedList(new Set([]));
      console.log('It is reaching here')
      let tempChecked = [];
      cart && cart.forEach(e => {
        console.log(`Adding ${e.product_id}`)
        tempChecked.push(e.product_id);
      });
      setCheckedList(new Set(tempChecked));
      setAllChecked(1)
      setAllLabel(translate('uncheckedItems'));
    }
  }

  return (
    <div>
      <div className="page-wrapper-grey one-whole">
        <div className="cart-page-wrapper section-wrapper one-whole flex-row-nowrap space-between">
          <div className="cart-wrapper margin-bottom-20 margin-top-20">
            <h3 className="margin-bottom-30">{translate('shoppingCart')}</h3>
            <div className="cart-table">
              <div className="items-selected">
                <p className="text-[#151E26] text-xl font-bold tracking-normal">
                  {translate('selectedItems')}
                </p>
              </div>
              <div className="cart-body">
                <div className="first-row">
                  <div className="first-col-header">
                    <p className='select-multiple' onClick={handleAll}>{allLabel}</p>
                  </div>
                  <div className="second-col">
                    <p className="links" onClick={deleteAll}>
                      {translate('excludeSelected')}
                    </p>
                  </div>
                  <div className="third-col">
                    <p className="price">{translate('unitPrice')}</p>
                  </div>
                  <div className="fourth-col">
                    <p className="price">{translate('totalPrice')}</p>
                  </div>
                  {loading[0] ? (
                    <div className="loader-wrapper">
                      <Loader light={true} />
                    </div>
                  ) : cart.length > 0 ? (

                    context && context.getSub_business_segment !== "Private Label" ?
                      cart.map((e, index) => (
                        <CartItem
                          key={e.product_id * e.price + index}
                          item={e}
                          index={index}
                          remove={() => handleDelete(index)}
                          handleCheck={() => handleCheck(e.product_id)}
                          handleUncheck={() => handleUncheck(e.product_id)}
                          checkAll={allChecked}
                          updateAmount={handleAmountChange}
                        />
                      )) :
                      cart.map((e, index) => (
                        <CartItemPl
                          key={e.product_id * e.price + index}
                          item={e}
                          index={index}
                          remove={() => handleDelete(index)}
                          handleCheck={() => handleCheck(e.product_id)}
                          handleUncheck={() => handleUncheck(e.product_id)}
                          checkAll={allChecked}
                          updateAmount={handleAmountChange}
                        />
                      ))
                  ) : (
                    <p className="margin-top-20 text-center">{translate('emptyCart')}</p>
                  )}
                </div>
                <div className="total-box flex-row margin-top-10">
                  <p className="">{translate('totalLabel')} ({amount} itens):</p>
                  &nbsp;
                  <p className="bold">{GlobalFuncs.priceToReal(total)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cart-purchase-box margin-bottom-20 margin-top-20">
            <div className="flex-row space-between">
              <h4 className="">Subtotal ({amount} itens):</h4>
              <h4 className="margin-bottom-20">
                {GlobalFuncs.priceToReal(total)}
              </h4>
            </div>


            {
              context.getSub_business_segment !== "Private Label" &&
              <>
                <div className="flex-row space-between">
                  <p className="credit">{translate('availableCredit')}:</p>
                  <p
                    className="credit margin-bottom-20"
                    style={{ color: `${balance ? "#1C8870" : "#B12704"}` }}
                  >
                    {isNaN(credit) ? credit : GlobalFuncs.priceToReal(credit)}
                  </p>
                </div>
                {!balance && credit !== translate('loading') && (
                  <p className="credit-card margin-top-10">
                    {translate('insuficientCreditMessage')}
                  </p>
                )}
              </>
            }


            {(context.getSub_business_segment === "Two Wheeler" ||
              context.getSub_business_segment === "VA Distributor") && (
                <div className="flex-row space-between">
                  <p className="credit">{translate('paymentConditionLabel')}:</p>
                  <p className="credit margin-bottom-30">{paymentCondition}</p>
                </div>
              )}

            {!open && context.getSub_business_segment === "Two Wheeler" && (
              <>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button margin-bottom-10 ${cart && (cart.length === 0 || total < 2000 || submittingCart)
                    ? "disabled"
                    : null
                    }`}
                  onClick={submitCart}
                >
                  {sendButtonText}
                </div>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button secondary margin-bottom-10 ${cart && (cart.length === 0 || total < 2000 || submittingCart)
                    ? "disabled"
                    : null
                    }`}
                  onClick={keepBuying}
                >
                  {translate('keepBuying')}
                </div>
              </>
            )}
            {!open && context.getSub_business_segment === "SKF Center" && (
              <>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button margin-bottom-10 ${cart && (cart.length === 0 || total < 300 || submittingCart)
                    ? "disabled"
                    : null
                    }`}
                  onClick={submitCart}
                  disabled={submittingCart}
                >
                  {sendButtonText}
                </div>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button secondary margin-bottom-10 ${cart && (cart.length === 0 || total < 300 || submittingCart)
                    ? "disabled"
                    : null
                    }`}
                  onClick={keepBuying}
                >
                  {translate('keepBuying')}
                </div>
              </>
            )}
            {!open && context.getSub_business_segment === "VA Distributor" && (
              <>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button margin-bottom-10 ${cart && cart.length === 0 ? "disabled" : null
                    }`}
                  onClick={submitCart}
                >
                  {translate('checkoutLabel')}
                </div>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button secondary margin-bottom-10 ${cart && cart.length === 0 ? "disabled" : null
                    }`}
                  onClick={keepBuying}
                >
                  {translate('keepBuying')}
                </div>
              </>
            )}
            {!open && context.getSub_business_segment === "Private Label" && (
              <>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button margin-bottom-10 ${cart && cart.length === 0 ? "disabled" : null
                    }`}
                  onClick={submitCart}
                >
                  {translate('checkoutLabel')}
                </div>
                <div
                  style={{
                    display: `${credit === translate('loading') ? "none" : "flex"}`,
                  }}
                  className={`action-button secondary margin-bottom-10 ${cart && cart.length === 0 ? "disabled" : null
                    }`}
                  onClick={keepBuying}
                >
                  {translate('keepBuying')}
                </div>
              </>
            )}
            {open && !sent ? (
              <div className="form-wrapper">
                <textarea
                  className="quote-message margin-bottom-10"
                  type="text"
                  rows="5"
                  placeholder="Informações adicionais"
                  ref={message}
                />
                {loading[1] ? (
                  <div className="loader-wrapper">
                    <Loader light={true} />
                  </div>
                ) : (
                  <div
                    className="action-button"
                    onClick={() => sendLowBalance()}
                  >
                    {translate('sendLabel')}
                  </div>
                )}
              </div>
            ) : open && sent ? (
              <p className="text-center">
                Nossa equipe de vendas foi comunicada e entrará em contato em
                breve.
              </p>
            ) : null}
            {context.getSub_business_segment === "Two Wheeler" &&
              total < 2000 && (
                <p className="balance-low margin-bottom-20 error bold-error">
                  Pedido Mínimo de R$ 2000,00
                </p>
              )}
            {context.getSub_business_segment === "SKF Center" &&
              total < 300 && (
                <p className="balance-low margin-bottom-20 error bold-error">
                  Pedido Mínimo de R$ 300,00
                </p>
              )}
            {/* {(!balance && credit !== 'Carregando...') && 
                        <p className="credit-card margin-top-10">
                                Saldo Insuficiente.
                                Pagamento seguirá via cartão de crédito
                        </p>} */}
          </div>
        </div>
      </div>
      {
        context && context.getSub_business_segment !== "Private Label" &&
        <div className="section-wrapper one-whole">
          <Carousel header="Produtos Recomendados" list={recommended} />
        </div>
      }
    </div>
  );
}

export default Cart;