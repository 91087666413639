import QuantityPicker from 'components/QuantityPicker';
import './style.css';

function CardBearing(props) {

    return (
        <div className="container" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <div className='texts border-bottom'>
                <p className='title'>{props.title}</p>
                <p className='subtitle'>Dianteiro e traseiro</p>
            </div>
            <div className={`product-info ${props.quantity > 0 ? "border-bottom" : ""}`}>
                <QuantityPicker 
                    text={"Quantidade"}
                    minimum={0} 
                    multiple={1}
                    max={props.availability} 
                    quantity={props.quantity} 
                    index={props.index} 
                    onChange={props.onChange} />
                {props.quantity > 0 && <><div className='flex-row space-between' style={{ width: "100%" }}>
                    <p>Valor unitário:</p>
                    <p className='bold'>{`R$ ${props.price}`}</p>
                </div>
                    <div className='flex-row space-between' style={{ width: "100%" }}>
                        <p>{`Valor total (${props.quantity} itens):`}</p>
                        <p className='bold'>{`R$ ${(props.quantity * props.price).toFixed(2)}`}</p>
                    </div></>}
            </div>
            {props.quantity > 0 && < div className='deadline-info'>
                <p className='h1'>Entrega</p>
                <div className='flex-row space-between' style={{ width: "100%" }}>
                    <p>Previsão:</p>
                    <p className=''>{props.delivery}</p>
                </div>
            </div>}

        </div >
    )
}

export default CardBearing;