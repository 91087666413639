import { useState, useRef, useEffect, useContext } from 'react';
import './style.css';
import { AppContext } from 'utils/ContextProvider';
import API from "utils/API";
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import { useLocation, useHistory } from 'react-router-dom';

function Registration() {

    const context = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [allow, setAllow] = useState(false);
    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [password, setPassword] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [submitConfirm, setSubmitConfirm] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const phoneRef = useRef();
    const [error, setError] = useState(false)
    const phoneError = useRef();
    // const password = useRef();
    const confPassword = useRef();
    const confPasswordError = useRef();
    const verifyPasswordError = useRef();
    const terms_use = useRef();
    const terms_privacy = useRef();
    const terms_marketing = useRef();
    const termsError = useRef();
    const [showPass, setShowPass] = useState(false)
    const [mask, setMask] = useState("+55 (99) 99999-9999");
    const [showPassConf, setShowPassConf] = useState(false)

    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: 'AZPartes - Cadastro',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        })
    }, []);

    useEffect(() => {
        setLoading(true);
        const params = new URLSearchParams(location.search);
        let email = params.get('email');
        email ? setEmail(email) : history.push('/');
    }, [])

    const evaluatePasswordStrength = (password) => {
        // Criteria checks as before
        if(password.length > 40){
            return 6
        }
        if(password.length < 5){
            return 1
        }
        const metCriteria = [password.length >= 8, /\d/.test(password), /[A-Z]/.test(password), /[a-z]/.test(password), /[!@#$%^&*(),.?":{}|<>]/.test(password)].filter(Boolean).length;
        return metCriteria; // Returns a number between 0 and 5
    };

    useEffect(() => {
        if(passwordStrength == 4 || passwordStrength == 5 || passwordStrength == 3){
            
            setSubmitEnabled(true);
            
        }else{
            setSubmitEnabled(false);
        }
    }, [passwordStrength]);

    const handlePasswordChange = (event) => {
        const { value } = event.target;
        setPassword(value);
        if(value == ""){
            setPasswordStrength(0);
            return;
        }
        setPasswordStrength(evaluatePasswordStrength(value));
    };

    const getStrengthIndicator = () => {
        let color, message;
        switch (passwordStrength) {
            case 0:
            case 1:
                color = 'red';
                message = 'Senha Fraca';
                break;
            case 2:
            case 3:
                color = 'orange';
                message = 'Senha Moderada';
                break;
            case 4:
            case 5:
                color = 'green';
                message = 'Senha Forte';
                break;
            case 6:
                color = 'red';
                message = 'Senha deve conter menos de 40 caracteres.';
                break;
            default:
                color = 'transparent';
                message = '';
        }
        return { color, message };
    };

    const { color, message } = getStrengthIndicator();

    useEffect(() => {
        if (!email) return;
        const cancelToken = API.cancelToken();
        API.getRegistrationForm(cancelToken, email).then(res => {
            if (res.data.user_data) {
                if (res.data.user_data.code) {
                    if (res.data.user_data.code == "ACTIVE") {
                        Swal.fire({
                            title: 'Erro.',
                            text: "Usuário já esta ativo.",
                            confirmButtonColor: "#131921",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                context.logOut()
                            }
                        })
                    }
                } else {
                    setName(res.data.user_data.name);
                    setPhone(res.data.user_data.phone_number);
                }
            } else {
                throw new Error();
            }
            setLoading(false);
        }).catch(err => {
            if (err.message === 'cancelling') return
            setLoading(false);
            let message = '';
            if (err.response && (err.response.status >= 400 && err.response.status < 500)) {
                message = 'Informações Incorretas.'
            } else {
                message = 'Tente novamente mais tarde.'
            }
            Swal.fire({
                title: 'Erro.',
                text: message,
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
        return () => {
            API.cancel(cancelToken);
        }
    }, [email]);

    const formatPassword = () => {
        let p = password.current.value.trim();
        if (p.length < 4 || p.length > 16) {
            verifyPasswordError.current.style.display = 'block'
        } else {
            verifyPasswordError.current.style.display = 'none'
        }
    }

    const togglePass = (e) => {
        setShowPass(!showPass)
    }

    const togglePassConf = (e) => {
        setShowPassConf(!showPassConf)
    }

    const formatCnpj = () => {
        let num = cnpj.current.value.trim();
        if (num.length > 18) {
            cnpj.current.value = num.substr(0, 18);
            return;
        }
        num = num.replace(/[^0-9]/g, '');

        if (num.length > 2 && num.charAt(2) !== '.') {
            num = num.substr(0, 2) + '.' + num.substr(2);
        }
        if (num.length > 6 && num.charAt(6) !== '.') {
            num = num.substr(0, 6) + '.' + num.substr(6);
        }
        if (num.length > 10 && num.charAt(10) !== '/') {
            num = num.substr(0, 10) + '/' + num.substr(10);
        }
        if (num.length > 15 && num.charAt(15) !== '-') {
            num = num.substr(0, 15) + '-' + num.substr(15);
        }
        cnpj.current.value = num;
    }

    const enterPhone = () =>{

    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
          searchPhone()
        }
      }

    const searchPhone = () => {
        let num = phoneRef.current.value.trim();
        phoneRef.current.value = num;
        var currPhoneValue = phoneRef.current.value.replace(/\D/g, "");
        var actualPhone = phone.replace(/\D/g, "");

        if (!phoneRef.current.value || currPhoneValue != actualPhone) {
            phoneError.current.style.display = 'block'
            setAllow(false)
            setError(true)
        } else {
            phoneError.current.style.display = 'none'
            setAllow(true);
            setError(false)
        }
    }

    const confirmPassword = () => {
        try{
            if (password.trim() !== confPassword.current.value.trim()) {
                confPasswordError.current.style.display = 'block'
                setSubmitConfirm(false)
            } else {
                setSubmitConfirm(true)
                confPasswordError.current.style.display = 'none'
            }
        }catch(e){
            console.log(e)
            console.log('Not possible to compare')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let err = false;
        
        if (password.trim().length < 4 || password.trim().length > 16) {
            verifyPasswordError.current.style.display = 'block'
            password.current.focus()
            err = true;
        } else {
            verifyPasswordError.current.style.display = 'none'
        }
        if (!password.trim() || password.trim() !== confPassword.current.value.trim()) {
            confPasswordError.current.style.display = 'block'
            err = true;
        } else {
            confPasswordError.current.style.display = 'none'
        }
        if (!terms_use.current.checked || !terms_privacy.current.checked) {
            termsError.current.style.display = 'block'
            err = true;
        } else {
            termsError.current.style.display = 'none'
        }

        if (!err) {
            sendRegistration();
        } else {
            setLoading(false);
        }

    }

    const sendRegistration = () => {
        const cancelToken = API.cancelToken();
        let payload = {
            name: name,
            email: email,
            password: password.trim(),
            marketing_allowed: 0,
            phone: phone,
        }
        if (terms_marketing.current.checked) {
            payload.marketing_allowed = 1
        }

        API.submitRegistrationForm(cancelToken, payload).then(res => {
            Swal.fire({
                icon: 'success',
                title: 'Seu cadastro está completo!',
                confirmButtonColor: "#080A0D",
                confirmButtonText: 'IR PARA LOGIN'
            }).then((result) => {
                if (result.isConfirmed) {
                    context.logOut()
                }
            })
        }).catch(err => {
            setLoading(false);
            let message = '';
            if (err.response && (err.response.status >= 400 && err.response.status < 500)) {
                message = 'Informações inconsistentes. Por favor verifique os dados inseridos.'
            } else {
                message = 'Tente novamente mais tarde.'
            }
            Swal.fire({
                title: 'Erro.',
                text: message,
                confirmButtonColor: "#080A0D",
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
        });
    }

    return (
        <div className="registration-page">
            <div className="form-container">
                <div className={`section-one ${allow ? 'order-one' : 'order-two'}`}>
                    <Link className="reset" to={'/home'}>
                        <h1 className="margin-bottom-20">AZPartes.com</h1>
                    </Link>
                </div>
                <div className={`section-two ${allow ? 'order-two' : 'order-one'}`}>
                    {
                        <div className="form-wrapper">
                            <form>
                                <h2 className='margin-bottom-60'>{allow ? "Complete seu cadastro" : "Validação de cadastro"}</h2>
                                {!allow && <h3 className='margin-bottom-20'>Parabéns!</h3>}
                                {!allow && <p className="description">Sua empresa foi pré-cadastrada na plataforma <strong>AZPartes.com</strong>, o e-commerce B2B da SKF.</p>}
                                {!allow && <p className="label">Insira o numero do telephone abaixo para confirmar os dados e concluir o cadastro</p>}
                                {
                                    !allow &&
                                    <div className={`pass-holder input ${error ? "error-input" : ""}`}>
                                        {/* <input className={`mask-password`} type="text" ref={phoneRef} placeholder='' /> */}
                                        <InputMask
                                                     mask={mask}
                                                     onBlur={e => {
                                                         if (e.target.value.replace("_", "").length === 18) {
                                                           setMask("+55 (99) 9999-9999");
                                                         }
                                                       }}
                                                       onFocus={e => {
                                                         if (e.target.value.replace("_", "").length === 18) {
                                                           setMask("+55 (99) 99999-9999");
                                                         }
                                                       }}
                                                       type="text" 
                                                       ref={phoneRef}
                                                       className={`mask-password`}
                                                       onKeyDown = {handleKeyDown}
                                                       name="phone" 
                                                       placeholder="" />
                                        <img className='pointer' onClick={searchPhone} src={require(`../../assets/images/search.png`).default} alt="search" />
                                    </div>
                                }
                                <p className="error" ref={phoneError}>Numero inválido. Por favor, revise e digite novamente.</p>
                                <p className={!allow ? 'label hidden' : 'label'}>Nome Completo</p>
                                {allow && <input className="input" type="text" defaultValue={name && name.includes("**") ? " - " : name}/>}
                                {/* <p className={!allow ? 'label hidden' : 'label'}>Endereço da sede</p>
                                {allow && <input className="input" type="text" value={address && address} disabled={true} />} */}
                                <p className={!allow ? 'label hidden' : 'label'}>Telefone</p>
                                {allow && <input className="input" type="text" value={phone && phone} disabled={true} />}
                                <p className={!allow ? 'label hidden' : 'label'}>E-mail</p>
                                {allow && <input className="input" type="email" value={email && email} disabled={true} />}
                                <p className={!allow ? 'label hidden' : 'label'}>Senha</p>
                                {allow &&
                                <div>
                                    <div className="pass-holder input">
                                        <input className='mask-password' type={showPass ? "text" : "password"} placeholder="Senha" onChange={handlePasswordChange}/>
                                        <img className='pointer' onClick={togglePass} src={require(`../../assets/images/${showPass ? 'hide_pass' : 'show_pass'}.png`).default} alt="success" />
                                    </div>
                                    {/* <div style={{ height: '5px', width: '80%', backgroundColor: 'red', marginTop: '5px' }}></div>
                                    <p style= {{color:"red"}} >Senha Fraca</p> */}
                                    <div style={{ marginTop: '5px', height: '5px', width: '100%', backgroundColor: color, borderRadius:'5px' }} hidden={passwordStrength == 0 ? true : false}></div>
                                    <p style={{ color, fontWeight:'bold' }} hidden={passwordStrength == 0 ? true : false}>{message}</p> {/* Dynamic message */}
                                </div>
                                }
                                <p className="error" ref={verifyPasswordError} >Sua senha deve ter entre 4 e 16 caracteres.</p>
                                <p className={!allow ? 'label hidden' : 'label'}>Confirme a Senha</p>
                                {allow &&
                                    <div className="pass-holder input" >
                                        <input className='mask-password' ref={confPassword} type={showPassConf ? "text" : "password"} placeholder="Confirme a Senha" onChange={confirmPassword} />
                                        <img className='pointer' onClick={togglePassConf} src={require(`../../assets/images/${showPassConf ? 'hide_pass' : 'show_pass'}.png`).default} alt="success" />
                                    </div>
                                }
                                <p className="error" ref={confPasswordError} >As senhas não coincidem</p>
                                {
                                    allow &&
                                    <div className="flex-row-nowrap mt-15">
                                        <input className="input" type="checkbox" ref={terms_use} />
                                        <p className={!allow ? 'terms-conditions' : 'terms-conditions'}>Confirmo que li e aceito os <a className="reset red" href="/terms" target="_blank">termos de uso. </a></p>
                                    </div>
                                }
                                {
                                    allow &&
                                    <div className="flex-row-nowrap mt-15">
                                        <input className="input" type="checkbox" ref={terms_privacy} />
                                        <p className={!allow ? 'terms-conditions' : 'terms-conditions'}>Confirmo que li e aceito as<a className="reset red" href="/privacy" target="_blank"> políticas de privacidade</a> e as <a className="reset red" href="/exchange" target="_blank">politicas de troca e garantia.</a></p>
                                    </div>
                                }
                                <p className="error" ref={termsError}>Por favor, leia e aceite os termos de uso.</p>
                                {
                                    allow &&
                                    <div className="flex-row-nowrap mt-15">
                                        <input className="input" type="checkbox" ref={terms_marketing} />
                                        <p className={!allow ? 'terms-conditions' : 'terms-conditions'}>Quero receber notificações do AZPartes por e-mail. (optional)</p>
                                    </div>
                                }
                                {
                                    loading &&
                                    <div className="loader-wrapper">
                                        <div className='rotator-wrapper'>
                                            <Loader color="#b0b1b2" />
                                        </div>
                                    </div>
                                }
                                {
                                    allow &&
                                    <input type="submit" className={`action-button margin-top-20 input ${submitEnabled ? '' : 'disabled'}`} value={"CONCLUIR CADASTRO"} onClick={handleSubmit} disabled={!submitEnabled} />
                                }
                                {
                                    allow &&
                                    <p className='text-center light-grey pointer' onClick={()=>history.push('/')}>Cancelar</p>
                                }

                                {/* <h5>Precisa de ajuda?</h5>
                            <Link className="reset" to={'/'}>
                                <p>Outros problemas com o acesso</p>
                            </Link> */}
                            </form>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default Registration; 