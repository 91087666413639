import './style.css';
import { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from 'utils/ContextProvider';
import GlobalFuncs from 'utils/GlobalFuncs';
import Loader from 'components/Loader';
import API from 'utils/API';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import * as XLSX from 'xlsx'

function OrderBox(props) {
    const history = useHistory();
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [orderDetails, setOrderDetails] = useState(false);
    const [reorderStatus, setReorderStatus] = useState(false);

    useEffect(() => {
        if(props.order){
            props.order.content.forEach((e) => {
                if(!e.product_id){
                    setReorderStatus(true)
                }
            });
        }
    }, [props])


    useEffect(() => {
        if (orderDetails.length > 0) {
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.aoa_to_sheet(orderDetails);
            worksheet["!cols"] = [{ wch: 30 }, { wch: 10 }, { wch: 30 }, { wch: 10 }, { wch: 20 }, { wch: 30 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Order');
            Swal.fire({
                title: 'Sucesso',
                text: 'Ordem exportada com sucesso.',
                confirmButtonColor: "#1e2b3c",
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            XLSX.writeFile(workbook, `order-${props.order.order_id}.xlsx`);
            setOrderDetails(false)
        }
    }, [orderDetails]);

    const downloadExcelOrder = (e) => {
        const cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Exportando pedido...',
            showConfirmButton: false,
            allowOutsideClick: false
        })
        API.getOrder(props.order.order_id, cancelToken).then(res => {
            const order = res.data
            if (res.data) {
                const total_order_value = order['total_amount']
                const shipping_cost = order['shipping_cost']
                var total_fees = 0
                if (order["payment_method"]["payment_method_id"] == 2) {
                    total_fees = total_order_value * 0.0227
                }
                var arr = [['Número do Pedido', 'Linha', 'Nota Fiscal', 'Código do Produto', 'Quantidade', 'Preço Unitário', 'Preço Total', 'Previsão de Faturamento', 'Data de Faturamento', 'Status', 'Status da entrega', 'Frete', 'Taxas', 'Total']]
                if (order.lines.length > 0) {
                    order.lines.forEach(product => {
                        var item_price = ''
                        var total_price = ''
                        order.products.forEach(p => {
                            if (p['skf_product_id'].localeCompare(product['product']['skf_product_id']) == 0) {
                                try {
                                    item_price = GlobalFuncs.priceToReal(p['item_price'])
                                    total_price = GlobalFuncs.priceToReal(p['item_price'] * p['quantity'])
                                } catch {
                                    console.log("Not able to convert item_price to real")
                                }
                            }
                        })
                        var invoice_number = ''
                        if (product['NF']['number'] != null && product['NF']['number'] != '') {
                            invoice_number = product['NF']['number']
                        }
                        var billing_expected_date = ''
                        if (!product['billing_expected_date'].includes('0000')) {
                            billing_expected_date = GlobalFuncs.databaseToDate(product['billing_expected_date'])
                        }
                        var billing_date = ''
                        if (!product['billing_date'].includes('0000')) {
                            billing_date = GlobalFuncs.databaseToDate(product['billing_date'])
                        }
                        arr.push(
                            [order['skf_order_id'],
                            `${product['line']}${product['split_id']}`,
                                invoice_number,
                            product['product']['skf_product_id'],
                            `${product['quantity']}`,
                                item_price,
                                total_price,
                            `${billing_expected_date}`,
                            `${billing_date}`,
                            product['status'],
                            product['delivery'],
                            `${shipping_cost}`,
                            GlobalFuncs.priceToReal(total_fees),
                            GlobalFuncs.priceToReal(total_order_value + total_fees)
                            ])
                    });
                } else {
                    var item_price = ''
                    order.products.forEach(product => {
                        order.products.forEach(p => {
                            if (p['skf_product_id'].localeCompare(product['skf_product_id']) == 0) {
                                try {
                                    item_price = GlobalFuncs.priceToReal(p['item_price'])

                                } catch (error) {
                                    item_price = ''
                                }
                            }
                        })
                        arr.push(
                            [
                                "",
                                "",
                                "",
                                product['skf_product_id'],
                                `${product['quantity']}`,
                                item_price,
                                ``,
                                ``,
                                `Em processamento`,
                                '',
                                GlobalFuncs.priceToReal(total_fees),
                                GlobalFuncs.priceToReal(total_order_value + total_fees)
                            ])
                    });

                }

                setOrderDetails(arr)
            } else {
                setOrderDetails(null);
            }
        }).catch(err => {
            if (err.message === 'cancelling') return
            console.log(err);
            Swal.fire({
                title: 'Erro.',
                text: 'Tente novamente mais tarde.',
                confirmButtonColor: "#131921",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        });
    }

    const reorder = async (e) => {
        const cancelToken = API.cancelToken();
        Swal.fire({
            title: 'Verificando pedido...',
            showConfirmButton: false,
            allowOutsideClick: false
        })
        var productArray = []
        var arr = []
        API.validateOrderPricelist(cancelToken, props.order.order_id).then(async res => {
            if (res.data.products !== "") {
                productArray = res.data.products.split(', ').map(productId => {
                    return productId;
                });
                Swal.fire({
                    html: `Informamos que o(s) produto(s) abaixo não pertence(m) ao seu portfolio disponível. </br>
                    ${res.data.products} </br>
                    Deseja continuar sem o registro deste(s)?
                    `,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: "#131921",
                    cancelButtonColor: "#9badc4",
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async result => {
                    if (result.isConfirmed) {
                        console.log(productArray)
                        setLoading(true);
                        for (let item of props.order.content) {
                            try {
                                if (item.skf_product_id in productArray) {
                                    console.log(`Adding Product ${item.skf_product_id}...`)
                                    await API.addToCart(item.product_id, item.quantity);
                                } else {
                                    console.log(`Product ${item.skf_product_id} not in array`)
                                }
                            } catch (e) {
                                arr.push(item.product_id)
                            }
                        }
                        if (arr.length > 0) {
                            Swal.fire({
                                html: `Falha ao adicionar: ${arr.map(e => `<p>${e}</p>`).join('')}`,
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: "#131921",
                                confirmButtonText: 'Ok',
                            }).then(async result => {
                                history.push('/cart');
                            });
                        } else {
                            history.push('/cart');
                        }
                    }
                });
            } else {
                Swal.close();
                setLoading(true);
                for (let item of props.order.content) {
                    try {
                         await API.addToCart(item.product_id, item.quantity);
                    } catch (e) {
                        arr.push(item.product_id)
                    }
                }
                if (arr.length > 0) {
                    Swal.fire({
                        html: `Falha ao adicionar: ${arr.map(e => `<p>${e}</p>`).join('')}`,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: "#131921",
                        confirmButtonText: 'Ok',
                    }).then(async result => {
                        history.push('/cart');
                    });
                } else {
                    history.push('/cart');
                }
            }
        }).catch(err => {
            Swal.fire({
                html: `Falha ao adicionar: ${arr.map(e => `<p>${e}</p>`).join('')}`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: "#131921",
                confirmButtonText: 'Ok',
            })
        })
    }


    return (

        props.loader === true || loading ?
            <div className="order-listing-mobile margin-bottom-20" style={{ 'position': 'relative', 'border': '0', 'height': '260px' }}>
                <Loader light={true} />
            </div>
            :
            <div className="order-listing margin-bottom-20">
                <div className="header flex-row-nowrap space-between">
                    <div className="first-box flex-row">
                        <div>
                            <p className="title">Data do Pedido</p>
                            <p>{props.order && GlobalFuncs.databaseToDate(props.order.created_at)}</p>
                        </div>
                        <div>
                            <p className="title">Valor Total</p>
                            <p>{props.order && GlobalFuncs.priceToReal(props.order.total)}</p>
                        </div>
                    </div>
                    <div className="first-box flex-row">
                        <div>
                            <p className="title">Pedido Nº {props.order.skf_order_id}</p>
                            {
                                (context.getSub_business_segment === "VA Distributor") &&
                                <p className="title">Número do Pedido do Cliente: {props.order.client_order_id}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="content flex-row space-between">
                    <div className="section1 flex-row-nowrap align-center">
                        <img src={props.order.image} alt={props.order.skf_order_id} />
                        <div>
                            <p className="product-name">{props.order.name}</p>
                            <p className="status margin-top-20">Status: <span className={props.order.status === "Pedido Concluído" ? "label label-success" : (props.order.status === "Pagamento Não Autorizado" || props.order.status === "Aguardando Pagamento" || props.order.status === "Aguardando Registro COH" ? "label label-yellow" : (props.order.status === "Em Processamento" ? "label label-warning" : (props.order.status === "Pedido Cancelado" || props.order.status === "Pagamento Expirado" ? "label label-danger" : "label label-info")))}>{props.order.status}</span></p>
                            {/* <button className="buy-again" onClick={(e) => reorder(e)}>Comprar Novamente</button> */}
                        </div>
                    </div>
                    <div className="section2">
                        <button className={`button1 ${reorderStatus ? "disabled":""}`} disabled={reorderStatus} onClick={(e) => reorder(e)}>Comprar Novamente</button>
                        {/* <Link className="reset" to="/">
                                <button className="button2">Avaliar Produto</button>
                            </Link> */}
                        <Link className="reset" to={`/pedido/${props.order.order_id}`}>
                            <button className="button2">Exibir Detalhes</button>
                        </Link>
                        {props.order.status === "Pedido Concluído" || props.order.status === "Em Processamento" ? <button className="button2" onClick={(e) => downloadExcelOrder(e)}>Baixar Pedido em Excel </button> : ''}

                    </div>
                </div>
            </div>
    )
}

export default OrderBox;